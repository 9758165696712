/* eslint-disable max-len */
/* eslint-disable import/prefer-default-export */
/* eslint-disable-next-line no-unused-vars */
import React from 'react';

export const locale = {
  "locales": ["it"],
  "messages": {
    "editor": {
      "first_name": "Nome",
      "name": "Nome",
      "subject": "Oggetto",
      "message": "Messaggio",
      "custom_dropdown": "Discesa personalizzata",
      "custom_label": "Etichettatura propria",
      "set_autoplay": "Autoplay",
      "autoplay_info": "Attenzione! Alcuni browser non consentono l'autoplay nella maggior parte dei casi. Tuttavia, l'autoplay silenzioso è sempre consentito.",
      "set_muted": "Muto",
      "data_protection_officer": "Responsabile della protezione dei dati",
      "data_protection_authority": "Autorità per la protezione dei dati",
      "button_font_color": "Colore del carattere",
      "set_label_dropdown": "Assegnare un'etichetta per il menu a tendina",
      "set_label_custom_field": "Assegnare un'etichetta per il campo di immissione",
      "empty_field": "Campo libero",
      "custom_field": "Campo definito dall'utente",
      "change_label": "Modifica dell'etichetta",
      "edit_label": "Modifica etichetta",
      "salutation": "Saluto",
      "title": "Titolo",
      "link_title": "Nome del link",
      "main_text": "Testo principale",
      "more_text": "ulteriore testo",
      "create_text": "Creare testo",
      "edit_text_slider": "Non è stato ancora creato alcun elemento di testo",
      "edit_rss_elements": "Non sono ancora stati selezionati elementi stampo, fare clic qui per aggiungerli.",
      "rss_settings": "Impostazioni del feed RSS",
      "select_rss": "È possibile selezionare un feed RSS dai modelli",
      "available_rss": "Feed RSS disponibili",
      "load_rss_part_1": "È stato selezionato il seguente feed RSS.",
      "load_rss_part_2": "Fare clic sul pulsante \"Ottieni feed RSS\" per caricare i feed.",
      "load_rss_feeds": "Recuperare i feed RSS",
      "color": "Colore",
      "background_color": "Colore di sfondo",
      "distance_to_border": "Distanza dal bordo",
      "color_of_qrcode": "Colore del codice QR",
      "click_me": "Clicca su di me",
      "new_menu_item": "Nuova voce di menu",
      "external": "esterno",
      "imprint": "Impronta",
      "privacy": "Protezione dei dati",
      "january": "Gennaio",
      "february": "febbraio",
      "march": "Marzo",
      "april": "Aprile",
      "may": "Maggio",
      "june": "Giugno",
      "july": "Luglio",
      "august": "Agosto",
      "september": "Settembre",
      "october": "ottobre",
      "november": "Novembre",
      "december": "Dicembre",
      "monday": "Lunedì",
      "tuesday": "Martedì",
      "wednesday": "Mercoledì",
      "thursday": "Giovedì",
      "friday": "Venerdì",
      "saturday": "Sabato",
      "sunday": "Domenica",
      "edit_date": "Modifica appuntamento",
      "oclock": "Orologio",
      "filter_selection": "Selezione del filtro",
      "use_filter": "Applicare",
      "sample_picture_found": "Immagine di esempio trovata",
      "already_edited_in_another_tab": "Questa edizione potrebbe essere già stata modificata in un'altra scheda.",
      "information_for_contact": "Dati per il modulo di contatto",
      "mail_receiver": "Destinatario dell'e-mail",
      "google_analytics": "Google Analytics",
      "measurement_id": "ID di misurazione per Google Analytics",
      "new_picture_inserted": "Nuova immagine inserita",
      "text_changed": "Testo modificato",
      "edit_title": "Fare clic qui per modificare il titolo",
      "edit_description": "Fare clic qui per modificare la descrizione dell'immagine",
      "edit_copyright": "Fare clic qui per modificare il copyright",
      "no_changes": "Nessuna modifica",
      "changes_on_document": "Modifiche al documento",
      "changes_on_page": "Modifiche a pagina",
      "page": "Pagina",
      "choose_button_label": "Selezionare un'etichetta per il pulsante",
      "edit_calendly": "Fare clic qui per modificare le impostazioni di Calendly",
      "exchanged_with_page": "scambiato con la pagina",
      "upload_failed": "Caricamento fallito",
      "try_again": "Si prega di riprovare",
      "saving_failed": "Salvataggio fallito",
      "new_page_inserted": " nuova/e pagina/e inserita/e dopo la pagina",
      "delete_pages": "Cancellare le pagine",
      "all_content_deleted": "Le pagine e tutti i loro contenuti saranno eliminati.",
      "yes_delete": "Sì, eliminare",
      "pages_deleted": " Pagina/e eliminata/e, per pagina",
      "rather_not": "Meglio non",
      "new_here": "Sei nuovo qui?",
      "first_start": "Sembra che abbiate aperto il nostro nuovo editor per la prima volta. Desidera un'introduzione?",
      "yes_absolutely": "Sì, assolutamente",
      "im_fine": "Posso gestire",
      "no_page_definitions_found": "Nessuna definizione di pagina trovata!",
      "move_up": " Spostare le pagine in alto",
      "side_position_fixed": "Posizione laterale fissa",
      "generated_in_pdf": "Le pagine vengono generate automaticamente nel PDF",
      "move_down": " Spostare le pagine in basso",
      "by": "A",
      "add_page": " Inserire le pagine",
      "remove_page": " Rimuovere le pagine",
      "unsaved_changes": "Le modifiche non sono state salvate",
      "i_have_it": "Ok, ora ho capito!",
      "document_pictures": "Immagini nel documento",
      "used_images": "Immagini utilizzate",
      "take_a_photo": "Scattare una foto",
      "unused_images": "Immagini non utilizzate",
      "content_is_saved": "I contenuti vengono salvati",
      "page_arrangement": "Disposizione laterale",
      "print_sheet_view": "Visualizzazione foglio di stampa",
      "view_safety_distance": "Vista della distanza di sicurezza",
      "view_structure": "Vista della struttura",
      "start_tutorial": "Avvio del tutorial",
      "adjust_by_window": "Adattare la pagina alla finestra",
      "adjust_by_width": "Adattare la pagina alla larghezza",
      "adjust_by_height": "Regolare la pagina in altezza",
      "delete_content": "Eliminare il contenuto",
      "delete_selected_content_block": "Rimuove il blocco di contenuti selezionato e i suoi contenuti (immagini, testi).",
      "delete_children_of_parents": "Si noti che questo è un elemento genitore. Se si elimina questo elemento, verranno eliminati anche tutti gli elementi figli associati.",
      "reset_content": "Azzeramento del contenuto",
      "reset_content_block_to_root": "Riporta il blocco di contenuti selezionato e i suoi contenuti (immagini, testi) allo stato originale.",
      "yes_reset": "Sì, reset",
      "copy_full_content": "Copiare il contenuto dell'intera pagina",
      "paste_content": "Incolla il contenuto dagli appunti",
      "enlarge_block": "Ingrandire il blocco",
      "shrink_block": "Ridurre la dimensione del blocco",
      "text_overflow": "Testo in overflow",
      "initial_image_available": " Immagine iniziale disponibile",
      "paste_content_after": "Incollare il contenuto dagli appunti dopo questi",
      "deselect_element": "Deselezionare l'elemento",
      "select_element": "Selezionare l'elemento",
      "block_can_not_move": "Il blocco non può essere spostato",
      "up": "In alto",
      "reset_block": "Blocco di reset",
      "change_backgroundcolor": "Cambiare il colore dello sfondo",
      "change_fontcolor": "Cambiare il colore dei caratteri",
      "copy_block": "Blocco copia",
      "delete_block": "Cancellare il blocco",
      "down": "Verso il basso",
      "product_in_cart": "Prodotto nel carrello",
      "added_to_cart": " è stato aggiunto al tuo carrello.",
      "great": "Grande!",
      "to_cart": "Al carrello della spesa",
      "are_you_sure": "Sei sicuro?",
      "reset_to_selected_status": "Il documento viene riportato allo stato selezionato",
      "abort": "Annullamento",
      "reset_to_version": "Ripristino della versione",
      "choose_a_campaign": "Selezionare la campagna",
      "name_for_edition": "Nome per questa edizione",
      "save_to": "Salva con nome...",
      "save": "Risparmiare",
      "settings": "Impostazioni",
      "short_summary": "Breve sintesi",
      "keywords_separated": "Parole chiave separate da una virgola",
      "serial_letter": "Unione della posta",
      "activate_serial_letter": "Attivare l'unione della posta",
      "serial_letter_database": "Database per l'unione della posta",
      "serial_letter_database_upload_info": "Il caricamento supporta i seguenti formati di file: .xlsx, .xls e .csv.",
      "serial_letter_info": "Inserire i segnaposto nelle celle della prima riga del database. Sotto, inserire i rispettivi valori. Ad esempio, come segue:",
      "preview_of_database": "Anteprima del database",
      "serial_letter_database_upload": "Caricare",
      "no_error": "Senza errori",
      "error": "Errore",
      "information_for_imprint": "Informazioni per l'impronta",
      "responsible_for_content": "Responsabile dei contenuti",
      "company_association": "Azienda / Associazione",
      "street": "Via",
      "zip_code": "Codice postale",
      "city": "Luogo",
      "email": "Indirizzo e-mail",
      "phone_number": "Telefono",
      "data_protection_information": "Informazioni sulla protezione dei dati",
      "responsible": "Persona responsabile",
      "change": "Cambiamento",
      "export_image": "Esportazione dell'immagine",
      "preview": "Anteprima",
      "preview_button": "Anteprima del pulsante",
      "print_pdf": "Stampa PDF",
      "initialize": "Inizializzare!",
      "order": "Ordine",
      "versions": "Versioni",
      "currently_used_image": "Immagine attualmente utilizzata",
      "upload_new_image": "Caricare una nuova immagine",
      "new_cut": "Recut",
      "take_on": "Rilevare",
      "choose_file": "Selezionare il file",
      "image_description": "Descrizione dell'immagine",
      "image_upload": "Caricamento dell'immagine",
      "mediathek": "Mediateca",
      "pixabay": "Pixabay",
      "add_content": "Aggiungi contenuto",
      "copied_content_block": "Blocco di contenuto copiato",
      "unsaved_content": "Le modifiche non sono state salvate! Vuoi davvero lasciare l'editor?",
      "filter_settings": "Impostazioni del filtro",
      "filter_grayscale": "Scala di grigi",
      "filter_sepia": "Seppia",
      "filter_blur": "sfocatura",
      "filter_brightness": "Luminosità",
      "filter_contrast": "Contrasto",
      "filter_hueRotate": "Ruotare la tonalità del colore",
      "filter_invert": "Invertire i colori",
      "filter_opacity": "Trasparenza",
      "filter_saturate": "Saturazione",
      "filter_default": "Standard",
      "filter_set": "Non più in uso",
      "work_surface": "Qui si trova il piano di lavoro.",
      "work_surface_description": "Qui si svolge il processo di progettazione. Qui si possono inserire e modificare immagini, testi ed elementi.",
      "page_navigation": "Qui trovate la navigazione della pagina",
      "page_navigation_description": "Qui è possibile passare da una pagina all'altra e aggiungere, rimuovere o scambiare le pagine.",
      "content_blocks": "Qui si trovano i blocchi di contenuto",
      "content_blocks_description": "Qui è possibile inserire vari elementi di immagine e di testo nel materiale pubblicitario facendo clic su di essi. Se il materiale pubblicitario non dispone di spazio sufficiente, è possibile eliminare in qualsiasi momento gli elementi superflui.",
      "screen_view": "Qui è possibile modificare la visualizzazione dello schermo.",
      "screen_view_description": "Qui potete cambiare la visualizzazione dei vostri media web. Il 100% rappresenta sempre la dimensione originale del materiale pubblicitario.",
      "error_display": "Gli errori vengono visualizzati qui.",
      "error_display_description": "Il sistema segnala all'utente un overflow di testo o altri errori tecnici.",
      "options": "Qui troverete le opzioni per il vostro materiale pubblicitario.",
      "options_description": "Cliccate sul pulsante di anteprima per generare un PDF di anteprima o sul pulsante di ordine per inserire il materiale pubblicitario nel carrello. Con \"Salva con nome\" è possibile copiare il materiale pubblicitario in un'altra campagna.",
      "tutorial_end": "Questo era il nostro editore in poche parole!",
      "tutorial_end_description": "Ora siete in grado di progettare in modo rapido e semplice il vostro materiale pubblicitario in conformità con il design aziendale.",
      "content_block_copied": "Blocco di contenuto copiato",
      "content_blocks_switched": "Blocchi di contenuto scambiati",
      "content_block_reset": "Reset del blocco di contenuti",
      "content_block_resize": "Modifica delle dimensioni dei blocchi di contenuto",
      "content_block_removed": "Blocco di contenuti cancellato",
      "no_place": "Non c'è spazio",
      "content_block_inserted": "Blocco contenuto inserito",
      "no_dates_available": "Non ci sono date disponibili, fare clic qui per aggiungerne una.",
      "new_date": "Nuova data",
      "edit_dates": "Modifica appuntamenti",
      "date_description": "Descrizione della nomina",
      "add": "Aggiungi",
      "no_dates_availableTwo": "Nessuna data disponibile",
      "date_title": "Titolo della nomina",
      "date_place": "Luogo dell'appuntamento",
      "date_from": "Am/Dn",
      "date_to": "Fino a quando",
      "no_forms": "Non sono ancora stati selezionati elementi stampo, fare clic qui per aggiungerli",
      "read_privacy_policy": "Ho letto e compreso l'informativa sulla privacy.",
      "contact_form_settings": "Impostazioni del modulo di contatto",
      "dropdown_settings": "Impostazioni del menu a tendina",
      "add_options": "Aggiungi opzioni",
      "dropdown_label": "Etichetta a discesa",
      "dropdown_option_fields": "Pulsanti di opzione a discesa",
      "pls_choose": "Selezionare un elemento del modulo",
      "pls_chooseTwo": "Selezionare gli elementi del modulo di contatto desiderato",
      "send": "Invio",
      "google_maps_element_settings": "Impostazioni di posizione Google Maps",
      "no_location": "Non avete ancora aggiunto un indirizzo, cliccate qui per modificarlo.",
      "found_textoverflow": "Trovato overflow di testo",
      "minimum_size": "Dimensione minima",
      "too_low_resolution": "L'immagine ha una risoluzione troppo bassa!",
      "set_scale_free": "Attivare la scalatura gratuita",
      "unset_scale_free": "Disattivare il ridimensionamento gratuito",
      "no_images_selected": "Non sono ancora state selezionate immagini, fare clic qui per aggiungerle",
      "gallery_settings": "Impostazioni della galleria",
      "add_image": "Aggiungi immagine",
      "add_image_front": "Aggiungere un'immagine all'inizio",
      "add_image_back": "Aggiungere l'immagine alla fine",
      "slider_images_invert": "Invertire l'ordine delle immagini",
      "image_text_settings": "Impostazioni del testo dell'immagine",
      "change_slider": "Per modificare il titolo o la descrizione, fare clic sul rispettivo testo nel dispositivo di scorrimento.",
      "change_image_title": "Modifica del titolo dell'immagine",
      "change_image_description": "Modifica della descrizione dell'immagine",
      "image_copyright": "Copyright immagine",
      "slider_settings": "Impostazioni del cursore",
      "new_titel": "Nuovo titolo",
      "new_link_title": "Titolo del nuovo link",
      "edit_text_title": "Modifica del titolo",
      "edit_text_link": "Modifica il link",
      "edit_link_title": "Modifica del titolo del link",
      "edit_text_text": "Modifica del testo",
      "add_element": "Aggiungi elemento",
      "add_element_before": "Aggiungere un elemento nella parte anteriore",
      "add_element_after": "Aggiungere un elemento sul retro",
      "edit_text_of_element": "Per modificare i testi, fare clic sul rispettivo testo nel dispositivo di scorrimento.",
      "invert_elements": "Invertire la sequenza",
      "edit_nav": "Modifica della navigazione",
      "term": "Designazione",
      "link": "Collegamento",
      "actions": "Azioni",
      "content_of_QRCode": "Quali sono i contenuti da includere nel codice QR?",
      "address_of_website": "Inserire l'indirizzo del sito web:",
      "name_surname": "Nome e cognome",
      "business_card": "Biglietto da visita",
      "must_url": "Deve essere un URL",
      "position": "Posizione",
      "phone": "Telefono",
      "website": "Sito web",
      "address": "Indirizzo",
      "email_message": "Messaggio e-mail",
      "text": "Testo",
      "recipient_email_address": "Indirizzo e-mail del destinatario",
      "reference": "Oggetto",
      "content": "Contenuti",
      "your_text": "Il vostro testo",
      "add_QRCode": "Inserire il codice QR",
      "no_preview_of_twitter": "Purtroppo non è possibile visualizzare Twitter in questa pagina, si prega di utilizzare la funzione di anteprima del sito web.",
      "no_preview_of_calendly": "Purtroppo non è possibile visualizzare Calendly in questa pagina, si prega di utilizzare la funzione di anteprima del sito web.",
      "twitter_link": "Link Twitter pubblicato:",
      "social_media_settings": "Impostazioni dei social media",
      "calendly_settings": "Impostazioni di Calendly",
      "button_settings": "Impostazioni dei pulsanti",
      "action_button_content_text": "Fare clic qui per modificare le impostazioni del pulsante azione",
      "action_button_link_element": "Riferimento a un elemento",
      "action_button_external_link": "Riferimento a un link esterno",
      "action_button_or": "o",
      "button_color": "Colore del pulsante",
      "add_social_media_account": "Non è stato ancora aggiunto alcun account di social media, fare clic qui per selezionarne uno.",
      "choice_of_provider": "Selezione dei fornitori di social media",
      "embed_code": "Codice di incorporamento da un canale di social media",
      "link_to_your_page": "Link alla vostra pagina su",
      "link_to_your_calendly_page": "Collegamento alla vostra pagina Calendly",
      "link_to_your_page_two": "Link alla vostra pagina",
      "video_settings": "Impostazioni video",
      "add_video": "Non è ancora stato selezionato alcun video, fare clic qui per inserirne uno",
      "choice_video_provider": "Selezione di fornitori di video",
      "upload_video": "Caricare il video",
      "choice_mp4_file": "Selezionare il file video (.mp4)",
      "nav_type": "Tipo",
      "nav_type_url": "URL",
      "nav_type_page": "Pagina",
      "nav_type_page_prefix": "Pagina"
    },
    "medialibrary": {
      "new_folder": "Nuova cartella",
      "sorting": "Ordinamento",
      "directories": "Elenchi",
      "medialibrary": "Mediateca",
      "media": "Media",
      "upload_media": "Caricare i media",
      "sorting_description": "Si noti che l'ordinamento si basa sul nome del file e non sul titolo definito.",
      "sorting_info_text": "Selezionare un ordine per le cartelle e i media. L'ordinamento impostato si applica anche a tutte le visualizzazioni delle cartelle.",
      "paste": "Inserire",
      "info": "Attenzione!",
      "info_text": "Uno o più file esistono già. Non sono stati caricati di nuovo.",
      "back_to_root": "Tornare alla directory principale",
      "directory_by": "Elenco di",
      "created_by": "Creato da",
      "no_folder_created": "Non sono state create cartelle",
      "no_media_found": "Nessun supporto nella cartella",
      "upload_processing": "I vostri upload vengono elaborati",
      "not_uploaded": "Non è stato possibile caricare i media!",
      "close": "Chiudere",
      "preview": "Anteprima immagine/video",
      "name": "Nome",
      "upload_info": "Info",
      "options": "Opzioni",
      "status": "Stato",
      "adjust_meta": "Personalizzare i metadati",
      "title": "Titolo",
      "description": "Descrizione del",
      "save": "Risparmiare",
      "create_new_directory": "Creare una nuova directory",
      "directory_name": "Nome della directory",
      "rename_folder": "Rinominare la cartella",
      "copyright": "Copyright",
      "upload": "Caricare",
      "wastebasket": "Cestino per la carta straccia",
      "are_you_sure_erase_file": "Siete sicuri di voler eliminare definitivamente il file?",
      "are_you_sure_erase_folder": "Siete sicuri di voler eliminare la cartella in modo irrevocabile?",
      "erase": "Cancellare"
    },
    "meta": {
      "campaigns": {
        "title": "Campagne",
        "description": "Campagne",
        "name": "Campagne"
      },
      "productShop": {
        "title": "Negozio",
        "description": "State cercando del merchandising? Allora siete nel posto giusto.",
        "name": "Articolo"
      },
      "carShop": {
        "title": "Negozio di veicoli",
        "description": "State cercando l'auto dei vostri sogni? Allora date subito un'occhiata ai nostri veicoli.",
        "name": "Veicoli"
      },
      "car_detail_search": {
        "title": "Non sei sicuro dell'auto che fa per te? Utilizzate la nostra ricerca auto per trovare l'auto perfetta",
        "description": "State cercando un'auto con requisiti specifici, ma non sapete esattamente quale auto vi serve? Selezionate il tipo di veicolo, la trasmissione e il livello di equipaggiamento.",
        "name": "Ricerca dettagliata"
      },
      "car_brands": {
        "title": "Seleziona il tuo marchio",
        "description": "Scegliete il vostro marchio e scoprite le migliori offerte del mercato.",
        "name": "Selezione del marchio"
      },
      "car_models": {
        "title": "Selezionate il modello della vostra {brandName}",
        "description": "Scegliete un modello tra i più popolari {modelloUno} e {modelloDue}.",
        "name": "Modelli"
      },
      "car_model_drive": {
        "title": "Selezionare il tipo di carburante e la trasmissione per il vostro {brandName} {modelName}",
        "description": "Abbiamo il {nome marca} {nome modello} con motori {tipo di carburante}. I tipi di cambio disponibili sono {gearboxTypes}.",
        "name": "Tipo di carburante"
      },
      "car_model_equipment": {
        "title": "Selezionare il livello di equipaggiamento per il vostro {brandName} {modelName}",
        "description": "Scegliete tra Budget, Family, Comfort o Business. La più popolare, Comfort, offre sensori di parcheggio, Bluetooth e altro ancora.",
        "name": "Attrezzatura"
      },
      "favorites": {
        "title": "I vostri preferiti, salvati per il futuro",
        "description": "È fantastico che abbiate conservato alcuni dei nostri articoli migliori per un secondo momento. Ora provate a selezionare quello che desiderate.",
        "name": "I preferiti"
      },
      "cart": {
        "title": "Cestino della spesa",
        "description": "Cestino della spesa",
        "name": "Cestino della spesa"
      },
      "checkout": {
        "title": "Cassa",
        "description": "Cassa",
        "name": "Cassa"
      },
      "clients": {
        "title": "Clienti",
        "description": "Clienti",
        "name": "Clienti"
      },
      "client_details": {
        "title": "Azienda",
        "description": "Azienda",
        "name": "Azienda"
      },
      "leads": {
        "title": "Conducenti",
        "description": "Conducenti",
        "name": "Conducenti"
      },
      "lead_details": {
        "title": "Piombo",
        "description": "Piombo",
        "name": "Piombo"
      },
      "cars": {
        "title": "Veicoli",
        "description": "Veicoli",
        "name": "Veicoli"
      },
      "car_overview": {
        "title": "Panoramica del veicolo",
        "description": "Panoramica del veicolo",
        "name": "Panoramica del veicolo"
      },
      "car_details": {
        "title": "Veicolo",
        "description": "Veicolo",
        "name": "Veicolo"
      },
      "pages": {
        "title": "Pagina",
        "description": "Pagina",
        "name": "Pagina"
      },
      "page_details": {
        "title": "Pagina",
        "description": "Pagina",
        "name": "Pagina"
      },
      "profile": {
        "title": "Profilo",
        "description": "Profilo",
        "name": "Profilo"
      },
      "locations": {
        "title": "Luoghi",
        "description": "Luoghi",
        "name": "Luoghi"
      },
      "company_locations": {
        "title": "Luoghi",
        "description": "Luoghi",
        "name": "Luoghi"
      },
      "data_push": {
        "title": "Spinta dei dati",
        "description": "Spinta dei dati",
        "name": "Spinta dei dati"
      },
      "white_label_projects": {
        "title": "Progetti white label",
        "description": "Progetti white label",
        "name": "Progetti white label"
      },
      "users": {
        "title": "Utenti",
        "description": "Utenti",
        "name": "Utenti"
      },
      "user_details": {
        "title": "Utenti",
        "description": "Utenti",
        "name": "Utenti"
      },
      "user_import": {
        "title": "Importazione di utenti",
        "description": "Importazione di utenti",
        "name": "Importazione di utenti"
      },
      "teams": {
        "title": "Squadre",
        "description": "Squadre",
        "name": "Squadre"
      },
      "questionnaires": {
        "title": "Questionari",
        "description": "Questionari",
        "name": "Questionari"
      },
      "questionnaire_details": {
        "title": "Questionario",
        "description": "Questionario",
        "name": "Questionario"
      },
      "document_types": {
        "title": "Tipi di documenti",
        "description": "Tipi di documenti",
        "name": "Tipi di documenti"
      },
      "document_type_details": {
        "title": "Tipo di documento",
        "description": "Tipo di documento",
        "name": "Tipo di documento"
      },
      "templates": {
        "title": "Modelli",
        "description": "Modelli",
        "name": "Modelli"
      },
      "cms_elements": {
        "title": "Elementi CMS",
        "description": "Elementi CMS",
        "name": "Elementi CMS"
      },
      "cms_element_details": {
        "title": "Elemento CMS",
        "description": "Elemento CMS",
        "name": "Elemento CMS"
      },
      "brands": {
        "title": "Marche",
        "description": "Marche",
        "name": "Marche"
      },
      "models": {
        "title": "Modelli",
        "description": "Modelli",
        "name": "Modelli"
      },
      "categories": {
        "title": "Categorie",
        "description": "Categorie",
        "name": "Categorie"
      },
      "category_details": {
        "title": "Categoria",
        "description": "Categoria",
        "name": "Categoria",
        "authorization_on_mobile_device": "Autorizzazione su dispositivi mobili",
        "hide_category": "Nascondi categoria"
      },
      "attributes": {
        "title": "Attributi",
        "description": "Attributi",
        "name": "Attributi"
      },
      "attribute_details": {
        "title": "Attributo",
        "description": "Attributo",
        "name": "Attributo"
      },
      "attribute_groups": {
        "title": "Gruppi di attributi",
        "description": "Gruppi di attributi",
        "name": "Gruppi di attributi"
      },
      "dynamic_data_definitions": {
        "title": "Definizioni dinamiche dei dati",
        "description": "Definizioni dinamiche dei dati",
        "name": "Definizioni dinamiche dei dati"
      },
      "pipelines": {
        "title": "Condotte",
        "description": "Condotte",
        "name": "Condotte"
      },
      "pipeline_details": {
        "title": "Condotte",
        "description": "Condotte",
        "name": "Condotte"
      },
      "items": {
        "title": "Prodotti",
        "description": "Prodotti",
        "name": "Prodotti"
      },
      "item_details": {
        "title": "Prodotto",
        "description": "Prodotto",
        "name": "Prodotto"
      },
      "calendar": {
        "title": "Calendario",
        "description": "Calendario",
        "name": "Calendario"
      },
      "orders": {
        "title": "Ordini",
        "description": "Ordini",
        "name": "Ordini"
      },
      "settings": {
        "title": "Impostazioni",
        "description": "Impostazioni",
        "name": "Impostazioni"
      },
      "four_zero_four": {
        "title": "404",
        "description": "404",
        "name": "404"
      },
      "account": {
        "title": "Conto",
        "description": "Conto",
        "name": "Conto"
      },
      "forgot_password": {
        "linktext": "Avete dimenticato la password?",
        "title": "Password dimenticata",
        "description": "Password dimenticata",
        "name": "Password dimenticata"
      },
      "renew_password": {
        "title": "Rinnovo della password",
        "description": "Rinnovo della password",
        "name": "Rinnovo della password"
      },
      "new_password_after_reset": {
        "title": "Nuova password dopo il reset",
        "description": "Nuova password dopo il reset",
        "name": "Nuova password dopo il reset"
      },
      "pricing": {
        "title": "Prezzi",
        "description": "Prezzi",
        "name": "Prezzi"
      },
      "signedup": {
        "title": "Registrato",
        "description": "Registrato",
        "name": "Registrato"
      },
      "go_pro": {
        "title": "Vai al professionista",
        "description": "Vai al professionista",
        "name": "Vai al professionista"
      },
      "paypal": {
        "title": "Pagare con Paypal",
        "description": "Pagare con Paypal",
        "name": "Pagare con Paypal"
      },
      "lead_catchers": {
        "title": "Cacciatori di piombo",
        "description": "Cacciatori di piombo",
        "name": "Cacciatori di piombo"
      },
      "bills": {
        "title": "Fatture",
        "description": "Fatture",
        "name": "Fatture"
      },
      "provisionServiceDocuments": {
        "title": "Commissioni"
      },
      "commissionServiceDocuments": {
        "title": "Documenti della Commissione"
      },
      "integrations": {
        "title": "Integrazioni",
        "description": "Integrazioni",
        "name": "Integrazioni"
      },
      "order_complete": {
        "title": "Ordine completato. Grazie mille",
        "description": "Ordine completato. Grazie mille",
        "name": "Ordine completato. Grazie mille"
      },
      "order_details": {
        "title": "Dettagli dell'ordine",
        "description": "Dettagli dell'ordine",
        "name": "Dettagli dell'ordine"
      },
      "my_company": {
        "title": "La mia azienda",
        "description": "La mia azienda",
        "name": "La mia azienda"
      },
      "white_label_plugins": {
        "title": "Plugin a marchio bianco",
        "description": "Plugin a marchio bianco",
        "name": "Plugin a marchio bianco"
      },
      "plugins": {
        "title": "Plugin Creacheck",
        "description": "Plugin Creacheck",
        "name": "Plugin Creacheck"
      },
      "bill_details": {
        "title": "Dettagli della fattura",
        "description": "Dettagli della fattura",
        "name": "Dettagli della fattura"
      },
      "activate_plugin": {
        "title": "Attivare il plugin",
        "description": "Attivare il plugin",
        "name": "Attivare il plugin"
      },
      "model_groups": {
        "title": "Gruppi di modelli",
        "description": "Gruppi di modelli",
        "name": "Gruppi di modelli"
      },
      "model_group_details": {
        "title": "Dettagli del gruppo di modelli",
        "description": "Dettagli del gruppo di modelli",
        "name": "Dettagli del gruppo di modelli"
      },
      "model_assets": {
        "title": "Immagini del modello",
        "description": "Immagini del modello",
        "name": "Immagini del modello"
      },
      "model_asset_details": {
        "title": "Modello Dettagli immagine",
        "description": "Modello Dettagli immagine",
        "name": "Modello Dettagli immagine"
      },
      "lead_per_mail_rules": {
        "title": "Regole di Lead by e-mail",
        "description": "Regole di Lead by e-mail",
        "name": "Regole di Lead by e-mail"
      },
      "car_rental": {
        "title": "Prenotazione sicura e veloce in {città}. {città} auto tra cui scegliere",
        "description": "Prenotate la vostra auto in modo rapido e sicuro. Credeteci, noleggiare un'auto non è mai stato così facile e veloce.",
        "name": "Lasciare che"
      },
      "failed_refunds": {
        "title": "Rimborsi non riusciti",
        "description": "Rimborsi non riusciti",
        "name": "Rimborsi non riusciti"
      },
      "companySignup": {
        "title": "Registrazione per le aziende",
        "description": "Registrate la vostra attività e approfittate dell'ultimo ecosistema per i concessionari d'auto",
        "name": "Registrazione per le aziende"
      },
      "price_negotiations": {
        "title": "Negoziazione del prezzo",
        "description": "Negoziazione del prezzo",
        "name": "Negoziazione del prezzo"
      },
      "price_negotiation_details": {
        "title": "Negoziazione del prezzo",
        "description": "Negoziazione del prezzo",
        "name": "Negoziazione del prezzo"
      },
      "communities": {
        "title": "Elenco delle comunità",
        "description": "Elenco delle comunità",
        "name": "Elenco delle comunità"
      },
      "car_service_locations": {
        "title": "Sedi in cui sono disponibili gli appuntamenti di assistenza",
        "description": "Luoghi",
        "name": "Luoghi"
      },
      "car_service_resources": {
        "title": "Scegliere un consulente di servizio",
        "description": "Consulente di servizio",
        "name": "Consulente di servizio"
      },
      "car_service_free_slots": {
        "title": "Scegliete una fascia oraria libera per parcheggiare la vostra auto",
        "description": "Finestra temporale",
        "name": "Finestra temporale"
      },
      "car_service_client_data": {
        "title": "Accedi o registrati per prenotare il tuo appuntamento",
        "description": "Accesso",
        "name": "Accesso"
      },
      "car_service_car_data": {
        "title": "Inserire i dati del veicolo per prenotare il servizio",
        "description": "Dati del veicolo",
        "name": "Dati del veicolo"
      },
      "car_service_summary": {
        "title": "Ultimo passo - Controllare i dati e la selezione",
        "description": "Controllo",
        "name": "Controllo"
      },
      "car_services": {
        "title": "Servizi di officina",
        "description": "servizi",
        "name": "servizi"
      },
      "car_service_stations": {
        "title": "Postazioni di lavoro in officina",
        "description": "Postazioni di lavoro",
        "name": "Postazioni di lavoro"
      },
      "document_templates": {
        "title": "Modelli di documento",
        "description": "Modelli di documento",
        "name": "Modelli di documento"
      },
      "document_template_details": {
        "title": "Modello di documento",
        "description": "Modello di documento",
        "name": "Modello di documento"
      }
    },
    "sort": {
      "relevance": "Rilevanza",
      "username": "e-mail",
      "lastName": "Cognome",
      "cdate": "Prima i più recenti",
      "cdateDesc": "Prima i più recenti",
      "dueDate": "Data di scadenza",
      "fav": "I più popolari",
      "priceAsc": "Prezzo più basso",
      "rentPriceAsc": "Prezzo più basso",
      "priceDesc": "Prezzo più alto",
      "rentPriceDesc": "Prezzo più alto",
      "priority": "Priorità",
      "mdate": "Ultimo aggiornamento",
      "countryName": "Paese",
      "vat": "IVA",
      "sumGross": "Importo"
    },
    "jobTypes": {
      "wedding": "Matrimonio",
      "family_portrait": "Ritratto di famiglia",
      "maternity": "Maternità",
      "newborn": "Neonato",
      "couple": "Coppia",
      "pet": "Animale domestico",
      "commercial_advertising": "Commerciale / Pubblicità",
      "fashion": "Moda",
      "model_portfolio": "Portafoglio modelli",
      "event": "Evento",
      "headshots": "Colpi di testa",
      "real_estate_and_architecture": "Immobiliare e architettura",
      "other": "Altro"
    },
    "pipelineEvent": {
      "afterLeadCreated": "creato in base al piombo",
      "afterJobAccepted": "accettato dopo il lavoro",
      "beforeJobScheduled": "prima del lavoro previsto",
      "afterJobScheduled": "dopo il lavoro pianificato",
      "afterGalleryCreated": "creato secondo la galleria",
      "afterPreviousStage": "dopo la fase precedente"
    },
    "paymentEvents": {
      "beforeJobDate": "prima della data di lavoro",
      "onJobDate": "alla data del lavoro",
      "afterJobDate": "per data di lavoro",
      "afterInvoiceDate": "dopo la data della fattura"
    },
    "autocomplete": {
      "placeholder": "Selezionare...",
      "noResultsText": "Nessun risultato trovato",
      "loadingPlaceholder": "Caricamento"
    },
    "salesDoc": {
      "debtor_number": "Numero cliente",
      "order_number": "Numero d'ordine",
      "order_totals": "Totali dell'ordine",
      "quote_from": "Da",
      "quote": "Offerta",
      "quote_for": "Offerta per",
      "quotes": "Offerte",
      "new_quote": "Nuova offerta",
      "invoice_from": "Da",
      "invoice": "Fattura",
      "invoice_date": "Data della fattura",
      "tax_invoice": "Fattura",
      "order": "ordine",
      "credit_note": "Nota di credito",
      "credit_notes": "Note di credito",
      "invoice_for": "Fattura per",
      "invoices": "Fatture",
      "new_invoice": "Nuova fattura",
      "cancellation_invoice_to": "Fattura di annullamento per",
      "cancellation_invoice": "Fattura di cancellazione",
      "to_refunded": "Ancora da rimborsare:",
      "positions": "Posizioni",
      "issued": "Rilasciato",
      "cancelled": "STORNO",
      "billing_period": "Periodo di fatturazione",
      "billing_period_not_valid": "Periodo di fatturazione non valido",
      "pro_rate": "pro rata",
      "name": "Nome",
      "description": "Descrizione del",
      "quantity": "Quantità",
      "unit_price": "Prezzo unitario",
      "inc_tax": "Tasse incluse",
      "exc_tax": "Senza tasse",
      "subtotal": "Subtotale",
      "total": "Importo totale",
      "amount_due": "Totale netto",
      "total_due": "Importo della fattura",
      "target": "Cliente",
      "due_date": "In scadenza il",
      "to_pay": "Ancora da pagare:",
      "payed": "Pagato",
      "payment": "Pagamento",
      "download_csv_ready": "Esportazione CSV completata",
      "no_vat_eu_country": "Consegne intracomunitarie esenti da imposte ai sensi del § 4 n. 1b UstG.",
      "no_vat_non_eu_country": "Consegna all'esportazione esente da imposte secondo il § 6 UstG",
      "thanks_for_order": "Grazie per il vostro ordine. Se non diversamente specificato, i servizi di cui sopra saranno eseguiti alla data della presente fattura.",
      "bill_by_date": "Si prega di trasferire l'importo, indicando il numero della fattura, sul seguente conto entro la data %:",
      "intended_use": "Uso previsto",
      "paid_with_cash_cc": "La fattura è stata pagata in contanti / con carta EC",
      "amount_from_credit_card": "L'importo di %amount% sarà addebitato sulla carta di credito.",
      "amount_from_paypal": "L'importo di %amount% sarà addebitato sul vostro conto PayPal.",
      "debit_from_account": "L'importo di %amount% sarà addebitato sul vostro conto.",
      "register_and_taxes": "Registro commerciale e imposte",
      "managing_director": "Direttore generale"
    },
    "provisionDoc": {
      "provision_calculation": "Calcolo della commissione",
      "client": "Cliente",
      "invoice_no_date": "N. fattura / data",
      "payedDate": "pagato su",
      "serviceType": "Tipo di servizio",
      "amount": "Importo",
      "provisionRate": "Tasso di commissione",
      "provisionValue": "Commissione",
      "totalAmount": "Importo totale",
      "provision": "Commissione",
      "provisions": "Commissioni",
      "provision_for": "Commissione per",
      "download_pdf": "Scaricare PDF"
    },
    "paymentStatus": {
      "late": "In ritardo",
      "paid": "Pagato",
      "unpaid": "Non pagato",
      "inProgress": "In corso"
    },
    "shopItem": {
      "plus_shipping": "più le spese di spedizione",
      "variants_possible": "Sono disponibili diverse opzioni di selezione"
    },
    "sepaAgreement": {
      "sepa_direct_debit_mandate": "Mandato di addebito diretto SEPA (autorizzazione all'addebito diretto)",
      "mandate_reference": "Riferimento del mandato",
      "will_be_communicated_separately": "saranno comunicati separatamente",
      "payee": "Beneficiario",
      "creditor": "Creditore",
      "payer": "Pagatore",
      "i_allow_payee_direct_debit": "Autorizzo il beneficiario a riscuotere i pagamenti dal mio conto tramite addebito diretto fino a nuovo avviso. Allo stesso tempo, do istruzioni alla mia banca di onorare gli addebiti diretti effettuati dal beneficiario sul mio conto.",
      "note_i_can_claim_reimbursement_within_eight_weeks": "Nota: posso richiedere il rimborso dell'importo addebitato entro otto settimane dalla data di addebito. Si applicano le condizioni concordate con la mia banca.",
      "document_issued_electronically_and_valid_without_signature": "Questa lettera è stata creata automaticamente ed è valida anche senza firma",
      "shortening_notice_period": "Riduzione del periodo di preavviso",
      "deadline_for_advance_information_of_direct_debit_is_shortened_to_one_day": "Il termine per la notifica anticipata dell'addebito diretto SEPA è ridotto a un giorno.",
      "i_issue_sepa_direct_debit_mandate": "Con la presente conferisco il mandato di addebito diretto SEPA"
    },
    "sad": {
      "offer": "Offerta",
      "modification": "Emendamento",
      "order": "ordine",
      "offers": "Offerte",
      "orders": "Ordini",
      "offer_short": "Ang.",
      "order_short": "Il migliore.",
      "conversion_rate_short": "CR",
      "conversion_rate": "Tasso di conversione",
      "last_offer_date": "Ultima offerta",
      "last_offer_date_short": "Ultimo Ang.",
      "car_data": "Dati del veicolo",
      "class": "Classe",
      "model_name": "Nome del modello",
      "model_name_short": "Nome del modello (breve)",
      "model_range": "Serie",
      "body_design": "Forma del corpo",
      "car_color": "Colore",
      "base_price": "Prezzo base",
      "list_price": "Prezzo di listino",
      "optional_equipment": "Attrezzature speciali",
      "incl": "incl.",
      "factory_discount": "Proprietà della fabbrica",
      "cash_discount": "Sconto in contanti",
      "volume_discount": "Sconto sulla quantità",
      "transportation_costs": "Costi di trasferimento",
      "price_reduction": "Riduzione del prezzo",
      "car_image": "Immagine del veicolo dal configuratore",
      "discount": "Proprietà",
      "sales_price": "Prezzo di vendita",
      "max_speed": "Velocità massima",
      "car_acceleration": "Accelerazione (0 - 100)",
      "online_code": "Codice online",
      "sales_activity": "Attività di vendita",
      "last_modified": "Ultima modifica",
      "offer_date": "Data dell'offerta",
      "order_date": "Data dell'ordine",
      "order_location": "Posizione dell'ordine",
      "pre_contract_nr": "V-Ordine n.",
      "pre_contract_date": "V ordine dat.",
      "contract_nr": "Numero d'ordine",
      "shipment_place": "Luogo di consegna",
      "shipment_key": "Chiave di consegna",
      "special_agreement": "Accordo speciale",
      "contact_person": "Persona di contatto",
      "customer_account_number": "Conto del debitore n.",
      "financing": "Finanziamento",
      "leasing": "Leasing",
      "financing_product": "Prodotto",
      "paid_deposit": "Acconto",
      "paid_deposit_leasing": "Pagamento speciale del leasing",
      "credit_amount": "Importo totale del prestito",
      "monthly_rate": "Rata mensile",
      "contract_duration": "Tempo di esecuzione",
      "total_mileage": "Chilometraggio",
      "purchase_price": "Prezzo di acquisto",
      "annual_percentage_rate": "Tasso di interesse annuo effettivo",
      "nominal_interest_percentage_rate": "Interessi passivi fissi p.a.",
      "debit_interest_percentage_rate": "Interesse nominale p.a.",
      "total_amount": "Importo totale",
      "final_instalment": "Rata finale",
      "application_date": "Data di applicazione",
      "application_approval_date": "Data di autorizzazione",
      "application_rejection_date": "Data di rifiuto",
      "client_private": "Privato",
      "client_business": "Commerciale",
      "monthly_leasing_rate": "Rata mensile di leasing",
      "monthly_financing_rate": "Rata di finanziamento mensile",
      "monthly_insurance_rate": "Premio mensile di assicurazione autoveicoli",
      "no_winleas_data_available": "Non sono disponibili dati WinLEAS",
      "winleas_data_available": "Dati di finanziamento (F) e/o di leasing (L) disponibili da WinLEAS",
      "discount_cash": "Ambulanza in contanti",
      "discount_volume": "Sconto quantità/riciclatore",
      "discount_environmental": "Bonus ambientale",
      "discount_additional": "Condizioni aggiuntive",
      "deduction": "Sconto",
      "deduction_mbl": "Sconto MBL",
      "deduction_mbl_interest": "Sconto sugli interessi MBL",
      "deduction_consultant": "Consulente di sconto",
      "deduction_consultant_interest": "Consulente per lo sconto dei tassi d'interesse",
      "commission": "Commissione",
      "commission_standard": "Standard",
      "commission_addition_deduction": "Aumentare / diminuire il punteggio",
      "commission_subsequent_scope_of_supply": "Avanti. Ambito di consegna",
      "commission_total": "Totale",
      "subsidy": "Sovvenzione",
      "subsidy_contract_partner": "Sovvenzione VP",
      "subsidy_factory": "Impianto di sovvenzione",
      "subsidy_mbvd": "Sovvenzione MBVD",
      "shortcut_lp_kp": "LP/KP",
      "campaign": "Azione",
      "service_rate": "Tasso di servizio",
      "residual_value": "Valore residuo",
      "table_residual_value": "Valore residuo della tabella",
      "residual_value_change": "Variazione del valore residuo",
      "ref": "per quanto riguarda",
      "net": "rete",
      "gross": "lordo",
      "list_price_short": "LP",
      "sales_price_short": "KP",
      "note_save": "Salva nota",
      "notes": "Note",
      "salesman": "Venditore",
      "salesman_blacklist": "Venditore (Lista nera)",
      "salesman_number": "Venditore no.",
      "salesman_number_short": "No.",
      "enterprise_number": "Azienda n.",
      "Mercedes": "Mercdes",
      "Smart": "Intelligente",
      "equipment_line": "Linea",
      "packages": "Pacchetti",
      "upholstery": "Tappezzeria",
      "single_equipments": "Altro",
      "included_in": "contenuto in",
      "store": "Ramo",
      "save_filterset": "Salvare il set di filtri",
      "internal_activity": "Affari interni",
      "offer_made": "Offerta creata",
      "in_progress": "In corso",
      "predecessor_offer": "Offerta precedente / duplicato",
      "lost": "Affari persi",
      "ordered": "Ordine completato",
      "canceled": "Ordine annullato"
    },
    "rad": {
      "earnings": "Rendimento",
      "occupancy_rate": "Utilizzo",
      "amount": "Importo"
    },
    "external_account_status": {
      "login_fail": "Controllare i dati di accesso! Altrimenti non è possibile leggere altri dati.",
      "login_warning": "Controllare i dati di accesso!",
      "login_valid": "Dati di accesso corretti",
      "last_successful_login": "Ultimo accesso riuscito"
    },
    "categoryitems_stock": "Pezzo",
    "initialize": "Init",
    "customer": "Azienda",
    "customer_": "Cliente",
    "select_mandant": "Selezionare il cliente",
    "initial_title": "Titolo iniziale del mezzo pubblicitario",
    "will_be_shown_to_the_user_in_frontend": "Viene visualizzato dagli utenti nel frontend",
    "producttemplate_data_saved": "Salvataggio dei dati del modello",
    "product_size": "Formato e dimensioni del prodotto",
    "template_upload_files": "Selezionare il/i file da caricare",
    "example_of_product_size": "ad esempio DIN A4, DIN lungo...",
    "output_format": "Formato di uscita",
    "product_size_width": "Larghezza in pixel",
    "product_size_height": "Altezza in pixel",
    "template_folder": "Percorso del modello",
    "template_dpi": "DPI per l'uscita",
    "template_upload": "Caricare",
    "template_pageSpace": "Spazi della griglia",
    "output_exportType": "Tipo di uscita",
    "template_fonts": "Caratteri",
    "template_fontSizeMin": "Dimensione carattere min",
    "template_styles": "Dati CSS e LESS",
    "template_fontSizeMax": "Dimensione massima del carattere",
    "template_init_amount_of_pages": "Numero iniziale di pagine",
    "template_page_add_increase": "Infedeltà",
    "template_pageMaxAmount": "Numero di pagine max",
    "template_pageMinAmount": "Numero di pagine min",
    "template_pageTop": "Imbottitura superiore",
    "template_pageBottom": "Imbottitura inferiore",
    "template_pageFirst": "Numero di pagina Pagina del titolo",
    "template_pageFirstFixed": "Fissata la prima pagina",
    "template_pageLast": "Numero di pagina Ultima pagina",
    "template_pageLastFixed": "Ultima pagina sistemata",
    "template_endless": "Pagina infinita",
    "template_fixed": "Pagina fissata",
    "template_sample_page": "Numero di pagine Contenuto standard",
    "font_colors": "Colori del testo",
    "back_colors": "Colori di sfondo",
    "client_logo": "Logo aziendale",
    "template_pageNumber": "Numero di pagine",
    "page_names": "Impostazioni della pagina",
    "add_contentBlock": "Aggiungere un blocco di contenuto",
    "css": "Generale CSS",
    "cssPDF": "CSS solo per l'output PDF",
    "cssPrintPDF": "CSS solo per l'output PDF stampabile",
    "cssWebsite": "CSS per l'output come pagina web",
    "chosenItem": "Selezione attuale di prodotti",
    "pdf_price": "Prezzo in PDF",
    "auflagen": "Le condizioni",
    "print_prices": "Prezzi base",
    "proof_base_price": "Verifica dei prezzi di base",
    "new_proof_base_price": "Verifica dei nuovi prezzi base",
    "get_vendor_price": "Prezzi di chiamata",
    "add_variant_line": "Aggiungi linea",
    "remove_all_item_variant": "Cancellare tutte le edizioni",
    "get_vendor_prices": "Prezzo di richiamo del fornitore",
    "get_vendor_id": "Interrogazione dell'ID del prodotto del fornitore",
    "no_vendor_id": "Nessun ID prodotto fornitore impostato!",
    "cc_item_id": "ID prodotto CreaCheck",
    "vendor_item_id": "ID prodotto Fornitore",
    "vendor_item_name": "Nome del prodotto Fornitore",
    "vendor_item_missing_attributes": "Non è possibile trovare un prezzo per questa combinazione di attributi.",
    "vendor_item_save_attributes": "Selezionare gli attributi!",
    "vendor_item_must_define_attributes": "È necessario definire gli attributi per creare le varianti.",
    "vendor_item_has_no_attributes": "Questo gruppo di prodotti non ha attributi",
    "vendor_item_all_n_quantities": "Mostra tutte le quantità di {quantitàCount}",
    "vendor": "Fornitore",
    "internal_item_id": "ID interno",
    "select_vendor_product": "Selezionare il gruppo di prodotti",
    "item_type": "Tipo di prodotto",
    "print_item": "Prodotto di stampa",
    "website_item": "Sito web",
    "normal_item": "Altro prodotto",
    "not_more_pages": "Non è possibile creare più nomi di pagine di quante siano le pagine possibili!",
    "info_title_page": "Il frontespizio non può essere spostato o cancellato",
    "info_last_page": "L'ultima pagina non può essere spostata o cancellata",
    "page_definition_page_count_info": "Numero di pagina 0 = valore predefinito per tutte le pagine senza definizione, ultimo per l'ultima pagina",
    "padding_on_top": "Spaziatura superiore",
    "padding_on_bottom": "Distanze inferiori",
    "padding_on_left": "Spaziatura a sinistra",
    "padding_on_right": "Spaziatura a destra",
    "delimitation_of_grid_blocks": "I blocchi della griglia possono essere aggiunti senza limiti. La pagina diventa così sempre più lunga.",
    "fixed_page": "La pagina è fissa e non può essere spostata.",
    "page_definition": "Definizione della pagina",
    "add_definition": "Aggiungi definizione",
    "page_default_value": "Numero di pagina 0 = valore predefinito per tutte le pagine senza definizione",
    "page_count": "Numero di pagine",
    "page_widths": "Larghezze laterali",
    "page_heights": "Altezze laterali",
    "no_special_formatting": "Nessuna formattazione speciale",
    "min_max_font_size": "Dimensione minima/massima del carattere",
    "activate_min_max_font_size": "Attivazione o disattivazione di Min-/Max-FontSize",
    "filename": "Nome del file",
    "for_user": "Per gli utenti",
    "user_can_use_font": "L'utente può utilizzare questo carattere nell'editor",
    "import_successful": "Importazione completata, guardare le altre schede",
    "import_resets_template": "L'importazione azzera il modello, deve essere reinizializzato, tutte le edizioni cliente esistenti con questo modello diventano inutilizzabili!!!!",
    "problem": "Si è verificato un problema",
    "reading_pages": "Pagine di lettura",
    "done": "Pronto",
    "versions": "Versioni",
    "template_not_edited": "Questo modello non è ancora stato modificato.",
    "dataProof_infoText": "In un controllo manuale dei dati, i nostri esperti verificano per voi tutti i criteri tecnici della fase di prestampa. Questi includono la stampabilità, la conformità a tutte le specifiche tecniche e l'effetto cromatico soggettivo. Informazioni più dettagliate sulla verifica dei dati sono disponibili alla voce FAQ.",
    "media_library": "Mediateca",
    "count": "Quantità",
    "welcome_message": "Ciao, {nome}",
    "email": "e-mail",
    "website": "Sito web",
    "phone": "Telefono",
    "fax": "fax",
    "password": "password",
    "repeat_password": "Ripetere la password",
    "company": "Azienda",
    "company_name": "Nome della società",
    "company_data_timeline_tab_title": "Cronologia dei dati aziendali",
    "company_data_success": "I dati aziendali sono stati modificati con successo!",
    "company_data_error": "Errore durante la modifica dei dati aziendali!",
    "updated_by": "Aggiornato da",
    "companies": "Aziende",
    "first_name": "Nome",
    "last_name": "Cognome",
    "street": "Via",
    "house_number": "Numero civico",
    "apartment_number": "Numero piatto",
    "address_line2": "Linea di indirizzo 2",
    "zip_code": "Codice postale",
    "city": "Città",
    "country": "Paese",
    "currency": "Valuta",
    "previous_year": "Anno precedente",
    "vat_name": "Nome IVA",
    "vat_id_name": "ID IVA Nome",
    "vat_id": "IVA - IdNr",
    "vat_rates": "Aliquote IVA",
    "coc_number": "Numero COC",
    "registered_at": "Registrato presso",
    "page": "Pagina",
    "page_of": "da",
    "save": "Risparmiare",
    "save_and_email": "Salvare e inviare via e-mail",
    "add": "Aggiungi",
    "add_client": "Aggiungi azienda",
    "update_company_data": "Modifica del nome dell'azienda",
    "add_user": "Aggiungi utente",
    "add_product": "Aggiungi prodotto",
    "add_producttemplate": "Aggiungi modello",
    "add_question": "Aggiungi domanda",
    "add_option": "Aggiungi opzione",
    "add_questionnaire": "Aggiungi un questionario",
    "add_template": "Aggiungi modello",
    "add_position": "Aggiungi posizione",
    "add_pipeline": "Aggiungere una conduttura",
    "add_quote": "Aggiungi offerta",
    "add_invoice": "Aggiungi fattura",
    "add_stage": "Aggiungi livello",
    "add_gallery": "Aggiungi galleria",
    "add_job": "Aggiungi lavoro",
    "add_payment_schedule": "Aggiungere un piano di pagamento",
    "add_payment": "Aggiungi pagamento",
    "add_location": "Aggiungi posizione",
    "add_data_definition": "Aggiungere la definizione dei dati",
    "add_car": "Aggiungi veicolo",
    "add_document": "Aggiungi documento",
    "add_document_type": "Aggiungi tipo di documento",
    "add_team": "Aggiungi squadra",
    "add_brand": "Aggiungi marchio",
    "add_model": "Aggiungi modello",
    "add_model_group": "Aggiungi gruppo di modelli",
    "add_model_asset": "Aggiungere l'immagine del modello",
    "add_category": "Aggiungi categoria",
    "add_attribute": "Aggiungi proprietà",
    "add_attribute_group": "Aggiungere un gruppo di attributi",
    "add_value": "Valore aggiunto",
    "add_variant": "Aggiungi variante",
    "add_lead": "Aggiungi piombo",
    "add_task": "Aggiungi attività",
    "add_page": "Aggiungi pagina",
    "add_data_push": "Aggiunta di dati push",
    "add_white_label": "Aggiungere un progetto white label",
    "add_menu_element": "Aggiungi voce di menu",
    "add_secondary_menu_element": "Aggiungere una voce di menu secondaria",
    "add_cms_element": "Aggiungere un elemento CMS",
    "add_lead_catcher": "Aggiungi l'acchiappapiatti",
    "add_white_label_plugin": "Aggiungere il plugin white label",
    "add_lead_per_mail_rule": "Aggiungere la regola \"lead by e-mail",
    "add_message": "Aggiungi un messaggio",
    "add_car_service": "Aggiungi servizio",
    "add_car_service_station": "Aggiungere una postazione di lavoro",
    "add_team_member": "Aggiungi un membro del team",
    "add_document_template": "Aggiungere un modello di documento",
    "add_lead_autoresponse": "Aggiungere la risposta automatica ai lead",
    "add_main_menu_version": "Aggiungi la versione del menu principale",
    "add_lead_tagger": "Aggiungi un lead tagger",
    "add_lead_assignment_strategy": "Aggiungere una strategia di assegnazione",
    "add_escalation_rule": "Aggiungere una regola di escalation",
    "add_coupon": "Aggiungi un buono",
    "add_company": "Aggiungi azienda",
    "add_item": "Aggiungi prodotto",
    "add_manual_entry": "Aggiungere l'input manuale",
    "add_allowed_domain": "Aggiungere un dominio autorizzato",
    "update_allowed_domain": "Aggiornamento del dominio autorizzato",
    "send_message": "Invia il messaggio",
    "send": "Inviare",
    "details": "dettagli",
    "delete": "Cancellare",
    "undelete": "Ripristino",
    "undelete_user_confirm": "Ripristinare l'utente?",
    "or": "o",
    "actions": "Azioni",
    "client": "Azienda",
    "job": "Lavoro",
    "active_jobs": "Lavori attivi",
    "questionnaire": "Questionario",
    "review_and_send": "Controllare e inviare",
    "download": "Scaricare",
    "duplicate": "Duplicato",
    "client_name": "Nome",
    "address": "Indirizzo",
    "title": "Titolo",
    "uploading": "Caricare",
    "status": "Stato",
    "next_stage": "Livello successivo",
    "type": "Tipo",
    "schedule": "Orario",
    "location": "Posizione",
    "sent_on": "Inviato su",
    "name": "Nome",
    "description": "Descrizione del",
    "bill_number": "Numero di fattura",
    "quote_number": "Numero di offerta",
    "do_sort": "Ordinamento",
    "date": "data",
    "gallery": "Galleria",
    "questionnaires": "Questionari",
    "job_takes_whole_day": "Questo lavoro occupa l'intera giornata",
    "from": "Da",
    "to": "A",
    "question": "Domanda",
    "subject": "Argomento",
    "content": "Contenuti",
    "template": "Modello",
    "stage_has_due_date": "Questo livello ha una data di scadenza",
    "stage_sends_automated_email": "Questa fase invia un'e-mail automatica",
    "day": "Giorno",
    "days": "Giorni",
    "hours": "Orario",
    "review": "Controllo",
    "timezone": "Fuso orario",
    "client_since": "Azienda aggiunta il",
    "user_since": "Utente da",
    "payment_instructions": "Istruzioni di pagamento",
    "bank_name": "Nome della banca",
    "debtor_number": "ID debitore",
    "account_holder": "Titolare del conto",
    "account_number": "Numero di conto",
    "bank_location": "Sede della banca",
    "bank_code_number": "Codice di selezione bancaria",
    "iban": "IBAN",
    "swift_bic": "SWIFT/BIC",
    "company_logo": "Logo aziendale",
    "percent_of_order": "Percentuale dell'ordine",
    "percent_of_remaining_balance": "Per cento dell'importo rimanente",
    "amount": "Importo",
    "due": "Dovuto",
    "payment_schedule": "Piano di pagamento",
    "record_payment": "Registro di pagamento",
    "mark_as_unpaid": "Contrassegnare come non pagato",
    "close": "Chiudere",
    "yes": "Sì",
    "want_to_mark_the_payment_as_paid": "Volete davvero contrassegnare questo pagamento come pagato?",
    "want_to_mark_the_payment_as_unpaid": "Volete davvero contrassegnare questo pagamento come non pagato?",
    "payment_marked_as_paid": "Pagamento registrato",
    "payment_marked_as_unpaid": "Pagamento contrassegnato come non pagato",
    "overdue_payments": "Pagamenti in ritardo",
    "next_payment": "Prossimo pagamento",
    "quote_accepted": "Offerta accettata",
    "quote_rejected": "Offerta rifiutata",
    "todays_tasks": "Compiti di oggi",
    "overdue_tasks": "Attività in ritardo",
    "overdue_and_upcoming_payments": "Pagamenti scaduti e imminenti",
    "jobs_in_progress": "Lavori in corso",
    "appointments": "Date",
    "appointment": "Data",
    "last_update": "Ultimo aggiornamento",
    "mark_as_completed": "Contrassegnare come completato",
    "want_to_mark_stage_complete": "Si desidera contrassegnare la fase come completata?",
    "stage_marked_complete": "Fase contrassegnata come completata",
    "gallery_download": "Scarica l'immagine",
    "gallery_sharing": "Galleria di condivisione",
    "gallery_social_media": "Immagini sui social media",
    "preview": "Anteprima",
    "invoice_preview": "Anteprima della fattura",
    "preview_pdf": "Anteprima PDF",
    "message": "Messaggio",
    "signup": "Registro",
    "continue": "Continua",
    "password_assistance": "Aiuto per la password",
    "password_renewing": "Nuova password",
    "not_any_user_information": "Nessuna informazione sull'utente.",
    "failed_redirection": "Inoltro fallito.",
    "renew_password": "Prima di poter accedere, è necessario impostare una nuova password. Vi abbiamo appena inviato un'e-mail con le istruzioni.",
    "get_new_password": "Richiesta di una nuova password",
    "set_new_password": "Impostare la nuova password",
    "forgot_password": {
      "infotext": "Se avete dimenticato la vostra password, potete reimpostarla qui. Inserite il vostro indirizzo e-mail registrato nel modulo sottostante e cliccate su \"Continua\". Vi invieremo un'e-mail con ulteriori informazioni.",
      "infotext_robethood": "Se avete dimenticato la vostra password, potete reimpostarla qui. Inserite l'indirizzo e-mail registrato nel modulo sottostante e cliccate su \"Continua\". Vi invieremo un'e-mail con ulteriori informazioni.",
      "info_email_not_used": "Se non utilizzate più l'indirizzo e-mail associato al vostro account Creacheck, contattateci tramite il nostro modulo di contatto: {link}.",
      "info_email_not_used_robethood": "Se non utilizzate più l'indirizzo e-mail associato al vostro account, contattateci tramite il nostro modulo di contatto: {link}.",
      "enter_new_password": "Per accedere a Creacheck è necessario effettuare solo questo passaggio. Basta inserire due volte la nuova password. È così semplice."
    },
    "thanks_for_signing_up": "Grazie per esservi registrati",
    "confirm_your_email": "Confermare l'indirizzo e-mail",
    "signed_up_gratulations": "Benvenuti a CreaCheck. Le abbiamo inviato un'e-mail per completare il processo di registrazione. Confermare la registrazione.",
    "signed_up_gratulations_robethood": "Benvenuti nel nostro sito web. Le abbiamo inviato un'e-mail per completare il processo di registrazione. La preghiamo di confermare la sua registrazione.",
    "check_email_for_message_from_us": "Controllate la vostra casella di posta elettronica.",
    "check_email_for_message_from_us_robethood": "Controllare la casella di posta elettronica",
    "new_clients": "Nuovi clienti",
    "users": "Utenti",
    "user": "Utenti",
    "won_from_count_leads": "Ottenuto da {conteggio} piste",
    "deals_won_out_of_count": "Offerte vinte da {conteggio}",
    "new_badge": "Nuovo",
    "answer_badge": "Risposta",
    "accept": "Accettare",
    "reject": "Rifiuto",
    "quote_rejected_on_date": "Offerta rifiutata il {data}",
    "quote_accepted_on_date": "Offerta accettata il {data}",
    "want_to_accept_the_quote": "Volete accettare l'offerta? Il contraente sarà informato direttamente della vostra decisione.",
    "want_to_reject_the_quote": "Volete rifiutare l'offerta? Il contraente sarà informato direttamente della vostra decisione.",
    "want_to_convert_quote_to_invoice": "Desiderate convertire questa offerta in una fattura?",
    "convert_quote_to_invoice": "Convertire l'offerta in fattura",
    "convert_to_invoice": "Convertire in fattura",
    "convert_quote_from_template": "Convertire l'offerta in un altro modello",
    "completed_on_date": "Completato il {data}",
    "due_date_on_date": "Data di scadenza {date}",
    "due_date": "Data di scadenza",
    "current_stages_due_date": "Data di scadenza della fase corrente",
    "go_pro": "Go Pro",
    "recent_activity": "Ultima attività",
    "load_more": "Per saperne di più",
    "your_galleries": "Le vostre gallerie",
    "unread_by_you": "Non letto da voi",
    "count_images": "{conteggio} immagini",
    "view": "Vista",
    "filled_out_on": "Compilato su",
    "filled_out_by": "Compilato da",
    "you_answered_the_questionnaire_on_date": "Ha risposto al questionario il {data}",
    "company_sent_a_questionnaire": "{azienda} ha inviato un questionario e chiede delle risposte",
    "allow_to_download_full_res": "Consentire al cliente di scaricare le immagini a piena risoluzione",
    "allow_to_share_with_friends": "Consentire al cliente di condividere la galleria con gli amici",
    "allow_to_share_on_social_media": "Consentire al cliente di condividere le immagini tramite i social media",
    "got_it": "OK",
    "learn_more": "Per saperne di più",
    "this_website_uses_cookies": "Il nostro sito web utilizza i cookie per garantire un'esperienza ottimale.",
    "are_you_sure": "Sei sicuro?",
    "no": "No",
    "closed_please_add_timeframe": "<span><span className='text-danger semi-bold'>Chiuso</span> - si prega di aggiungere un periodo di tempo</span>.",
    "not_active_please_add_timeframe": "<span><span className='text-danger semi-bold'>Non attivo</span> - si prega di aggiungere un intervallo di tempo</span>.",
    "note": "Nota",
    "change_password": "Modifica della password",
    "roles": "Ruoli e diritti",
    "active_set": "Abbonamento attuale",
    "infos": "Informazioni su",
    "documents": "Documenti",
    "document": "Documento",
    "financial": "Finanziario",
    "bookingAccountNumber": "Conto di distacco",
    "costAccountNumber": "Centro di costo",
    "locations": "Luoghi",
    "white_label": "Etichetta bianca",
    "show_password_text": "Mostra il testo della password",
    "parent": "Genitore",
    "change_parent": "Cambiare il genitore",
    "cancel": "Annullamento",
    "timeline_undo": "Annullamento",
    "confirm_action_ramifications": "Confermate di aver compreso le implicazioni della vostra azione.",
    "client_will_not_be_visible_in_search_results": "Il cliente selezionato non è più visibile nella ricerca clienti.",
    "changing_parent_takes_a_while": "La modifica del precursore può richiedere un po' di tempo",
    "load_message_from_template": "Carica il messaggio dal modello",
    "questions": "Domande",
    "search": "Ricerca",
    "search_for": "Ricerca di",
    "dynamic_data_definitions": "Definizioni dinamiche dei dati",
    "dynamic_data_definition": "Definizione dinamica dei dati",
    "data_identifier": "Identificatore dei dati",
    "cars": "Veicoli",
    "rentals": "Affitto",
    "no_car_available": "Nessun carrello disponibile",
    "file": "File",
    "files": "File",
    "teams": "Squadre",
    "team": "Squadra",
    "document_types": "Tipi di documenti",
    "document_type": "Tipo di documento",
    "system_type": "Tipo di sistema",
    "brands": "Marche",
    "models": "Modelli",
    "model": "Modello",
    "model_groups": "Gruppi di modelli",
    "model_group": "Gruppo di modelli",
    "model_assets": "Immagini del modello",
    "model_asset": "Modello immagine",
    "categories": "Categorie",
    "attributes": "Attributi",
    "attribute": "Attributo",
    "values": "Valori",
    "value": "Valore",
    "select_category": "Selezionare la categoria",
    "position": "Posizione",
    "color": "Colore",
    "car": "Prodotto",
    "category": "Categoria",
    "brand": "Marchio",
    "required": "Campo obbligatorio",
    "multilanguage": "Più lingue",
    "seo": "SEO",
    "page_title": "Titolo della pagina",
    "keywords": "Parole chiave",
    "item": "Articolo",
    "sku": "SKU",
    "variants": "Varianti",
    "variant": "Variante",
    "price": "Prezzo",
    "prices": "Prezzi",
    "variant_attributes": "Attributi della variante",
    "no_value_set": "Nessun valore impostato",
    "identification": "Identificazione",
    "templates": "Modelli",
    "vin": "FIN",
    "leads": "Conducenti",
    "lead": "Piombo",
    "order_it": "Ordine",
    "order": "ordine",
    "orders": "Ordini",
    "images": "Immagini",
    "image": "Immagine",
    "upc": "UPC",
    "visibility": "Visibilità",
    "show_item_online": "Prodotto visibile online",
    "use_as_resource": "Utilizzo come risorsa",
    "stock_and_prices": "Inventario e prezzi",
    "sales_price": "Prezzo di vendita",
    "sales_point": "Distribuzione",
    "warehouse": "Magazzino",
    "stock_level": "Stock",
    "rent_price": "Prezzo di noleggio",
    "pseudo_price": "Pseudo prezzo",
    "express_price": "Prezzo espresso",
    "overnight_price": "Prezzo del pernottamento",
    "sameday_price": "Prezzo al giorno",
    "basic_price": "Prezzo base",
    "weekday_price": "Prezzo feriale",
    "weekend_price": "Prezzo del weekend",
    "week_price": "Prezzo settimanale",
    "month_price": "Prezzo mensile",
    "unit_of_measure": "Unità di misura",
    "basic_unit": "Unità di base",
    "packaging_unit": "Unità di imballaggio",
    "subscribe": "Abbonarsi",
    "all": "Tutti",
    "pipeline": "Condotte",
    "pipelines": "Condotte",
    "default_team": "Squadra standard",
    "select_pipeline": "Selezionare una conduttura",
    "please_select_pipeline_first": "Selezionare prima una conduttura",
    "view_type": "Vista",
    "stage": "Livello",
    "stages": "Passi",
    "properties": "Proprietà",
    "unassigned": "Senza assegnazione",
    "assignee": "Processore",
    "order_type": "Tipo di ordine",
    "internal_comment": "Commento interno",
    "comments": "Commenti",
    "comment": "Commento",
    "no_comments_yet": "Non ci sono ancora commenti",
    "show_all_comments": "Mostra tutti i commenti",
    "people": "Persone",
    "watchers": "Osservatore",
    "dates": "Date",
    "date_created": "Creato",
    "date_updated": "Aggiornato",
    "closed": "Chiuso",
    "start_watching_lead": "Iniziare a guardare questa pista",
    "stop_watching_lead": "Smettete di guardare questa pista",
    "no_longer_watching_lead": "Non si osserva più questa pista",
    "watching_lead": "Osservate questa pista",
    "wrote_comment": "Ha scritto un commento",
    "replied_to_comment": "Ha risposto ad un commento",
    "language": "Lingua",
    "languages": "Le lingue",
    "primary_language": "Lingua principale",
    "secondary_languages": "Lingue secondarie",
    "won": "Vinto",
    "lost": "Perso",
    "change_pipeline": "Cambiamento della pipeline",
    "start_pipeline": "Avvio della conduttura",
    "pipeline_started": "Avvio del gasdotto",
    "change_stage": "Modifica del livello",
    "switch_team": "Gruppo di cambiamento",
    "open": "Aperto",
    "budget": "Bilancio",
    "always_available": "Sempre disponibile",
    "default_location": "Posizione standard",
    "selected_category_has_no_attributes": "La categoria selezionata non ha attributi",
    "car_overview": "Panoramica del veicolo",
    "make_reservation": "Effettuare una prenotazione",
    "reservation": "Prenotazione",
    "cms_pages": "Pagine",
    "grid_fluid": "Liquido",
    "grid_normal": "Normale",
    "cms_add_container": "Aggiungere un contenitore",
    "cms_add_row": "Aggiungi linea",
    "cms_add_element": "Aggiungi elemento",
    "media_files": "File multimediali",
    "upload": "Caricare",
    "page_design": "Design della pagina",
    "translations": "Traduzioni",
    "translate": "Tradurre",
    "category_filters": "Filtro di categoria",
    "attribute_groups": "Gruppi di attributi",
    "select_attribute": "Selezionare l'attributo",
    "display": "Display",
    "select_multiple": "Selezione multipla",
    "use_null_as_min_val": "Impostare 0 come minimo",
    "filter": "Filtri",
    "do_filter": "Filtro",
    "mobile_phone": "Telefono cellulare",
    "mark_lead_won": "Contrassegnare la pista come vinta",
    "mark_lead_lost": "Contrassegnare la pista come persa",
    "mark_lead_deleted": "Contrassegnare la traccia come cancellata",
    "reopen_lead": "Riaprire la pista",
    "won_reason": "Motivo della vittoria",
    "lost_reason": "Motivo della perdita",
    "delete_reason": "Motivo della cancellazione",
    "reopen_reason": "Motivo della riapertura",
    "no_reason_specified": "Non è stata fornita alcuna motivazione",
    "lead_marked_won": "Il piombo segnato ha vinto. Congratulazioni",
    "lead_marked_lost": "Piombo marcato perso",
    "lead_marked_deleted": "Piombo contrassegnato come cancellato",
    "lead_reopened": "Piombo riaperto",
    "stage_changed": "Il livello di piombo è cambiato",
    "pipeline_changed": "La pipeline dei lead è cambiata",
    "team_changed": "Lead assegnato a un altro team",
    "current_pipeline": "Condotta attuale",
    "current_stage": "Livello attuale",
    "file_extension": "Estensione",
    "file_size": "Dimensione",
    "file_uploaded": "Caricato",
    "no_client_assigned": "Nessun cliente assegnato",
    "no_products_assigned": "Nessun prodotto assegnato",
    "no_order_assigned": "Nessun ordine assegnato",
    "planned_duration_in_days": "Durata prevista (giorni)",
    "lead_budget_must_be_set": "Il budget per il lead deve essere fissato",
    "client_must_be_assigned": "Il cliente deve essere assegnato",
    "order_must_be_assigned": "L'ordine deve essere assegnato",
    "product_must_be_assigned": "Il prodotto deve essere assegnato",
    "lead_has_to_have_following_documents": "Il capofila deve essere in possesso dei seguenti documenti (i documenti devono essere validi)",
    "client_has_to_have_following_documents": "Il cliente deve essere in possesso dei seguenti documenti (i documenti devono essere validi)",
    "count_days": "{conteggio, plurale, un {# giorno} altri {# giorni}}",
    "longer": "Più lungo",
    "date_from": "Data da",
    "time_from": "Tempo da",
    "date_to": "Data fino a",
    "time_to": "Tempo fino a",
    "rate": "Tasso",
    "car_rental_weekday": "Affitto - Tariffa feriale",
    "car_rental_weekend": "Noleggio - Tariffa weekend",
    "car_rental_week": "Locazione - rata settimanale",
    "car_rental_month": "Affitto - Rata mensile",
    "document_overdue_please_review": "Documento in ritardo. Si prega di controllare",
    "count_cars": "{conteggio, plurale, uno {# veicolo} altri {# veicoli}}",
    "count_products": "{conteggio, plurale, uno {# prodotto} altri {# prodotti}}",
    "we_found_count_cars": "Abbiamo trovato {conteggio, plurale, un {# veicolo} altri {# veicoli}}.",
    "we_found_count_products": "Abbiamo trovato {conteggio, plurale, un {# prodotto} altri {# prodotti}}",
    "delete_all_filters": "Eliminare tutti i filtri",
    "image_recognition_tags": "Tag riconosciuti dall'immagine",
    "username": "Nome utente",
    "handover_must_be_performed": "Il protocollo di consegna del veicolo deve essere completato",
    "document_pages": "Pagine del documento",
    "pages": "Pagine",
    "handover": "Passaggio di consegne",
    "mileage": "Chilometraggio",
    "client_documents": "Documenti del cliente",
    "lead_documents": "Documenti di riferimento",
    "fuel_level": "Livello del carburante",
    "start_editing": "Avviare la modifica",
    "design": "Design",
    "responsive": "Reattivo",
    "back": "Indietro",
    "use_text_description": "Utilizzare una descrizione testuale",
    "use_visual_builder": "Utilizzare il costruttore visivo",
    "set_image": "Imposta immagine",
    "select_image": "Selezionare l'immagine",
    "uploaded_by_me": "Caricato da me",
    "current_item": "Articolo attuale",
    "current_page": "Pagina attuale",
    "difficulties": "Difficoltà",
    "damages": "Danni",
    "damage_note": "Avviso di danno",
    "no_damages_in_protocol": "Nessun danno nel tronco",
    "add_damage": "Aggiungi danni",
    "my_company": "La mia azienda",
    "user_administration": "Amministrazione degli utenti",
    "client_administration": "Gestione dei contatti",
    "crm_administraion": "Gestione del CRM",
    "content_administration": "Gestione dei contenuti",
    "product_administration": "Gestione dei prodotti",
    "producttemplate_administration": "Gestione dei modelli",
    "car_service_administration": "Gestione dell'officina",
    "site_administration": "Amministrazione del sito web",
    "data_push": "Spinta dei dati",
    "client_has_no_ancestors": "Il cliente non ha antenati",
    "domain": "Dominio",
    "entity": "Entità",
    "event": "Evento",
    "endpoint_url": "URL del punto finale",
    "authentication": "Autenticazione",
    "headers": "Intestazioni",
    "none": "Nessuno",
    "social_media": "I social media",
    "style": "Stile",
    "html_header": "Intestazione HTML",
    "custom_html_header": "Intestazione HTML personalizzata",
    "css_file_name": "Nome del file CSS definito dall'utente",
    "custom_css": "CSS personalizzato",
    "custom_less": "LESS definito dall'utente",
    "custom_font_folder": "Selezionare la directory dei font",
    "custom_font": "Caricare un font personalizzato",
    "custom_css_mail": "CSS personalizzato per le e-mail",
    "main_menu": "Menu principale",
    "menu_css_class": "Classe CSS del menu principale",
    "menu_css_class_scroll": "Classe CSS del menu principale quando si scorre",
    "white_label_projects": "Progetti white label",
    "imprint": "Impronta",
    "terms_of_service": "GTC",
    "rental_terms_of_service": "Condizioni generali di noleggio",
    "car_sales_terms_of_service": "Condizioni generali di vendita per i veicoli usati",
    "item_sales_terms_of_service": "Condizioni generali di vendita Prodotti",
    "privacy_policy": "Protezione dei dati",
    "cancellation_policy": "Politica di cancellazione",
    "footer": "Piè di pagina",
    "hide_footer": "Nascondi piè di pagina",
    "unknown_property": "Parametro sconosciuto",
    "unknown": "sconosciuto",
    "price_from": "da",
    "price_to": "fino a quando",
    "slider_from": "da",
    "slider_to": "fino a quando",
    "within": "all'interno",
    "of": "da",
    "within_count_km_of_place": "entro {conteggio}km da {luogo}",
    "click_to_start_geolocation": "Fare clic qui per avviare la geolocalizzazione",
    "geolocation_error": "Abbiamo avuto un problema nel riconoscere la vostra posizione o la vostra posizione non è nota. Inserire manualmente la posizione.",
    "count_km_from": "km da",
    "car_power": "Prestazioni",
    "car_power_unit": "PS",
    "car_gear": "Cambio",
    "car_capacity": "Spostamento",
    "car_capacity_unit": "cm³",
    "car_fueltype": "Tipo di carburante",
    "car_type": "Tipo di veicolo",
    "car_num_previous_owners": "Numero di proprietari di veicoli",
    "car_num_doors": "Numero di porte",
    "car_num_seats": "Numero di posti a sedere",
    "car_emission_class": "Classe di inquinanti",
    "car_condition": "Condizioni del veicolo",
    "car_construction_year": "Anno di costruzione",
    "car_color_manufacturer": "Colore (produttore)",
    "car_parking_assistance": "Aiuto al parcheggio",
    "car_interior_material": "Materiale degli arredi interni",
    "car_airbags": "Airbag",
    "car_air_conditioning": "Aria condizionata",
    "car_environment_badge": "Distintivo ambientale",
    "car_co2_emissions": "Emissioni di CO2",
    "car_co2_emissions_unit": "g/km",
    "car_fuel_consumption": "Consumo",
    "car_fuel_consumption_unit": "l/100km",
    "combined": "combinato",
    "combined_short": "pettine.",
    "urban": "urbano",
    "extra_urban": "fuori città",
    "energy_efficiency_class": "Classe di efficienza energetica",
    "technical_data": "Dati tecnici",
    "first_registration": "Prima registrazione",
    "proceed_to_checkout": "Cassa",
    "home": "Pagina iniziale",
    "checkout": "Cassa",
    "your_cart": "Il vostro carrello della spesa",
    "your_car": "La vostra auto",
    "your_data": "I vostri dati",
    "services": "Servizio",
    "shipping_information": "Trasferimento",
    "payment_details": "Pagamento",
    "summary": "Sintesi",
    "find_out_more": "Per saperne di più",
    "qty": "Quantità",
    "checkout_next": "Ulteriori",
    "checkout_previous": "Indietro",
    "please_select": "Selezionare",
    "enum_many_glue": ",",
    "enum_last_glue": " e",
    "enum_end": " selezionare",
    "show_details": "Mostra i dettagli",
    "add_to_cart": "Aggiungi al carrello",
    "show_cart": "Mostra il carrello della spesa",
    "cart": "Cestino della spesa",
    "missingQuantity": {
      "cart": "cestino della spesa"
    },
    "cart_is_empty": "Il carrello è vuoto",
    "item_already_in_cart": "Questo articolo è già presente nel tuo carrello",
    "remove": "Cancellare",
    "removed_from_cart": "è stato cancellato dal carrello",
    "continue_with_login": "Continuare con il login",
    "dont_have_an_account": "Non c'è ancora un account",
    "continue_without_login": "Continuare senza login",
    "already_have_an_account_enter_username_and_password": "Avete già un account? Inserite il vostro nome utente e la vostra password nel modulo sottostante.",
    "continue_without_login_and_register": "Potete continuare il processo di acquisto e creare il vostro account qui.",
    "continue_booking_without_login_and_register": "Potete continuare il processo di prenotazione e creare il vostro account qui",
    "online_discount": "Sconto online",
    "your_personal_data": "I vostri dati personali",
    "login_data": "Dati di accesso",
    "shipping": "Spedizione",
    "payment_cash": "Contanti",
    "payment_creditCard": "Carta di credito",
    "payment_payPal": "PayPal",
    "payment_wire_transfer": "Bonifico bancario",
    "payment_bill": "Fattura",
    "choose_different_payment_method": "Selezionare un altro metodo di pagamento",
    "i_will_pick_up_my_car": "Ritirerò la mia auto",
    "i_will_pick_up_my_order": "Ritirerò il mio ordine",
    "i_want_my_car_delivered": "Vorrei che il mio veicolo fosse consegnato.",
    "i_want_my_order_delivered": "Desidero che il mio ordine venga consegnato",
    "we_could_not_calculate_route_to_address": "Non è stato possibile calcolare un percorso per l'indirizzo specificato. Controllare e riprovare.",
    "credit_card_holder": "Porta carte di credito",
    "credit_card_number": "Numero di carta di credito",
    "car_registration": "Immatricolazione del veicolo",
    "car_insurance": "Assicurazione auto",
    "service_provided_in_country_by_company": "Servizio in {paese} fornito da {azienda}",
    "i_wish_to_have_car_registered": "Sì, desidero utilizzare il servizio di autorizzazione",
    "i_wish_to_get_car_insurance": "Vorrei stipulare un'assicurazione auto",
    "today": "Oggi",
    "in_10_days": "In 10 giorni",
    "your_money_is_safe_with_us": "Il vostro denaro è al sicuro con noi",
    "car_transport": "Trasporto auto",
    "delivery": "consegna",
    "decide_car_delivery_or_pickup": "Sta a voi decidere se ritirare l'auto personalmente o farvela consegnare. In ogni caso, l'auto sarà in perfetto stato, pulita e con il pieno di benzina.",
    "secured_payment_until_car_is_delivered": "Processo di pagamento sicuro fino alla consegna del veicolo",
    "e_payment_to_reserve_car_and_secure_services": "Pagamento elettronico per prenotare l'auto e assicurarsi i servizi selezionati",
    "e_payment_credit_card_paypal": "Pagamento elettronico (carta di credito o PayPal)",
    "multiple_secure_options_to_pay_for_the_car": "Opzioni di pagamento sicure per l'auto (bonifico bancario, contanti al ritiro)",
    "buy_car_by_clicking_button": "È possibile acquistare questa auto ora facendo clic sul pulsante qui sotto",
    "payment_with_credit_card_ending_last4_expiring_exp_month_exp_year_issued_to_card_holder": "Pagamento con carta di credito che termina con {ultimo4} e che scade tra {expMonth}/{expYear}, emessa a favore di {cardHolder}.",
    "click_on_paypal_checkout_to_continue": "Per continuare, fare clic sul pulsante Paypal Checkout.",
    "paypal_is_approved_continue_to_summary": "Il pagamento con PayPal è stato autorizzato. Proseguire con il riepilogo per completare l'ordine e autorizzare il pagamento.",
    "paypal_buttons_loading": "Carica i pulsanti di PayPal...",
    "please_wait_saving_order": "Salvare l'ordine. Attendere prego...",
    "error_loading_paypal_scripts": "Non è stato possibile caricare gli script di PayPal. Chiudere questo popup e riaprirlo.",
    "link": "Collegamento",
    "hide_on_devices": "Nascondere sui dispositivi",
    "mobile_phones": "Telefoni cellulari",
    "tablets": "Compresse",
    "desktop_pcs": "PC desktop",
    "show_content_on_mouse_over": "Mostra il contenuto al passaggio del mouse",
    "menu_content": "Contenuto del menu",
    "link_to_page": "Collegamento alla pagina",
    "page_has_no_translations": "Questa pagina non ha traduzioni",
    "search_results": "Risultati della ricerca",
    "press_enter_to_search": "Premere il tasto Invio per effettuare la ricerca",
    "no_results_found": "Nessun risultato trovato",
    "create_new_entry_now": "È ora possibile creare una nuova voce:",
    "no_results_for_your_search": "Non abbiamo trovato alcun risultato per la tua domanda di ricerca",
    "change_image": "Cambia immagine",
    "employee_data": "Dati dei dipendenti",
    "newsletter": "Autorizzazione a ricevere newsletter",
    "editorAutosave": "Salvataggio automatico nell'editor",
    "is_profile_public": "Il profilo è pubblico?",
    "parent_page": "Pagina dei genitori",
    "cms_elements": "Elementi CMS",
    "cms_element": "Elemento CMS",
    "new_lead_from_website": "Nuovo lead dal sito web",
    "website_settings": "Impostazioni del sito web",
    "profile": "Profilo",
    "my_bills": "Le mie fatture",
    "integrations": "Integrazioni",
    "email_templates": "Modelli di e-mail",
    "cms_templates": "Modelli CMS",
    "cms_template": "Modello CMS",
    "select_brand": "Selezionare la marca",
    "all_brands_visible": "Tutti i marchi sono visibili",
    "contact_us": "Contattateci",
    "thank_you": "Grazie mille",
    "thank_you_for_contacting_us": "Grazie per averci contattato. Abbiamo ricevuto la sua richiesta e la contatteremo al più presto.",
    "cms_standard": "Standard",
    "select_element": "Selezionare l'elemento CMS",
    "divider": "Divisore",
    "testimonial": "Raccomandazione",
    "hero_unit": "Elemento eroe",
    "image_carousel": "Cursore",
    "call_to_action": "Invito all'azione",
    "bar_counter": "Banco bar",
    "products": "Prodotti",
    "producttemplates": "Modelli",
    "single_product": "Prodotto singolo",
    "single_producttemplate": "Modello singolo",
    "lead_form": "Modulo di piombo",
    "image_carousel_element": "Immagine di scorrimento",
    "cms_grid": "Contenitore",
    "cms_row": "Linea",
    "cms_col": "Colonna",
    "text_color": "Colore del testo",
    "bar_counter_now": "Ora",
    "shadow": "Ombra",
    "no_shadow": "Nessuna ombra",
    "small": "Piccolo",
    "big": "Grande",
    "rounded_corners": "Angoli arrotondati",
    "no_rounded_corners": "Nessun angolo arrotondato",
    "image_position": "Posizione dell'immagine",
    "icon": "Icona",
    "icon_class": "Classe dell'icona (CSS)",
    "position_top": "Sopra",
    "position_left": "Collegamenti",
    "bar_color": "Colore della barra",
    "bar_background_color": "Colore di sfondo della barra",
    "button_icon": "Simbolo del pulsante",
    "button_text": "Testo del pulsante",
    "full_height": "A tutta altezza",
    "padding_top": "Distanza superiore",
    "padding_bottom": "Distanza inferiore",
    "lead_source": "Fonte del piombo",
    "change_item": "Cambia articolo",
    "show_infos_on_top": "Mostra info sopra",
    "show_infos_on_bottom": "Mostra info qui sotto",
    "condensed": "Condensato",
    "assign_item": "Assegnare il prodotto",
    "image_animation": "Animazione dell'immagine",
    "no_animation": "Nessuna animazione",
    "fade_in": "Dissolvenza",
    "from_left": "Da sinistra",
    "from_right": "Da destra",
    "from_top": "Dall'alto",
    "from_bottom": "Da sotto",
    "image_gallery": "Galleria di immagini",
    "elements": "Elementi",
    "accordion": "Fisarmonica",
    "map": "Mappa",
    "height": "Altezza",
    "video": "Video",
    "link_to_video": "Link al video",
    "video_possible_sources": "Sono supportati i video provenienti dai seguenti portali:: YouTube, Vimeo, Dailymotion",
    "autoplay": "Riproduzione automatica",
    "aspect_ratio": "Rapporto d'aspetto",
    "image_compare": "Confronta l'immagine",
    "second_image": "Seconda immagine",
    "login_form": "Modulo di accesso",
    "button_signin": "Accedi",
    "alternative_login": "...o accedere con:",
    "alternative_login_long": "Utilizzate la nostra funzione di login sociale",
    "login_with": "Accedere con",
    "show_facebook_login": "Mostra il login a Facebook",
    "show_google_login": "Mostra il login di Google",
    "signup_form": "Modulo di registrazione",
    "signup_legal_explanation": "Registrazione - dichiarazione legale",
    "signup_agreement_text": "Registrandomi, accetto i {termini}.",
    "contact_agreement_text": "Ho letto e compreso il {data_use}.",
    "signup_agreement_text_terms": "Termini e condizioni generali",
    "signup_agreement_text_data_use": "Informativa sulla privacy",
    "signup_agreement_text_cancellation_policy_and_form": "Politica di cancellazione e modulo di cancellazione",
    "will_become_link_with_title_title": "un link con il titolo: {title}",
    "show_only_when_user_logged_id": "Viene visualizzato solo se l'utente è connesso",
    "car_rental_form": "Modulo di noleggio auto",
    "car_search_form": "Modulo di ricerca auto",
    "car_search_funnel": "Configuratore di ricerca auto",
    "background_image": "Immagine di sfondo",
    "background_color": "Colore di sfondo",
    "same_col_height": "Stessa altezza della colonna",
    "pick_up_and_drop_off": "Ritiro e restituzione",
    "pick_up_time": "Orario di ritiro",
    "drop_off_time": "Tempo di ritorno",
    "get_a_quote": "Determinare i prezzi",
    "select_location": "Selezionare una località",
    "type_address_to_start_search": "Inserire un indirizzo per avviare la ricerca",
    "menu_version": "Versione del menu",
    "menu_transparent": "Trasparente",
    "menu_dark": "Scuro",
    "menu_light": "Luce",
    "logo_version": "Versione del logo",
    "logo_default": "Utilizzare il logo standard",
    "logo_scroll": "Utilizzare il logo a scorrimento",
    "select_a_pick_up_location": "Selezionare prima un luogo di ritiro",
    "could_not_find_location_please_enter_more_details": "Non siamo riusciti a trovare un luogo. Inserire il luogo di ritiro con ulteriori dettagli",
    "client_not_found": "Cliente non trovato",
    "order_will_directly_be_marked_paid": "L'ordine viene immediatamente contrassegnato come pagato",
    "invoice_will_directly_be_marked_paid": "La fattura viene immediatamente contrassegnata come pagata",
    "rental_period": "Periodo di noleggio",
    "change_booking_dates": "Modifica dei dati di prenotazione",
    "per_day": "al giorno",
    "buy_now": "Acquista ora",
    "book_now": "Prenota ora",
    "rent_car_now": "Noleggia ora",
    "commit_to_buy": "Ordine con obbligo di pagamento",
    "back_to_cart": "Torna al carrello",
    "equipment": "Attrezzatura",
    "technical_specifications": "Dati tecnici",
    "cancel_checkout_question": "Annullare il checkout?",
    "how_it_works": "Come funziona",
    "secure_payment": "Pagamento sicuro",
    "top_services": "Servizi top",
    "if_you_buy_this_car_you_will_get_lots_of_services": "Quando acquisterete quest'auto, non abbiate paura delle pratiche burocratiche. Se lo desiderate, possiamo organizzare l'immatricolazione, l'assicurazione e il prestito dell'auto. È sufficiente selezionare il servizio desiderato al momento del pagamento.",
    "need_more_info_question": "Avete bisogno di ulteriori informazioni?",
    "contact_dealer": "Contatto con il rivenditore",
    "move_around_to_see_changes": "Muoversi per vedere le modifiche",
    "lead_catchers": "Cacciatori di piombo",
    "lead_catcher": "Capocannoniere",
    "advanced_routing": "Routing avanzato",
    "add_route": "Aggiungi percorso",
    "source": "Fonte",
    "can_be_ordered_online": "Può essere ordinato online",
    "count_years_warranty": "{conteggio} {conteggio, plurale, uno {anno} altri {anni}} Garanzia",
    "amount_online_advantage": "{Vantaggio online",
    "your_order": "Il vostro ordine",
    "file_name": "Nome del file",
    "tags": "Tag",
    "save_and_set_image": "Salvataggio e impostazione",
    "choose_or_drop_file_to_upload": "Selezionare o trascinare un file sulla pagina di caricamento.",
    "media_formats_supported": "Sono supportati JPG e PNG.",
    "max_file_upload_size": "La dimensione massima del file è di 15 MB",
    "no_files_found": "Nessun file trovato.",
    "upload_file_by_dragging_over": "Caricare un file semplicemente trascinandolo sulla pagina",
    "your_car_is_ready_to_check_out": "L'auto dei vostri sogni è pronta per voi",
    "choosing_one_of_our_cars_is_a_good_decision": "Ancora pochi passi per arrivare al veicolo dei vostri sogni. Approfittate dei nostri vantaggi online.",
    "your_car_will_be_delivered_to_you": "Il veicolo viene consegnato nel luogo desiderato",
    "your_will_get_a_amount_discount_for_ordering_online": "Per gli ordini online riceverete uno sconto di {importo}",
    "you_will_receive_extended_warranty": "Riceverete un'estensione della garanzia",
    "you_will_get_exclusive_offers_when_using_our_app": "Riceverete offerte esclusive quando utilizzerete la nostra app mobile",
    "you_dont_risk_when_ordering_online": "Non si rischia molto quando si ordina online.",
    "your_car_rent_is_nearly_done": "Il vostro noleggio auto è quasi pronto!",
    "please_continue_with_booking_and_payment": "Procedete con la prenotazione e il pagamento. Ci assicureremo che la vostra auto sia pronta nella data di ritiro selezionata. La prenotazione online vi fa risparmiare tempo!",
    "please_provide_address_delivery_method_payment": "Inserite il vostro indirizzo, selezionate il metodo di spedizione e i dettagli del pagamento.",
    "your_information_is_safe_with_us": "Le vostre informazioni sono al sicuro con noi.",
    "we_offer_parcel_shipment_for_just_amount": "Per la vostra convenienza, offriamo la consegna di un solo pacco per tutti i nostri ordini a solo {importo}.",
    "free_delivery_on_all_our_orders": "Offriamo la consegna gratuita su tutti i nostri ordini.",
    "get_your_car_delivered": "consegna",
    "cant_pick_your_car_yourself_we_got_you_covered": "Cosa succede se trovate l'auto dei vostri sogni e non potete ritirarla personalmente? Non c'è da preoccuparsi. Abbiamo una soluzione. Una volta che l'auto è stata immatricolata e assicurata, un autista fisserà con voi un appuntamento per la consegna e parcheggerà l'auto nel vostro vialetto.",
    "we_are_more_than_happy_to_show_you_around_your_new_car": "Naturalmente, non vediamo l'ora di accogliervi di persona e di mostrarvi la vostra nuova auto.",
    "we_can_complete_your_order_in_shop_for_pickup": "Ma, se lo desiderate, possiamo completare il vostro ordine nel nostro negozio e potete ritirarlo quando avete tempo.",
    "we_will_inform_you_when_we_complete_order_for_pickup": "Vi informeremo quando avremo completato il vostro ordine. Poi aspetteremo pazientemente che lo ritiriate.",
    "we_will_ship_your_order_to_provided_address": "L'ordine verrà spedito con DHL all'indirizzo indicato. Vi informeremo quando l'ordine è stato spedito. Riceverete anche un numero di tracciamento dell'ordine.",
    "we_calculated_route_from_car_to_you": "Abbiamo calcolato il percorso dal luogo in cui si trova l'auto all'indirizzo da lei fornito. Sembra che il nostro autista debba percorrere {distanza}km per portare l'auto davanti alla vostra porta.",
    "service_includes": "Il servizio comprende:",
    "driver_will_call_you_and_schedule_delivery": "Il nostro autista vi chiamerà e vi consegnerà l'auto quando più vi aggrada",
    "your_car_will_be_washed": "La vostra auto viene lavata",
    "your_car_will_be_fully_tanked": "L'auto fa il pieno di benzina",
    "you_will_get_quick_tour_around_car": "Si può fare un breve tour dell'auto",
    "you_can_pick_up_car_yourself": "Avete domande? Il nostro team sarà lieto di aiutarvi.",
    "only_one_step_left": "Solo un altro passo!",
    "check_if_everything_is_correct_and_provide_payment_data": "Controllate che tutto sia corretto, comunicateci i dati per il pagamento e inviate l'ordine.",
    "terms_of_contract_for_additional_services": "Condizioni contrattuali per i servizi aggiuntivi",
    "terms_of_use_marketplace": "Condizioni di utilizzo del mercato",
    "for_the_sales_of_used_vehicles": "Vendita di veicoli usati",
    "for_the_rental_of_vehicles": "Noleggio di veicoli",
    "your_payment_schedule": "Esecuzione del pagamento",
    "shipping_pickup": "Collezione",
    "shipping_parcel": "Pacchetto",
    "shipping_transport": "Trasporto",
    "car_interior": "Accessori per interni",
    "car_exterior": "Accessori esterni",
    "we_are_processing_your_order_please_wait": "Stiamo elaborando il suo ordine. Attendere prego...",
    "we_are_processing_your_booking_please_wait": "Stiamo elaborando la sua prenotazione. Attendere prego...",
    "your_order_was_completed_successfully": "L'ordine è stato completato con successo.",
    "we_created_a_user_profile": "Abbiamo creato un profilo utente per voi",
    "check_your_email": "Controllare la posta in arrivo",
    "your_orders": "{uno {numero d'ordine} altri {numeri d'ordine}}:",
    "first_experience_with_our_shop_saved_profile": "Sembra che questa sia la sua prima esperienza con il nostro negozio online. Abbiamo salvato le informazioni del suo profilo nel nostro database. Per continuare a elaborare il suo ordine, la preghiamo di controllare la sua casella di posta elettronica e di confermare il suo indirizzo e-mail.",
    "order_details_sent_to_email_check_history_for_details": "Tutti i dettagli dell'ordine sono stati inviati al vostro indirizzo e-mail. È possibile visualizzare la cronologia e lo stato dell'ordine in qualsiasi momento nel proprio account utente.",
    "timeline": "Linea temporale",
    "promotions": "Azioni",
    "payment_method": "Metodo di pagamento",
    "first_payment": "1. pagamento",
    "second_payment": "2. pagamento",
    "shipping_method": "Metodo di spedizione",
    "payment_1_e_payment_to_secure_your_order": "Deposito tramite pagamento elettronico per prenotare il veicolo.",
    "payment_2_choose_payment_method_for_the_car": "Selezionate un metodo di pagamento per il vostro veicolo.",
    "payment_escrow": "Conto deposito",
    "payment_car_credit": "Finanziamento",
    "full_refund": "Rimborso completo",
    "partial_refund": "Rimborso parziale",
    "cancel_order": "Annullamento dell'ordine",
    "create_shipping": "Creare l'invio",
    "schedule_car_registration": "Autorizzazione",
    "schedule_transport": "Pianificare il trasporto",
    "is_published": "Viene pubblicato",
    "no_salesman_available": "Nessun venditore è attualmente disponibile",
    "any_questions": "Domande?",
    "feel_free_to_ask_our_online_sales_team": "Basta contattare il nostro team di vendita online",
    "please_complete_your_details": "Compilate i vostri dati",
    "registration_is_stressfull_get_your_car_registered": "Per alcune persone immatricolare un'auto è stressante e richiede molto tempo. Ottenete l'immatricolazione dell'auto a vostro nome senza problemi.",
    "review_your_order": "Controllare l'ordine",
    "in_order_to_confirm_identity_please_complete_your_details": "Per confermare la vostra identità, abbiamo bisogno di alcune informazioni aggiuntive:",
    "payment_instructions_will_be_provided_after_seller_accepts_order": "Il venditore vi fornirà istruzioni dettagliate sul pagamento dopo l'accettazione dell'ordine.",
    "nationality": "Nazionalità",
    "date_of_birth": "Data di nascita",
    "place_of_birth": "Luogo di nascita",
    "discover_more": "Scoprire di più",
    "more_of_product": "Altro {prodotto}",
    "more_of_product_in_city": "Più {prodotto} in {città}",
    "order_placed": "Ordine effettuato",
    "show_order_details": "Mostra i dettagli dell'ordine",
    "white_label_plugins": "Plugin a marchio bianco",
    "white_label_plugin": "Plugin a marchio bianco",
    "enhance_chedri_project_with_plugins": "Migliorare l'esperienza di Creacheck con i plugin",
    "plugin": "Plugin",
    "plugins": "Plugin",
    "enhance_your_project_with_plugins": "Potete scegliere la quantità di funzionalità di Creacheck di cui avete bisogno. Verificate i plugin disponibili, le loro funzioni e i prezzi.",
    "week": "settimana",
    "month": "mese",
    "quarter": "Trimestre",
    "year": "Anno",
    "more_infos": "Ulteriori informazioni",
    "less_infos": "Meno informazioni",
    "activate_plugin": "Attivare il plugin",
    "partially_activate_plugin": "Attivazione del plugin Sign",
    "terminate_plugin": "Uscita dal plugin",
    "detailed_description": "Descrizione dettagliata",
    "installation_instructions": "Istruzioni per l'installazione",
    "you_are_not_an_adminitrator": "Non siete un amministratore",
    "creditor_id": "ID creditore",
    "i_understand_the_cost_and_want_activate_plugin": "Comprendo i costi e desidero attivare il plugin.",
    "i_understand_the_running_cost_when_terminating_plugin": "Sono a conoscenza dei costi che verranno addebitati alla fine del periodo di fatturazione.",
    "are_you_sure_you_want_to_terminate_plugin": "Volete davvero interrompere il plugin?",
    "security_settings": "Impostazioni di sicurezza",
    "limit_write_rights": "Limitare i permessi di scrittura",
    "limit_read_rights": "Limitare i diritti di lettura",
    "edit_your_company_data": "Qui potete modificare i dati della vostra azienda. Modificare i dati di contatto e finanziari utilizzati per la fatturazione",
    "latest_order": "Ultimo ordine",
    "latest_orders": "Ultimi ordini",
    "latest_invoices": "Ultime fatture",
    "latest_editions": "Ultima modifica",
    "overdue_invoices": "Fatture in scadenza",
    "view_all": "Mostra tutti",
    "i_accept": "Accetto",
    "accept_terms_before_continuing": "Prima di continuare, è necessario accettare i nostri termini e condizioni e l'informativa sulla privacy.",
    "approve_car_sale": "Autorizzare la vendita dell'auto",
    "enter_vin_number": "Inserire il numero di telaio del veicolo",
    "mark_as_paid": "Contrassegnare come pagato",
    "enter_ein_number": "Inserire la conferma elettronica dell'assicurazione",
    "send_payment_instructions": "Inviare le istruzioni di pagamento",
    "submit_vin_number": "Inviare il numero di telaio del veicolo",
    "approve_and_inform_client": "Autorizzare e informare il cliente",
    "submit_ein_number": "Invio di una conferma elettronica dell'assicurazione",
    "ein": "Conferma elettronica dell'assicurazione",
    "payment_reference": "Riferimento di pagamento",
    "upload_sales_cotract": "Caricare il contratto di vendita",
    "cancellation_reason": "Motivo della cancellazione",
    "enter_bank_data": "Inserire le coordinate bancarie",
    "pickup_invite": "Invito al ritiro",
    "mark_picked_up": "Contrassegnare come raccolto",
    "refunds": "Rimborsi",
    "sent_on_date": "Inviato il {data}",
    "not_sent_yet": "Non ancora spedito",
    "pick_up_location": "Luogo di ritiro",
    "emails": "Email",
    "use_following_list_to_personalize_your_emails": "Utilizzate il seguente elenco per personalizzare le e-mail:",
    "recipients_first_name": "Nome del destinatario",
    "recipients_last_name": "Cognome del destinatario",
    "senders_company_name": "Nome della società mittente",
    "system_mail_registration_description": "Inviato quando un nuovo utente si registra sul sito web",
    "system_mail_invitation_description": "Inviato quando un dipendente crea un nuovo utente con un indirizzo email",
    "system_mail_car_rental_pickup_reminder_description": "Inviato un giorno prima che il cliente ritiri l'auto a noleggio",
    "system_mail_car_rental_dropoff_reminder_description": "Inviato un giorno prima della consegna dell'auto a noleggio da parte del cliente",
    "terms_of_use": "Condizioni di utilizzo",
    "contract": "Contratto",
    "order_data_processing": "Contratto di elaborazione degli ordini",
    "i_read_and_accept_plugins_terms_of_use": "Ho letto e compreso le condizioni di utilizzo e le accetto espressamente.",
    "i_read_and_accept_plugins_contract": "Ho letto e compreso il contratto e lo accetto espressamente.",
    "i_read_and_accept_plugins_order_data_processing": "Ho letto e compreso il trattamento dei dati dell'ordine e lo accetto espressamente.",
    "send_activation_request": "Inviare la richiesta di attivazione",
    "missing_companys_bank_data": "Sembra che manchino le coordinate bancarie della vostra azienda.",
    "you_can_fill_missing_data_in_settings": "È possibile inserire i dati mancanti in {impostazioni}.",
    "not_sole_representative_click_below_to_request_plugin_activation": "Non siete autorizzati ad attivare i plugin da soli. Fare clic sul pulsante per attivare il plugin. All'utente autorizzato viene richiesto di completare il processo di attivazione.",
    "not_authorized_click_below_to_request_plugin_activation": "Non siete autorizzati ad attivare i plugin. Fare clic sul pulsante per inviare una richiesta di attivazione agli utenti autorizzati.",
    "select": "Selezionare",
    "enter_app_secret": "Inserire il segreto dell'applicazione",
    "enter_page_access_token": "Inserire il token di accesso alla pagina",
    "legal": "Legale",
    "activation_pending": "In attesa di attivazione",
    "termination_pending": "Cancellazione in corso",
    "form_contains_errors": "Ci sono alcuni problemi nella forma",
    "internal_car_reference": "Riferimento auto interno",
    "system_debtor_number": "Numero di debitore del sistema",
    "internal_company_reference": "Riferimento aziendale interno",
    "internal_location_reference": "Riferimento alla posizione interna",
    "show_notes": "Note sullo spettacolo",
    "show_my_notes": "Mostra i miei appunti",
    "show_by_me": "Mostra da parte mia",
    "items": "Prodotti",
    "send_text_message": "Inviare SMS",
    "wrote_message": "Messaggio scritto",
    "sent_text_message": "Un SMS inviato",
    "wrote_note": "Nota scritta",
    "please_check_document_check_expiration_date": "Controllare il documento. Assicuratevi di aver visto il documento. Controllare la data di scadenza",
    "i_reviewed_the_document": "Ho controllato il documento",
    "receive_updates_in_messenger": "Volete ricevere gli aggiornamenti su Messenger?",
    "weve_received_your_request_and_will_contact_you": "Abbiamo ricevuto la sua richiesta e la contatteremo al più presto.",
    "check_your_email_to_confirm": "Si prega di controllare la posta elettronica per confermare l'invio di questo modulo di contatto.",
    "response_text": "Testo della risposta",
    "entries": "Entrate",
    "timeline_block": "Blocco della linea temporale",
    "external_accounts": "Conti esterni",
    "send_copy_to_me": "Inviatemi una copia",
    "user_tracking": "Tracciamento degli utenti",
    "select_model": "Selezionare il modello",
    "select_model_group": "Selezionare il gruppo di modelli",
    "equipment_level_budget": "Bilancio",
    "equipment_level_family": "Famiglia",
    "equipment_level_comfort": "Comfort",
    "equipment_level_business": "Affari",
    "salutation": "Saluto",
    "getting_started": "Primi passi",
    "set_your_locations": "Definire le sedi",
    "set_up_locations_for_your_car_business_set_services_opening_hours_upload_photos": "Ogni veicolo richiede una posizione tra cui i clienti possono scegliere. Andate nelle impostazioni, dove potrete definire tutte le sedi desiderate. È possibile decidere quali servizi offrire in ogni sede, impostare gli orari di apertura e caricare foto per facilitare ai clienti la ricerca della vostra attività.",
    "now_that_locations_are_set_create_users_so_they_can_work_together": "Ora che i vostri clienti sanno dove lavorate, fateci sapere chi lavora per voi. Create degli utenti in modo che possano collaborare con le tecnologie di Creacheck.",
    "cms_content": "Contenuto del CMS",
    "this_is_blank_page_use_editor_to_create_great_one": "Questa è una pagina vuota. Nel nostro editor visuale è possibile creare una bella pagina con grafica, bella tipografia e compatibile con tutti i dispositivi.",
    "cars_to_sell": "Veicoli in vendita",
    "your_business_is_car_sales_but_there_are_no_cars_create_yourself_or_use_import": "Il vostro mestiere è vendere veicoli. Il nostro è quello di rendervi le cose più facili. Non avete ancora inserito alcun veicolo nel nostro sistema. Utilizzate semplicemente la comoda funzione di importazione dei veicoli o inseriteli manualmente.",
    "create_manually": "Creare manualmente",
    "import": "Importazione",
    "car_import": "Veicoli di importazione",
    "most_car_dealers_use_mobilede_enter_your_interface_username_and_password_to_import": "Come abbiamo scoperto, la maggior parte dei concessionari di auto utilizza mobile.de per presentare le proprie auto. È quindi molto probabile che abbiate accesso al servizio web. Inserite il vostro nome utente e la vostra password. Importeremo automaticamente tutte le vostre auto e manterremo sincronizzato il vostro elenco. Se avete più dati di accesso, potete inserirne altri nel vostro profilo.",
    "we_will_import_count_cars_for_this_username": "Importeremo {count} auto per questo nome utente",
    "mobile_de_username": "Nome utente API del venditore di Mobile.de",
    "mobile_de_password": "Mobile.de Seller API Password",
    "missing_bank_data": "Dati bancari mancanti",
    "we_dont_have_your_bank_data": "A quanto pare non abbiamo le sue coordinate bancarie.",
    "monthly_billing_relies_on_sepa_direct_debit_fill_in_bank_data_in_settings": "La nostra fatturazione mensile si basa sull'addebito diretto SEPA. Poiché non disponiamo di dati bancari, non è possibile utilizzare molti dei nostri plugin. Andate nelle impostazioni e inserite le vostre coordinate bancarie.",
    "fill_in_bank_data": "Inserire le coordinate bancarie",
    "sepa_mandate": "Mandato SEPA",
    "sepa_mandates": "Mandati SEPA",
    "missing_sepa_mandate": "Manca un mandato SEPA",
    "changed_bank_data_please_generate_new_sepa_mandate": "Sembra che di recente abbiate cambiato il numero di conto corrente. Per automatizzare il nostro processo di fatturazione mensile e creare meno stress per voi, vi preghiamo di creare un nuovo mandato SEPA.",
    "generate_sepa_mandate_now": "Generare un nuovo mandato SEPA",
    "columns": "Colonne",
    "send_doc_as_message": "Inviare il documento al cliente (e-mail)",
    "alternative_recepient": "Ricevitore alternativo",
    "enter_email_address": "Inserire un indirizzo e-mail valido",
    "job_title": "Titolo del lavoro",
    "service_point": "Servizio",
    "rental_point": "Base di noleggio",
    "seller_data": "Dati del venditore",
    "car_location": "Posizione del veicolo",
    "save_data_before_nav_to_pathname": "I dati non verranno salvati. Volete passare a {pathname} senza salvare?",
    "share": "Condividi",
    "assignee_changed_from_assignee_to_assignee": "Il cessionario è stato cambiato da %oldAssignee% a %newAssignee%.",
    "stage_changed_from_name_to_name": "Il livello è stato cambiato da %oldName% a %newName%.",
    "handover_filled_out": "Il protocollo di consegna del veicolo è stato completato",
    "data_changed": "Dati aggiornati",
    "key": "chiave",
    "old_value": "Vecchio valore",
    "new_value": "Nuovo valore",
    "no_details_available": "Non sono disponibili informazioni dettagliate",
    "services_we_offer_in_city": "Servizi che offriamo per voi in {città}",
    "damages_present": "Danno presente",
    "user_is_online": "L'utente è online",
    "user_is_offline": "L'utente è offline",
    "click_to_start_chat": "Fare clic qui per avviare la chat",
    "click_to_view_chat_history": "Fare clic qui per visualizzare la cronologia della chat",
    "theme_colors": "Colori del tema",
    "logos": "Loghi",
    "send_invitation_with_username_and_password": "Inviare l'invito con nome utente e password (solo per i nuovi utenti)",
    "cookie_settings": "Dettagli sui cookie",
    "accept_only_necessary_cookies": "Accettare solo i cookie necessari",
    "accept_all_cookies": "Accettare tutti i cookie",
    "privacy_preference_center": "Centro di preferenza per la protezione dei dati",
    "your_privacy": "La vostra privacy",
    "basic_information_on_cookies": "Quando visitate un sito web, le informazioni possono essere memorizzate o recuperate nel vostro browser, di solito sotto forma di cookie. Queste informazioni possono riguardare l'utente, le sue preferenze o il suo dispositivo. Queste informazioni vengono utilizzate principalmente per far funzionare il sito web come previsto. In genere non identificano direttamente l'utente, ma possono fornire un'esperienza web più personalizzata. Poiché rispettiamo il vostro diritto alla privacy, potete scegliere di non consentire alcuni tipi di cookie. Selezionare una categoria per saperne di più e modificare le impostazioni predefinite. Tuttavia, il blocco di alcuni tipi di cookie può influire sulla vostra esperienza del sito web e sui servizi che siamo in grado di offrire.",
    "necessary_cookies": "Cookie necessari",
    "performance_cookies": "Cookie di prestazione",
    "targeting_cookies": "Cookie di targeting",
    "always_active": "Sempre attivo",
    "cookies_used": "Cookie utilizzati",
    "necessary_cookies_information": "Questi cookie sono necessari per il funzionamento del sito web e non possono essere disabilitati nei nostri sistemi. Di solito vengono impostati solo in risposta ad azioni che l'utente compie in relazione a una richiesta di servizio, come l'impostazione delle preferenze sulla privacy, il login o la compilazione di moduli. È possibile impostare il browser in modo da bloccare o avvisare l'utente di questi cookie, ma alcune parti del sito web non funzioneranno. Questi cookie non memorizzano alcuna informazione di identificazione personale.",
    "save_settings": "Salva le impostazioni",
    "save_and_continue": "Salvare e continuare",
    "priority": "Priorità",
    "closed_leads": "Condotti chiusi",
    "show": "Display",
    "hide": "Nascondino",
    "first_registration_from": "Prima registrazione da",
    "first_registration_to": "Prima registrazione fino a",
    "power_from": "Potenza del motore da",
    "power_to": "Potenza del motore fino a",
    "mileage_from": "Chilometraggio da",
    "mileage_to": "Chilometraggio per",
    "included_mileage": "{chilometri",
    "really_cancel": "Cancellare davvero?",
    "unsaved_information_will_be_lost": "I dati non salvati vengono persi",
    "client_signature": "Firma del cliente",
    "another_photo": "Un'altra foto",
    "try_again": "Riprova",
    "back_to_vehicle": "Ritorno al veicolo",
    "image_upload_finished": "Il caricamento delle immagini è completo",
    "you_have_no_internet_connection": "Non si dispone di una connessione a Internet",
    "waiting_to_reconnect": "In attesa della riconnessione",
    "plus_count_more": "+ {conteggio} altro",
    "document_is_missing": "Manca un documento",
    "upload_document_name_now": "Caricare ora il documento '{nome}",
    "lead_per_mail": "Regola del lead by e-mail",
    "create_rules_to_forward_emails": "Creare regole per inoltrare le e-mail in copia ad altri utenti. Se il 'Data e-mail plugin' è attivato, potete anche inoltrare le e-mail con informazioni importanti in formato XML direttamente a Customer One.",
    "lead_per_mail_rules": "Regole di Lead by e-mail",
    "include_xml_data_in_email": "Inserire dati in formato XML nell'e-mail",
    "sad_activity": "Attività SAD",
    "e_payments": "Pagamenti elettronici",
    "stripe_connect": "Stripe Connect",
    "we_will_handle_epayments_for_you": "Con Stripe Connect, possiamo elaborare i pagamenti con carta di credito per vostro conto. Dopo aver fatto clic su \"Connect to Stripe\", potete utilizzare il vostro account Stripe esistente per connettervi alla piattaforma Creacheck o creare un nuovo account Stripe se non ne avete ancora uno. Con il proprio account Stripe, è possibile vedere l'intera cronologia dei pagamenti e prelevare denaro in qualsiasi momento.",
    "connect_with_stripe": "Connettetevi con Stripe",
    "stripe_accounts": "Conti Stripe",
    "stripe_account_connected": "Il vostro conto Stripe è ora collegato",
    "stripe_accounts_are_connected": "Il vostro conto Stripe è ora collegato a {nomeProgetto}. {nomeProgetto} può ora accettare pagamenti con carta di credito a vostro nome.",
    "money_won": "Soldi vinti",
    "leads_won": "Contatti acquisiti",
    "offered_by": "Il vostro concessionario auto",
    "pay_with_saved_credit_card": "Pagare con la carta di credito memorizzata",
    "pay_with_new_credit_card": "Pagare con una nuova carta di credito",
    "settings": "Impostazioni",
    "account": "Funzioni",
    "clients": "Aziende",
    "payments": "Pagamenti",
    "calendar": "Calendario",
    "contact": "Contattateci",
    "login": "Accedi",
    "logout": "Disconnessione",
    "previous_odometer_reading": "Lettura del contachilometri precedente",
    "current_odometer_reading": "Chilometraggio attuale",
    "odometer_reading": "Chilometraggio",
    "tank_volume": "Volume del serbatoio",
    "vehicle_fuel_costs": "Costi di rifornimento del veicolo",
    "mileage_surcharge": "Supplemento chilometrico aggiuntivo",
    "insurance_self_participation": "Franchigia assicurativa",
    "additional_costs_due": "Costi aggiuntivi dovuti",
    "no_additional_costs": "Nessun costo aggiuntivo",
    "charge_customer_additional_costs": "Addebitare al cliente costi aggiuntivi",
    "warning_you_chose_not_to_charge_customer_for_additional_costs": "Attenzione! Avete deciso di non addebitare al cliente alcun costo aggiuntivo",
    "cancel_booking": "Annullamento della prenotazione",
    "no_invoices_found": "Nessuna fattura trovata",
    "upload_drivers_license": "Caricare la patente di guida",
    "order_details": "Dettagli dell'ordine",
    "shipping_address": "Indirizzo di spedizione",
    "order_overview": "Panoramica dell'ordine",
    "booked_by_mistake": "Prenotato per errore",
    "booked_wrong_dates": "Date sbagliate prenotate",
    "found_better_price": "Prezzo migliore trovato",
    "change_of_plans": "Cambio di piano",
    "client_called_to_cancel": "Il cliente ha chiamato e ha chiesto di cancellare",
    "client_wrote_email_to_cancel": "Il cliente ha scritto un'e-mail e ha chiesto di annullare il servizio",
    "car_damaged_before_transport": "Il veicolo è stato danneggiato prima del trasporto",
    "car_damaged_in_transport": "Il veicolo è stato danneggiato durante il trasporto",
    "item_not_available": "Articolo non più disponibile",
    "other_reason": "Altro",
    "license_plate_number": "Targa",
    "open_in_google_maps": "Aprire in Google Maps",
    "access_identifier": "Codice di accesso",
    "branch": "Ramo",
    "new": "Nuovo",
    "in_progress": "In corso",
    "failed": "Fallito",
    "not_started": "Non iniziato",
    "finished": "Finito",
    "failed_refunds": "Rimborsi non riusciti",
    "list_all_failed_refund_payments": "Elenco di tutti i rimborsi non andati a buon fine con i relativi dettagli",
    "rent_sync_vamoso_reservation_success": "Prenotazione effettuata con successo nel sistema Vamoso. Il numero di prenotazione è %reservationId%.",
    "rent_sync_vamoso_reservation_failure": "Non è stato possibile prenotare l'auto nel sistema Vamoso. Il sistema ha restituito il seguente messaggio di errore: \"%errorText%\".",
    "rent_sync_vamoso_cancellation_success": "La prenotazione nel sistema Vamoso è stata annullata con successo",
    "rent_sync_vamoso_cancellation_failure": "Non è stato possibile annullare la prenotazione nel sistema Vamoso. Il sistema restituisce il seguente messaggio di errore: \"%errorText%\".",
    "rent_sync_vamoso_booking_success": "Prenotazione riuscita nel sistema Vamoso",
    "rent_sync_vamoso_booking_failure": "La prenotazione nel sistema Vamoso non è stata possibile. Il sistema ha restituito il seguente messaggio di errore: \"%errorText%\".",
    "rent_sync_vamoso_return_success": "Il veicolo è stato contrassegnato come restituito nel sistema Vamoso.",
    "rent_sync_vamoso_return_failure": "Non è stato possibile restituire l'auto al sistema Vamoso. Il sistema ha restituito il seguente messaggio di errore: \"%errorText%\".",
    "view_lead_details": "Mostra i dettagli della pista",
    "change_category": "Cambia categoria",
    "json_url_parameters": "Parametri URL JSON",
    "layout_version": "Versione layout",
    "layout_wide": "Ampio",
    "layout_narrow": "Stretto",
    "layout_detailed": "Dettagliato",
    "layout_slider": "Cursore",
    "layout_list": "Elenco",
    "search_version": "Versione del modulo di ricerca",
    "not_sure_go_to_advisory_mode": "Non ne sono sicuro: ecco il {link}",
    "link_to_advisory_mode": "Modalità di consulenza",
    "desired_pickup_location": "Il luogo di ritiro desiderato",
    "incl_short": "incl.",
    "included": "Incluso",
    "included_services": "Servizi inclusi",
    "insurance": "Assicurazione",
    "liability_and_comprehensive_insurance_with_excess_amount_of_amount_per_claim": "Assicurazione di responsabilità civile e casco totale con una franchigia di {importo} per sinistro",
    "tyres_suitable_for_winter": "Pneumatici invernali",
    "additional_driver": "Conducente aggiuntivo",
    "on_request": "su richiesta",
    "navigation_system": "Sistema di navigazione",
    "child_seat": "Seggiolino per bambini",
    "order_comments_and_special_wishes": "Ulteriori desideri",
    "ordering_as_person": "Ordino come privato",
    "ordering_as_company": "Ordino come azienda",
    "filter_by_date": "Filtrare per data",
    "car_rental_max_days_count_exceeded": "Purtroppo il periodo selezionato supera il nostro periodo massimo di noleggio di {conteggio, plurale, un {#giorno} altri {#giorni}}.",
    "require_document_number": "Numero del documento richiesto",
    "require_document_issuing_city": "Richiedere il luogo di emissione del documento",
    "require_document_issuing_authority": "Richiesta dell'autorità di emissione del documento",
    "require_document_issue_date": "Richiesta della data di emissione del documento",
    "require_document_expiration_date": "Richiedere la data di scadenza del documento",
    "doc_number": "Numero del documento",
    "doc_issuing_city": "Luogo di emissione del documento",
    "doc_issuing_authority": "Autorità di emissione del documento",
    "doc_issue_date": "Data di emissione del documento",
    "doc_expiration_date": "Data di scadenza del documento",
    "chedri_plugins": "Plugin Creacheck",
    "in_order_to_use_our_software_in_its_full_potential_you_need_to_activate_plugins_in_order_to_do_that_please_confirm_that_you_may_sign_contracts_on_behalf_of_your_company": "<p>Per poter utilizzare il nostro software, è necessario attivare le funzioni desiderate. Ogni funzione viene fornita sotto forma di plugin, che di solito è accompagnato da un obbligo di pagamento (tassa).<br/>Quando attivate un plugin, vi verrà chiesto di accettare i termini e le condizioni del plugin. Tuttavia, prima di procedere, è necessario confermare di essere autorizzati ad agire per conto della propria azienda.</p",
    "i_declare_that_i_am_entitled_to_legally_sign_contracts_on_behalf_of_my_company": "Dichiaro di essere autorizzato a firmare contratti legalmente vincolanti per conto della mia azienda, {azienda}",
    "your_company_was_successfully_registered": "Abbiamo registrato con successo voi e la vostra azienda nel nostro sistema.",
    "check_email_for_more_instructions_about_your_company_registration": "Abbiamo inviato le informazioni su come procedere all'indirizzo e-mail indicato. La preghiamo di controllare la sua casella di posta. Se avete domande o non sapete come procedere, contattateci.",
    "dependent_on": "A seconda di",
    "we_have_more_plugins_that_further_enhance_the_functionality": "Abbiamo altri plugin che migliorano ulteriormente la funzionalità di {plugin}.",
    "finish_plugin_configuration": "Uscita dalla configurazione del plugin",
    "it_seems_that_you_havent_finished_configuring_plugins": "Sembra che non sia stata ancora completata la configurazione dei plugin attivati. Per alcuni plugin è necessario definire dati di accesso esterni (ad esempio per l'interfaccia mobile.de o per i dati di accesso di Vamoso). Andare nelle impostazioni e aggiungere gli account mancanti.",
    "lead_created_from_an_email": "Questo lead è stato creato da un'e-mail",
    "show_original_message": "Mostra il messaggio originale",
    "we_found_count_similar_leads": "Abbiamo trovato {conteggio, plurale, una {# pista simile} altre {# piste simili}}.",
    "show_similiar_leads": "Mostra le piste simili",
    "new_lead_from_failed_refund": "Nuova pista a causa del mancato rimborso",
    "you_can_apply_company_data_from_company_profile": "È possibile trasferire i dati aziendali dal profilo aziendale",
    "load_company_data": "Caricare i dati aziendali",
    "company_lookup": "Ricerca di aziende",
    "match_lead_tags": "Sincronizzare le etichette dei contatti",
    "is_active": "È attivo",
    "is_renew_password_at_next_login": "Rinnovare la password al prossimo accesso",
    "no_overdue_tasks": "Non ci sono attività in ritardo",
    "great_job": "Ben fatto!",
    "no_leads_for_today": "Nessun indizio per voi oggi",
    "address_not_set": "Indirizzo non impostato",
    "statistics": "Statistiche",
    "select_assignee": "Selezionare un editor",
    "kroschke_order_in_progress": "L'ordine Kroschke con ID veicolo %kroschkeVehicleId% è stato inizializzato con successo.",
    "in_original": "Nell'originale",
    "a_copy": "Una copia",
    "mark_documents_sent": "Contrassegnare i documenti come inviati",
    "i_sent_the_documents": "Ho inviato i documenti",
    "documents_were_sent": "I documenti sono stati inviati",
    "documents_sent": "Documenti inviati",
    "define_custom_license_plate_number": "Definire la targa preferita",
    "about_car_registration": "Il servizio di autorizzazione comprende",
    "i_would_like_individual_license_plate_number": "Sì, desidero selezionare il mio personaggio desiderato",
    "we_gather_necessary_documents": "Registrazione presso l'autorità preposta al rilascio delle licenze",
    "we_produce_license_plates": "Acquisto e montaggio di targhe",
    "license_plate_reservation_number": "Numero di prenotazione della targa",
    "with_custom_license_plate_number": "Con targa",
    "after_the_order_reserve_desired_license_plate_at_local_registration_office": "Dopo l'ordine, è necessario prenotare la targa desiderata online presso l'ufficio di immatricolazione locale.",
    "inform_us_about_your_license_plate_reservation_so_we_can_start_the_process": "Una volta fatto questo, vi preghiamo di comunicarci il numero di targa desiderato e il numero di prenotazione (che avete ricevuto dall'ufficio immatricolazioni).",
    "clarify": "Chiarire",
    "notification": "Comunicazione",
    "license_plate_reservation_data_missing": "Mancano i dati di prenotazione della targa",
    "license_plate_reservation_number_filled_out": "Dati di prenotazione per le targhe inserite. Numero di targa: %licensePlateNumber%. Numero di prenotazione: %reservationNumber%.",
    "distance_planned": "Percorso pianificato",
    "distance_driven": "Percorso effettuato",
    "planned_pickup_date": "Data di raccolta prevista",
    "pickup_date": "Data di raccolta",
    "planned_delivery_date": "Consegna programmata",
    "your_initial_offer": "La vostra prima offerta",
    "your_next_offer": "La vostra prossima offerta",
    "car_number": "Numero del veicolo",
    "send_price_proposal": "Inviare una proposta di prezzo",
    "our_price": "Il nostro prezzo",
    "purchase_price": "Prezzo di acquisto",
    "our_prices_are_already_optimized_but_if_you_think_price_is_too_high_negotiate_it": "Cerchiamo di ottimizzare i nostri prezzi per voi, ma se pensate che il prezzo sia ancora troppo alto, fateci sapere cosa possiamo fare per voi.",
    "select_the_car_you_love": "Selezionare l'auto desiderata",
    "sign_up_or_log_in_if_you_already_have_an_account": "Registrati o accedi se hai già un account",
    "enter_desired_price": "Inserire il prezzo desiderato",
    "send_the_form_our_employee_will_contact_you": "Inviare il modulo. Un nostro collaboratore vi contatterà al più presto",
    "if_we_settle_on_a_price_you_can_buy_the_car_online": "Se concordiamo un prezzo, avete la possibilità di acquistare l'auto direttamente sul nostro sito web.",
    "you_have_to_log_in_to_proceed": "È necessario effettuare il login per continuare",
    "rent_a_car": "Noleggiare un veicolo",
    "pending_price_negotiations_count": "In attesa di {conteggio, plurale, una {# proposta di prezzo} altre {# proposte di prezzo}}",
    "my_price_negotiation": "Il mio suggerimento di prezzo",
    "price_negotiation": "Proposta di prezzo",
    "price_negotiations": "Proposte di prezzo",
    "new_price_negotiation_request": "Nuova richiesta di negoziazione del prezzo",
    "expired": "Scaduto",
    "first_offer": "Prima offerta",
    "current_offer": "Offerta attuale",
    "expires": "Scadenza",
    "accept_the_offer": "Accettare l'offerta",
    "send_a_new_offer": "Invia una nuova offerta",
    "your_new_offer": "La vostra nuova offerta",
    "your_project_dashboard": "Il cruscotto del vostro {progetto}",
    "add_my_vehicle": "Aggiungi il mio veicolo",
    "no_reply_yet": "Ancora nessuna risposta",
    "employee_replied": "Il dipendente ha risposto",
    "price_negotiation_details": "Dettagli della proposta di prezzo",
    "current_offer_is_amount_you_can_now_proceed_to_checkout": "L'offerta attuale è {importo}. È possibile procedere al checkout con il prezzo negoziato.",
    "show_timeline": "Mostra la storia",
    "communities": "Comunità",
    "gain_coverage_with_community": "Aumentate la vostra portata con nuove piattaforme. Acquisite nuovi clienti con un solo clic",
    "community": "Comunità",
    "is_community": "Comunità attiva",
    "join_community": "Entrare nella comunità",
    "leave_community": "Lasciare la comunità",
    "join_jahreswagen_community_and_profit_from_additional_sales_channel": "<div><p>Una novità assoluta di Creacheck sono le community. Grazie al software Creacheck, è possibile creare piattaforme a cui possono aderire più aziende.</p><p>Una di queste piattaforme è Jahreswagen.de. Potete dare un'occhiata alle offerte di Jahreswagen. Se volete diventare membri, andate nelle impostazioni della comunità e unitevi alla comunità Jahreswagen.</p><p>L'iscrizione è gratuita. Tuttavia, si applicano delle commissioni di transazione.</p><p className='m-t-20 alert-info'>Se avete una vostra idea per una comunità, contattateci. Troveremo una soluzione per voi.</p></div>",
    "car_description": "Descrizione del veicolo",
    "marketplace_terms": "Condizioni di utilizzo del mercato",
    "online_appointment_booking": "Prenotazione di appuntamenti online",
    "my_online_appointments": "I miei appuntamenti online",
    "employee": "I dipendenti",
    "choice_of_date": "Scelta della data",
    "works": "Lavoro",
    "make_selection": "Selezionare",
    "remove_selection": "Rimuovere",
    "tasks": "Compiti",
    "task": "Compito",
    "car_service": "Servizio",
    "car_services": "servizi",
    "car_service_station": "Postazione di lavoro",
    "car_service_stations": "Postazioni di lavoro",
    "services_offered_at_your_locations": "Definite i servizi nelle vostre sedi.",
    "service_stations_where_your_employees_work": "Definite le postazioni di lavoro in modo da poter monitorare l'utilizzo e vedere le risorse occupate e libere.",
    "team_members": "Membri del team",
    "service_details": "Dettagli del servizio",
    "service_location": "La sede del servizio scelta",
    "service_drop_off_time": "L'orario di servizio selezionato",
    "car_service_appointments": "Date dei workshop",
    "car_service_booked_at": "Appuntamento prenotato",
    "show_booking_details": "Mostra i dettagli della prenotazione",
    "saving_draft": "La bozza viene salvata",
    "draft_saved": "Bozza salvata",
    "define_custom_page_templates_to_meet_your_cd": "Forniamo il sistema Creacheck con modelli integrati. Qui potete creare i vostri modelli personalizzati per adattarli meglio al vostro design aziendale.",
    "edit_description": "Modifica la descrizione",
    "edit_language": "Modifica della lingua",
    "create_blank_page": "Creare una pagina vuota",
    "create_from_template": "Creare da un modello",
    "use_template": "Utilizzare il modello",
    "time_left": "Tempo rimanente",
    "animated_header": "Intestazione animata",
    "header_size": "Dimensione dell'intestazione",
    "options": "Opzioni",
    "effects": "Effetti",
    "animation": "Animazione",
    "parallax": "Parallasse",
    "animation_repeat": "Ripetizione dell'animazione",
    "animation_intensity": "Intensità dell'animazione",
    "animation_duration": "Durata dell'animazione",
    "animation_delay": "Ritardo nell'animazione",
    "animation_initial_opacity": "Opacità iniziale dell'animazione",
    "animation_speed_curve": "Curva di velocità dell'animazione",
    "parallax_intensity": "Intensità della parallasse",
    "animation_direction": "Direzione della parallasse",
    "parallax_initial_opacity": "Potere coprente iniziale della parallasse",
    "append_html": "Allegare HTML",
    "css_classes": "Classi CSS",
    "forward_url": "Reindirizzare a questo URL",
    "effect_fade": "Dissolvenza",
    "effect_slide": "Diapositive",
    "effect_zoom": "Zoom",
    "effect_bounce": "Rimbalzo",
    "effect_flip": "Capovolgere",
    "effect_fold": "Pieghe",
    "effect_roll": "Rulli",
    "direction_center": "Centro",
    "direction_left": "Collegamenti",
    "direction_right": "Diritto",
    "direction_top": "Sopra",
    "direction_bottom": "Sotto",
    "repeat_once": "Una volta",
    "repeat_loop": "Anello",
    "selected_template": "Modello selezionato",
    "premade_layouts": "Layout prefabbricati",
    "your_existing_pages": "Le vostre pagine esistenti",
    "count_templates": "{Modelli",
    "background_attachment": "Appendice di sfondo",
    "background_position": "Posizione di fondo",
    "background_repeat": "Ripetizione dello sfondo",
    "background_size": "Dimensione dello sfondo",
    "edit_mode": "Modalità di modifica",
    "effects_active": "Effetti attivi",
    "document_templates": "Modelli di documento",
    "code_document_templates_for_print_use_the_templates_for_individual_quotes_and_invoices": "Codificate i vostri modelli di documenti per stamparli per i vostri clienti. È inoltre possibile creare versioni personalizzate di preventivi e fatture.",
    "currencies": "Valute",
    "document_template": "Modello di documento",
    "document_content": "Contenuto del documento",
    "number_prefix": "Prefisso numerico",
    "sales_documents": "Documenti di vendita",
    "theme": "Tema",
    "choose_template": "Selezionare il modello di documento",
    "default": "Standard",
    "favorites": "I preferiti",
    "favorites_list": "Elenco dei preferiti",
    "require_client_address": "Richiedere l'indirizzo del cliente",
    "require_client_email": "Richiesta dell'indirizzo e-mail del cliente",
    "require_client_phone": "Richiedere il numero di telefono del cliente",
    "require_message": "Messaggio di richiesta",
    "subscription": "Abbonamento",
    "subscription_plan": "Piano di abbonamento",
    "main_plugin": "Plugin principale",
    "target_organisation": "Organizzazione target",
    "reference_number": "Numero di riferimento",
    "show_invoice": "Mostra fattura",
    "delivery_date": "Data di consegna",
    "test_drive": "Test drive",
    "find_your_perfect_car": "Trova il veicolo perfetto",
    "select_car_design": "Selezionare il design",
    "multiple_selection_possible": "Possibilità di selezione multipla",
    "fuel_type": "Carburante",
    "select_equipment_level": "Selezionare il livello di equipaggiamento",
    "engine": "Motorizzazione",
    "like_budget": "Come il budget",
    "like_family": "Come la famiglia",
    "like_comfort": "Come il comfort",
    "we_did_not_find_any_results_for_your_search": "Siamo spiacenti, la ricerca non ha trovato riscontro",
    "we_did_not_find_any_cars_at_this_location_for_the_selected_period": "Non abbiamo trovato nessuna auto in questo luogo per il periodo selezionato.",
    "we_did_not_find_any_cars_for_your_seaech": "Purtroppo non abbiamo trovato nessuna auto per la tua richiesta di ricerca",
    "all_cars": "Mostra tutte le auto",
    "search_for_cars": "Ricerca di auto",
    "used_car_search": "Ricerca di auto usate",
    "dropdown": "Elenco a discesa",
    "checkboxes": "Casella di controllo",
    "multiple_choice": "Selezione multipla",
    "text_input": "Immissione di testo",
    "number_input": "Ingresso numero",
    "multiline_input": "Immissione di testo multilinea",
    "paragraph": "Sezione",
    "change_questionnaire": "Questionario di modifica",
    "answers_to_a_questionnaire": "Risposte a un questionario",
    "request_filling_out_questionnaire": "Inviate ai vostri clienti un questionario",
    "send_questionnaire_as_message": "Inviare il questionario come messaggio",
    "ingoing": "In arrivo",
    "outgoing": "In uscita",
    "vat_short": "IVA.",
    "vat": "Imposta sul valore aggiunto",
    "price_net": "{Prezzo netto",
    "environment": "Ambiente",
    "inquire": "Richieste di informazioni",
    "continue_to_product_name": "Continua con {nome prodotto}",
    "you_can_find_this_and_other_vehicles_at_company": "Potete trovare questo e altri veicoli presso {azienda}",
    "more_vehicles_based_on_your_search": "Altri veicoli in base alla vostra ricerca",
    "add_to_favorites": "Salva come preferito",
    "remove_from_favorites": "Rimuovi dai preferiti",
    "share_the_offer": "Condividi l'offerta",
    "any_question_the_company_team_is_at_your_disposal": "Avete domande? Il team di {company} è a vostra disposizione.",
    "you_want_this_vehicle_but_the_price_isnt_right_submit_price_proposal": "Volete questo veicolo, ma il prezzo non è adatto? Inviate la vostra proposta di prezzo.",
    "you_want_to_rent_this_vehicle_at_project_name_you_can": "Se desiderate guidare questo veicolo per un periodo di tempo più lungo, presso {nomeProgetto} avete la possibilità di noleggiare il veicolo dei vostri sogni.",
    "uploaded_video": "Caricare il video",
    "lead_autoresponse": "Risposta automatica dei lead",
    "automatically_respond_to_incoming_leads": "Risponde automaticamente ai lead in arrivo. Definite i giorni della settimana e le ore in cui l'autoresponder è attivo.",
    "client_gave_count_answers_to_the_name_questionnaire": "Il cliente ha dato {conteggio} risposte al questionario \"{nome}\".",
    "new_lead_from_comms_center": "Nuovo lead dopo una comunicazione chiusa",
    "customer_chat_ended": "Chat con un cliente conclusa",
    "chatter_welcome_message": "Non c'è di che. Grazie per aver provato la nostra chat. Come posso aiutarla oggi?",
    "please_rate_us": "Per favore, dateci un voto",
    "reservation_fee": "Costo della prenotazione",
    "item_name": "Nome dell'articolo",
    "deposit": "Acconto",
    "balance_due": "Importo residuo dovuto",
    "authorize": "Autorizzare",
    "authorized_by_name_on_date": "Approvato da {nome} il {data}",
    "auto_generated": "Generato automaticamente",
    "not_authorized": "Non autorizzato",
    "settings_instructions": "Istruzioni per le impostazioni",
    "payment_config": "Configurazione del pagamento",
    "projects": "Progetti",
    "invoice_address": "Indirizzo di fatturazione",
    "deliver_adress": "Indirizzo di consegna",
    "your_contract_partner": "Il vostro partner contrattuale",
    "remaining_balance": "Importo rimanente",
    "item_filter_is_active": "Visibile online",
    "item_filter_is_resource": "È risorsa",
    "item_filter_is_subscription": "Finanziamento",
    "cancelled_by_name_on_date": "Annullato da {nome} il {data}",
    "add_credit_card_data_in_next_step": "Nella fase successiva è possibile aggiungere i dati della carta di credito",
    "upon_collection": "Al ritiro",
    "leads_manually_set_to_high_priority": "Leads impostati manualmente ad alta priorità",
    "leads_with_no_reaction_for_2_hours_since_creation": "Leads senza risposta per 2 ore dalla creazione",
    "leads_that_were_not_updated_for_2_days": "Leads che non sono state aggiornate per 2 giorni",
    "use_this_element_as_reference": "Utilizzare questo elemento come riferimento",
    "you_can_reuse_this_element_on_multiple_pages": "I contenuti riutilizzati su più siti web possono essere referenziati. Gli elementi per l'Express Page Builder non possono essere definiti come riferimento. Tali elementi vengono sempre copiati",
    "company_data": "Dati aziendali",
    "company_singup_company_data_hint": "Per poter creare la vostra azienda e assegnarvi un dominio, abbiamo bisogno delle seguenti informazioni.",
    "company_singup_personal_data_hint": "<p>Per poter ricevere il vostro accesso con i vostri dati utente, abbiamo bisogno delle seguenti informazioni.<br/>Riceverete quindi un'e-mail con i vostri dati personali di accesso.</p>",
    "your_test_domaine": "Il vostro nome di dominio di prova",
    "your_test_domaine_hint": "<p>Per favore, create un nome di dominio in cui il vostro progetto sarà ospitato. Il dominio sarà disponibile subito dopo la registrazione.<br/>Ricordate che possiamo anche ospitare progetti con un nome di dominio specificato da voi. Basta contattarci in seguito.</p>",
    "features": "Caratteristiche",
    "forms": "Moduli",
    "pricings": "Prezzi",
    "testimonials": "Riferimenti",
    "show_your_page_structure_and_responsive_visibility": "Mostra la struttura della pagina con etichette e visibilità reattiva",
    "toggle_edit_mode_for_page_preview": "Cambiare la modalità di modifica per l'anteprima di una pagina",
    "toggle_animation_and_parallax_effects": "Attivare e disattivare l'animazione e gli effetti di parallasse",
    "use_quick_page_builder_to_create_content_faster": "Utilizzate il Quick Page Builder per creare contenuti molto più velocemente",
    "cancel_invoice": "Annullamento della fattura",
    "refunded": "Rimborsato",
    "invoice_mark_refunded": "Rendere la fattura rimborsabile",
    "denied_to_pay": "Rifiuto di pagare",
    "invoice_mark_denied_to_pay": "Non pagherà",
    "registration_success": "Registrato con successo",
    "no_images_yet": "Non ci sono ancora immagini.",
    "sortingPrice": "Prezzo di smistamento",
    "main_menu_version": "Versione del menu principale",
    "main_menu_versions": "Versioni del menu principale",
    "administration_of_main_menu_versions": "Gestione delle versioni del menu principale",
    "featured": "In evidenza",
    "list_element_class": "Elemento elenco (sinistra) Classe CSS",
    "link_class": "Classe CSS di collegamento",
    "width": "Larghezza",
    "primary_menu_elements": "Elementi del menu principale",
    "secondary_menu_elements": "Elementi del menu secondario",
    "has_secondary_elements": "Ha elementi secondari",
    "active": "attivo",
    "company_singup_wizard_step_subscription_plan": "Seleziona i pacchetti",
    "company_singup_wizard_step_subscription_plan_headline": "Selezionate il pacchetto da testare.",
    "company_singup_wizard_step_additional_plugins": "Pacchetti aggiuntivi",
    "company_singup_wizard_step_subscription_plan_main_plugin_headline": "Vorrei testare le seguenti funzionalità:",
    "company_singup_wizard_step_company_data": "Dati di contatto della vostra azienda",
    "company_singup_wizard_step_company_data_headline": "Nome e dati di contatto della vostra azienda",
    "company_singup_wizard_step_personal_data": "Il profilo del dipendente",
    "company_singup_wizard_step_personal_data_headline": "Il profilo del dipendente",
    "company_singup_wizard_step_personal_data_hint": "Per poter creare un profilo di dipendente per voi, abbiamo bisogno dei vostri dati personali. Questo account vi dà diritti di amministrazione per il vostro progetto %platformName%.",
    "company_singup_wizard_step_personal_data_domain_hint": "<p>Per favore, create un nome di dominio in cui il vostro progetto sarà ospitato. Il dominio sarà disponibile subito dopo la registrazione.<br/>Ricordate che possiamo anche ospitare progetti con un nome di dominio da voi specificato. Basta contattarci in seguito.</p>",
    "company_singup_wizard_free_trial_hint": "Provate le nostre offerte per un mese gratuitamente e senza impegno.",
    "company_signup_wizard_free_trial_plugin_hint": "Un mese di prova gratuita, quindi:",
    "included_in_subscription_plan": "Incluso nell'abbonamento, altrimenti",
    "mostly_asked": "Le domande più frequenti",
    "test_package": "Pacchetto di prova",
    "active_since": "attivo da",
    "test_period_until": "Fase di test fino a",
    "navigate_to_plugins_and_configure": "Alla panoramica e alle impostazioni dei plugin",
    "termination_date": "Data di cessazione",
    "lead_taggers": "Taggatori principali",
    "lead_tagger": "Tagger principale",
    "share_with": "Condividi con",
    "password_weak": "Debole",
    "password_medium": "Medio",
    "password_strong": "Forte",
    "plugin_already_added_in_whitelabel_project": "Il plugin è già assegnato al progetto white label.",
    "lead_channel": "Canale",
    "lead_channel_web": "Web",
    "lead_channel_mobile": "App",
    "lead_channel_phone": "Telefono",
    "lead_channel_email": "E-mail",
    "lead_channel_personally": "Personale",
    "allow_others_to_share": "Consentire agli altri di condividere con più persone",
    "additional_imprint_information": "Informazioni aggiuntive sulla stampa",
    "share_with_the_company_i_work_for": "Con l'azienda per cui lavoro, condivido",
    "share_with_all_companies_in_my_holding_structure": "Con tutte le società presenti nella mia struttura di partecipazione, le azioni",
    "latest_lead": "L'ultima pista",
    "latest_edit": "Ultima modifica",
    "reaction_time": "Tempo di risposta",
    "no_reaction_time": "Nessuna reazione",
    "gt_2_days": "> 2 giorni",
    "lt_5_minutes": "< 5 minuti",
    "lt_1_hour": "< 1 ora",
    "lt_2_hours": "< 2 ore",
    "lt_6_hours": "< 6 ore",
    "lt_1_day": "< 1 giorno",
    "lt_2_days": "< 2 giorni",
    "by_employee": "Per dipendente",
    "by_company": "Per azienda",
    "by_location": "Per posizione",
    "by_source": "Secondo la fonte",
    "client_data": "Dati del cliente",
    "show_in_lead_description": "Mostra nella descrizione della traccia",
    "this_answer_will_be_shown_in_lead_description": "Questa risposta viene visualizzata nella descrizione della pista",
    "this_answer_will_not_be_shown_in_lead_description": "Questa risposta non viene visualizzata nella descrizione della pista",
    "lead_assignment_strategy": "Strategia di assegnazione della leadership",
    "lead_assignment_strategies": "Strategie di assegnazione dei leader",
    "change_the_lead_assignment_strategies_for_companies_and_pipelines": "Modificate il modo in cui ai vostri dipendenti vengono assegnati i lead in base alle loro organizzazioni e pipeline.",
    "strategy": "Strategia",
    "deleted": "Soppresso",
    "assign_to_me": "Assegnare a me",
    "responsibilities": "Responsabilità",
    "escalation_rules": "Regole di escalation",
    "escalation_rule": "Regola dell'escalation",
    "define_rules_when_you_would_like_to_inform_users_about_overdue_leads": "Definisce le regole che i team possono utilizzare per identificare i lead abbandonati o a cui i membri del team non rispondono.",
    "category_images": "Categoria immagini",
    "use_document_template": "Utilizzare un modello di documento",
    "escalation_level_one": "Prima fase: informare i dipendenti",
    "escalation_level_two": "Fase due: informare il caposquadra",
    "automatically_terminate_the_lead": "Termina automaticamente la pista (segna la pista come persa)",
    "no_reaction": "Nessuna reazione",
    "no_reaction_for": "Nessuna reazione per",
    "no_update": "Nessun aggiornamento",
    "no_update_for": "Nessun aggiornamento per",
    "escalation_level": "Livello di escalation",
    "time_settings": "Impostazioni di tempo",
    "pending": "Si distingue",
    "skipped": "Saltato",
    "holding_structure": "Struttura di partecipazione",
    "show_sibling_companies": "Le aziende sorelle mostrano",
    "show_descendant_companies": "Mostra le aziende subordinate",
    "subscription_type": "Modello di finanziamento",
    "no_subscription_type_selected": "È necessario selezionare almeno un modello di finanziamento.",
    "financing": "Finanziamento",
    "monthly_payment": "Rata mensile",
    "final_payment": "Rata finale",
    "interest_rate": "Tasso di interesse",
    "availability": "Disponibilità",
    "delivery_time_month": "Tempo di consegna (in mesi)",
    "delivery_time": "Tempi di consegna",
    "cancelation_period_month": "Periodo di cancellazione (in mesi)",
    "enter_financing_data": "Inserire i dati di finanziamento",
    "marketing_agency": "Agenzia di marketing",
    "edit_company_read_and_write_rights": "Modificare le autorizzazioni di lettura e scrittura dell'azienda",
    "grant_data_access": "Concedere l'accesso ai dati",
    "deny_data_access": "Negare l'accesso ai dati",
    "allow_data_access_for_a_single_company": "Consentire l'accesso ai dati per una singola azienda",
    "allow_data_access_for_a_whole_level": "Consentire l'accesso ai dati per un intero livello",
    "deny_data_access_for_a_single_company": "Negare l'accesso ai dati per una singola azienda",
    "deny_data_access_for_a_whole_level": "Negare l'accesso ai dati per un intero livello",
    "closing_costs": "Tassa di chiusura",
    "nominal_interest_rate": "Tasso di interesse target",
    "has_rate_insurance": "Protezione dei pagamenti",
    "is_user_based": "È basato sull'utente",
    "min_user_count": "Numero minimo di utenti",
    "max_user_count": "Numero massimo di utenti",
    "price_per_user": "Prezzo per utente",
    "monthly": "Mensile",
    "instantly": "Immediatamente",
    "quarterly": "Trimestrale",
    "annual": "Annualmente",
    "monthly_fee": "Canone mensile",
    "starting_fee": "Quota di iscrizione",
    "included_templates_count": "Numero di modelli inclusi",
    "simple_templates_count": "Numero di modelli inclusi (singolo)",
    "middle_templates_count": "Numero di modelli inclusi (media)",
    "complex_templates_count": "Numero di modelli inclusi (complesso)",
    "surcharge": "Supplemento",
    "base_surcharge": "Supplemento base",
    "max_surcharge": "Supplemento massimo",
    "dataproof_surcharge": "Supplemento Dataproof",
    "aws_domain_hosting": "Hosting del dominio tramite AWS",
    "domain_hosting": "Hosting di dominio",
    "desired_domain": "Dominio desiderato",
    "redirect_uri": "Inoltro",
    "check_availability": "Verifica la disponibilità",
    "cart_edition_title": "Designazione:",
    "cart_recent_edition": "Ultima modifica:",
    "cart_campaign_title": "Campagna:",
    "cart_last_saved": "Ultimo salvataggio:",
    "remove_item": "Rimuovere il prodotto",
    "config": "Configurare",
    "submit_config": "Confermare la configurazione",
    "complete_order": "Finalizzare l'ordine",
    "payment_method_description": "Selezionare il metodo di pagamento desiderato",
    "cart_hint": "Nota:",
    "cart_hint_description_part_1": "Inserire qui solo commenti di carattere generale. I commenti sui prodotti possono essere presi in considerazione solo se è stata selezionata l'opzione",
    "cart_hint_description_part_2": "Controllo manuale dei dati",
    "cart_hint_description_part_3": "e hanno inserito il commento per il rispettivo prodotto.",
    "your_message": "Il vostro messaggio per noi",
    "hint_dataproof_1": "Avete rinunciato alla verifica manuale dei dati da parte del nostro esperto per uno o più articoli.",
    "hint_dataproof_2": "Desideriamo sottolineare che in questo caso il cliente si assume la piena responsabilità del proprio design. In caso di errori di dati, colori errati nelle foto, ecc. è escluso un reclamo. Si applicano le nostre condizioni generali di contratto.",
    "hint_dataproof_3": "Se optate per il controllo manuale dei dati, il vostro file sarà controllato da un esperto per verificare la presenza di eventuali errori (controllo tecnico, non ortografico). Nel caso in cui ci sfugga un errore, ci assumiamo la responsabilità. Nessun rischio per voi!",
    "hint_missing_order_quantity": "Manca la quantità dell'ordine!",
    "missing_order_quantity": "Non è stata selezionata una quantità d'ordine per uno o più articoli.",
    "missing_invoice_shipping_address": "Manca l'indirizzo di fatturazione o di consegna. Selezionare un indirizzo.",
    "billing_address_form_is_not_submitted": "L'indirizzo di fatturazione non è stato inserito. Si prega di compilare il modulo.",
    "shipping_address_form_is_not_submitted": "L'indirizzo di consegna non è stato inserito. Si prega di compilare il modulo.",
    "no_invoice_address": "Non è stato creato un indirizzo di fatturazione",
    "no_delivery_address": "Non è stato creato un indirizzo di consegna",
    "use_invoice_contact": "Utilizzare il contatto di fatturazione",
    "order_item": "Articolo",
    "order_price": "Prezzo",
    "order_copies": "Edizione",
    "order_totals": "Totali dell'ordine",
    "subtotal": "Subtotale",
    "invoice_amount": "Importo della fattura",
    "remainder_available": "Quantità residua disponibile non distribuita",
    "reduce_edition": "Per aggiungere un nuovo indirizzo, è necessario prima ridurre il numero di copie dell'indirizzo esistente.",
    "split_delivery_item_hint": "La distribuzione della quantità totale non è ancora stata definita. La specifica della quantità deve essere un valore compreso tra 1 e la quantità rimanente ancora da distribuire o la tiratura; attualmente il valore massimo è di",
    "net_amount": "Importo netto",
    "gross_amount": "Importo lordo",
    "is_debtor": "Creditore",
    "is_debtor_number_provider": "Numero del debitore Fornitore",
    "is_never_remind": "Non inviare promemoria",
    "is_demo": "Modalità demo",
    "is_vendor": "Fornitore",
    "should_user_pay": "L'utente deve pagare",
    "receive_payment_from_users": "Ricevere i pagamenti dagli utenti",
    "use_payment_receiver": "Utilizzare l'opzione beneficiario",
    "payment_receiver": "Beneficiario",
    "use_summary_invoice": "Utilizzare la fattura collettiva",
    "credit_limit": "Limite di credito",
    "commission_receiver": "Destinatario della Commissione",
    "budget_value": "Valore di bilancio",
    "billing_value": "Valore di liquidazione",
    "coupon_billing": "Valore di fatturazione per il periodo di fatturazione selezionato",
    "coupon_billing_period": "Periodo di fatturazione",
    "coupon_recharge_period": "Buono di ricarica",
    "coupon_print_type": "Limitare i voucher",
    "coupon_never": "Mai",
    "coupon_unset": "Nessuna restrizione",
    "coupon_print_pdf": "Stampa solo PDF",
    "recurring": "Ricorrente",
    "period": "Periodo",
    "without_end_date": "Senza data di scadenza",
    "budget_settings": "Impostazioni di bilancio",
    "no_budget_set": "Nessun budget stabilito",
    "current_period_budget_usage": "Utilizzo del bilancio nel periodo in corso",
    "user_entered_no_message": "L'utente non ha inserito un messaggio",
    "coupons": "Buoni",
    "coupon": "Voucher",
    "create_discounts_and_subsidy_products": "Creare codici sconto e sovvenzionare prodotti per i vostri partner",
    "authorization_status": "Stato dell'autorizzazione",
    "user_budget": "Bilancio dell'utente",
    "manual_vat_check": "Verifica dell'aliquota IVA ridotta",
    "manual_data_check": "Controllo manuale dei dati",
    "completed": "Completato",
    "changes_requested": "Modifiche richieste",
    "rejected": "Rifiutato",
    "request_changes": "Richiesta di modifiche",
    "reply": "Risposte",
    "coupon_monetary": "Sconto fisso",
    "coupon_percentage": "Sconto percentuale",
    "calculations": "Calcoli",
    "percent_discount_value": "Sconto percentuale",
    "monetary_discount_value": "Sconto in contanti",
    "company_budget": "Bilancio aziendale",
    "enter_0_to_ignore_this_setting": "Immettere 0 per ignorare questa impostazione",
    "min_order_value": "Valore minimo dell'ordine",
    "max_order_discount": "Sconto massimo per ordine",
    "coupon_budget_exceeded": "Superamento del budget per i voucher",
    "billing_target": "Obiettivo di fatturazione",
    "billing_settings": "Impostazioni di fatturazione",
    "dataproof_textarea": "Dobbiamo considerare qualcosa?",
    "edit": "Modifica",
    "edit_client": "Modifica dei dati del cliente",
    "edit_gallery": "Modifica dei dettagli della galleria",
    "edit_job": "Modifica dei dettagli del lavoro",
    "edit_position": "Modifica posizione",
    "edit_product": "Modifica del prodotto",
    "edit_producttemplate": "Modifica del modello",
    "edit_question": "Modifica domanda",
    "edit_template": "Modifica del modello",
    "edit_step": "Modifica della fase della pipeline",
    "edit_payment": "Modifica del pagamento",
    "edit_team": "Modifica del team",
    "edit_document_type": "Modifica del tipo di documento",
    "edit_brand": "Modifica del marchio",
    "edit_model": "Modifica del modello",
    "edit_category": "Modifica categoria",
    "edit_attribute": "Modifica attributo",
    "edit_data_push": "Modifica dei dati push",
    "edit_lead_catcher": "Modifica del lead catcher",
    "edit_external_account": "Modifica dell'account esterno",
    "edit_comment": "Modifica commento",
    "edit_delivery_date": "Modifica della data di consegna",
    "edit_lead_tagger": "Modifica del tagger principale",
    "edit_commission_receiver": "Modifica del destinatario della commissione",
    "edit_payment_receiver": "Modifica del beneficiario",
    "edit_billing_target": "Modifica dell'obiettivo di fatturazione",
    "create_client": "Creare una nuova azienda",
    "create_gallery": "Creare una nuova galleria",
    "create_job": "Creare un nuovo lavoro",
    "create_product": "Creare un nuovo prodotto",
    "create_producttemplate": "Creare un nuovo modello",
    "create_questionnaire": "Creare un nuovo questionario",
    "create_question": "Creare una nuova domanda",
    "create_template": "Creare un nuovo modello di e-mail",
    "create_cms_template": "Creare un nuovo modello CMS",
    "create_pipeline": "Creare una nuova pipeline",
    "create_step": "Creare una nuova fase della pipeline",
    "create_payment_schedule": "Creare un nuovo piano di pagamento",
    "create_payment": "Creare un nuovo pagamento",
    "create_message": "Scrivi un messaggio",
    "create_text_message": "Scrivere un messaggio di testo (SMS)",
    "create_note": "Scrivere una nota",
    "create_user": "Creare un nuovo utente",
    "create_users": "Creare un utente",
    "create_doc_type": "Creare un nuovo tipo di documento",
    "create_team": "Creare un team",
    "create_brand": "Creare un marchio",
    "create_model": "Creare il modello",
    "create_category": "Creare una categoria",
    "create_attribute": "Creare l'attributo",
    "create_attribute_group": "Creare un gruppo di attributi",
    "create_car": "Creare un veicolo",
    "create_item": "Creare il prodotto",
    "create_lead": "Creare un vantaggio",
    "assign_lead": "Assegnare il comando",
    "create_page": "Crea pagina",
    "create_data_push": "Creare un nuovo push di dati",
    "create_white_label_project": "Creare un nuovo progetto white label",
    "create_cms_element": "Creare un nuovo elemento CMS",
    "create_lead_catcher": "Creare un nuovo lead catcher",
    "create_white_label_plugin": "Creare un nuovo plugin white label",
    "create_dynamic_data_definition": "Creare una nuova definizione di dati dinamici",
    "add_account": "Aggiungi account",
    "create_external_account": "Aggiungere un nuovo account esterno",
    "create_model_group": "Creare un nuovo gruppo di modelli",
    "create_model_asset": "Creare una nuova immagine del modello",
    "create_lead_per_mail_rule": "Creare un nuovo lead tramite la regola dell'e-mail",
    "duplicate_page": "Pagina duplicata",
    "duplicate_cms_element": "Elemento CMS duplicato",
    "duplicate_cms_template": "Modello CMS duplicato",
    "create_task": "Creare un'attività",
    "create_car_service": "Creare un servizio",
    "create_car_service_station": "Creare una postazione di lavoro",
    "create_doc_template": "Creare un nuovo modello di documento",
    "create_lead_autoresponse": "Creare una nuova risposta automatica per i lead",
    "create_main_menu_version": "Creare la versione del menu principale",
    "create_lead_tagger": "Creare un lead tagger",
    "create_lead_assignment_strategy": "Creare una strategia di assegnazione dei lead",
    "create_escalation_rule": "Creare una regola di escalation",
    "create_coupon": "Creare un voucher",
    "client_data_saved": "Dati dei clienti memorizzati",
    "user_data_saved": "Dati utente salvati",
    "job_data_saved": "Dati del lavoro salvati",
    "product_data_saved": "Dati del prodotto salvati",
    "template_data_saved": "Salvataggio dei dati del modello",
    "company_logo_saved": "Il logo è stato salvato",
    "payment_schedule_data_saved": "Il piano di pagamento è stato salvato",
    "questionnaire_filled_out": "Questionario compilato",
    "your_message_has_been_sent": "Il messaggio è stato inviato",
    "email_with_password_reset_instructions_sent": "È stata inviata un'e-mail con le istruzioni per la reimpostazione della password",
    "password_changed_after_reset": "La password è stata modificata. Bentornati",
    "quote_accepted_saved": "L'offerta è stata accettata",
    "quote_rejected_saved": "L'offerta è stata rifiutata",
    "quote_saved": "Offerta salvata",
    "message_sent": "Il messaggio è stato inviato",
    "note_saved": "La nota è stata salvata",
    "holding_info_saved": "Informazioni di mantenimento memorizzate",
    "new_password_set": "È stata impostata una nuova password",
    "questionnaire_data_saved": "Questionario salvato",
    "doc_type_saved": "Tipo di documento salvato",
    "team_saved": "Squadra salvata",
    "document_saved": "Documento salvato",
    "brand_saved": "Marchio salvato",
    "model_saved": "Modello salvato",
    "category_saved": "Categoria salvata",
    "attribute_saved": "Attributo salvato",
    "item_data_saved": "Dati del prodotto salvati",
    "car_data_saved": "Dati del veicolo memorizzati",
    "variant_saved": "Variante salvata",
    "pipeline_saved": "Condotte salvate",
    "comment_saved": "Commento salvato",
    "lead_saved": "Piombo salvato",
    "order_saved": "Ordine salvato",
    "document_reviewed": "Documento rivisto",
    "locations_saved": "Posizioni salvate",
    "email_confirmed": "E-mail confermata",
    "page_saved": "Pagina salvata",
    "template_saved": "Modello salvato",
    "data_push_saved": "Salvataggio dei dati push",
    "white_label_saved": "Progetto white label salvato",
    "attribute_group_saved": "Gruppo di attributi salvato",
    "cms_element_saved": "Elemento CMS salvato",
    "lead_catcher_saved": "Il capocannoniere si è salvato",
    "image_data_saved": "Dati immagine salvati",
    "white_label_plugin_saved": "Plugin a marchio bianco salvato",
    "dynamic_data_definition_saved": "Salvataggio della definizione dinamica dei dati",
    "company_data_saved": "Dati aziendali memorizzati",
    "contact_data_saved": "Dettagli di contatto salvati",
    "no_changes_made": "Nessuna modifica apportata",
    "car_order_approved": "Vendita auto autorizzata",
    "vin_number_updated": "Numero di telaio del veicolo aggiornato",
    "ein_number_updated": "Aggiornamento della conferma elettronica dell'assicurazione",
    "order_marked_as_paid": "Ordine contrassegnato come pagato",
    "payment_instructions_sent": "Istruzioni di pagamento inviate",
    "order_cancelled": "Ordine annullato",
    "sales_contract_uploaded": "Contratto di vendita caricato",
    "bank_data_updated": "Dettagli bancari aggiornati",
    "pickup_invite_sent": "Invito al ritiro inviato",
    "order_picked_up": "Ordine ritirato",
    "order_assignee_kroschke_documents_sent": "Il concessionario ha inviato i documenti necessari per l'immatricolazione del veicolo.",
    "order_client_kroschke_documents_sent": "Il cliente ha inviato i documenti necessari per l'immatricolazione del proprio veicolo",
    "plugin_partial_activated": "Plugin parzialmente attivato",
    "plugin_request_activate_sent": "Richiesta di attivazione del plugin inviata",
    "plugin_activated": "Plugin attivato",
    "plugin_terminated": "Plugin terminato",
    "changes_saved": "Modifiche salvate",
    "app_secret_saved": "Segreto dell'app salvato",
    "page_access_token_saved": "Token di accesso alla pagina salvato",
    "text_message_sent": "SMS inviato",
    "account_saved": "Account salvato",
    "model_group_saved": "Gruppo di modelli salvato",
    "model_asset_saved": "Immagine del modello salvata",
    "sepa_agreement_saved": "Salvataggio del mandato SEPA",
    "document_uploaded": "È stato caricato un documento",
    "customer_sent_message": "Il cliente ha inviato un messaggio",
    "lead_created": "Piombo creato",
    "lead_watching": "Iniziata con l'osservazione degli indizi",
    "lead_unwatching": "Termina il monitoraggio delle derivazioni",
    "lead_per_mail_rule_saved": "Lead salvati dalla regola dell'e-mail",
    "drivers_license_uploaded": "Patente di guida caricata",
    "plugin_saved": "Plugin salvato",
    "settings_saved": "Impostazioni salvate",
    "page_duplicated": "Pagina duplicata",
    "cms_template_duplicated": "Modello CMS duplicato",
    "cms_element_duplicated": "Elemento CMS duplicato",
    "price_negotiation_started": "Avvio della negoziazione del prezzo",
    "new_price_negotiation_message_offer_sent": "Invio di una nuova offerta di negoziazione del prezzo",
    "you_joined_community": "Siete entrati a far parte della comunità",
    "you_left_community": "Avete lasciato la comunità",
    "car_service_booked": "Appuntamento prenotato online",
    "car_service_saved": "Servizio risparmiato",
    "car_service_station_saved": "Stazione di lavoro salvata",
    "team_member_added": "Aggiunta di un membro del team",
    "team_member_removed": "Membro del team rimosso",
    "doc_template_saved": "Modello di documento salvato",
    "invoice_issued": "Fattura emessa",
    "request_to_fill_out_questionnaire_saved": "Richiesta di compilare un questionario salvato",
    "request_to_fill_out_questionnaire_name_saved": "Richiesta di compilazione del questionario \"{nome}\" salvata",
    "lead_autoresponse_saved": "Salvataggio delle risposte automatiche dei lead",
    "main_menu_version_saved": "Versione del menu principale salvata",
    "lead_tagger_saved": "Lead Tagger salvato",
    "share_settings_updated": "Aggiornamento delle impostazioni di rilascio",
    "lead_assignment_strategy_saved": "Strategia di allocazione dei lead salvata",
    "escalation_rule_saved": "Regola di escalation salvata",
    "value_type": "Tipo",
    "value_precision": "Precisione",
    "category_already_in_attribute": "La categoria è già assegnata all'attributo.",
    "no_attributes_for_variants": "È necessario definire gli attributi per creare le varianti.",
    "budget_saved": "Bilancio risparmiato",
    "budget_removed": "Bilancio rimosso",
    "coupon_saved": "Buono sconto risparmiato",
    "authorization_data_saved": "Dati di autorizzazione memorizzati",
    "template_data": "Informazioni generali",
    "template_pages": "Impostazioni della pagina",
    "template_editor": "Impostazioni dell'editor e del PDF",
    "template_timeline": "Linea temporale",
    "template_blocks": "Blocchi di contenuto",
    "template_import": "Importazione",
    "template_export": "Esportazione",
    "template_generate": "Generatore (TEST)",
    "template_print_data": "Dati di stampa",
    "template_import_select_template": "Selezionare la cartella dei modelli",
    "template_import_select_print_data_document": "Selezionare il documento di stampa dei dati",
    "template_upload_print_data_title": "Caricare i dati di stampa nella directory dei modelli",
    "template_upload_print_data_actual_file_title": "Dati di stampa caricati",
    "template_upload_print_data_info": "Aggiunge un file alla directory dei modelli S3. Questo file viene utilizzato per i prodotti quando vengono creati e non vengono generati dati di stampa.",
    "template_blocks_template": "Blocco HTML",
    "template_category_thumb": "Classe dell'icona della miniatura (ad esempio: fad fa-circle)",
    "add_block_category": "Nuova categoria",
    "edit_block_category": "Modifica categoria",
    "add_block_variant": "Nuova variante",
    "edit_block_variant": "Variante di modifica",
    "add_block": "Nuovo blocco",
    "copy_block": "Blocco copia",
    "edit_block": "Blocco di modifica",
    "edit_takeit": "Rilevare",
    "edit_import": "Importazione",
    "edit_import_warning": "Sei sicuro? Questo sovrascriverà tutte le voci non salvate!",
    "template_has_content_blocks": "Contenuto variabile",
    "template_in_test": "Modalità di prova per gli ordini",
    "placeholder": "Segnaposto per l'unione della posta",
    "select_customer": "Selezionare i clienti",
    "company_added": "Azienda aggiunta",
    "company_removed": "Azienda rimossa",
    "item_added": "Prodotto aggiunto",
    "item_removed": "Prodotto rimosso",
    "category_added": "Categoria aggiunta",
    "category_removed": "Categoria rimossa",
    "select_user_set": "Selezionare l'autorizzazione dell'utente",
    "sent_reminders": "Ricordi inviati",
    "number_of_sent_reminders": "Numero di solleciti inviati",
    "set_number": "Numero di set",
    "email_to_the_customer_on_save": "Inviare al cliente un'e-mail al momento del salvataggio",
    "email_on_save": "E-mail al momento del salvataggio",
    "shown_name": "Nome visualizzato",
    "filename_thumbnail": "Nome del file per la miniatura",
    "name_of_imagefile": "Nome del file immagine, senza directory. Esempio: thumb1.jpg",
    "block_position": "Posizione del blocco nelle linee della griglia, 0 per l'alto, rende il blocco assoluto",
    "defined_raster_space": "Posizione predefinita della griglia",
    "needed_raster_space": "Spazio richiesto per la griglia",
    "size_group_info_text": "Tutti i blocchi con lo stesso SizeGroup possono essere spostati di dimensione nell'editor.",
    "size_group": "Gruppo di dimensioni",
    "group_index_info_text": "Tutti i blocchi con lo stesso SizeGroup e la stessa dimensione del blocco possono essere passati attraverso l'indice. Dall'indice 1.",
    "group_index": "Indice del gruppo",
    "bottom_info_text": "ATTENZIONE: il fondo posiziona il blocco assolutamente in fondo alla pagina, con la distanza impostata in PX",
    "bottom_label": "Posizionare il blocco assolutamente in basso",
    "fixed_block_label": "Blocco fisso",
    "fixed_block_info_text": " Il blocco non può essere spostato",
    "once_per_document_label": "Una volta per documento",
    "once_per_document_info_text": "consentito solo una volta nell'intero documento",
    "no_copies_label": "Il blocco non può essere copiato",
    "no_copies_info_text": "Il blocco non può essere copiato",
    "no_options_label": "Non mostrare alcuna opzione",
    "no_options_info_text": "Il blocco non ha opzioni nell'editor quando viene inserito.",
    "once_per_page_label": "Una volta per pagina",
    "once_per_page_info_text": "consentito solo una volta su un lato",
    "once_per_variant_label": "Una volta per variante",
    "once_per_variant_info_text": "Consentito solo una volta per variante",
    "once_per_category_label": "Una volta per categoria",
    "once_per_category_info_text": "è consentito solo uno per categoria",
    "only_whitelabel_admin_can_modify_label": "Solo amministratore Whitelabel",
    "only_whitelabel_admin_can_modify_info_text": "Questo campo può essere modificato solo dall'amministratore di whitelabel. Deve essere utilizzato quando l'amministratore del whitelabel crea un modello.",
    "restricted_to_page_label": "Consentito a pagina",
    "restricted_to_page_info_text": "Disponibile solo in questa pagina, -1 = consentito ovunque",
    "exclude_pages_label": "Escludere le pagine (ad esempio: 1,2,3...)",
    "exclude_pages_info_text": "Non consentito in queste pagine, separare con una virgola",
    "only_last_label": "Solo l'ultima pagina",
    "only_last_info_text": "Il blocco può essere inserito solo nell'ultima pagina.",
    "container_block_label": "Blocco contenitore",
    "container_block_info_text": "Questo blocco contiene un contenitore per altri blocchi",
    "inline_block_label": "Blocco in linea",
    "inline_block_info_text": "Il blocco viene trattato come display:inline-block",
    "inner_label": "Può essere inserito nel contenitore a blocchi",
    "inner_info_text": "Il blocco può essere inserito nel contenitore per blocchi",
    "container_class_label": "Limite al blocco di contenitori",
    "container_class_info_text": "Nome della classe del contenitore in cui il blocco può essere inserito.",
    "only_inside_block_label": "Può essere inserito solo in contenitori a blocchi",
    "only_inside_block_info_text": "Il blocco può essere inserito solo in contenitori di blocchi",
    "container_space_label": "Spazi della griglia nel blocco contenitore",
    "container_space_info_text": "Linee di griglia disponibili all'interno del blocco (stessa griglia della pagina!)",
    "container_cols_label": "Colonne del blocco contenitore",
    "container_cols_info_text": "le linee della griglia disponibili vengono moltiplicate per questo valore",
    "website_only": "Solo per i siti web",
    "navName": "Nome per la navigazione",
    "nav_link_label": "Link per la navigazione",
    "nav_link_info_text": "Collegamento di ancoraggio all'ID del blocco",
    "nav_options": "Opzioni per la navigazione",
    "combi_parent_label": "Elemento combinato (genitore)",
    "combi_parent_info_text": "Elemento padre, può essere inserito in qualsiasi momento",
    "combi_child_label": "Elemento combinato (Bambino)",
    "combi_child_info_text": "Gli elementi figli possono essere impostati solo in combinazione con l'elemento genitore",
    "disable_group_label": "Opzioni di gruppo",
    "disable_group_info_text": "Qui è possibile definire gruppi che si escludono a vicenda, ad esempio P#Gruppo1,Gruppo2 e D#Gruppo2,Gruppo1. Parametro di definizione - P = valido per la pagina, D = valido per il documento.",
    "events": {
      "TimelineMailPasswordReset": "Il cliente ha richiesto la modifica della password",
      "TimelineMailRegistration": "Il cliente si è registrato su Creacheck.com",
      "TimelineMailOauthRegistration": "Il cliente si è registrato a Creacheck.com con il login ai social media",
      "TimelineMailInvitation": "Il cliente è stato invitato a utilizzare Creacheck.com",
      "TimelineMailContactForm": "Il cliente ha inviato un messaggio tramite il modulo di contatto",
      "TimelineMailInvitationAccepted": "Il cliente ha accettato l'invito",
      "TimelineMailNewInvoice": "Avete emesso una fattura al cliente",
      "TimelineMailNewQuote": "Avete inviato un'offerta al cliente",
      "TimelineMailNewMessage": "Avete inviato un messaggio al cliente",
      "TimelineQuoteAccepted": "Il cliente ha accettato la vostra offerta",
      "TimelineQuoteRejected": "Purtroppo il cliente ha rifiutato la vostra offerta",
      "TimelineOrderCreated": "Nuovo ordine",
      "TimelineMailNewCustomerMessage": "Messaggio e-mail del cliente",
      "TimelineMonitoringPaymentReceipt": "Monitorare i pagamenti in entrata",
      "TimelineClosingManualDataCheck": "Controllo manuale dei dati chiuso",
      "TimelineUpdatedByForeignStatus": "Stato dell'ordine aggiornato",
      "TimelineMailedForeignOrderStatusOrderCanceled": "annullato dal fornitore",
      "TimelineMailedForeignOrderStatusInDelivery": "in consegna",
      "TimelineMailedForeignOrderStatusInProduction": "in produzione",
      "TimelineMailedForeignOrderStatusInReview": "in consultazione",
      "TimelineMailedForeignOrderStatusInOrder": "in ordine",
      "TimelineMailedOrderedWebAndDownloadPDF": "PDF scaricabile e abilitato al web inviato ai destinatari della posta elettronica.",
      "TimelineMailedOrderedDownloadPDF": "Download del PDF inviato ai destinatari della posta elettronica.",
      "TimelineMailedOrderedWebPDF": "Inviati via web ai destinatari della posta elettronica.",
      "TimelineDomainHostingHasBeenAuthorized": "L'hosting del dominio è stato autorizzato",
      "TimelineDomainNotAvailable": "Il dominio non è più disponibile",
      "TimelineDomainHostingDomainValidated": "Il dominio è stato convalidato",
      "TimelineExceptionCheckDomainAvailability": "Errore durante la verifica della disponibilità del dominio {0}. Errore: {2}",
      "TimelineDomainFailRegistering": "Registrazione del dominio {0} fallita",
      "TimelineExceptionRegisterDomain": "La registrazione del dominio {0} è fallita con l'errore {2}.",
      "TimelineDomainSuccessfullyRegistered": "Il dominio {0} è stato registrato con successo.",
      "TimelineDomainSuccessfullyRedirected": "Dominio reindirizzato con successo",
      "TimelineDomainCreatingCertificate": "Il certificato per il dominio {0} è stato creato con l'errore {1}.",
      "TimelineDomainCreatingDistributionFailed": "La distribuzione del dominio {0} è fallita con l'errore {1}.",
      "TimelineDomainCreatingSuccessfullyHosted": "Il dominio {0} è stato ospitato con successo",
      "TimelineDomainCreatingFailedHosting": "Il dominio {0} non può essere ospitato con l'errore: {1}",
      "TimelineDomainHostingCompleted": "Completato",
      "TimelineMissingClientDataFirstAndLastName": "Mancano nome e cognome del cliente!",
      "TimelineMissingClientDataPhoneNumber": "Manca il numero di telefono del cliente!",
      "TimelineDomainCreatingSuccessfullyFinished": "Il dominio {0} è stato completato con successo"
    },
    "attributeName": {
      "select": "Campo di selezione",
      "html": "Campo HTML",
      "checkbox": "Casella di controllo",
      "text": "Campo di testo",
      "colors": "Scelta dei colori",
      "number": "Campo numerico",
      "boolean": "Sì/No",
      "date": "data"
    },
    "attributeType": {
      "select": "È possibile definire diverse opzioni da selezionare. L'utente può quindi selezionarne una",
      "html": "L'utente può scrivere un testo più lungo con Rich Content Editor",
      "checkbox": "È possibile definire diverse opzioni da selezionare. L'utente può quindi selezionarne diverse",
      "text": "L'utente può inserire un semplice testo",
      "colors": "L'utente può selezionare uno dei colori definiti qui",
      "number": "L'utente può inserire un numero",
      "boolean": "L'utente può scegliere tra sì e no",
      "date": "L'utente può selezionare una data"
    },
    "attributeValueType": {
      "string": "Testo",
      "number": "Numero",
      "date": "data"
    },
    "attributePrecision": {
      "year": "Anno",
      "month": "mese",
      "day": "Giorno",
      "time": "Ora del giorno"
    },
    "categoryDisplay": {
      "select": "Sì/no",
      "number_range": "Gamma di numeri",
      "date_range": "Intervallo di date",
      "list": "Selezione dell'elenco"
    },
    "invoice_actions": {
      "tab_title": "Azioni sulla fattura",
      "refunded": {
        "title": "Rimborso manuale",
        "label": "Contrassegnare come rimborsato",
        "info_text": "Contrassegno manuale della fattura come già rimborsata senza creare una fattura di annullamento"
      },
      "denied_to_pay": {
        "title": "Pagamento rifiutato",
        "label": "Contrassegnare come negato",
        "info_text": "Contrassegnare che la fattura non sarà mai pagata"
      },
      "amount_paid": {
        "title": "Inserire l'importo pagato",
        "placeholder": "Inserire l'importo pagato",
        "button": "Salva l'importo pagato"
      },
      "sent_reminders": {
        "title": "Promemoria inviati",
        "placeholder": "Numero di solleciti inviati",
        "button": "Salva numero"
      }
    },
    "mail": {
      "registration": {
        "subject": "Grazie per esservi registrati presso la %azienda%.",
        "subject_robethood": "Portale Robethood Creatives: registrazione riuscita.",
        "explanation": "Grazie per la vostra registrazione. Vi preghiamo di confermare il vostro indirizzo e-mail entro 24 ore. Solo con un indirizzo e-mail confermato è possibile ricevere una nuova password Internet o informazioni sulle modifiche.",
        "explanation_first_name_form": "<p>Grazie per la sua registrazione. La preghiamo di confermare il suo indirizzo e-mail entro 24 ore.</p><p>Solo con un indirizzo e-mail confermato potrà ricevere una nuova password o informazioni sulle modifiche.</p>",
        "possible_actions": "Una volta confermato il vostro indirizzo e-mail, avrete immediatamente accesso a tutte le funzioni che abbiamo preparato per voi.",
        "possible_actions_first_name_form": "Una volta confermato il vostro indirizzo e-mail, avrete immediatamente accesso a tutte le funzioni che abbiamo preparato per voi.",
        "call_to_action": "Conferma il mio indirizzo e-mail"
      },
      "ordered_web_pdf": {
        "subject": "PDF abilitato al web per l'ordine di %orderId% da %company%.",
        "explanation": "Grazie per il vostro ordine. È possibile aprire e scaricare il PDF abilitato al web facendo clic sul pulsante sottostante.",
        "explanation_first_name_form": "Grazie per il vostro ordine. È possibile aprire e scaricare il PDF abilitato al web facendo clic sul pulsante sottostante.",
        "possible_actions": "È possibile accedere al portale online all'indirizzo %domain% in qualsiasi momento e visualizzare l'ordine.",
        "possible_actions_first_name_form": "È possibile accedere al portale online all'indirizzo %domain% in qualsiasi momento e visualizzare l'ordine.",
        "call_to_action": "Mostra l'ordine",
        "download": "Scarica il PDF abilitato al web"
      },
      "ordered_download_pdf": {
        "subject": "PDF per l'ordine %orderId% di %company%",
        "explanation": "Grazie per il vostro ordine. È possibile aprire e scaricare il PDF facendo clic sul pulsante sottostante.",
        "explanation_first_name_form": "Grazie per il vostro ordine. È possibile aprire e scaricare il PDF facendo clic sul pulsante sottostante.",
        "possible_actions": "È possibile accedere al portale online all'indirizzo %domain% in qualsiasi momento e visualizzare l'ordine.",
        "possible_actions_first_name_form": "È possibile accedere al portale online all'indirizzo %domain% in qualsiasi momento e visualizzare l'ordine.",
        "call_to_action": "Mostra l'ordine",
        "download": "Scaricare PDF"
      },
      "order_vat_data_check_done": {
        "subject": "Il vostro ordine per il controllo manuale dei dati per l'ordine %orderId% di %company%.",
        "explanation": "Grazie per l'ordine e per aver commissionato il controllo manuale dei dati.",
        "possible_actions": "È possibile accedere al portale online all'indirizzo %domain% in qualsiasi momento e visualizzare l'ordine.",
        "call_to_action": "Mostra lo stato"
      },
      "order_reduced_vat_authorized": {
        "subject": "La vostra richiesta di aliquota IVA ridotta per l'ordine %orderId% è stata approvata.",
        "explanation": "Grazie per il vostro ordine. Abbiamo autorizzato l'ordine per l'aliquota IVA ridotta.",
        "possible_actions": "È possibile accedere al portale online all'indirizzo %domain% in qualsiasi momento e visualizzare l'ordine.",
        "call_to_action": "Mostra lo stato"
      },
      "order_reduced_vat_rejected": {
        "subject": "La richiesta di aliquota IVA ridotta per l'ordine %orderId% non è stata approvata.",
        "explanation": "Grazie per il vostro ordine. Abbiamo controllato il suo ordine e abbiamo constatato che non può beneficiare dell'aliquota IVA ridotta. Verrà applicata l'aliquota IVA normale.",
        "possible_actions": "È possibile accedere al portale online all'indirizzo %domain% in qualsiasi momento e visualizzare l'ordine.",
        "call_to_action": "Mostra lo stato"
      },
      "chedri_registration": {
        "subject": "La vostra registrazione nella %azienda%",
        "subject_fiendly": "La vostra registrazione presso %azienda%",
        "explanation": "<p>Ciao %firstName% %lastName%,</p><p>Grazie per la sua registrazione.</p><p>Abbiamo creato con successo un account di prova per lei e per la sua azienda.</p>",
        "possible_actions": "Una volta confermato l'indirizzo e-mail, è possibile accedere con <strong>%username%</strong> come nome utente e la password selezionata durante la registrazione.",
        "call_to_action": "Conferma il mio indirizzo e-mail"
      },
      "invitation": {
        "subject": "Invito da parte di %azienda%",
        "explanation": "A nome di %company%, siamo lieti di invitarvi a visitare il nostro portale di design online.",
        "login_data": "Se accettate l'invito, utilizzate i seguenti dati per accedere: <strong>%username%</strong> come nome utente e <strong>%password%</strong> come password. Assicurarsi di modificare la password dopo l'accesso.",
        "call_to_action": "Accettare l'invito",
        "explanation_first_name_form": "A nome di %company%, siamo lieti di invitarvi a visitare il nostro portale di design online.",
        "login_data_first_name_form": "Se accettate l'invito, utilizzate i seguenti dati per accedere: <strong>%username%</strong> come nome utente e <strong>%password%</strong> come password. Assicurarsi di modificare la password dopo l'accesso."
      },
      "new_quote": {
        "subject": "Nuova offerta da %azienda%",
        "explanation": "La %azienda% vi invia un preventivo per un servizio da voi richiesto.",
        "possible_actions": "A questo punto si ha la possibilità di controllare e accettare l'offerta. Potete anche rifiutare l'offerta e lasciare un messaggio che ne spieghi il motivo.",
        "call_to_action": "Controllare l'offerta"
      },
      "new_invoice": {
        "subject": "Nuova fattura da %azienda%",
        "explanation": "La %azienda% vi invierà una fattura per un servizio da voi richiesto. La fattura è disponibile nel nostro portale online. Abbiamo anche allegato la fattura a questo messaggio.",
        "explanation_first_name_form": "La %azienda% vi invierà una fattura per un servizio da voi richiesto. La fattura è disponibile nel nostro portale online. Abbiamo anche allegato la fattura a questo messaggio.",
        "possible_actions": "È possibile accedere al portale online all'indirizzo %domain% in qualsiasi momento e visualizzare le fatture.",
        "possible_actions_first_name_form": "È possibile accedere al portale online all'indirizzo %domain% in qualsiasi momento e visualizzare le fatture.",
        "call_to_action": "Mostra fattura"
      },
      "new_sales_commission": {
        "subject": "Nuova commissione da parte di %azienda%.",
        "explanation": "La %azienda% vi invierà una commissione per un servizio da voi richiesto. La commissione è disponibile nel nostro portale online. Abbiamo anche allegato la commissione a questo messaggio.",
        "possible_actions": "È possibile accedere al portale online all'indirizzo %domain% in qualsiasi momento e visualizzare la propria commissione online.",
        "call_to_action": "Mostra commissione"
      },
      "new_questionnaire": {
        "subject": "La %azienda% desidera che Lei compili un questionario",
        "explanation": "<p>Ciao %firstName% %lastName%,</p><p>la%azienda% le sta inviando un nuovo questionario.</p>",
        "possible_actions": "È possibile visualizzare e compilare il questionario online.",
        "call_to_action": "Visualizza il questionario"
      },
      "questionnaire_filled_out": {
        "subject": "Il questionario è stato appena compilato",
        "explanation": "<p>Ciao %firstName% %lastName%,</p><p>il questionario richiesto da %targetFirstName% è appena stato completato.</p>",
        "possible_actions": "Le risposte possono essere visualizzate nella timeline di testa.",
        "call_to_action": "Piombo aperto"
      },
      "contact_form_cms": {
        "subject": "Nuovo contatto da %name%",
        "info": "Notifica dell'invio di un modulo di contatto. Si prega di elaborarlo immediatamente e di garantire che il cliente riceva la migliore assistenza possibile."
      },
      "contact_form_edition": {
        "subject": "Nuovo contatto",
        "info": "<p>Il modulo di contatto è stato utilizzato. Ora assicuratevi di fornire il miglior servizio clienti possibile :) Buona fortuna</p>",
        "form_fields": "Campi del modulo completati"
      },
      "password_renew": {
        "subject": "Rinnovo della password",
        "explanation": "<p>Ciao %email%,<br><br>Hai provato ad accedere a %platformName%. La sua password è scaduta. È necessario impostare una nuova password per poter accedere in futuro. Clicchi sul link sottostante per inserire una nuova password.</p>",
        "possible_actions": "È possibile impostare una nuova password. È sufficiente fare clic sul pulsante.",
        "call_to_action": "Impostare una nuova password",
        "explanation_first_name_form": "<p>Ciao %email%,<br><br>hai provato ad accedere a %platformName%. La sua password è scaduta. È necessario impostare una nuova password per poter accedere in futuro. Clicchi sul link sottostante per inserire una nuova password.</p>",
        "possible_actions_first_name_form": "È possibile impostare una nuova password. È sufficiente fare clic sul pulsante."
      },
      "password_reset": {
        "subject": "Reimpostare la password",
        "explanation": "<p>Buon pomeriggio %email%,</p><br /><p>è stata appena richiesta una nuova password da %platformName% tramite la nostra piattaforma. Se ha effettuato questa richiesta, la preghiamo di cliccare sul link sottostante.</p",
        "explanation_first_name_form": "<p>Ciao %email%,</p><br /><p>è stata appena richiesta una nuova password a %platformName% tramite la nostra piattaforma. Se hai fatto questa richiesta, clicca sul link qui sotto.</p",
        "possible_actions": "È possibile impostare una nuova password. È sufficiente fare clic sul pulsante.",
        "possible_actions_first_name_form": "È possibile impostare una nuova password. È sufficiente fare clic sul pulsante.",
        "call_to_action": "Impostare una nuova password"
      },
      "message": {
        "explanation": "%azienda% ti ha inviato un messaggio",
        "possible_actions": "È possibile rispondere a questo messaggio facendo clic su questo pulsante:",
        "call_to_action": "Risposte"
      },
      "customerMessage": {
        "explanation": "Il cliente vi ha inviato un messaggio"
      },
      "lead_comment_mention": {
        "subject": "Sono stati citati nella traccia %titolo",
        "explanation": "%creator% ha parlato di te in un commento.",
        "call_to_action": "Piombo aperto"
      },
      "lead_assignee_changed": {
        "subject_assigned": "Ora siete assegnati al lead '%title%'.",
        "subject_unassigned": "Non sono più assegnati al lead \"%title%\".",
        "explanation_assigned": "Ora siete assegnati alla traccia '%title%'. L'editore precedente era %oldAssignee%.",
        "explanation_unassigned": "Non sono più assegnati al lead \"%title%\". Il nuovo assegnatario è %newAssignee%.",
        "call_to_action": "Piombo aperto"
      },
      "bank_data_reminder": {
        "subject": "Abbiamo bisogno delle vostre coordinate bancarie per il vostro ordine",
        "explanation": "Avete prenotato dei servizi per i quali sono richieste delle coordinate bancarie.",
        "possible_actions": "Andare all'ordine e inserire i dati mancanti.",
        "call_to_action": "Inserire le coordinate bancarie"
      },
      "order_approved": {
        "subject": "Il vostro ordine con %azienda% è stato appena approvato",
        "explanation": "Siamo lieti di informarla che il suo ordine %orderId% è stato approvato.",
        "possible_actions": "Per visualizzare i dettagli, è possibile accedere al proprio ordine.",
        "call_to_action": "Mostra l'ordine"
      },
      "sales_contract_uploaded": {
        "subject": "Il contratto di acquisto per il vostro ordine è ora disponibile"
      },
      "order_cancelled": {
        "subject": "L'ordine con %company% è stato annullato.",
        "explanation": "Siamo spiacenti, ma abbiamo dovuto annullare l'ordine %orderId%.",
        "possible_actions": "Per visualizzare i dettagli, accedere al proprio ordine.",
        "call_to_action": "Mostra l'ordine"
      },
      "payment_instructions": {
        "subject": "Informazioni di pagamento per il vostro ordine presso %azienda%.",
        "explanation": "Di seguito troverete le informazioni di pagamento per il vostro ordine %orderId%:",
        "possible_actions": "Per visualizzare i dettagli, accedere al proprio ordine.",
        "call_to_action": "Mostra l'ordine"
      },
      "pickup_invite": {
        "subject": "Il vostro ordine è pronto per il ritiro",
        "explanation": "Il vostro ordine con il %orderId% è pronto per il ritiro.",
        "possible_actions": "Per visualizzare i dettagli, accedere al proprio ordine.",
        "call_to_action": "Mostra l'ordine"
      },
      "order_picked_up": {
        "subject": "Grazie per il vostro ordine"
      },
      "request_plugin_activation": {
        "subject": "Richiesta di attivazione del plugin",
        "explanation": "<p>Ciao %firstName% %lastName%,</p><p>%creatorFirstName% %creatorLastName% desidera attivare il plugin <strong>%pluginName%</strong> per il progetto %platformName%.</p>",
        "possible_actions": "È possibile accedere e utilizzare il modulo di attivazione premendo il seguente pulsante.",
        "call_to_action": "Attivare il plugin"
      },
      "plugin_activated": {
        "subject": "Plugin attivato",
        "explanation": "<p>Ciao %firstName% %lastName%,</p><p>Vorremmo informarvi che il %pluginName% per il progetto %platformName% è stato attivato.</p>",
        "call_to_action": "Vedetelo in azione"
      },
      "lead_data": {
        "subject": "Nuova traccia: %title%",
        "call_to_action": "Mostra la pista"
      },
      "order": {
        "item_sales": {
          "title": "Il vostro ordine",
          "explanation": "I dettagli dell'ordine sono riportati di seguito.",
          "subject": "Il tuo ordine con %azienda%",
          "possible_actions": "Potete trovare tutte le informazioni sul vostro ordine anche nel vostro conto cliente.",
          "call_to_action": "Visualizza nel conto cliente"
        }
      },
      "rent_sync_vamoso_error": {
        "subject": "Ordine #%orderId%: Errore nella sincronizzazione dell'affitto per il plugin Vamoso: %errorType%.",
        "explanation": "Purtroppo il nostro plugin non è riuscito a eseguire un'operazione nel vostro sistema Vamoso. Si prega di controllare manualmente lo stato delle prenotazioni. <br/>Tipo di errore: %errorType% <br/>Messaggio errore: %errorText%"
      },
      "lead_deadline_reminder": {
        "subject": "Sono assegnati alle piste che devono essere consegnate domani",
        "explanation": "Qui troverete un elenco delle piste assegnate a voi. Queste piste sono ancora aperte e devono essere consegnate domani."
      },
      "chat_history": {
        "subject": "La cronologia delle chat",
        "explanation": "Grazie per averci contattato. In allegato trovate la cronologia della vostra chat con %firstName% %lastName%. Spero che siamo stati in grado di aiutarla a risolvere le sue domande o i suoi problemi. In caso di ulteriori domande, non esiti a contattarci nuovamente in qualsiasi momento.",
        "greatingchat_history": "Storia della chat",
        "greating": "Grazie per aver utilizzato la chat di %platformName%. Il tuo partner di chat era %firstName% %lastName% Di seguito puoi vedere la cronologia della chat da %date%.",
        "adaption": "Spero di essere stato in grado di aiutarvi con tutte le vostre domande o problemi. Se avete altre domande, non esitate a contattarci di nuovo in qualsiasi momento.",
        "privacy_policy": "L'informativa sulla privacy è disponibile all'indirizzo <a href=\"%link%\" target=\"_blank\">'%link%</a>.",
        "call_to_action": "Tornate a trovarci"
      },
      "lead_pool_notification": {
        "subject": "Nuova traccia nel pool: %title%",
        "explanation": "Nel sistema è disponibile un nuovo lead. Non abbiamo ancora assegnato un dipendente. Fare clic sul pulsante sottostante per visualizzare i dettagli.",
        "call_to_action": "Piombo aperto"
      },
      "lead_escalation_level1": {
        "subject": "[LIVELLO1] %nome%",
        "explanation": "State ricevendo questa e-mail perché uno o più contatti hanno raggiunto il primo livello di escalation definito nella regola %name%. Si prega di controllare le derivazioni per vedere cosa è necessario fare."
      },
      "lead_escalation_level2": {
        "subject": "[LIVELLO2] %nome%",
        "explanation": "State ricevendo questa e-mail perché uno o più contatti hanno raggiunto il secondo livello di escalation definito nella regola %name%. Vi invitiamo a controllare le richieste e a motivare i vostri team a lavorare di più."
      },
      "campaign_budget_auth_request": {
        "subject": "Attenzione: il budget della campagna è stato superato.",
        "explanation": "il budget della campagna è stato superato.",
        "call_to_action": "Mostra l'ordine"
      },
      "campaign_budget_auth_request_unsuccessful": {
        "subject": "Attenzione: problema di autorizzazione del budget della campagna.",
        "explanation": "È stato rilevato un problema con l'autorizzazione del budget della campagna.",
        "call_to_action": "Mostra l'ordine"
      },
      "user_budget_auth_request": {
        "subject": "Attenzione: il budget utente è stato superato.",
        "explanation": "il budget dell'utente è stato superato.",
        "call_to_action": "Mostra l'ordine"
      },
      "user_budget_auth_request_unsuccessful": {
        "subject": "Attenzione: problema con l'autorizzazione del budget dell'utente",
        "explanation": "È stato rilevato un problema con l'autorizzazione del budget utente.",
        "call_to_action": "Mostra l'ordine"
      },
      "order_auth_changes_requested": {
        "subject": "Info: Modifiche richieste",
        "call_to_action": "Mostra l'ordine"
      },
      "order_auth_changes_implemented": {
        "subject": "Info: Modifiche apportate",
        "call_to_action": "Mostra l'ordine"
      },
      "manage_notification": "Gestire le notifiche e-mail",
      "unsubscribe": "Annullamento dell'iscrizione",
      "confidentiality_notice": "Avviso di riservatezza: questo messaggio di posta elettronica è destinato esclusivamente al destinatario specificato e può contenere informazioni riservate e/o legalmente protette. Se non siete il destinatario corretto o avete ricevuto questa e-mail per errore, siete pregati di informare immediatamente il mittente e di distruggere questa e-mail. Non è consentita la copia e l'inoltro non autorizzati di questa e-mail.",
      "no_legal_binding_notice": "Questo messaggio ha uno scopo puramente informativo e non è giuridicamente vincolante. A causa della facile manipolazione delle e-mail, non ci assumiamo alcuna responsabilità per il loro contenuto.",
      "invoice_due_reminder": {
        "subject": "Promemoria per il pagamento",
        "explanation": "Purtroppo non siamo ancora riusciti a stabilire la ricevuta di pagamento della seguente fattura.",
        "explanation_first_name_form": "Purtroppo non abbiamo ancora ricevuto il pagamento della seguente fattura.",
        "request_payment": "Per evitare ulteriori costi, chiediamo il pagamento immediato dell'importo totale. Per il pagamento di questa fattura si prega di utilizzare le seguenti coordinate bancarie:",
        "request_payment_first_name_form": "Per evitare ulteriori costi, si prega di pagare immediatamente l'importo totale. Per il pagamento della fattura si prega di utilizzare le seguenti coordinate bancarie:",
        "contact": "In caso di domande su questo promemoria, vi preghiamo di contattarci via e-mail all'indirizzo %email% o per telefono al numero verde %phone%.",
        "contact_first_name_form": "In caso di domande su questo promemoria, vi preghiamo di contattarci via e-mail all'indirizzo %email% o per telefono al numero verde %phone%.",
        "bank_account": "Coordinate bancarie",
        "bank_name": "Nome della banca",
        "account_holder": "Titolare del conto",
        "iban": "IBAN",
        "swift_bic": "SWIFT/BIC",
        "bank_code_number": "Codice di selezione bancaria",
        "account_number": "Numero di conto",
        "invoice_number": "Fattura n:"
      },
      "last_seen_reminder": {
        "subject": "Promemoria ultima visita",
        "user_not_seen": "L'utente '%clientUsername%' non è stato visto per %daysNotSeen% giorni.",
        "contact_user": "Contattateli per assicurarvi che tutto sia in ordine.",
        "contact_user_first_name_form": "Contattateli per assicurarvi che tutto sia in ordine.",
        "customer_profil": "Profilo del cliente"
      },
      "order_foreign_status": {
        "do_contact_support": {
          "subject": "Ordine - Contattare l'assistenza clienti",
          "explanation": "Grazie per il vostro ordine. Abbiamo una domanda sul suo ordine. La preghiamo di contattare il nostro servizio clienti.",
          "explanation_first_name_form": "Grazie per il vostro ordine. Abbiamo una domanda sul suo ordine. La preghiamo di contattare il nostro servizio clienti.",
          "possible_actions": "-",
          "contact": "Contattateci",
          "phone": "Telefono:",
          "email": "e-mail:",
          "call_to_action": "-"
        },
        "in_delivery": {
          "subject": "L'ordine è in fase di spedizione",
          "explanation": "Il vostro ordine è stato prodotto e consegnato al corriere per la consegna.",
          "explanation_first_name_form": "Il vostro ordine è stato prodotto e consegnato al corriere per la consegna.",
          "possible_actions_tracking": "È possibile seguire la consegna sul sito web del corriere.",
          "possible_actions_tracking_first_name_form": "È possibile seguire la consegna sul sito web del corriere.",
          "possible_actions": "È possibile accedere al portale online all'indirizzo %domain% in qualsiasi momento e visualizzare l'ordine.",
          "possible_actions_first_name_form": "È possibile accedere al portale online all'indirizzo %domain% in qualsiasi momento e visualizzare l'ordine.",
          "call_to_action": "Dettagli dell'ordine",
          "tracking_numbers": "Numeri di tracciamento"
        },
        "in_production": {
          "subject": "L'ordine è attualmente in fase di produzione",
          "explanation": "Grazie per il vostro ordine. Abbiamo effettuato l'ordine. È attualmente in fase di produzione.",
          "explanation_first_name_form": "Grazie per il vostro ordine. Abbiamo effettuato l'ordine. È attualmente in fase di produzione.",
          "possible_actions": "È possibile accedere al portale online all'indirizzo %domain% in qualsiasi momento e visualizzare l'ordine.",
          "possible_actions_first_name_form": "È possibile accedere al portale online all'indirizzo %domain% in qualsiasi momento e visualizzare l'ordine.",
          "call_to_action": "Dettagli dell'ordine"
        },
        "invalid_print_data": {
          "subject": "Ordine - dati di stampa non validi",
          "explanation": "Durante l'ordine sono stati rilevati dati di stampa non validi.",
          "call_to_action": "Dettagli dell'ordine"
        },
        "missing_print_data": {
          "subject": "Ordine - dati di stampa mancanti",
          "explanation": "Al momento dell'ordine sono stati rilevati dati di stampa mancanti.",
          "call_to_action": "Dettagli dell'ordine"
        },
        "order_canceled": {
          "subject": "L'ordine è stato annullato",
          "explanation": "Il suo ordine è stato annullato con successo. Ci dispiace che abbia dovuto annullare l'ordine e speriamo di poterla aiutare di nuovo in futuro.",
          "explanation_first_name_form": "Il suo ordine è stato annullato con successo. Ci dispiace che abbia dovuto annullare l'ordine e speriamo di poterla aiutare di nuovo in futuro.",
          "possible_actions": "È possibile accedere al portale online all'indirizzo %domain% in qualsiasi momento e visualizzare l'ordine.",
          "possible_actions_first_name_form": "È possibile accedere al portale online all'indirizzo %domain% in qualsiasi momento e visualizzare l'ordine.",
          "call_to_action": "Dettagli dell'ordine"
        },
        "payment_pending": {
          "subject": "Ordine di acquisto - pagamento in sospeso",
          "explanation": "Non abbiamo ancora ricevuto il pagamento dell'ordine.",
          "explanation_first_name_form": "Non abbiamo ancora ricevuto il pagamento dell'ordine.",
          "possible_actions": "È possibile accedere al portale online all'indirizzo %domain% in qualsiasi momento e visualizzare l'ordine.",
          "possible_actions_first_name_form": "È possibile accedere al portale online all'indirizzo %domain% in qualsiasi momento e visualizzare l'ordine.",
          "call_to_action": "Dettagli dell'ordine"
        }
      },
      "contact_form_cms_confirm": {
        "subject": "Confermare l'e-mail",
        "explanation": "<p>La sua email è stata inserita nel modulo di contatto del nostro sito web.</p><p>Se ha completato il modulo di contatto, clicchi sul pulsante Conferma.</p><p>Altrimenti, ignori semplicemente questo messaggio.</p>",
        "call_to_action": "Confermare"
      },
      "contact_form_edition_confirm": {
        "subject": "Confermare l'e-mail",
        "explanation": "<p>Il suo indirizzo e-mail è stato inserito nel modulo di contatto sul nostro sito web.</p><p>Se ha completato il nostro modulo di contatto, clicchi sul pulsante Conferma.</p><p>Altrimenti, ignori semplicemente questo messaggio.</p>",
        "call_to_action": "Confermare"
      },
      "order_content_check_status": {
        "subject": "il controllo del contenuto per l'ordine %orderId% è stato %status%",
        "explanation": "Grazie per il vostro ordine. Abbiamo controllato il contenuto del suo ordine e gli abbiamo assegnato lo stato %.",
        "possible_actions": "È possibile richiamare l'ordine per visualizzarne i dettagli.",
        "call_to_action": "Visualizza l'ordine",
        "reason": "Motivo"
      },
      "lead_follow_up_reminder": {
        "subject": "Controllare i compiti assegnati",
        "explanation": "Qui troverete un elenco dei compiti che vi sono stati assegnati. Questi compiti sono ancora aperti."
      }
    },
    "contact_form_cms_confirm": {
      "success": {
        "heading": "La vostra richiesta è stata inviata",
        "body": "<p>Grazie per averci contattato! </p><p>Abbiamo ricevuto la sua richiesta e le risponderemo al più presto.</p>"
      },
      "already_confirmed": {
        "heading": "La domanda è già stata confermata",
        "body": "<p> Abbiamo ricevuto la sua richiesta e ci metteremo in contatto con lei il prima possibile.</p>"
      },
      "not_found": {
        "heading": "Non trovato",
        "body": "<p>Assicuratevi di aver cliccato il pulsante nella nostra e-mail per arrivare qui.</p>"
      }
    },
    "contact_form_edition_confirm": {
      "success": {
        "heading": "La vostra richiesta è stata inviata",
        "body": "<p>Grazie per averci contattato! </p><p>Abbiamo ricevuto la sua richiesta e le risponderemo al più presto.</p>"
      },
      "already_sent": {
        "heading": "La domanda è già stata confermata",
        "body": "<p> Abbiamo ricevuto la sua richiesta e ci metteremo in contatto con lei il prima possibile.</p>"
      },
      "not_found": {
        "heading": "Non trovato",
        "body": "<p>Assicuratevi di aver cliccato il pulsante nella nostra e-mail per arrivare qui.</p>"
      },
      "expired": {
        "heading": "Codice scaduto",
        "body": "<p>Il link di conferma era valido solo per 2 giorni. Si prega di compilare nuovamente il modulo di contatto.</p>"
      }
    },
    "formGroup": {
      "basic_information": "Informazioni di base",
      "contact_person": "Persona di contatto",
      "address_information": "Indirizzo",
      "address_settings": "Impostazioni dell'indirizzo",
      "bank_account": "Coordinate bancarie",
      "company_information": "Informazioni sulla società",
      "user_roles": "Ruoli utente",
      "subscription_roles": "Abbonamento utente",
      "opening_hours": "Orari di apertura",
      "position_on_map": "Posizione sulla mappa",
      "white_label_parent": "Precursore",
      "user_teams": "Team di utenti",
      "contact_data": "Dettagli di contatto",
      "permissions": "Autorizzazioni",
      "person_information": "Informazioni sull'utente"
    },
    "hints": {
      "small_hint_for_you": "Un piccolo suggerimento per voi",
      "task_is_automated": "Questo task è automatizzato. È necessario attendere l'esecuzione dell'attività di automazione. Dopodiché, la segneremo come completata per voi.",
      "please_complete_previous_tasks": "Non è possibile modificare questo compito ora. Si prega di occuparsi prima dei compiti precedenti.",
      "companyLogoNew": "<p>Non avete ancora caricato il vostro logo. Per caricarne uno, è sufficiente trascinare e rilasciare un'immagine (jpeg o png) su questa pagina.</p>",
      "companyLogoChange": "<p>Questo è il logo che avete caricato. Per modificarlo, è sufficiente trascinare un'immagine (jpeg o png) su questa pagina.</p>)",
      "companyFinancial": "<div><p>Compilare le informazioni necessarie per la fatturazione</p><p>Le coordinate bancarie qui inserite saranno visualizzate in calce ai preventivi e alle fatture</p></div>",
      "companyPaymentInfo": "<p>Queste informazioni vengono visualizzate su ogni fattura creata.</p"
    },
    "vendors": {
      "association": "Associazione con i dati del cliente",
      "vendor_api": "Nome del servizio",
      "pricing": "Prezzi",
      "operating_mode": "Modalità operativa",
      "price_update_method": "Metodo di aggiornamento dei prezzi",
      "order_processing": "Elaborazione dell'ordine",
      "order_method": "Metodo di ordinazione",
      "order_email": "Indirizzo e-mail per gli ordini",
      "phone_number": "Numero di telefono",
      "web_shop_url": "URL del negozio web",
      "trial": "processo",
      "live": "dal vivo",
      "prices_by_vendor": "I prezzi sono gestiti dal fornitore",
      "prices_by_employee": "I prezzi sono controllati e mantenuti dal dipendente",
      "prices_by_api": "I prezzi vengono aggiornati tramite un'API",
      "notified_by_email": "Riceverete una notifica degli ordini in arrivo via e-mail",
      "accepts_only_email": "Accetta solo ordini via e-mail",
      "use_webshop": "Ordini via webshop",
      "order_by_api": "Ordini tramite API",
      "order_by_api_and_webshop": "Ordini tramite API o webshop"
    },
    "client_account": "Conto cliente",
    "deleted_campaigns": "Campagne cancellate",
    "deleted_editions": "Edizioni cancellate",
    "deleted_folders": "Cartelle eliminate",
    "deleted_media": "Media cancellati",
    "root": "Directory principale",
    "trash_bin": "Cestino per la carta straccia",
    "delete_trash_bin": "Cestino vuoto",
    "pls_wait": "Attendere. L'azione desiderata viene eseguita.",
    "go_back": "Indietro",
    "restore": "Ripristino",
    "archived_campaigns": "Campagne archiviate",
    "load_all_campaigns": "Carica tutte le campagne",
    "load_creacheck_campaigns": "Caricare le campagne Creacheck",
    "load_flyeralarm_campaigns": "Caricare le campagne Flyeralarm",
    "load_designgenie_campaigns": "Campagne di carico DesignGenie",
    "archived_editions": "Edizioni archiviate",
    "sorting_name": "Nome",
    "sorting_date_ASC": "Prima il più vecchio",
    "sorting_date_DESC": "Prima i più recenti",
    "sorting_owner": "Azienda",
    "sorting_creator": "Creato da",
    "campaign_title": "Campagna",
    "dataproof": "Controllo dei dati",
    "not_ordered": "non ordinato",
    "supplier_order": "Ordine del fornitore",
    "change_of_address": "Cambio di indirizzo",
    "not_available": "non disponibile",
    "selected_shipping_method": "Metodo di spedizione selezionato",
    "ordered_items": "Articoli ordinati",
    "standard": "Standard",
    "express": "Espresso",
    "aws_iframe_hosting": "Hosting AWS Iframe",
    "aws_hosting_html_file_for_iframe_downloaded": "File HTML dell'hosting AWS per l'iframe con titolo: %title%",
    "lead_stage_change_confirm_title": "Cambiare lo stato della guida",
    "lead_stage_change_confirm_body": "Volete davvero cambiare lo stato della pista?",
    "iframe_implementation_file": "File di implementazione Iframe",
    "iframe_implementation_file_download_failed": "Non è stato possibile scaricare il file di implementazione dell'iframe: %errore%",
    "lead_stage_change_error_domain_hosting_in_progress": "La linea non può essere modificata se la fase corrente è in corso o se l'autorizzazione non è stata approvata.",
    "lead_stage_change_error_domain_hosting_command_to_start_pipeline_has_been_sent": "La linea non può essere modificata se la fase corrente è in corso o se l'autorizzazione non è stata approvata.",
    "allowed_domain_already_exists": "Il dominio %domain% è già presente nell'elenco dei domini autorizzati.",
    "allowed_domain_domain_name_should_not_be_empty": "Il nome del dominio non deve essere vuoto.",
    "allowed_domains_title": "Domini consentiti",
    "allowed_domains_description": "Qui si possono aggiungere i domini che possono essere utilizzati per l'implementazione degli iframe.",
    "allowed_domains_add": "Aggiungi dominio",
    "allowed_domains_domain_name": "Nome del dominio",
    "allowed_domains_default_src": "Default-Src",
    "allowed_domains_script_src": "Script-Src",
    "allowed_domains_connect_src": "Connetti-Src",
    "allowed_domains_style_src": "Stile-Src",
    "allowed_domains_img_src": "Img-Src",
    "allowed_domains_font_src": "Font-Src",
    "allowed_domains_frame_src": "Telaio-Src",
    "created_at": "Creato il",
    "created_by": "Creato da",
    "overnight": "Pernottamento",
    "create_printData": "Creare dati di stampa",
    "created_new_printData": "Sono stati creati nuovi dati di stampa",
    "uploaded_printData_successful": "I dati di stampa sono stati caricati con successo",
    "no_printData": "Nessun dato di stampa",
    "no_images": "Nessun file immagine disponibile",
    "not_the_original_printFile": "Nota: questo non è il file di stampa originale",
    "printDataFiles": "Stampa dei file di dati",
    "image_files": "File immagine",
    "customer_comment": "Commento del cliente",
    "actions_": " Azioni",
    "import_success": "L'importazione è stata eseguita",
    "import_rejected": "L'importazione è stata annullata",
    "import_exec": "L'importazione viene eseguita",
    "import_successRE": "L'importazione è stata ripristinata",
    "import_rejectedRE": "Il processo è stato annullato",
    "import_execRE": "L'importazione verrà reimpostata",
    "choice_csv": "Selezionare un file CSV",
    "csv_content": "Contenuto CSV",
    "warning_and_notes": "Avvertenze e note",
    "start_remove": "Avvio della rimozione",
    "reverse_remove": "Annullamento della rimozione",
    "user_import_success": "Importazione riuscita",
    "user_duplicate_import": "Trovato un duplicato",
    "start_import": "Avviare l'importazione",
    "reverse_import": "Annullamento dell'importazione",
    "upload_local_changes": "Caricare le modifiche locali",
    "dataproof_master_copies_upload": "Qui è possibile caricare i file di modelli/dati modificati localmente con [Upload].",
    "edit_edition": "Modifica del materiale pubblicitario",
    "dataproof_master_copies_recreate": "Qui, con [Crea dati di stampa] si può avviare una nuova creazione di file di modello/dati di stampa sulla base dell'originale oppure si possono modificare localmente i file di modello/dati di stampa con [Modifica].",
    "hint": "Nota",
    "dataproof_hint_master_copies_upload": "I file dei modelli/dati di stampa modificati localmente devono essere caricati con [Carica] affinché la modifica abbia effetto al termine della postelaborazione.",
    "edit_": "Modifica",
    "send_invoice": "Inviare la fattura",
    "sended_invoice": "La fattura è stata inviata a",
    "send_email_tracking": "Tracciabilità della spedizione via e-mail",
    "sended_email": "L'e-mail è stata inviata!",
    "tracking": "Tracciabilità della spedizione",
    "send_tracking_to_customer": "Inviare il tracking della spedizione ai clienti",
    "to_the_product": "Al prodotto",
    "order_sended": "L'ordine è stato inviato!",
    "failure_when_ordering": "Si è verificato un errore durante l'ordine!",
    "please_create_orderhistory_email": "L'ordine è stato inviato via e-mail! Si prega di creare uno storico degli ordini!",
    "new_status_set": "Il nuovo stato è stato impostato!",
    "change_of_status": "Cambiamento di stato",
    "statusQuery": "Richiesta di stato",
    "totalStatus": "Stato generale:",
    "printData": "Dati di stampa",
    "current_printdata_sended": "I dati di stampa attuali sono stati inviati!",
    "orderHistory": "Processo d'ordine",
    "orderHistory_created": "Lo storico degli ordini è stato creato!",
    "order_created": "L'ordine è stato creato con successo!",
    "enter_trackingLink": "Inserire il link di tracciamento",
    "query_orderStatus": "Interrogare lo stato dell'ordine",
    "foreignId": "Ordine esterno n.",
    "orderStatus": "Stato dell'ordine",
    "orderDate": "Data dell'ordine",
    "options_": "Opzioni",
    "type_": "Gentile",
    "comment_": "Commento",
    "orderOptions": "Opzioni di ordinazione",
    "ordering_by_webshop": "Ordine via webshop",
    "ordering_by_email": "Ordine via e-mail",
    "info_to_the_emailOrder": "Informazioni aggiuntive per gli ordini via e-mail",
    "add_or_edit_foreignId": "Aggiungere o modificare il numero d'ordine esterno",
    "create_trackingLink": "Creare un link per il monitoraggio della spedizione",
    "campaign": {
      "copy_edition": "Duplicato",
      "created_by": "Creato da:",
      "no_edition": "Non hai ancora pubblicato alcun materiale pubblicitario!",
      "configuration": "Opzioni generali",
      "select_all": "Tutti i prodotti",
      "budget": "Bilancio della campagna",
      "access": "Diritti di accesso",
      "inactive": "Campagna di chiusura",
      "delete": "Cancellare la campagna",
      "transfer_title": "Trasferire la campagna a un altro utente",
      "campaign_owner": "Proprietario della campagna",
      "campaign_creator": "Creatore di campagne",
      "change_owner": "Cambiare il proprietario della campagna",
      "change_creator": "Creatore di campagne di cambiamento",
      "order_all": "Ordinare l'intera campagna",
      "share": "Campagna di condivisione",
      "archive": "Campagna d'archivio",
      "recent_editions": "Ultima modifica",
      "marked_editions": "Materiale pubblicitario contrassegnato",
      "marked_campaigns": "Campagne etichettate",
      "own_campaigns": "Campagne proprie",
      "shared_editions": "Materiale pubblicitario condiviso",
      "last_edited": "Modificato in data:",
      "generated": "Creato il:",
      "edition_edit": "Avvio dell'editor",
      "edition_item": "Prodotto selezionato",
      "submission_item": "Presentazione selezionata",
      "edition_configuration": "2. impostazioni",
      "edition_order": "Aggiungi al carrello",
      "edition_reorder": "Riordino",
      "ordered": "Ordinato!",
      "edition_delete": "Cancellare",
      "edition_preview": "Anteprima",
      "edition_edit_alt": "Modifica",
      "edition_error_empty": "Hai %s pagine non modificate",
      "submissions": "Modelli",
      "new": "Creare una nuova campagna",
      "back_to_list": "Tutte le campagne",
      "new_edition": "Creare nuovo materiale pubblicitario",
      "settings": "Gestione della campagna",
      "campaign_archived": "La vostra campagna è stata archiviata",
      "successfully_archived": "Archiviazione riuscita",
      "save_settings": "Risparmiare",
      "cancel_settings": "Annullamento",
      "title": "Nome della campagna",
      "edition_title": "Designazione",
      "publisher": "Indicazione dell'editore",
      "creator": "Campagna creata da:",
      "editions": "Materiale pubblicitario",
      "edition": "Materiale pubblicitario",
      "sidebar": "Campagne",
      "mark_edition": "Contrassegnare il materiale pubblicitario",
      "unmark_edition": "Rimuovere la marcatura",
      "campaign_title_valid": "Inserire un nome per la campagna.",
      "campaign_publisher_valid": "Inserire i dati relativi al copyright.",
      "choose_company_to_load_campaign": "Per caricare le campagne, selezionare un'azienda o fare clic sul pulsante \"Carica tutte le campagne\".",
      "no_product_selected": "Nessun prodotto selezionato",
      "new_edition_title": "Nuova edizione",
      "added_to_cart": "La tua campagna è stata aggiunta al carrello!",
      "edition_add_to_cart_title": "Prodotto nel carrello",
      "edition_add_to_cart_button_label": "Grande",
      "edition_add_to_cart_label": "Al carrello della spesa",
      "edition_added_to_cart_message": " è stato aggiunto al tuo carrello.",
      "edit_disabled": "L'editing dell'edizione è stato temporaneamente disattivato a causa di un aggiornamento del template.",
      "export_image": "Esportazione dell'immagine",
      "unknown": "sconosciuto",
      "already_ordered": "Questa edizione è già stata ordinata e non può più essere modificata. Tuttavia, è possibile duplicare l'edizione e modificarla. Vedere il menu",
      "modal_tabs": {
        "default_edition": "Materiale pubblicitario generale",
        "edition_from_submission": "Materiale pubblicitario da modello"
      }
    },
    "uom": {
      "piece": "Pezzo",
      "gram": "grammo",
      "kilogram": "Chilogrammo",
      "running_meter": "Metro di corsa",
      "liter": "Litri",
      "package": "Pacchetto",
      "kilometer": "Chilometri"
    },
    "uom_symbole": {
      "gram": "g",
      "kilogram": "kg",
      "running_meter": "metri lineari",
      "liter": "l",
      "package": "Pkt.",
      "kilometer": "km"
    },
    "pipelineViews": {
      "self": "Assegnato a me",
      "unassigned": "Senza assegnazione",
      "pastdeadline": "Scadenza superata",
      "duetoday": "Da consegnare oggi"
    },
    "leadPriority": {
      "critical": "Critico",
      "high": "Alto",
      "normal": "Normale",
      "low": "Basso"
    },
    "orderTypes": {
      "item_sales": "Vendita",
      "car_rent": "Noleggio auto",
      "car_sales": "Vendite di auto",
      "car_service": "Appuntamento al workshop"
    },
    "genderOptions": {
      "male": "Uomo",
      "female": "Donna"
    },
    "salutationOptions": {
      "mr": "Il Sig.",
      "mrs": "Signora"
    },
    "team_service": "Team di assistenza",
    "team_dispo": "Squadra di smaltimento",
    "team_rent": "Team di noleggio",
    "team_sales": "Team di vendita",
    "car_documents": "Documenti del veicolo",
    "nav_module_chip": "Modulo / chip del navigatore satellitare",
    "safety_vest": "Gilet di sicurezza",
    "parking_disk": "Disco di parcheggio",
    "ice_scraper": "Raschietto per ghiaccio",
    "key_supplement_heating_taxis": "Chiave aggiuntiva (riscaldatore di parcheggio/segnalazione taxi)",
    "shelf_blind": "Mensola per cappelli / tenda a rullo",
    "onboard_folder": "Cartella di bordo",
    "first_aid_kit": "Kit di pronto soccorso",
    "warning_triangle": "Triangolo di avvertimento",
    "tirefit_spare_wheel": "Tirefit / ruota di scorta",
    "handover_damage": {
      "front": "Anteriore",
      "back": "Posteriore",
      "left": "Collegamenti",
      "right": "Diritto",
      "top": "Sopra",
      "interior": "Interno"
    },
    "dark": "È buio",
    "garage": "In un garage",
    "snow_ice": "Neve / Ghiaccio",
    "rain_wet": "Piove / l'auto è bagnata",
    "countries": {
      "de": "Germania",
      "pl": "Polonia",
      "fr": "Francia"
    },
    "cookie_description": {
      "PHPSESSID": "Identifica la sessione corrente del browser",
      "tempTarget": "Utilizziamo il cookie di destinazione temporanea per identificare i prodotti che state cercando e i vostri interessi.",
      "chedriCookiesBoxClosed": "Utilizziamo questo cookie per indicare che l'utente ha visto e accettato la nostra informativa sui cookie.",
      "chedriCookiesPerformance": "Utilizzato per determinare se l'utente accetta i nostri cookie di prestazione",
      "chedriCookiesTargeting": "Utilizzato per determinare se l'utente accetta i nostri cookie di targeting",
      "ga": "Utilizzato per distinguere gli utenti nel servizio Google Analytics",
      "gid": "Utilizzato per distinguere gli utenti nel servizio Google Analytics",
      "act": "Si tratta di un cookie di Facebook utilizzato per le loro analisi e ricerche.",
      "c_user": "Il login di Facebook per gli accessi degli utenti, che viene utilizzato per visualizzare annunci pubblicitari più pertinenti agli utenti e ai loro interessi",
      "datr": "Utilizzato da Facebook per identificare il browser dell'utente",
      "fr": "Contiene un ID univoco del browser e dell'utente che viene utilizzato per la pubblicità mirata.",
      "sb": "Utilizzato da Facebook per migliorare i suggerimenti degli amici",
      "xs": "Un cookie di Facebook utilizzato per gestire una sessione. Funziona insieme al cookie c_user per autenticare la vostra identità su Facebook."
    },
    "cookie_ttl": {
      "6_months": "6 mesi",
      "60_days": "60 giorni",
      "2_years": "2 anni",
      "1_day": "1 giorno"
    },
    "upload_and_manage_media_files": "Qui è possibile caricare e gestire i propri media.",
    "profile_information_avatar_contact_address": "Qui è possibile modificare le informazioni del proprio profilo. Avatar, dati di contatto, indirizzo per la fatturazione, informazioni finanziarie.",
    "define_sales_points_address_position_map_opening_hours": "Definite i vostri punti vendita: Indirizzo, posizione sulla mappa, orari di apertura. Importante per la gestione dei prodotti e dei dipendenti.",
    "check_incoming_and_outgoing_invoices_check_details_and_download_as_pdf": "È possibile controllare le fatture in entrata e in uscita generate dal sistema o da altri utenti. Visualizzate i dettagli e scaricate in formato PDF.",
    "found_api_interesting_personal_api_key_here": "Trovate interessante la nostra API? Volete provarla? Potete trovare la vostra chiave API personale qui.",
    "need_our_data_in_own_system_configure_data_push": "Avete bisogno dei nostri dati nel vostro sistema? Qui è possibile configurare i servizi di push dei dati per sottoscrivere gli eventi dei dati.",
    "projects_running_on_different_domains": "Progetti eseguiti su domini diversi",
    "administration_of_your_website_logo_menu_pages": "Gestite il vostro sito web. Logo, menu principale, pagine di sistema",
    "create_users_manage_profile_financial_roles_teams": "Create gli utenti che lavorano nella vostra organizzazione, gestite il loro profilo e le loro informazioni finanziarie, rivedete e definite i ruoli di sicurezza e assegnate i team.",
    "provision_service": "Servizi prenotati",
    "provision_service_description": "Qui è possibile creare, modificare o eliminare nuovi tipi di servizi.",
    "provision_service_title": "Titolo",
    "provision_service_type": "Nome",
    "provision_service_description_text": "Descrizione del",
    "provision_service_primary_rate": "Commissione in percentuale per il prim. proprietario",
    "provision_service_secondary_rate": "Commissione in percentuale per il proprietario del sec.",
    "provision_service_type_status": "Stato",
    "add_provision_type": "Aggiungere il tipo di commissione",
    "edit_provision_type": "Modifica",
    "create_provision_type": "Creare un tipo di commissione",
    "service_type_onboarding": "Onboarding (installazione)",
    "service_type_subscriptions": "Abbonamenti (MRR, abbonamenti && quote utente)",
    "service_type_additional_services": "Servizi aggiuntivi (modelli)",
    "service_type_product_sales": "Vendita di prodotti",
    "sales": "Distribuzione",
    "primary_owner": "Proprietario principale",
    "secondary_owner": "Proprietario secondario",
    "provision_start": "Inizio",
    "provision_ending": "Fine",
    "provision_actions": "Azioni",
    "add_provision_service": "Creare una commissione",
    "edit_provision_service": "Modifica commissione",
    "save_provision_type": "Risparmiare",
    "prov_service_client_name": "Nome",
    "provision_service_documents": "Commissioni",
    "commission_service_documents": "Commissioni - Documenti",
    "assign_service_type": "Assegnare un nuovo tipo di servizio",
    "provision_service_documents_description": "Potete vedere tutte le commissioni qui",
    "provision_service_documents_download": "Scarica i documenti",
    "provision_service_documents_download_start": "scaricare",
    "provision_service_documents_name": "Nome",
    "provision_service_documents_title": "Titolo",
    "provision_service_documents_description_text": "Descrizione del",
    "provision_service_documents_serviceType": "Tipo",
    "provision_service_documents_provisionRate": "Tasso di commissione",
    "provision_service_documents_provisionValue": "Valore della commissione",
    "provision_service_documents_provisionAssignee": "Ricevitore",
    "provision_service_documents_provisionPayedDate": "pagato su",
    "provision_service_documents_IsActive": "Stato",
    "provision_service_documents_Client": "Cliente",
    "provision_service_documents_choose_month": "Selezionare il mese desiderato",
    "commission_service_documents_description": "È possibile visualizzare e scaricare tutte le fatture e i dettagli delle commissioni qui",
    "commission_service_documents_download": "Scarica i documenti",
    "commission_service_documents_download_start": "scaricare",
    "commission_client": "Cliente",
    "commission_invoice_no": "Numero di fattura",
    "commission_payed_data": "pagato su",
    "commission_service_type": "Tipo di servizio",
    "commission_amount": "Importo",
    "commission_provision": "Commissione",
    "create_update_teams_assign_system_types": "Creare e modificare i team all'interno dell'azienda. Assegnare tipi di sistema ai team.",
    "create_document_types_use_for_process_automation": "Create i vostri tipi di documenti per poterli utilizzare nelle integrazioni o per l'automazione dei processi.",
    "write_email_templates_send_to_clients": "Creare modelli di e-mail da utilizzare per l'invio di messaggi ai clienti o per automatizzare alcuni flussi di lavoro.",
    "define_reusable_cms_elements": "Definire elementi riutilizzabili che possono essere utilizzati in qualsiasi pagina.",
    "manage_brands_edit_name_description": "Gestite i marchi dei prodotti, modificatene i nomi e le descrizioni in più lingue.",
    "manage_car_models_and_aliases": "Gestire i modelli di auto tra cui gli utenti possono scegliere. Definire gli alias per l'importazione.",
    "display_and_manage_category_tree": "Visualizzare e manipolare l'albero delle categorie. È possibile modificare nomi e descrizioni.",
    "display_and_manage_product_templates": "Creare e gestire modelli e consentire agli utenti di essere creativi.",
    "define_product_attributes_search_and_variants": "Definire attributi aggiuntivi da specificare nei prodotti. Utilizzate questi attributi per aiutare gli utenti a cercare i prodotti. Creare varianti di prodotto in base agli attributi.",
    "group_attributes_to_better_manage_and_inform": "È possibile raggruppare gli attributi per gestire meglio l'esperienza dell'utente nel backend e presentare gli attributi degli articoli ai clienti in modo più chiaro.",
    "entity_fields_missing_add_new": "Se nei vostri moduli mancano alcuni campi, potete aggiungerli qui. Utile per le campagne di marketing e le integrazioni.",
    "define_manage_pipelines_and_stages": "Definire e personalizzare le pipeline per i flussi di lavoro. Aggiungete, modificate e rimuovete le fasi.",
    "create_questionnaires_for_workflows_and_campaigns": "Creare questionari che possono essere utilizzati nei flussi di lavoro o in varie campagne di marketing.",
    "catch_leads_from_other_sources_with_custom_email": "Catturate i contatti da più fonti con il nostro Lead Catcher. Configurate indirizzi e-mail individuali per ogni fonte desiderata.",
    "group_car_models_into_families_for_users_to_easily_find": "Raggruppare i modelli di veicoli in famiglie per rendere ancora più facile la ricerca delle auto per gli utenti.",
    "enrich_car_search_functionality_with_own_model_images": "Arricchite la funzione di ricerca delle auto con le foto dei vostri modelli. Assegnare le foto ai modelli o ai gruppi di modelli.",
    "tag_leads_with_custom_regular_expressions": "Estendere la funzionalità del nostro sistema di tag con tag definiti dall'utente. È richiesta la conoscenza delle espressioni regolari.",
    "unknown_error": "Si è verificato un errore sconosciuto",
    "username_required": "Inserire il proprio nome utente",
    "email_required": "Inserisci il tuo indirizzo e-mail",
    "email_invalid": "L'e-mail non è valida",
    "client_name_required": "Inserisci il tuo nome",
    "password_required": "Inserire la password",
    "password_length": "La password deve essere composta da almeno 8 caratteri",
    "repeat_password_required": "Ripetere la password",
    "repeat_password_length": "La password ripetuta deve essere composta da almeno 8 caratteri.",
    "message_required": "Inserire un messaggio",
    "second_password_incorrect": "La seconda password non è uguale alla prima",
    "registration_not_confirmed": "La sua iscrizione non è ancora stata confermata. Si prega di controllare la casella di posta elettronica e di cliccare sul pulsante per confermare il proprio indirizzo e-mail.",
    "account_deleted": "Questo account è stato cancellato!",
    "account_inactive": "L'abbonamento a questo account è stato cancellato!",
    "company_required": "Inserire il nome della società",
    "first_name_required": "Inserire il nome",
    "last_name_required": "Inserire il cognome",
    "country_required": "Selezionare un paese",
    "phone_required": "Inserire il numero di telefono",
    "street_required": "Inserire il nome della via",
    "house_number_required": "Inserire il numero civico",
    "zip_code_required": "Inserire il codice postale",
    "zip_code_invalid": "Il codice postale '{{valore }}' non è un codice postale valido",
    "city_required": "Inserire il nome della città",
    "vat_id_required": "Inserire l'ID dell'imposta sulle vendite",
    "timezone_required": "Selezionare un fuso orario",
    "select_job": "Selezionare un lavoro",
    "title_required": "Inserire il titolo",
    "type_required": "Selezionare un tipo",
    "location_required": "Inserire la località",
    "questionnaire_required": "Selezionare un questionario",
    "subject_required": "Inserire l'oggetto",
    "amount_required": "Inserire l'importo",
    "time_count_required": "Inserire la quantità di tempo",
    "name_required": "Inserire il nome",
    "price_required": "Inserire il prezzo",
    "description_required": "Inserire la descrizione",
    "question_required": "Inserire la domanda",
    "content_required": "Inserire il contenuto",
    "template_required": "Selezionare un modello",
    "payment_schedule_required": "Selezionare un piano di pagamento",
    "answer_required": "Inserire la risposta",
    "website_required": "Inserire l'indirizzo del sito web",
    "fax_required": "Inserire il numero di fax",
    "currency_required": "Selezionare una valuta",
    "vat_name_required": "Inserire il nome dell'IVA",
    "vat_id_name_required": "Inserire il nome dell'ID IVA",
    "vat_rates_required": "Inserire le aliquote IVA",
    "coc_number_required": "Inserire il numero di COC",
    "registered_at_required": "Inserire i dati con cui è registrata la vostra azienda",
    "bank_name_required": "Inserire il nome della banca",
    "account_holder_required": "Inserire il nome completo del titolare del conto",
    "account_number_required": "Inserire il numero di conto",
    "bank_location_required": "Inserire la sede della banca",
    "bank_code_number_required": "Inserire il codice di selezione della banca",
    "iban_required": "Inserire l'IBAN",
    "min_message": "Questo valore è troppo corto. Dovrebbe avere {{ limite }} caratteri o più",
    "max_message": "Questo valore è troppo lungo. Dovrebbe avere {{ limite }} caratteri o meno",
    "min_max_length": "Questo valore deve essere compreso tra {{ min }} e {{ max }} caratteri",
    "greater_than_or_equal": "Questo valore deve essere maggiore o uguale a {{ compared_value }}.",
    "price_type_invalid": "Il tipo di prezzo non è valido",
    "This value should not be blank": "Questo valore non deve essere vuoto",
    "required_message": "Questo valore non deve essere vuoto",
    "This value is not a valid URL": "Questo valore non è un URL valido",
    "must_upload_a_file": "È necessario caricare un file",
    "file_format_not_supported": "Il file che si sta cercando di caricare non è supportato",
    "category_not_empty": "La categoria non è vuota",
    "brand_has_items": "Il marchio ha assegnato prodotti",
    "template_has_items": "I prodotti sono assegnati al modello",
    "item_has_variants": "Il prodotto ha assegnato delle varianti",
    "no_valid_variant": "Nessuna variante valida",
    "no_stage_set": "Non è stato impostato alcun livello",
    "no_pipeline_set": "Non è stata impostata alcuna pipeline",
    "no_users_found": "Nessun utente trovato",
    "no_team_set": "Nessuna squadra impostata",
    "budget_required": "Definire il budget",
    "client_required": "Assegnare un cliente",
    "order_required": "Assegnare un ordine",
    "item_required": "Assegnare un prodotto",
    "document_required": "Assicurarsi che i documenti siano disponibili e validi",
    "brand_required": "Selezionare una marca",
    "model_required": "Selezionare un modello",
    "order_type_invalid": "Tipo di ordine non valido",
    "item_already_reserved": "Alcuni prodotti non sono disponibili in giorni selezionati",
    "wrong_credentials": "Il nome utente o la password non sono stati inseriti correttamente. Riprovare o cliccare su \"Password dimenticata\".",
    "no_car_found": "Nessun veicolo trovato per questa pista",
    "data_push_event_invalid": "L'evento Data Push non è valido",
    "data_push_auth_invalid": "Il tipo di autenticazione Data Push non è valido",
    "cart_type_invalid": "Il tipo di carrello non è valido",
    "system_type_invalid": "Il tipo di sistema non è valido",
    "menu_version_invalid": "La versione del menu non è valida",
    "logo_version_invalid": "La versione del logo non è valida",
    "payment_method_invalid": "Non è stato possibile elaborare il metodo di pagamento specificato",
    "nationality_required": "Inserisci la tua nazionalità",
    "place_of_birth_required": "Inserire il luogo di nascita",
    "date_of_birth_required": "Inserire la data di nascita",
    "id_required": "Inserire l'ID",
    "meta_identifier_required": "Selezionare un identificatore",
    "payment_status_invalid": "Lo stato del pagamento non è valido",
    "iban_invalid": "Il valore inserito non è un numero di conto valido.",
    "swift_bic_required": "Inserire un numero SWIFT/BIC valido",
    "reference_required": "Inserire un riferimento",
    "service_status_invalid": "Lo stato del servizio non è valido",
    "date_proposal_type_invalid": "Il tipo di data suggerito non è valido",
    "mobile_phone_invalid": "Il numero di cellulare non è valido",
    "mobile_phone_required": "Inserisci il tuo numero di cellulare",
    "phone_invalid": "Il numero di telefono non è valido",
    "fax_invalid": "Il numero di fax non è valido",
    "salutation_required": "Selezionare un saluto",
    "gender_required": "Selezionare un sesso",
    "stripe_error": "Errore durante la connessione a Stripe",
    "stripe_connect_error_with_type": "Non è stato possibile collegare CreaCheck al vostro conto Stripe. Codice di errore: {tipo}.",
    "car_rental_pickup_time_invalid": "L'orario di ritiro desiderato è al di fuori dell'orario di apertura del locale",
    "car_rental_drop_off_time_invalid": "L'orario di consegna richiesto per un'autovettura è al di fuori dell'orario di apertura della sede.",
    "accept_terms_and_cancellation_before_continuing": "Confermate di aver letto i nostri Termini e Condizioni Generali e la politica di cancellazione.",
    "problem_processing_your_credit_card": "Abbiamo un problema nell'elaborazione della carta di credito",
    "invoice_type_invalid": "Il tipo di fattura non è valido",
    "doc_number_required": "Inserire il numero del documento",
    "doc_issuing_city_required": "Indicare la città di emissione",
    "doc_issuing_authority_required": "Indicare l'autorità emittente",
    "doc_issue_date_required": "Inserire la data di emissione",
    "doc_expiration_date_required": "Inserire la data di scadenza",
    "test_domain_required": "Inserire un nome di dominio di prova",
    "iban_has_wrong_format": "Il formato IBAN non è corretto",
    "car_cash_payment_data_missing_or_invalid": "I dati di pagamento in contanti sono mancanti o non validi",
    "car_registration_service_only_available_in_germany": "Il servizio di autorizzazione è attualmente disponibile solo in Germania",
    "car_transport_only_available_in_germany": "Il nostro servizio di trasporto è attualmente disponibile solo in Germania",
    "target_type_invalid": "Tipo di target non valido",
    "company_email_must_be_different_from_users_email": "L'indirizzo e-mail dell'azienda deve essere diverso dal vostro indirizzo e-mail.",
    "car_transport_distance_too_short": "Il trasporto in auto è possibile solo per località distanti più di 100 km.",
    "first_offer_price_too_low": "La vostra prima offerta è troppo bassa e non è stata accettata",
    "first_offer_price_too_high": "La prima offerta è superiore al prezzo originale e non è stata accettata.",
    "current_offer_too_low": "L'offerta attuale è troppo bassa",
    "not_your_turn_to_negotiate_the_price": "Non è possibile aggiornare l'offerta in questo momento. Attendete una risposta dal nostro collaboratore",
    "the_question_must_be_answered": "È necessario rispondere alla domanda \"{{ domanda }}\".",
    "validation_problems_with_item_attributes": "Controllare se gli attributi sono stati compilati correttamente. I problemi riscontrati sono contrassegnati in rosso",
    "the_field_name_contains_following_errors": "Il campo {fieldName} contiene il seguente errore: {helpText}",
    "plugin_required": "Selezionare un piano di abbonamento.",
    "invalidRegex": "L'espressione regolare specificata non è valida",
    "assignment_strategy_type_invalid": "Il tipo di strategia di assegnazione non è valido",
    "cannot_remove_role_company": "Non è possibile rimuovere il ruolo utente RUOLO_COMPAGNIA",
    "coupon_type_invalid": "Selezionare un tipo di buono valido",
    "subscription_starting_fee": "Quota di iscrizione all'abbonamento",
    "comms_center_monthly_fee": "Centro comunicazioni Creacheck",
    "comms_center_chat": "Centro di comunicazione Creacheck - Chat con i clienti",
    "comms_center_call": "Centro di comunicazione Creacheck - Chiamata al cliente",
    "0": "<strong>Fehler beim Einloggen</strong>",
    "You are not authenticated": "Purtroppo non conosciamo l'email e la password",
    "access_denied": "Per effettuare il login, è necessario accettare l'accesso a Creacheck.com",
    "oauth_email_missing": "Non è possibile effettuare il login attraverso i social media. Si prega di accedere con il proprio indirizzo e-mail.",
    "User already exists": "L'indirizzo e-mail inserito esiste già nel nostro database. Avete dimenticato la password?",
    "next": "Ulteriori",
    "create_comment": "Scrivi un commento",
    "new_pipeline": "Nuova conduttura",
    "new_stage": "Nuovo livello",
    "quantity": "Quantità",
    "login_error": "Errore durante l'accesso",
    "not_found": "Non trovato",
    "deal_information": "Informazioni sul contratto",
    "login_domain": "Dominio di accesso",
    "login_domain_successfully_changed": "Il dominio di accesso è stato modificato con successo.",
    "missing_lead_information": "Inserire le informazioni mancanti sulla pista",
    "missing_informations": "Informazioni mancanti",
    "missing_client_signature": "Manca la firma del cliente",
    "previous": "In precedenza",
    "current": "Attuale",
    "customCssInvalid": "Il CSS personalizzato non è valido ({{ errore }})",
    "accountAndLogin": "Account e login",
    "invalidEIN": "Numero di eVB (conferma elettronica dell'assicurazione) non valido",
    "radio": "Radio",
    "airConditioning": "Aria condizionata",
    "parkingAssistanceRear": "Aiuto al parcheggio posteriore",
    "airConditioningAutomatic": "Climatizzatore automatico",
    "navigation": "Navigatore satellitare",
    "alloyRims": "Cerchi in alluminio",
    "parkingAssistanceFrontRear": "Aiuto al parcheggio anteriore e posteriore",
    "metallicPaint": "Vernice metallizzata",
    "cruiseControl": "Controllo della velocità di crociera",
    "seatHeating": "Riscaldamento dei sedili",
    "leatherSeats": "Sedili in pelle",
    "equipmentLevel": "Livello dell'attrezzatura",
    "motorPower": "Prestazioni",
    "currentLocation": "Posizione attuale",
    "distance": "Distanza",
    "selected": "selezionato",
    "noBrandSelected": "Nessun marchio selezionato",
    "showMore": "Mostra di più",
    "showLess": "Mostra meno",
    "optional": "Opzionale",
    "altogether": "In totale",
    "monday": "Lunedì",
    "tuesday": "Martedì",
    "wednesday": "Mercoledì",
    "thursday": "Giovedì",
    "friday": "Venerdì",
    "saturday": "Sabato",
    "sunday": "Domenica",
    "payment_data_missing_or_invalid": "I dati di pagamento sono mancanti o non validi",
    "previousSearchRequests": "Ricerche precedenti",
    "reset": "Reset",
    "number_days": "{Giorni, plurale, zero {# giorni} uno {# giorni} altri {# giorni}}",
    "number_months": "{mesi, plurale, zero {#mesi} uno {#mese} altri {#mesi}}.",
    "outdated_browser": "Il tuo browser è obsoleto!",
    "update_browser_text": "Si prega di aggiornare il browser per visualizzare correttamente questo sito web.",
    "update_browser_btn_text": "Aggiornare subito il browser",
    "save_successfully": "Salvato con successo",
    "add_quickly": "Aggiungere rapidamente",
    "load_data": "Dati di carico",
    "car_data_import_vin_info": "Inserire il VIN valido per completare automaticamente il modulo dei dati del veicolo.",
    "car_data_import_missing_attr_values": "Alcuni valori degli attributi sono mancanti - sono contrassegnati nel modulo.",
    "vin_invalid": "FIN non è valido",
    "others": "Altro",
    "activate_plugin_to_enable_feature": "Questa funzione è disponibile solo con il seguente plugin. Attivare il plugin per continuare a utilizzare questa funzionalità.",
    "car_data_import_failed": "Non è stato possibile importare i dati del veicolo per questo VIN. Controllare se il VIN specificato è corretto.",
    "send_mail_via_outlook": "L'e-mail viene inviata con l'account Microsoft Outlook ({email}) ed è successivamente disponibile nella cartella \"Inviati\".",
    "permission_required": "Autorizzazioni richieste",
    "phone_call_permissions_required": "Questa applicazione deve accedere ai vostri account telefonici per poter effettuare chiamate.",
    "calling": "Chiamatemi",
    "callingTo": "Chiamata a",
    "startingCall": "Avviare una chiamata",
    "callEnded": "Chiamata terminata",
    "inCall": "'In chiamata",
    "callCancelled": "Chiamata annullata",
    "callRejected": "Chiamata rifiutata",
    "is_calling": "%name% chiama...",
    "contacts": "Contatti",
    "addresses": "Rubrica",
    "chat": "chat",
    "calls": "Chiamate",
    "endCustomerNewMessage": "Messaggio di un nuovo cliente",
    "returnToTheActiveCall": "Tornare alla chiamata attiva",
    "agentStatus": "Stato dell'agente",
    "switchOnAgentNote": "Attivare l'accettazione di chat e videochiamate dei clienti",
    "switchOffAgentNote": "Disattivare per non accettare le chat e le videochiamate dei clienti",
    "activateAgent": "Attivare l'accettazione delle chat dei clienti",
    "deactivateAgent": "Disattivare l'accettazione delle chat dei clienti",
    "startAudioCallWith": "Iniziare la telefonata con {{to}}",
    "startVideoCallWith": "Avviare la videochiamata con {{to}}",
    "terms_and_conditions": "Termini e condizioni",
    "form_incorrect_data": "I dati inseriti non sono corretti. Si prega di controllare i dati inseriti e di inviarli nuovamente.",
    "accept_terms_and_condition_start_chat": "Se si clicca su \"Avvia la chat\", si accettano i termini e le condizioni.",
    "start_chat": "Avviare la chat",
    "connecting_with": "Collegati con",
    "connected_with": "Collegato con",
    "do_calling": "chiamate",
    "chat_inactivity_warning": "La chat è rimasta inattiva per troppo tempo e sarà presto chiusa.",
    "get_in_contact_with_us": "Mettetevi in contatto con noi",
    "no_agent_available": "Purtroppo nessuno degli agenti è online. Lasciate un messaggio e vi contatteremo al più presto.",
    "hello": "Ciao!",
    "we_are_here_for_you": "Siamo qui per voi e saremo lieti di ricevere le vostre domande o i vostri commenti.",
    "start_conversation": "Iniziare la conversazione",
    "welcome_customer": "Mi chiamo {nome} {cognome}. Come posso aiutarla?",
    "end_chat": "Uscita",
    "send_request": "Invia una richiesta",
    "checkout_payment_agreement": "Acconsento a che {azienda} inizi la fornitura dei servizi aggiuntivi prima dell'inizio del periodo di cancellazione e comprendo che perderò il mio diritto di cancellazione una volta che tali servizi aggiuntivi saranno stati completamente forniti.",
    "checkout_buy_order_legal_text": "All'acquisto si applicano i {termini_generali_e_condizioni} e la {politica_di_annullamento_e_formulario_di_annullamento} di {azienda} ({politica_privacy}).",
    "checkout_services_terms_cancelation_legal_text": "Per i servizi aggiuntivi prenotati ({servizi prenotati}), si applicano i {termini_generali_e_condizioni} e la {politica_di_annullamento_e_formulario_di_annullamento} di {azienda} ({politica_privacy}).",
    "no_credit_cards": "Nessuna carta di credito",
    "minimize_video": "Riduci al minimo la visualizzazione del video",
    "maximize_video": "Massimizzare la visualizzazione dei video",
    "enable_fullscreen": "Attivare la modalità a schermo intero",
    "disable_fullscreen": "Disattivare la modalità a schermo intero",
    "enable_screensharing": "Condividi il desktop",
    "disable_screensharing": "Terminare la condivisione del desktop",
    "mute_microphone": "Microfono muto",
    "unmute_microphone": "Disattivare il microfono",
    "enable_camera": "Accendere la fotocamera",
    "disable_camera": "Spegnere la fotocamera",
    "end_call": "Terminare la chiamata",
    "end_videocall": "Terminare il flusso video",
    "muted": "Silenzioso",
    "overall_feedback": "Come giudica il suo contatto con noi?",
    "connection_quality_feedback": "Come giudica la qualità della connessione?",
    "send_feedback": "Invia un feedback",
    "customer_chat_ended_info": "La chat è stata chiusa",
    "checking_connection": "La connessione è controllata",
    "call_again": "Richiamare",
    "end_call_local_disconnected": "Interruzione della chiamata per problemi di connessione",
    "end_call_remote_disconnected": "Interruzione della chiamata per problemi di connessione alla pagina {remoteUserName}",
    "network_disconnected_warning": "Attualmente non c'è connessione a Internet.",
    "network_disconnected": "Connessione interrotta",
    "network_want_reconnect": "La connessione è stata interrotta. Desidera riconnettersi?",
    "leave_as_an_email_message": "Lasciateci un messaggio e-mail",
    "continue_call": "Continua a chiamare",
    "continue_text_chat": "Continuare la chat di testo",
    "continue_conversation": "Continua la conversazione",
    "disconnected": "Separato",
    "disconnect_reason": "Possibile motivo",
    "disconnect_reason_transport_close": "Lasciare questa pagina",
    "disconnect_reason_ping_timeout": "Nessuna connessione a Internet",
    "customer_may_return": "Il cliente può tornare presto.",
    "mobile": "Telefono cellulare",
    "headquarter": "Centro",
    "extension-number": "Numero di estensione",
    "add_contact": "Aggiungi contatto",
    "add_address": "Aggiungi indirizzo",
    "choose_contact": "Selezionare il contatto",
    "number": "Numero",
    "new_number": "Nuovo numero",
    "address_mail": "Indirizzo postale",
    "address_delivery": "Indirizzo di consegna",
    "address_invoice": "Indirizzo della fattura",
    "new_address": "Nuovo indirizzo",
    "find_address": "Trova l'indirizzo",
    "searching": "Ricerca...",
    "address_change": "Cambiamento di indirizzo",
    "address_ask_change": "La modifica di questo indirizzo avrà effetto su tutti i contatti con questo indirizzo. Volete cambiare l'indirizzo?",
    "designation": "Designazione",
    "net": "Netto",
    "gross": "Lordo",
    "exp_delivery_time": "tempi di consegna previsti",
    "workdays": "Giorni lavorativi",
    "special_offer": "Azione",
    "transportation_cost": "Costi di spedizione",
    "discount": "Sconto",
    "total": "Totale",
    "total_gross": "Totale (IVA inclusa)",
    "product_details": "Dettagli del prodotto",
    "type_of_order": "Tipo di ordine",
    "number_of_copies": "Edizione",
    "type_of_order_choices": "Produzione regolare di stampe o download dei dati di stampa come file PDF",
    "type_of_order_website": "Fornitura di questo sito web",
    "print_production": "Produzione di stampa",
    "pdf_download": "PDF-Scaricare",
    "website_upload": "Caricare il sito web",
    "production_time": "Tempo di produzione",
    "production_time_description": "Selezionare l'opzione desiderata",
    "production_standard": "Produzione standard",
    "production_express": "Produzione espressa",
    "production_overnight": "Produzione notturna",
    "choose_edition": "Edizione selezionata",
    "choose_edition_description": "Edizioni con prezzi graduati. Gli sconti e le promozioni sono evidenziati se disponibili.",
    "more_options": "Altre opzioni",
    "more_options_description": "Aggiungete altre opzioni al vostro ordine.",
    "manual_data_review": "Controllo manuale dei dati (assicurazione inclusa)",
    "manual_item_entry": "Inserimento manuale della posizione",
    "web_enabled_pdf": "I dati di stampa sono disponibili anche in formato PDF abilitato al web.",
    "cart_production_type_change_warning_title": "La circolazione è regolata",
    "cart_production_type_change_warning": "La tiratura selezionata non è disponibile per il {{tipo di spedizione}}. Verrà modificata nella tiratura massima disponibile. È sicuro di voler continuare?",
    "cart_no_warranty_without_manual_data_check": "Nota: il controllo manuale dei dati non è selezionato.",
    "campaign_budget_exceed_warning": "Il budget della campagna è stato superato. È necessaria l'approvazione!",
    "user_budget_exceed_warning": "Budget utente superato. È necessario il rilascio!",
    "order_quantity": "Quantità dell'ordine",
    "data_check": "Controllo dei dati",
    "split_shipping": "Consegna parziale",
    "cart_not_logged_in": "Nessun utente connesso",
    "cart_contact_or_address_not_found_invoice": "Selezionare un indirizzo di fatturazione",
    "cart_contact_or_address_not_found_shipping": "Selezionare un indirizzo di consegna",
    "cart_edtion_item_or_variant_not_found": "Numero, articolo o variante non trovati",
    "cart_edtion_item_not_available": "L'articolo %item_name% non è più disponibile",
    "cart_no_print_type_found": "Nessun tipo di stampa trovato",
    "cart_print_type_invalid": "Tipo di stampa non valido:",
    "cart_no_shipping_type_found": "Nessun metodo di spedizione trovato",
    "cart_checkout_info_missing": "Mancano le informazioni sulla casella di controllo:",
    "cart_reduced_vat_rate_check_not_supported": "Richiesta di verifica dell'aliquota IVA ridotta per gli articoli che non la supportano:",
    "cart_no_price_found": "Il prezzo non è stato trovato",
    "cart_price_mismatch": "Il prezzo non corrisponde a quello che abbiamo presentato all'utente",
    "cart_web_pdf_price_mismatch": "Il prezzo del PDF web non corrisponde al prezzo che abbiamo presentato all'utente",
    "cart_contact_or_address_not_found_split_shipping": "Selezionare un indirizzo per la spedizione frazionata",
    "cart_quantity_not_found_split_shipping": "Parametri di quantità non trovati per la spedizione frazionata",
    "cart_split_shipping_quantity_mismatch": "La quantità di spedizione suddivisa non corrisponde alla quantità di produzione",
    "cart_coupons_mismatch": "I coupon utilizzati non corrispondono",
    "cart_coupon_cant_be_used": "Il coupon richiesto è scaduto o non può essere utilizzato",
    "cart_coupon_value_mismatch": "Il valore del coupon non è corretto",
    "cart_camaign_budget_processing_error": "Si è verificato un problema imprevisto durante l'elaborazione del budget della campagna.",
    "cart_campaign_budget_has_changed": "Il budget della campagna è cambiato",
    "cart_user_budget_has_changed": "Il budget dell'utente è cambiato",
    "cart_user_budget_processing_error": "Si è verificato un problema inatteso durante l'elaborazione del budget utente.",
    "cart_domain_not_active": "Il dominio non è attivo",
    "cart_missing_desired_domain": "Manca l'indicazione del dominio desiderato",
    "lead_monitor_order_payment_receipt": "Monitorare i pagamenti in entrata",
    "lead_format_message_description_route53_register_domain": "Registrare il dominio {0} e reindirizzarlo a {1}.",
    "lead_title_route53_register_domain": "Registrazione del dominio AWS",
    "domain_not_available": "Dominio non disponibile",
    "invoice_payment_option": {
      "enabled": "Attivato",
      "disabled": "Disattivato",
      "inherited": "Ereditato",
      "title": "Pagamento della fattura",
      "loading": "Carica l'impostazione ereditata",
      "effective_setting": "Impostazione efficace"
    },
    "invoice_payment_threshold": {
      "title": "Valore di soglia",
      "placeholder": "nell'esempio, 100",
      "no_effect": "Nessun effetto",
      "loading": "Caricamento delle impostazioni ereditate",
      "info_text": "Valore minimo dell'ordine per il pagamento tramite fattura",
      "effective_threshold": "Soglia effettiva",
      "not_defined": "non definito"
    },
    "remind_period": {
      "days": "Giorni",
      "inherited_setting": "Atteggiamento ereditato",
      "title": "Data di scadenza della fattura Periodo di sollecito"
    },
    "sellDownloadPdf": "Scarica il PDF",
    "success_management": {
      "title": "Gestione del successo",
      "manager": "Manager di successo",
      "switch_title": "Attivare le memorie visualizzate di recente",
      "interval": "Ultimo periodo visualizzato, giorni",
      "last_seen": "Ultimo visto",
      "error_no_self": "Impossibile assegnare se stessi come Success Manager",
      "error_no_same_parent": "Il Success Manager deve essere subordinato a un'altra azienda"
    },
    "copy": "Copia",
    "is_client_registration_allowed": "Attivare la registrazione",
    "is_email_validation_enabled": "Convalida dell'e-mail",
    "email_validation_pattern": "Regex per la convalida delle e-mail",
    "confirmation_email_cc": "CC per la conferma della registrazione",
    "client_registration_title": "Impostazioni di registrazione",
    "registration_fields_show": "Campi di visualizzazione",
    "registration_fields_require": "Campi obbligatori",
    "accept_terms_of_use_before_continuing": "Prima di continuare, è necessario accettare le nostre condizioni di utilizzo.",
    "subscription_settings": "Abbonamenti",
    "client_subscription_settings": "Abbonamenti a clienti e prodotti",
    "subscription_settings_description": "Qui è possibile creare un abbonamento di base e assegnare un tipo",
    "client_subscription_settings_description": "Qui è possibile assegnare l'abbonamento base a un cliente o a un articolo, a seconda del tipo, e regolare i prezzi.",
    "subscriptions_": "Abbonamenti",
    "subscription_name": "Nome",
    "subscription_title": "Titolo",
    "subscription_description": "Descrizione del",
    "subscription_fee": "Tassa",
    "subscription_startingFee": "Quota di iscrizione (facoltativa)",
    "subscription_start": "Inizio",
    "subscription_end": "Fine",
    "subscription_status": "Stato",
    "subscription_action": "Azioni",
    "subscription_items": "Prodotti",
    "subscription_clients": "Clienti",
    "subscription_companies": "Aziende",
    "subscription_companies_detailed_role": "Azienda Utente/Ruolo",
    "subscription_basic": "Abbonamento di base",
    "add_subscription": "Aggiungi abbonamento",
    "create_subscription": "Crea abbonamento",
    "edit_subscription": "Risparmiate l'abbonamento",
    "subscription_language": "Selezione della lingua",
    "period_status": "Disattivare alla fine del periodo",
    "subscription_edit": "Modifica",
    "omit_subscription_fee": "Omettere la tassa di iscrizione",
    "invoice_email": "Indirizzo e-mail per le fatture",
    "order_processing_contract": "Contratto di elaborazione degli ordini",
    "accept_order_processing_contract_before_continuing": "Prima di continuare, è necessario accettare il nostro contratto di elaborazione degli ordini.",
    "no_image_title": "Nessun titolo di immagine trovato. Fare clic qui per modificare.",
    "no_image_description": "Nessuna descrizione dell'immagine trovata. Fare clic qui per modificare",
    "no_image_copyright": "Non sono state trovate informazioni sul copyright. Fare clic qui per modificare",
    "no_text": "Nessun testo trovato. Fare clic qui per modificare",
    "subscription_clients_and_companies": "Clienti e aziende",
    "save_subscription": "Risparmiare",
    "go_to": "Vai a",
    "no_invoices_to_display": "Nessuna fattura da visualizzare",
    "url_settings": "Impostazioni dell'URL",
    "update_notification": "Gestione degli aggiornamenti",
    "update_notification_description": "Descrizione del",
    "update_notification_settings_description": "È possibile gestire le notifiche per gli aggiornamenti qui",
    "update_name": "Nome",
    "update_notification_meta": {
      "title": "Gestione degli aggiornamenti",
      "description": "Gestisci le notifiche di aggiornamento qui",
      "name": "Gestione degli aggiornamenti"
    },
    "new_update_notification": "Creare una nuova notifica di aggiornamento",
    "update_from": "da",
    "update_to": "fino a quando",
    "edit_update_notification": "Notifica di aggiornamento delle modifiche",
    "individual_prices": "Prezzi personalizzati",
    "template_pdf_profile_x3": "Creare PDF in formato X-3",
    "countries_for_prices": {
      "de": "Germania",
      "ch": "Svizzera",
      "at": "Austria",
      "es": "Spagna"
    },
    "choose_country": "Selezionare un paese",
    "product_groups": "Gruppi di prodotti - Fornitori",
    "product_groups_description": "Qui è possibile gestire i gruppi di prodotti dei fornitori",
    "new_product_group": "Aggiungere un nuovo gruppo di prodotti",
    "edit_product_group": "Modifica",
    "create_product_group": "Creare",
    "product_group_number": "Numero del gruppo di prodotti",
    "save_product_group": "Risparmiare",
    "product_group_actions": "Azioni",
    "product_group_status": "Stato",
    "hide_preview": "Disattivare l'anteprima nell'editor",
    "editor_settings": "Editor delle impostazioni",
    "share_edition": "Condividi l'edizione",
    "canceled_quote": "Offerta rifiutata",
    "active_quote": "Offerta in corso",
    "manual_quote_status": "Stato delle offerte",
    "invalid_manual_quote_status": "Stato dell'offerta non valido",
    "internal_status": "Stato interno",
    "edit_quote": "Modifica offerta",
    "copy_quote": "Copia offerta",
    "open_lead": "Piombo aperto",
    "upload_pdf": "Caricare e collegare il PDF",
    "upload_pdf_title": "Caricamenti in PDF",
    "upload_pdf_status_done": "Il file PDF è stato caricato con successo",
    "upload_pdf_status_error": "Caricamento PDF non riuscito.",
    "placeholder_upload_error": "Si è verificato un problema durante il caricamento!",
    "linkedin_insight_tag": "Tag Approfondimento LinkedIn",
    "linkedin_partner_id": "Id del partner",
    "tracking_pixel": "Pixel di tracciamento, ad esempio Meta Pixel",
    "tracking_pixel_data": "Codice di implementazione",
    "jsWebsite": "Javascript",
    "upload_website_favicon": "Favicon",
    "upload_website_favicon_label": "Caricamento e anteprima delle immagini",
    "upload_website_favicon_info": "Attenzione! Il formato di file richiesto è PNG. A seconda del dispositivo utilizzato, la risoluzione ottimale è compresa tra 32 x 32px e un massimo di 64 x 64px. È possibile utilizzare anche altri formati, purché siano quadrati e non superino la risoluzione massima.",
    "upload_favicon_error": "L'immagine non ha la risoluzione richiesta",
    "go_back_to_campaign": "Torna alla campagna",
    "no_orders_to_display": "Keine Bestellungen zum Anzeigen",
    "no_editions_to_display": "Keine Editionen zum Anzeigen",
    "google_tag_manager": "Google Tag Manager",
    "google_tag_manager_id": "ID GTM",
    "website_analyse": "Analisi del sito web",
    "legal_information": "Informazioni legali",
    "contact_info": "Informazioni di contatto",
    "import_users_via_csv": "Creare utenti importando un file CSV",
    "remove_users_via_csv": "Rimuovere gli utenti importando un file CSV",
    "import_users": "Importazione di utenti",
    "remove_users": "Rimuovere l'utente",
    "fill_data_policy_before_autosave": "Salvataggio automatico non riuscito. Completare le informazioni sulla protezione dei dati e sulle note legali. Queste si trovano nelle impostazioni",
    "invoice_customer_info": "ID - Azienda / Nome",
    "invoice_search_debtor": "Numero del debitore",
    "invoice_search_vat": "IVA",
    "invoice_search_amount": "Importo",
    "search_field_name": "Selezionare un campo di ricerca",
    "search_field_info_text": "Se si desidera cercare un valore numerico nel campo di ricerca, è necessario selezionare un campo",
    "your_export": "La vostra esportazione",
    "export_download_info": "Il file è stato creato e può essere utilizzato.",
    "export_download": "scaricare",
    "export_file": "File",
    "export_share": "Condividi",
    "image_permission_title": "Attenzione!",
    "image_permission_warning": "Diritti d'immagine disponibili/consenso ottenuto da tutte le persone?",
    "other_settings": "Altre impostazioni",
    "image_rights_query": "Richiesta di diritti d'immagine",
    "direct_ordering_label": "Ordine diretto",
    "direct_ordering_info_text": "In caso di ordine diretto, l'edizione creata non può più essere modificata. Viene inserita direttamente nel carrello.",
    "subscription_automation": {
      "title": "Obiettivo di fatturazione per gli abbonamenti dei nuovi utenti",
      "payer": "Pagatore",
      "fee_for_role_user": "Tassa per ROLE_USER",
      "fee_for_role_admin": "Tariffa per ROLE_ADMIN",
      "fee_for_role_wl_admin": "Tariffa per ROLE_WL_ADMIN",
      "hint_title": "Nota",
      "hint_body": "Si prega di creare e attivare un abbonamento aziendale per questa azienda per coprire tutti gli utenti creati successivamente.",
      "debtor_ancestor": "Ogni abbonamento utente viene fatturato al %billingTargetName%.",
      "debtor_ancestor_missing": "Nessuna società precedente è indicata come debitrice. Si prega di correggere questo dato."
    },
    "need_content_authorization": "È richiesta l'autorizzazione per i contenuti",
    "publisher_info_text": "L'editore viene inserito automaticamente in ogni mezzo pubblicitario necessario, in conformità alle direttive legali.",
    "mark_campaign": "Campagna Mark",
    "template_exports": {
      "download_complete": "Scaricamento completato",
      "export": "Esportazione",
      "download_files": "Scarica i file",
      "synchronize": "Sincronizzare"
    },
    "info_import_and_export": {
      "note": "Informazioni generali sull'importazione e l'esportazione",
      "import": "Importa: Importa tutte le informazioni sulle impostazioni del modello (CSS, blocchi di contenuto, altre impostazioni) e i file. Crea un file zip iniziale per l'esportazione. I file zip esistenti vengono sovrascritti.",
      "upload": "Carica: Aggiunge altri file alla directory del modello S3. Questi file vengono aggiunti al file zip per l'esportazione.",
      "sync": "Sincronizza: sincronizza le impostazioni del modello (CSS, blocchi di contenuto, altre impostazioni) con i file (index.html, page.css, pdf.css, printpdf.css, website.css, website.js) nella directory dei modelli S3 e nel file zip (esportazione).",
      "export": "Esportazione: crea la struttura di un index.html da copiare e incollare.",
      "download": "Scarica i file: Scarica il file zip esistente. (Eseguire una sincronizzazione prima del download per ottenere i dati più recenti).",
      "copy": "Copia modello: la funzione \"Copia modello\" copia solo le impostazioni del modello, ma non le directory e i file."
    },
    "template_upload_title": "Caricare i file nella directory dei modelli",
    "pls_choose_text": "Selezionare un testo",
    "discounts": {
      "discount_type_invalid": "Il tipo di sconto non è valido",
      "discounts_tab_title": "Sconti",
      "intro_text": "Inserire il numero del gruppo di prodotti FlyerAlarm e il tipo e il valore di sconto corrispondente. Questi saranno applicati a tutti gli utenti dell'organizzazione corrente durante il checkout.",
      "none_yet_add_some": "Non sono ancora stati definiti sconti. Si prega di aggiungerne alcuni.",
      "number_must_be_unique": "Il numero deve essere unico",
      "must_be_0_to_100": "Deve essere compreso tra 0 e 100"
    },
    "link_to_uploaded_pdf": "Url del file PDF caricato",
    "upload_qrcode_logo": "Caricare il logo per il codice QR",
    "choose_file": "Selezionare il file",
    "upload_successful": "Caricamento riuscito",
    "upload_failed": "Caricamento fallito",
    "qr_logo": "Logo",
    "qr_example": "Esempio",
    "qr_settings": "Impostazioni del codice QR",
    "qr_margin": "Distanza",
    "qr_upload": "Caricare il logo QRCode",
    "google_search_console": "Google Search Console",
    "gsc_html_tag": "Verifica HTML-TAG",
    "content_id": "Contenuto-Id",
    "gsc_html_tag_example": "Utilizzare la verifica dei tag HTML. Copiare l'ID, ad esempio Xu9c238409d8JDSF8... dall'attributo content del meta tag ricevuto da Google per la verifica e incollare l'ID qui. Esempio: <meta name='google-site-verification' content='Xu9c238409d8JDSF8...' />",
    "order_complete": {
      "good_luck": "In bocca al lupo per la campagna!",
      "order_complete": "L'ordine è stato completato con successo",
      "pls_check_inbox": "Si prega di controllare la posta in arrivo.",
      "order_info": "Tutti i dettagli dell'ordine sono stati inviati all'indirizzo e-mail in nostro possesso. È possibile visualizzare la cronologia degli ordini in qualsiasi momento nel proprio account utente all'indirizzo",
      "orders": "Ordini",
      "view": "vista.",
      "start_next_campaign": "Avviare subito un'altra campagna"
    },
    "no_product_selected": "Nessun prodotto selezionato",
    "mail_messages": {
      "header": {
        "header_title": "Il portale di design online intelligente",
        "support": "Aiuto e FAQ",
        "login": "ACCEDI"
      },
      "footer": {
        "email_info": "State ricevendo questa e-mail perché vi siete registrati presso la %azienda%.",
        "email_info_first_name_form": "State ricevendo questa e-mail perché vi siete registrati presso la %azienda%.",
        "dont_reply": "Questa e-mail è stata inviata da un indirizzo che non può ricevere e-mail in entrata. </Si prega di non rispondere a questo messaggio. Se avete domande o dubbi, contattateci a",
        "agb": "GTC",
        "privacy_policy": "Protezione dei dati",
        "imprint": "Impronta"
      }
    },
    "content_check": "Recensione dei contenuti",
    "terms_of_payment": "Condizioni di pagamento",
    "terms_of_payment_edit": "Modifica dei termini di pagamento",
    "cancel_impersonation": "Fine dell'inversione dei ruoli",
    "impersonate": "Accettare il ruolo",
    "template_deleted_warning": "Attenzione! Il modello dell'edizione non è più disponibile.",
    "click_to_enable_editing": "Fare clic qui per attivare la modifica della scheda corrente.",
    "click_to_send_a_request_to_enable_editing": "Fare clic qui per inviare una richiesta di attivazione dell'elaborazione",
    "waiting_for_form_release": "Attendere il rilascio",
    "already_edited_by_another_user": "Questa edizione potrebbe essere già stata modificata da un altro utente.",
    "please_wait": "Attendere prego.",
    "user_already_requested_release": "ha già richiesto l'approvazione",
    "user_requested_that_you_give_up_the_editing": "chiede il permesso di modificare.",
    "emergency_release": "Applicare la liberatoria. Utilizzare a proprio rischio e pericolo. L'edizione potrebbe essere sovrascritta da un altro utente se ci sta lavorando.",
    "alt_debtor_number": "ID alternativo del debitore",
    "use_bootstrap_label": "Bootstrap",
    "use_bootstrap_infotext": "Se si attiva questa opzione, le librerie di bootstrap vengono integrate",
    "last_queried_attributes": "Ultimi prezzi richiesti",
    "transfer_campaign": "Trasferimento",
    "duplicate_campaign_description": "Duplicare la campagna e assegnarla a singoli utenti o a tutti gli utenti di un'azienda.",
    "duplicate_campaign": "Duplicato",
    "clone_to_single_user": "Creare un duplicato per uno o più utenti",
    "clone_to_all_users_of_company": "Creare duplicati per tutti gli utenti di un'azienda",
    "enum_option_invalid": "Opzione non valida",
    "pdf_download_option": {
      "enabled": "Attivato",
      "disabled": "Disattivato",
      "inherited": "Ereditato",
      "title": "Download PDF consentito",
      "loading": "Carica l'impostazione ereditata",
      "effective_setting": "Impostazione efficace"
    },
    "choose_image_for_filter": "Selezionare un'immagine",
    "invoice_email_bcc": {
      "send_new_invoice_email_bcc": "Invio di una nuova fattura via e-mail BCC",
      "email_address_bcc": "Indirizzo e-mail BCC",
      "info_text": "A questo indirizzo verrà inviata anche una copia nascosta dell'e-mail della nuova fattura."
    },
    "reduced_vat_rate": {
      "cart_item_hint": "Questo articolo può beneficiare di un'aliquota IVA ridotta dopo un controllo manuale da parte di un dipendente Creacheck."
    },
    "forgot_password_infotext": "Se avete dimenticato la vostra password, potete reimpostarla qui. Inserite il vostro indirizzo e-mail registrato nel modulo sottostante e cliccate su \"Continua\". Vi invieremo un'e-mail con ulteriori informazioni.",
    "forgot_password_infotext_robethood": "Se avete dimenticato la vostra password, potete reimpostarla qui. Inserite l'indirizzo e-mail registrato nel modulo sottostante e cliccate su \"Continua\". Vi invieremo un'e-mail con ulteriori informazioni.",
    "forgot_password_info_email_not_used": "Se non utilizzate più l'indirizzo e-mail associato al vostro account Creacheck, contattateci tramite il nostro modulo di contatto:",
    "forgot_password_info_email_not_used_robethood": "Se non utilizzate più l'indirizzo e-mail associato al vostro account, contattateci tramite il nostro modulo di contatto:",
    "invoice_greeting_male": "Caro Sig.",
    "invoice_greeting_female": "Cara signora",
    "invoice_greeting_text": "Vi ringraziamo molto per il vostro ordine tramite %company%. Vi addebiteremo i seguenti servizi:",
    "bank_account": "Coordinate bancarie",
    "mandatory_field": "Dichiarare questo campo come obbligatorio",
    "custom_checkbox": "Casella di controllo personalizzata",
    "set_label_checkbox": "Assegnare un'etichetta alla casella di controllo",
    "set_checkbox_link": "È possibile collegare l'etichetta qui",
    "change_checkbox_link": "Cambia link",
    "checkbox_infotext": "È possibile collegare l'etichetta inserendo l'area corrispondente in caratteri $. Ad esempio, accetto la $Privacy Policy$.",
    "perforation": {
      "cart_radiobox_title": "Opzioni per la perforazione dell'archivio",
      "attribute_is_custom_fa_setting": "È personalizzato FA opzioni di perforazione preselezionate",
      "set_perforation_options": "Impostare le opzioni di perforazione",
      "preset": "Impostazione predefinita",
      "how_to": "Cercate l'identificativo del gruppo di prodotti, selezionate alcune varianti, provate diverse quantità e metodi di spedizione. Quindi selezionare l'opzione di perforazione da preselezionare con questo attributo. È possibile aggiungere diverse opzioni.",
      "select_variant": "Selezionare una variante per visualizzarne le quantità e i metodi di spedizione.",
      "select_shipping_type": "Fare clic su un metodo di spedizione per visualizzare le opzioni disponibili.",
      "select_option_value": "Fare clic su un'opzione per aggiungerla alla selezione.",
      "please_try_other": "Si prega di provare un'altra variante, quantità o metodo di spedizione",
      "options": "Opzioni",
      "other_options": "Altre opzioni",
      "selected_options": "Opzioni selezionate",
      "perforation_options": "Opzioni di perforazione",
      "product_group": "Gruppo di prodotti",
      "variants": "Varianti",
      "quantities_in_variant": "Quantità in variante"
    },
    "textile_select_exactly_n_items": "Selezionare esattamente {totale}",
    "textile_exact_number_selected": "Tutto bene: esattamente {totale} articoli selezionati",
    "textile_order_quantity_mismatch": "Il numero di taglie di tessuto deve corrispondere all'edizione selezionata.",
    "tracking_pixel_help_text": "Si noti che l'implementazione può variare a seconda del fornitore. Nel nostro esempio, utilizziamo il pixel di tracciamento di Meta. L'integrazione richiede innanzitutto la verifica del dominio. Per farlo, è necessario integrare un meta tag, che nel nostro esempio è stato fornito da Meta. Si veda il codice di esempio qui sotto. Si noti che la verifica può richiedere fino a 72 ore, a seconda del provider. Nella seconda fase, aggiungere il codice di base per l'integrazione del pixel di tracciamento. Per ulteriori informazioni, leggere la documentazione fornita dal rispettivo provider.",
    "reg_greeting_male": "Caro Sig.",
    "reg_greeting_female": "Cara signora",
    "reg_gender_male": "Il Sig.",
    "reg_gender_female": "Signora",
    "reg_name": "Saluto e nome",
    "reg_phone_number": "Numero di telefono",
    "reg_domain": "Dominio",
    "reg_ancestors": "ID struttura",
    "reg_explanation": "<p>Grazie per la sua registrazione. La preghiamo di confermare il suo indirizzo e-mail entro 24 ore.</p><p>Solo con un indirizzo e-mail confermato potrà ricevere una nuova password o informazioni sulle modifiche.</p>",
    "widget_order_stats": {
      "title": "Totale ordini",
      "orders_count": "Conteggio ordini",
      "orders_gross_total": "Totale ordini lordi",
      "no_orders_in_date_range": "Nessun ordine nel periodo selezionato",
      "loading": "Caricamento",
      "this_month": "Questo mese",
      "previous_month": "Il mese scorso",
      "last_3_months": "Ultimi 3 mesi",
      "last_6_months": "Ultimi 6 mesi",
      "last_12_months": "Ultimi 12 mesi",
      "for_companies": "per la/e società",
      "for_clients": "per il/i cliente/i",
      "title1": "Totale ordini",
      "title2": "I 10 prodotti più ordinati",
      "items_count": "Conteggio dei prodotti",
      "item_types_count": "Prodotti (tipi)"
    },
    "cart_domain": "Il dominio specificato",
    "cart_domain_available": "è disponibile!",
    "cart_domain_not_available": "non è più disponibile!",
    "cart_domain_not_available_title": "Dominio non disponibile",
    "pages_to_delete": "Le seguenti pagine saranno rimosse:",
    "page_to_delete": "Pagina",
    "sales_doc_reverse_charge": "La fattura viene emessa senza IVA, poiché in questo caso si applica la procedura di inversione contabile. L'IVA deve essere dichiarata e pagata dal destinatario del servizio.",
    "invoice_for_service": "Fattura per il servizio",
    "phone_invalid_country": "Il codice paese del numero di telefono non è valido",
    "mobile_phone_invalid_country": "Il codice paese del numero di cellulare non è valido",
    "fax_invalid_country": "Il codice paese del numero di fax non è valido.",
    "search_campaign": "Ricerca di campagne",
    "coupon_usage": "Utilizzo del voucher",
    "coupon_balance_title": "Bilancio delle cedole",
    "get_coupon_balance": "Interrogazione dell'importo residuo",
    "total_coupon_budget": "Budget totale:",
    "remaining_coupon_budget": "Budget rimanente:",
    "spent_coupon_budget": "Budget speso:",
    "coupon_balance_success": "Interrogazione riuscita",
    "upload_print_data_previews": "Caricamento di immagini di anteprima dei dati di stampa",
    "uploaded_print_data_previews": "Dati di stampa caricati Anteprima delle immagini",
    "confirm_upload": "Confermare",
    "image_minimum_size": "L'immagine non corrisponde alla dimensione minima!",
    "data_policy_info": "Protezione dei dati e impronta",
    "fill_data_policy_before_save": "Si prega di completare le informazioni sulla protezione dei dati e sulle note legali prima di salvare. Queste informazioni si trovano nelle impostazioni",
    "subscription_liu_subscribe": "Abbonarsi",
    "subscription_liu_cancel": "Cancellazione",
    "subscription_liu_extend": "Estendere",
    "subscription_status_changed_to_active": "Stato cambiato in attivo!",
    "subscription_status_changed_to_inactive": "Stato cambiato in inattivo!",
    "sub_start_end": "La data di inizio non può essere maggiore della data di fine!",
    "subscription_liu_send": "Invio",
    "subscription_to_client": {
      "subscription_not_available": "Scegliete un abbonamento!",
      "type_parameter_not_present": "Il parametro %type% è mancante",
      "date_parameter_not_present": "Il parametro %type% è mancante",
      "end_date_greater_then_start_date": "La data di fine non può essere maggiore della data di inizio!",
      "end_date_less_then_first_day_of_next_month": "La data di fine non può essere anteriore alla prima data del mese successivo!"
    },
    "subscription_billing_period": "Periodo contabile",
    "mail_greeting_female": "Salve signora",
    "mail_greeting_male": "Salve Sig.",
    "mail_greeting_first_name_form": "Ciao",
    "mail_preview": "Anteprima e-mail",
    "mail_preview_update": "Anteprima di avvio/aggiornamento",
    "mail_preview_close": "Chiudere",
    "mail_preview_hint": "Si noti che l'anteprima serve solo a verificare il layout e i messaggi. Tutti i modelli contengono dati fittizi.",
    "tracking_details": "Fare clic sul numero di tracciamento per ulteriori informazioni",
    "ups_tracking_title": "Tracciabilità della spedizione tramite API (UPS)",
    "ups_tracking_button": "Stato dell'interrogazione",
    "tracking_informations": "Informazioni sulla consegna",
    "ups_tracking_status": "Stato della spedizione (UPS)",
    "shipment_package": "Pacchetto",
    "shipment": "Programma",
    "shipment_package_current_status": "Stato attuale",
    "shipment_package_current_status_description": "Descrizione del",
    "shipment_package_delivery_date": "Consegnato il",
    "shipment_package_delivery_location": "Ricevere dove",
    "shipment_package_delivery_receivedBy": "Ricevere da",
    "tracking_error": "Si è verificato un errore durante l'interrogazione!",
    "1": "<strong>Fehler beim Einloggen</strong>",
    "website_title": "Titolo per il sito web (motori di ricerca)",
    "basic_billing_settings": "Impostazioni di base per la fatturazione",
    "currency_and_vat": "Valuta e IVA",
    "basic_client_settings": "Impostazioni di base",
    "client_settings_info_text": {
      "info_text_phone": "Per inserire correttamente il numero di telefono fisso, inserire il numero completo, compreso il prefisso internazionale. Assicuratevi di non utilizzare spazi o caratteri speciali. Esempio per la Germania: +49XXXXXXXXXXX.",
      "info_text_mobile": "Per inserire correttamente il numero di cellulare, inserire il numero completo, compreso il prefisso internazionale. Assicuratevi di non utilizzare spazi o caratteri speciali. Esempio per la Germania: +49XXXXXXXXXXX.",
      "info_text_demo": "Attivare la modalità demo, ad esempio per eseguire ordini di prova.",
      "info_text_image_rights_query": "Se si attiva questa opzione, ogni volta che si carica un'immagine viene visualizzata un'ulteriore richiesta. Vi verrà chiesto di confermare che possedete i diritti di utilizzo dell'immagine caricata. Questo ci aiuta a garantire che tutte le immagini caricate siano legalmente valide e non violino alcun copyright.",
      "info_text_pdf_download_option": "Attiva l'opzione di download dei PDF nell'editor.",
      "info_text_needs_content_authorization": "Se si attiva il controllo dei contenuti, tutti gli ordini effettuati dai membri dell'azienda devono essere controllati e confermati manualmente.",
      "info_text_main_language": "Selezionare la lingua in cui si desidera utilizzare principalmente l'applicazione. Selezionando una lingua, personalizziamo l'interfaccia utente in base alle vostre esigenze.",
      "info_text_password_renew": "Se si attiva questa opzione, l'utente riceverà un'e-mail di rinnovo della password al successivo accesso.",
      "info_text_editor_auto_save": "Se si attiva questa opzione, l'editor salva automaticamente le modifiche ogni 5 minuti."
    },
    "content_check_status_rejected": "Rifiutato",
    "content_check_status_approved": "autorizzato",
    "cart_remaining_user_budget": "Budget residuo per gli utenti",
    "cart_remaining_user_budget_after_order": "Budget utente rimanente dopo l'ordine",
    "cart_budget_user": "Bilancio dell'utente",
    "archived_product_templates": "Modelli archiviati",
    "archived_product_templates_description": "Qui potete trovare tutti i modelli archiviati",
    "archive_template": "Archivio",
    "restore_template": "Ripristino",
    "budget_value_used": "Utilizzato",
    "budget_value_reserved": "Riservato",
    "budget_value_residual": "Valore residuo",
    "double_opt_in": "Procedura di consenso double opt-in"
  }
}
