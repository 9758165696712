/* eslint-disable max-len */
/* eslint-disable import/prefer-default-export */
/* eslint-disable-next-line no-unused-vars */
import React from 'react';

export const locale = {
  "locales": ["es"],
  "messages": {
    "editor": {
      "first_name": "Nombre",
      "name": "Nombre",
      "subject": "Asunto",
      "message": "Mensaje",
      "custom_dropdown": "Desplegable personalizado",
      "custom_label": "Etiquetado propio",
      "set_autoplay": "Reproducción automática",
      "autoplay_info": "Atención Algunos navegadores no permiten la reproducción automática en la mayoría de los casos. Sin embargo, la reproducción automática silenciada siempre está permitida.",
      "set_muted": "Silenciar",
      "data_protection_officer": "Responsable de protección de datos",
      "data_protection_authority": "Autoridad de protección de datos",
      "button_font_color": "Color de fuente",
      "set_label_dropdown": "Por favor, asigne una etiqueta al desplegable",
      "set_label_custom_field": "Asigne una etiqueta al campo de entrada",
      "empty_field": "Campo libre",
      "custom_field": "Campo definido por el usuario",
      "change_label": "Cambiar etiqueta",
      "edit_label": "Editar etiqueta",
      "salutation": "Saludo",
      "title": "Título",
      "link_title": "Nombre del enlace",
      "main_text": "Texto principal",
      "more_text": "más texto",
      "create_text": "Crear texto",
      "edit_text_slider": "Aún no se ha creado ningún elemento de texto",
      "edit_rss_elements": "Aún no hay elementos de molde seleccionados, haga clic aquí para añadirlos.",
      "rss_settings": "Configuración del canal RSS",
      "select_rss": "Puede seleccionar un canal RSS de las plantillas",
      "available_rss": "Canales RSS disponibles",
      "load_rss_part_1": "Ha seleccionado el siguiente canal RSS.",
      "load_rss_part_2": "Haga clic en el botón \"Obtener canales RSS\" para cargar los canales.",
      "load_rss_feeds": "Recuperar canales RSS",
      "color": "Color",
      "background_color": "Color de fondo",
      "distance_to_border": "Distancia al borde",
      "color_of_qrcode": "Color del código QR",
      "click_me": "Hazme clic",
      "new_menu_item": "Nueva opción de menú",
      "external": "externo",
      "imprint": "Pie de imprenta",
      "privacy": "Protección de datos",
      "january": "Enero",
      "february": "febrero",
      "march": "Marzo",
      "april": "Abril",
      "may": "Mayo",
      "june": "Junio",
      "july": "Julio",
      "august": "Agosto",
      "september": "Septiembre",
      "october": "octubre",
      "november": "Noviembre",
      "december": "Diciembre",
      "monday": "Lunes",
      "tuesday": "Martes",
      "wednesday": "Miércoles",
      "thursday": "Jueves",
      "friday": "Viernes",
      "saturday": "Sábado",
      "sunday": "Domingo",
      "edit_date": "Editar nombramiento",
      "oclock": "Reloj",
      "filter_selection": "Selección de filtro",
      "use_filter": "Solicitar",
      "sample_picture_found": "Imagen de muestra encontrada",
      "already_edited_in_another_tab": "Es posible que esta edición ya esté editada en otra pestaña.",
      "information_for_contact": "Datos del formulario de contacto",
      "mail_receiver": "Destinatario de correo electrónico",
      "google_analytics": "Google Analytics",
      "measurement_id": "ID de medición para Google Analytics",
      "new_picture_inserted": "Nueva imagen insertada",
      "text_changed": "Texto modificado",
      "edit_title": "Haga clic aquí para editar el título",
      "edit_description": "Haga clic aquí para editar la descripción de la imagen",
      "edit_copyright": "Haga clic aquí para editar los derechos de autor",
      "no_changes": "Sin cambios",
      "changes_on_document": "Cambios en el documento",
      "changes_on_page": "Cambios en la página",
      "page": "Página",
      "choose_button_label": "Seleccione una etiqueta para el botón",
      "edit_calendly": "Haga clic aquí para editar la configuración de Calendly",
      "exchanged_with_page": "intercambiado con página",
      "upload_failed": "Error de carga",
      "try_again": "Por favor, inténtelo de nuevo",
      "saving_failed": "Guardado fallido",
      "new_page_inserted": " nueva(s) página(s) insertada(s) después de la página",
      "delete_pages": "Borrar páginas",
      "all_content_deleted": "Se eliminarán las páginas y todo su contenido.",
      "yes_delete": "Sí, borrar",
      "pages_deleted": " Página/s eliminada/s, por página",
      "rather_not": "Mejor no",
      "new_here": "¿Eres nuevo aquí?",
      "first_start": "Parece que ha abierto nuestro nuevo editor por primera vez. ¿Desea una introducción?",
      "yes_absolutely": "Sí, por supuesto.",
      "im_fine": "Puedo gestionar",
      "no_page_definitions_found": "No se han encontrado definiciones de página.",
      "move_up": " Subir página(s)",
      "side_position_fixed": "Posición lateral fija",
      "generated_in_pdf": "Las páginas se generan automáticamente en el PDF",
      "move_down": " Mover página(s) hacia abajo",
      "by": "A",
      "add_page": " Insertar páginas",
      "remove_page": " Eliminar páginas",
      "unsaved_changes": "Tienes cambios sin guardar",
      "i_have_it": "Vale, ¡ya lo tengo!",
      "document_pictures": "Imágenes en el documento",
      "used_images": "Imágenes utilizadas",
      "take_a_photo": "Haz una foto",
      "unused_images": "Imágenes no utilizadas",
      "content_is_saved": "El contenido se guarda",
      "page_arrangement": "Disposición lateral",
      "print_sheet_view": "Imprimir vista de hoja",
      "view_safety_distance": "Vista de la distancia de seguridad",
      "view_structure": "Vista de la estructura",
      "start_tutorial": "Iniciar tutorial",
      "adjust_by_window": "Adaptar la página a la ventana",
      "adjust_by_width": "Ajustar la página al ancho",
      "adjust_by_height": "Ajustar la página a la altura",
      "delete_content": "Borrar contenido",
      "delete_selected_content_block": "Elimina el bloque de contenido seleccionado y su contenido (imágenes, textos).",
      "delete_children_of_parents": "Tenga en cuenta que se trata de un elemento padre. Si lo elimina, también se eliminarán todos los elementos hijos asociados.",
      "reset_content": "Restablecer contenido",
      "reset_content_block_to_root": "Esto restablece el bloque de contenido seleccionado y su contenido (imágenes, textos) a su estado original.",
      "yes_reset": "Sí, reiniciar",
      "copy_full_content": "Copiar todo el contenido de la página",
      "paste_content": "Pegar contenido desde el portapapeles",
      "enlarge_block": "Ampliar bloque",
      "shrink_block": "Reducir el tamaño del bloque",
      "text_overflow": "Desbordamiento de texto",
      "initial_image_available": " Imagen inicial disponible",
      "paste_content_after": "Pegar contenido del portapapeles después de estos",
      "deselect_element": "Deseleccionar elemento",
      "select_element": "Seleccionar elemento",
      "block_can_not_move": "El bloque no se puede mover",
      "up": "Hasta arriba",
      "reset_block": "Bloque de reinicio",
      "change_backgroundcolor": "Cambiar el color de fondo",
      "change_fontcolor": "Cambiar el color de la fuente",
      "copy_block": "Bloque de copia",
      "delete_block": "Borrar bloque",
      "down": "Hacia abajo",
      "product_in_cart": "Producto en la cesta de la compra",
      "added_to_cart": " se ha añadido a su cesta de la compra.",
      "great": "¡Estupendo!",
      "to_cart": "A la cesta de la compra",
      "are_you_sure": "¿Seguro?",
      "reset_to_selected_status": "El documento se restablece al estado seleccionado",
      "abort": "Cancelar",
      "reset_to_version": "Restablecer versión",
      "choose_a_campaign": "Seleccionar campaña",
      "name_for_edition": "Nombre para esta edición",
      "save_to": "Guardar como...",
      "save": "Guardar",
      "settings": "Ajustes",
      "short_summary": "Breve resumen",
      "keywords_separated": "Palabras clave separadas por una coma",
      "serial_letter": "Combinación de correspondencia",
      "activate_serial_letter": "Activar la combinación de correspondencia",
      "serial_letter_database": "Base de datos Mail merge",
      "serial_letter_database_upload_info": "La carga admite los siguientes formatos de archivo: .xlsx, .xls y .csv",
      "serial_letter_info": "Introduzca los marcadores de posición en las celdas de la primera fila de la base de datos. A continuación, introduzca los valores correspondientes. Por ejemplo",
      "preview_of_database": "Vista previa de la base de datos",
      "serial_letter_database_upload": "Cargar",
      "no_error": "Sin error",
      "error": "Error",
      "information_for_imprint": "Información para la impresión",
      "responsible_for_content": "Responsable del contenido",
      "company_association": "Empresa / Asociación",
      "street": "Calle",
      "zip_code": "Código postal",
      "city": "Lugar",
      "email": "Correo electrónico",
      "phone_number": "Teléfono",
      "data_protection_information": "Información sobre protección de datos",
      "responsible": "Persona responsable",
      "change": "Cambia",
      "export_image": "Exportar imagen",
      "preview": "Vista previa",
      "preview_button": "Vista previa del botón",
      "print_pdf": "Imprimir PDF",
      "initialize": "¡Inicializar!",
      "order": "Pida",
      "versions": "Versiones",
      "currently_used_image": "Imagen utilizada actualmente",
      "upload_new_image": "Cargar nueva imagen",
      "new_cut": "Recorte",
      "take_on": "Hazte cargo",
      "choose_file": "Seleccionar archivo",
      "image_description": "Descripción de la imagen",
      "image_upload": "Carga de imágenes",
      "mediathek": "Mediateca",
      "pixabay": "Pixabay",
      "add_content": "Añadir contenido",
      "copied_content_block": "Bloque de contenido copiado",
      "unsaved_content": "¡Tienes cambios sin guardar! ¿De verdad quieres salir del editor?",
      "filter_settings": "Ajustes del filtro",
      "filter_grayscale": "Escala de grises",
      "filter_sepia": "Sepia",
      "filter_blur": "desenfoque",
      "filter_brightness": "Luminosidad",
      "filter_contrast": "Contraste",
      "filter_hueRotate": "Rotar tono de color",
      "filter_invert": "Invertir colores",
      "filter_opacity": "Transparencia",
      "filter_saturate": "Saturación",
      "filter_default": "Estándar",
      "filter_set": "Descatalogado",
      "work_surface": "Aquí se encuentra la superficie de trabajo.",
      "work_surface_description": "Aquí es donde tiene lugar el proceso de diseño. Aquí pueden insertarse y editarse imágenes, textos y elementos.",
      "page_navigation": "Aquí encontrará la navegación de la página",
      "page_navigation_description": "Aquí puede cambiar de una página a otra y añadir, eliminar o intercambiar páginas.",
      "content_blocks": "Aquí encontrará los bloques de contenido",
      "content_blocks_description": "Aquí puede insertar diversos elementos de imagen y texto en su material publicitario haciendo clic sobre ellos. Si no hay espacio suficiente en el material publicitario, puede eliminar los elementos superfluos en cualquier momento.",
      "screen_view": "Aquí puede cambiar la vista de la pantalla.",
      "screen_view_description": "Aquí puede cambiar la vista de sus medios web. 100% representa siempre el tamaño original del material publicitario.",
      "error_display": "Aquí se muestran los errores.",
      "error_display_description": "El sistema le notifica aquí si se encuentra un desbordamiento de texto u otros errores técnicos.",
      "options": "Aquí encontrará opciones para su material publicitario.",
      "options_description": "Haga clic en el botón de vista previa para generar un PDF de vista previa o en el botón de pedido para colocar el material publicitario en la cesta de la compra. Con 'Guardar como' puedes copiar tu material publicitario a otra campaña.",
      "tutorial_end": "Ese era nuestro editor en pocas palabras.",
      "tutorial_end_description": "Ahora puede diseñar rápida y fácilmente su material publicitario de acuerdo con el diseño corporativo.",
      "content_block_copied": "Bloque de contenido copiado",
      "content_blocks_switched": "Bloques de contenido intercambiados",
      "content_block_reset": "Reinicio del bloque de contenidos",
      "content_block_resize": "Tamaño del bloque de contenido modificado",
      "content_block_removed": "Bloque de contenido eliminado",
      "no_place": "No hay espacio",
      "content_block_inserted": "Bloque de contenido insertado",
      "no_dates_available": "No hay fechas disponibles, haga clic aquí para añadir una.",
      "new_date": "Nueva fecha",
      "edit_dates": "Editar citas",
      "date_description": "Descripción del nombramiento",
      "add": "Añadir",
      "no_dates_availableTwo": "No hay fechas disponibles",
      "date_title": "Título del nombramiento",
      "date_place": "Lugar de la cita",
      "date_from": "Am/Dn",
      "date_to": "Hasta",
      "no_forms": "Aún no hay elementos de molde seleccionados, haga clic aquí para añadirlos",
      "read_privacy_policy": "He leído y comprendido la política de privacidad.",
      "contact_form_settings": "Configuración del formulario de contacto",
      "dropdown_settings": "Configuración desplegable",
      "add_options": "Añadir opciones",
      "dropdown_label": "Etiqueta desplegable",
      "dropdown_option_fields": "Botones de radio desplegables",
      "pls_choose": "Seleccione un elemento del formulario",
      "pls_chooseTwo": "Seleccione los elementos del formulario de contacto que desee",
      "send": "Envío de",
      "google_maps_element_settings": "Ajustes de ubicación Google Maps",
      "no_location": "Aún no ha añadido una dirección, haga clic aquí para editarla",
      "found_textoverflow": "Texto desbordado encontrado",
      "minimum_size": "Tamaño mínimo",
      "too_low_resolution": "La imagen tiene una resolución demasiado baja.",
      "set_scale_free": "Activar el escalado gratuito",
      "unset_scale_free": "Desactivar el escalado libre",
      "no_images_selected": "Aún no hay imágenes seleccionadas, haga clic aquí para añadirlas",
      "gallery_settings": "Configuración de la galería",
      "add_image": "Añadir imagen",
      "add_image_front": "Añadir imagen al principio",
      "add_image_back": "Añadir imagen al final",
      "slider_images_invert": "Invertir el orden de las imágenes",
      "image_text_settings": "Configuración del texto de la imagen",
      "change_slider": "Para cambiar el título o la descripción, haga clic en el texto correspondiente de la barra deslizante.",
      "change_image_title": "Cambiar el título de la imagen",
      "change_image_description": "Cambiar la descripción de la imagen",
      "image_copyright": "Derechos de autor de la imagen",
      "slider_settings": "Ajustes del control deslizante",
      "new_titel": "Nuevo título",
      "new_link_title": "Nuevo título del enlace",
      "edit_text_title": "Editar título",
      "edit_text_link": "Editar enlace",
      "edit_link_title": "Editar el título del enlace",
      "edit_text_text": "Editar texto",
      "add_element": "Añadir elemento",
      "add_element_before": "Añadir elemento en la parte delantera",
      "add_element_after": "Añadir elemento en la parte trasera",
      "edit_text_of_element": "Para editar los textos, haga clic en el texto correspondiente de la barra deslizante",
      "invert_elements": "Invertir secuencia",
      "edit_nav": "Editar navegación",
      "term": "Designación",
      "link": "Enlace",
      "actions": "Acciones",
      "content_of_QRCode": "¿Qué contenido debe incluir el código QR?",
      "address_of_website": "Introduzca la dirección del sitio web:",
      "name_surname": "Nombre y apellidos",
      "business_card": "Tarjeta de visita",
      "must_url": "Debe ser una URL",
      "position": "Posición",
      "phone": "Teléfono",
      "website": "Página web",
      "address": "Dirección",
      "email_message": "Mensaje de correo electrónico",
      "text": "Texto",
      "recipient_email_address": "Dirección de correo electrónico del destinatario",
      "reference": "Asunto",
      "content": "Contenido",
      "your_text": "Su texto",
      "add_QRCode": "Insertar código QR",
      "no_preview_of_twitter": "Lamentablemente no es posible mostrar Twitter aquí, por favor utilice la función de vista previa del sitio web",
      "no_preview_of_calendly": "Lamentablemente no es posible mostrar Calendly aquí, por favor utilice la función de vista previa del sitio web",
      "twitter_link": "Enlace publicado en Twitter:",
      "social_media_settings": "Configuración de las redes sociales",
      "calendly_settings": "Configuración de Calendly",
      "button_settings": "Configuración de los botones",
      "action_button_content_text": "Haga clic aquí para editar la configuración del botón de acción",
      "action_button_link_element": "Referencia a un elemento",
      "action_button_external_link": "Referencia a un enlace externo",
      "action_button_or": "o",
      "button_color": "Color de los botones",
      "add_social_media_account": "Si aún no ha añadido ninguna cuenta en las redes sociales, haga clic aquí para seleccionar una.",
      "choice_of_provider": "Selección de proveedores de redes sociales",
      "embed_code": "Incrustar código de canal de medios sociales",
      "link_to_your_page": "Enlace a su página en",
      "link_to_your_calendly_page": "Enlace a su página de Calendly",
      "link_to_your_page_two": "Enlace a su página",
      "video_settings": "Ajustes de vídeo",
      "add_video": "Aún no hay ningún vídeo seleccionado, haga clic aquí para insertar uno",
      "choice_video_provider": "Selección de proveedores de vídeo",
      "upload_video": "Cargar vídeo",
      "choice_mp4_file": "Seleccionar archivo de vídeo (.mp4)",
      "nav_type": "Tipo",
      "nav_type_url": "URL",
      "nav_type_page": "Página",
      "nav_type_page_prefix": "Página"
    },
    "medialibrary": {
      "new_folder": "Nueva carpeta",
      "sorting": "Clasificación",
      "directories": "Directorios",
      "medialibrary": "Mediateca",
      "media": "Medios de comunicación",
      "upload_media": "Cargar medios",
      "sorting_description": "Tenga en cuenta que la clasificación se basa en el nombre del archivo y no en el título definido.",
      "sorting_info_text": "Seleccione un orden de clasificación para las carpetas y los soportes. El conjunto de ordenación también se aplica dentro de todas las vistas de carpetas.",
      "paste": "Inserte",
      "info": "Atención",
      "info_text": "Ya existen uno o más archivos. No se han vuelto a cargar.",
      "back_to_root": "Volver al directorio raíz",
      "directory_by": "Directorio de",
      "created_by": "Creado por",
      "no_folder_created": "No se ha creado ninguna carpeta",
      "no_media_found": "No hay medios en la carpeta",
      "upload_processing": "Sus cargas se procesan",
      "not_uploaded": "No se han podido cargar los archivos.",
      "close": "Cerrar",
      "preview": "Vista previa de imagen/vídeo",
      "name": "Nombre",
      "upload_info": "Información",
      "options": "Opciones",
      "status": "Estado",
      "adjust_meta": "Personalizar metadatos",
      "title": "Título",
      "description": "Descripción de la",
      "save": "Guardar",
      "create_new_directory": "Crear un nuevo directorio",
      "directory_name": "Nombre del directorio",
      "rename_folder": "Cambiar nombre de carpeta",
      "copyright": "Copyright",
      "upload": "Cargar",
      "wastebasket": "Papelera",
      "are_you_sure_erase_file": "¿Estás seguro de que quieres eliminar el archivo de forma permanente?",
      "are_you_sure_erase_folder": "¿Estás seguro de que quieres borrar la carpeta irrevocablemente?",
      "erase": "Borrar"
    },
    "meta": {
      "campaigns": {
        "title": "Campañas",
        "description": "Campañas",
        "name": "Campañas"
      },
      "productShop": {
        "title": "Tienda",
        "description": "¿Busca mercancía? Entonces ha llegado al lugar adecuado.",
        "name": "Artículo"
      },
      "carShop": {
        "title": "Tienda de vehículos",
        "description": "¿Buscas el coche de tus sueños? Entonces echa un vistazo a nuestros vehículos ahora.",
        "name": "Vehículos"
      },
      "car_detail_search": {
        "title": "¿No está seguro de qué coche le conviene? Utiliza nuestro buscador para encontrar el coche perfecto",
        "description": "¿Busca un coche con requisitos específicos, pero no sabe exactamente qué coche necesita? Seleccione el tipo de vehículo, la transmisión y el nivel de equipamiento.",
        "name": "Búsqueda detallada"
      },
      "car_brands": {
        "title": "Seleccione su marca",
        "description": "Elige tu marca y descubre las mejores ofertas del mercado.",
        "name": "Selección de marcas"
      },
      "car_models": {
        "title": "Seleccione el modelo de su {brandName}",
        "description": "Elija un modelo entre los más populares {modelOne} y {modelTwo}.",
        "name": "Modelos"
      },
      "car_model_drive": {
        "title": "Seleccione el tipo de combustible y la transmisión para su {brandName} {modelName}",
        "description": "Tenemos el {brandName} {modelName} con {fuelTypes} motores. Los tipos de caja de cambios disponibles son {gearboxTypes}.",
        "name": "Tipo de combustible"
      },
      "car_model_equipment": {
        "title": "Seleccione el nivel de equipamiento para su {brandName} {modelName}",
        "description": "Elija entre Budget, Family, Comfort o Business. El más popular, Comfort, ofrece sensores de aparcamiento, Bluetooth y mucho más.",
        "name": "Equipamiento"
      },
      "favorites": {
        "title": "Tus favoritos, guardados para más tarde",
        "description": "Es estupendo que hayas guardado algunos de nuestros mejores artículos para más tarde. Ahora prueba a seleccionar el que quieras.",
        "name": "Favoritos"
      },
      "cart": {
        "title": "Cesta de la compra",
        "description": "Cesta de la compra",
        "name": "Cesta de la compra"
      },
      "checkout": {
        "title": "Caja",
        "description": "Caja",
        "name": "Caja"
      },
      "clients": {
        "title": "Clientes",
        "description": "Clientes",
        "name": "Clientes"
      },
      "client_details": {
        "title": "Empresa",
        "description": "Empresa",
        "name": "Empresa"
      },
      "leads": {
        "title": "Plomos",
        "description": "Plomos",
        "name": "Plomos"
      },
      "lead_details": {
        "title": "Plomo",
        "description": "Plomo",
        "name": "Plomo"
      },
      "cars": {
        "title": "Vehículos",
        "description": "Vehículos",
        "name": "Vehículos"
      },
      "car_overview": {
        "title": "Vista general del vehículo",
        "description": "Vista general del vehículo",
        "name": "Vista general del vehículo"
      },
      "car_details": {
        "title": "Vehículo",
        "description": "Vehículo",
        "name": "Vehículo"
      },
      "pages": {
        "title": "Página",
        "description": "Página",
        "name": "Página"
      },
      "page_details": {
        "title": "Página",
        "description": "Página",
        "name": "Página"
      },
      "profile": {
        "title": "Perfil",
        "description": "Perfil",
        "name": "Perfil"
      },
      "locations": {
        "title": "Ubicaciones",
        "description": "Ubicaciones",
        "name": "Ubicaciones"
      },
      "company_locations": {
        "title": "Ubicaciones",
        "description": "Ubicaciones",
        "name": "Ubicaciones"
      },
      "data_push": {
        "title": "Introducción de datos",
        "description": "Introducción de datos",
        "name": "Introducción de datos"
      },
      "white_label_projects": {
        "title": "Proyectos de marca blanca",
        "description": "Proyectos de marca blanca",
        "name": "Proyectos de marca blanca"
      },
      "users": {
        "title": "Usuarios",
        "description": "Usuarios",
        "name": "Usuarios"
      },
      "user_details": {
        "title": "Usuarios",
        "description": "Usuarios",
        "name": "Usuarios"
      },
      "user_import": {
        "title": "Importar usuarios",
        "description": "Importar usuarios",
        "name": "Importar usuarios"
      },
      "teams": {
        "title": "Equipos",
        "description": "Equipos",
        "name": "Equipos"
      },
      "questionnaires": {
        "title": "Cuestionarios",
        "description": "Cuestionarios",
        "name": "Cuestionarios"
      },
      "questionnaire_details": {
        "title": "Cuestionario",
        "description": "Cuestionario",
        "name": "Cuestionario"
      },
      "document_types": {
        "title": "Tipos de documentos",
        "description": "Tipos de documentos",
        "name": "Tipos de documentos"
      },
      "document_type_details": {
        "title": "Tipo de documento",
        "description": "Tipo de documento",
        "name": "Tipo de documento"
      },
      "templates": {
        "title": "Plantillas",
        "description": "Plantillas",
        "name": "Plantillas"
      },
      "cms_elements": {
        "title": "Elementos CMS",
        "description": "Elementos CMS",
        "name": "Elementos CMS"
      },
      "cms_element_details": {
        "title": "Elemento CMS",
        "description": "Elemento CMS",
        "name": "Elemento CMS"
      },
      "brands": {
        "title": "Marcas",
        "description": "Marcas",
        "name": "Marcas"
      },
      "models": {
        "title": "Modelos",
        "description": "Modelos",
        "name": "Modelos"
      },
      "categories": {
        "title": "Categorías",
        "description": "Categorías",
        "name": "Categorías"
      },
      "category_details": {
        "title": "Categoría",
        "description": "Categoría",
        "name": "Categoría",
        "authorization_on_mobile_device": "Autorización en dispositivos móviles",
        "hide_category": "Ocultar categoría"
      },
      "attributes": {
        "title": "Atributos",
        "description": "Atributos",
        "name": "Atributos"
      },
      "attribute_details": {
        "title": "Atributo",
        "description": "Atributo",
        "name": "Atributo"
      },
      "attribute_groups": {
        "title": "Grupos de atributos",
        "description": "Grupos de atributos",
        "name": "Grupos de atributos"
      },
      "dynamic_data_definitions": {
        "title": "Definiciones dinámicas de datos",
        "description": "Definiciones dinámicas de datos",
        "name": "Definiciones dinámicas de datos"
      },
      "pipelines": {
        "title": "Tuberías",
        "description": "Tuberías",
        "name": "Tuberías"
      },
      "pipeline_details": {
        "title": "Tuberías",
        "description": "Tuberías",
        "name": "Tuberías"
      },
      "items": {
        "title": "Productos",
        "description": "Productos",
        "name": "Productos"
      },
      "item_details": {
        "title": "Producto",
        "description": "Producto",
        "name": "Producto"
      },
      "calendar": {
        "title": "Calendario",
        "description": "Calendario",
        "name": "Calendario"
      },
      "orders": {
        "title": "Pedidos",
        "description": "Pedidos",
        "name": "Pedidos"
      },
      "settings": {
        "title": "Ajustes",
        "description": "Ajustes",
        "name": "Ajustes"
      },
      "four_zero_four": {
        "title": "404",
        "description": "404",
        "name": "404"
      },
      "account": {
        "title": "Cuenta",
        "description": "Cuenta",
        "name": "Cuenta"
      },
      "forgot_password": {
        "linktext": "¿Ha olvidado su contraseña?",
        "title": "Contraseña olvidada",
        "description": "Contraseña olvidada",
        "name": "Contraseña olvidada"
      },
      "renew_password": {
        "title": "Renovar contraseña",
        "description": "Renovar contraseña",
        "name": "Renovar contraseña"
      },
      "new_password_after_reset": {
        "title": "Nueva contraseña tras el restablecimiento",
        "description": "Nueva contraseña tras el restablecimiento",
        "name": "Nueva contraseña tras el restablecimiento"
      },
      "pricing": {
        "title": "Precios",
        "description": "Precios",
        "name": "Precios"
      },
      "signedup": {
        "title": "Registrado",
        "description": "Registrado",
        "name": "Registrado"
      },
      "go_pro": {
        "title": "Go pro",
        "description": "Go pro",
        "name": "Go pro"
      },
      "paypal": {
        "title": "Pagar con Paypal",
        "description": "Pagar con Paypal",
        "name": "Pagar con Paypal"
      },
      "lead_catchers": {
        "title": "Captadores de plomo",
        "description": "Captadores de plomo",
        "name": "Captadores de plomo"
      },
      "bills": {
        "title": "Facturas",
        "description": "Facturas",
        "name": "Facturas"
      },
      "provisionServiceDocuments": {
        "title": "Comisiones"
      },
      "commissionServiceDocuments": {
        "title": "Documentos de la Comisión"
      },
      "integrations": {
        "title": "Integraciones",
        "description": "Integraciones",
        "name": "Integraciones"
      },
      "order_complete": {
        "title": "Pedido realizado. Muchas Gracias",
        "description": "Pedido realizado. Muchas Gracias",
        "name": "Pedido realizado. Muchas Gracias"
      },
      "order_details": {
        "title": "Detalles del pedido",
        "description": "Detalles del pedido",
        "name": "Detalles del pedido"
      },
      "my_company": {
        "title": "Mi empresa",
        "description": "Mi empresa",
        "name": "Mi empresa"
      },
      "white_label_plugins": {
        "title": "Plugins de marca blanca",
        "description": "Plugins de marca blanca",
        "name": "Plugins de marca blanca"
      },
      "plugins": {
        "title": "Plugins de Creacheck",
        "description": "Plugins de Creacheck",
        "name": "Plugins de Creacheck"
      },
      "bill_details": {
        "title": "Detalles de la factura",
        "description": "Detalles de la factura",
        "name": "Detalles de la factura"
      },
      "activate_plugin": {
        "title": "Activar plugin",
        "description": "Activar plugin",
        "name": "Activar plugin"
      },
      "model_groups": {
        "title": "Grupos de modelos",
        "description": "Grupos de modelos",
        "name": "Grupos de modelos"
      },
      "model_group_details": {
        "title": "Detalles del grupo de modelos",
        "description": "Detalles del grupo de modelos",
        "name": "Detalles del grupo de modelos"
      },
      "model_assets": {
        "title": "Imágenes de modelos",
        "description": "Imágenes de modelos",
        "name": "Imágenes de modelos"
      },
      "model_asset_details": {
        "title": "Modelo Detalles de la imagen",
        "description": "Modelo Detalles de la imagen",
        "name": "Modelo Detalles de la imagen"
      },
      "lead_per_mail_rules": {
        "title": "Reglas del correo electrónico",
        "description": "Reglas del correo electrónico",
        "name": "Reglas del correo electrónico"
      },
      "car_rental": {
        "title": "Reserva segura y rápida en {ciudad}. {cuenta} coches para elegir",
        "description": "Reserve su coche de forma rápida y segura. Créenos, alquilar un coche nunca ha sido tan rápido y fácil.",
        "name": "Dejar"
      },
      "failed_refunds": {
        "title": "Reembolsos fallidos",
        "description": "Reembolsos fallidos",
        "name": "Reembolsos fallidos"
      },
      "companySignup": {
        "title": "Registro de empresas",
        "description": "Registre su empresa y benefíciese del último ecosistema para concesionarios de automóviles",
        "name": "Registro de empresas"
      },
      "price_negotiations": {
        "title": "Negociación de precios",
        "description": "Negociación de precios",
        "name": "Negociación de precios"
      },
      "price_negotiation_details": {
        "title": "Negociación de precios",
        "description": "Negociación de precios",
        "name": "Negociación de precios"
      },
      "communities": {
        "title": "Lista de comunidades",
        "description": "Lista de comunidades",
        "name": "Lista de comunidades"
      },
      "car_service_locations": {
        "title": "Lugares en los que está disponible el servicio de citas",
        "description": "Ubicaciones",
        "name": "Ubicaciones"
      },
      "car_service_resources": {
        "title": "Elija un asesor de servicios",
        "description": "Asesor de servicios",
        "name": "Asesor de servicios"
      },
      "car_service_free_slots": {
        "title": "Elija una franja horaria libre para aparcar su coche",
        "description": "Ventana de tiempo",
        "name": "Ventana de tiempo"
      },
      "car_service_client_data": {
        "title": "Conéctese o regístrese para reservar su cita",
        "description": "Inicio de sesión",
        "name": "Inicio de sesión"
      },
      "car_service_car_data": {
        "title": "Introduzca los datos del vehículo para reservar el servicio",
        "description": "Datos del vehículo",
        "name": "Datos del vehículo"
      },
      "car_service_summary": {
        "title": "Último paso: compruebe los datos y la selección",
        "description": "Consulte",
        "name": "Consulte"
      },
      "car_services": {
        "title": "Servicios de taller",
        "description": "servicios",
        "name": "servicios"
      },
      "car_service_stations": {
        "title": "Puestos de trabajo de taller",
        "description": "Puestos de trabajo",
        "name": "Puestos de trabajo"
      },
      "document_templates": {
        "title": "Plantillas de documentos",
        "description": "Plantillas de documentos",
        "name": "Plantillas de documentos"
      },
      "document_template_details": {
        "title": "Modelo de documento",
        "description": "Modelo de documento",
        "name": "Modelo de documento"
      }
    },
    "sort": {
      "relevance": "Relevancia",
      "username": "correo electrónico",
      "lastName": "Apellido",
      "cdate": "Lo más nuevo primero",
      "cdateDesc": "Lo más nuevo primero",
      "dueDate": "Fecha de vencimiento",
      "fav": "Más populares",
      "priceAsc": "Precio más bajo",
      "rentPriceAsc": "Precio más bajo",
      "priceDesc": "Precio más alto",
      "rentPriceDesc": "Precio más alto",
      "priority": "Prioridad",
      "mdate": "Última actualización",
      "countryName": "País",
      "vat": "IVA",
      "sumGross": "Importe"
    },
    "jobTypes": {
      "wedding": "Boda",
      "family_portrait": "Retrato de familia",
      "maternity": "Maternidad",
      "newborn": "Recién nacido",
      "couple": "Par",
      "pet": "Mascota",
      "commercial_advertising": "Comercial / Publicidad",
      "fashion": "Moda",
      "model_portfolio": "Cartera de modelos",
      "event": "Evento",
      "headshots": "Headshots",
      "real_estate_and_architecture": "Inmobiliaria y arquitectura",
      "other": "Otros"
    },
    "pipelineEvent": {
      "afterLeadCreated": "creado en función del plomo",
      "afterJobAccepted": "aceptado después del trabajo",
      "beforeJobScheduled": "antes del trabajo previsto",
      "afterJobScheduled": "después del trabajo previsto",
      "afterGalleryCreated": "creado según galería",
      "afterPreviousStage": "después de la etapa anterior"
    },
    "paymentEvents": {
      "beforeJobDate": "antes de la fecha del trabajo",
      "onJobDate": "en la fecha del trabajo",
      "afterJobDate": "por fecha de trabajo",
      "afterInvoiceDate": "después de la fecha de facturación"
    },
    "autocomplete": {
      "placeholder": "Seleccione...",
      "noResultsText": "No se han encontrado resultados",
      "loadingPlaceholder": "Cargando"
    },
    "salesDoc": {
      "debtor_number": "Número de cliente",
      "order_number": "Número de pedido",
      "order_totals": "Total de pedidos",
      "quote_from": "En",
      "quote": "Oferta",
      "quote_for": "Oferta para",
      "quotes": "Ofertas",
      "new_quote": "Nueva oferta",
      "invoice_from": "En",
      "invoice": "Factura",
      "invoice_date": "Fecha de la factura",
      "tax_invoice": "Factura",
      "order": "pedir",
      "credit_note": "Nota de crédito",
      "credit_notes": "Notas de crédito",
      "invoice_for": "Factura de",
      "invoices": "Facturas",
      "new_invoice": "Nueva factura",
      "cancellation_invoice_to": "Factura de anulación de",
      "cancellation_invoice": "Factura de anulación",
      "to_refunded": "Pendiente de reembolso:",
      "positions": "Puestos",
      "issued": "Publicado",
      "cancelled": "STORNO",
      "billing_period": "Período de facturación",
      "billing_period_not_valid": "Periodo de facturación no válido",
      "pro_rate": "a prorrateo",
      "name": "Nombre",
      "description": "Descripción de la",
      "quantity": "Cantidad",
      "unit_price": "Precio unitario",
      "inc_tax": "Impuestos incluidos",
      "exc_tax": "Sin impuestos",
      "subtotal": "Subtotal",
      "total": "Importe total",
      "amount_due": "Total neto",
      "total_due": "Importe de la factura",
      "target": "Cliente",
      "due_date": "Vence el",
      "to_pay": "Pendiente de pago:",
      "payed": "Pagado",
      "payment": "Pago",
      "download_csv_ready": "Exportación CSV finalizada",
      "no_vat_eu_country": "Entregas intracomunitarias exentas de impuestos según el art. 4 nº 1b UstG.",
      "no_vat_non_eu_country": "Entrega de exportación libre de impuestos según § 6 UstG",
      "thanks_for_order": "Gracias por su pedido. Salvo que se indique lo contrario, los servicios mencionados se realizarán en la fecha de esta factura.",
      "bill_by_date": "Por favor, transfiera el importe, indicando el número de factura, a la siguiente cuenta antes del %date%:",
      "intended_use": "Uso previsto",
      "paid_with_cash_cc": "La factura se pagó en efectivo / con tarjeta EC",
      "amount_from_credit_card": "El importe de %amount% se cargará en su tarjeta de crédito.",
      "amount_from_paypal": "El importe de %amount% se cargará en su cuenta PayPal.",
      "debit_from_account": "El importe de %amount% se cargará en su cuenta.",
      "register_and_taxes": "Registro mercantil e impuestos",
      "managing_director": "Director General"
    },
    "provisionDoc": {
      "provision_calculation": "Cálculo de la comisión",
      "client": "Cliente",
      "invoice_no_date": "Nº de factura / fecha",
      "payedDate": "pagado el",
      "serviceType": "Tipo de servicio",
      "amount": "Importe",
      "provisionRate": "Comisión",
      "provisionValue": "Comisión",
      "totalAmount": "Importe total",
      "provision": "Comisión",
      "provisions": "Comisiones",
      "provision_for": "Comisión de",
      "download_pdf": "Descargar PDF"
    },
    "paymentStatus": {
      "late": "Tarde",
      "paid": "Pagado",
      "unpaid": "Sin pagar",
      "inProgress": "En curso"
    },
    "shopItem": {
      "plus_shipping": "más gastos de envío",
      "variants_possible": "Existen varias opciones de selección"
    },
    "sepaAgreement": {
      "sepa_direct_debit_mandate": "Mandato de adeudo directo SEPA (autorización de adeudo directo)",
      "mandate_reference": "Referencia del mandato",
      "will_be_communicated_separately": "se comunicará por separado",
      "payee": "Beneficiario",
      "creditor": "Acreedor",
      "payer": "Pagador",
      "i_allow_payee_direct_debit": "Por la presente autorizo al beneficiario a cobrar los pagos de mi cuenta mediante domiciliación bancaria hasta nuevo aviso. Al mismo tiempo, doy instrucciones a mi banco para que pague los adeudos domiciliados en mi cuenta por el beneficiario.",
      "note_i_can_claim_reimbursement_within_eight_weeks": "Nota: Puedo solicitar la devolución del importe adeudado en un plazo de ocho semanas a partir de la fecha de adeudo. Se aplican las condiciones acordadas con mi banco.",
      "document_issued_electronically_and_valid_without_signature": "Esta carta se creó automáticamente y también es válida sin firma",
      "shortening_notice_period": "Reducción del plazo de preaviso",
      "deadline_for_advance_information_of_direct_debit_is_shortened_to_one_day": "El plazo para la notificación anticipada del adeudo directo SEPA se reduce a un día.",
      "i_issue_sepa_direct_debit_mandate": "Por la presente emito el mandato de adeudo directo SEPA"
    },
    "sad": {
      "offer": "Oferta",
      "modification": "Enmienda",
      "order": "pedir",
      "offers": "Ofertas",
      "orders": "Pedidos",
      "offer_short": "Ang.",
      "order_short": "Lo mejor.",
      "conversion_rate_short": "CR",
      "conversion_rate": "Tasa de conversión",
      "last_offer_date": "Última oferta",
      "last_offer_date_short": "Última Ang.",
      "car_data": "Datos del vehículo",
      "class": "Clase",
      "model_name": "Nombre del modelo",
      "model_name_short": "Nombre del modelo (abreviado)",
      "model_range": "Serie",
      "body_design": "Forma del cuerpo",
      "car_color": "Color",
      "base_price": "Precio base",
      "list_price": "Precio de catálogo",
      "optional_equipment": "Equipamiento especial",
      "incl": "incl.",
      "factory_discount": "Polígono industrial",
      "cash_discount": "Descuento por pronto pago",
      "volume_discount": "Descuento por cantidad",
      "transportation_costs": "Gastos de transferencia",
      "price_reduction": "Reducción de precios",
      "car_image": "Imagen del vehículo desde el configurador",
      "discount": "Propiedad",
      "sales_price": "Precio de venta",
      "max_speed": "Velocidad máxima",
      "car_acceleration": "Aceleración (0 - 100)",
      "online_code": "Código en línea",
      "sales_activity": "Actividad comercial",
      "last_modified": "Último cambio",
      "offer_date": "Fecha de la oferta",
      "order_date": "Fecha del pedido",
      "order_location": "Localización del pedido",
      "pre_contract_nr": "V-Orden no.",
      "pre_contract_date": "V orden dat.",
      "contract_nr": "Nº de pedido",
      "shipment_place": "Lugar de entrega",
      "shipment_key": "Clave de entrega",
      "special_agreement": "Acuerdo especial",
      "contact_person": "Persona de contacto",
      "customer_account_number": "Nº de cuenta del deudor",
      "financing": "Financiación",
      "leasing": "Arrendamiento",
      "financing_product": "Producto",
      "paid_deposit": "Anticipo",
      "paid_deposit_leasing": "Arrendamiento financiero especial",
      "credit_amount": "Importe total del préstamo",
      "monthly_rate": "Pago mensual",
      "contract_duration": "Tiempo de ejecución",
      "total_mileage": "Kilometraje",
      "purchase_price": "Precio de compra",
      "annual_percentage_rate": "Tipo de interés efectivo anual",
      "nominal_interest_percentage_rate": "Interés deudor fijo p.a.",
      "debit_interest_percentage_rate": "Interés nominal anual",
      "total_amount": "Importe total",
      "final_instalment": "Último plazo",
      "application_date": "Fecha de solicitud",
      "application_approval_date": "Fecha de autorización",
      "application_rejection_date": "Fecha de rechazo",
      "client_private": "Privado",
      "client_business": "Comercial",
      "monthly_leasing_rate": "Cuota mensual de arrendamiento financiero",
      "monthly_financing_rate": "Cuota mensual de financiación",
      "monthly_insurance_rate": "Prima mensual del seguro de automóvil",
      "no_winleas_data_available": "No se dispone de datos de WinLEAS",
      "winleas_data_available": "Datos de financiación (F) y/o arrendamiento financiero (L) disponibles en WinLEAS",
      "discount_cash": "Ambulancia de efectivo",
      "discount_volume": "Descuento por cantidad/reciclador",
      "discount_environmental": "Bonificación medioambiental",
      "discount_additional": "Condiciones adicionales",
      "deduction": "Descuento",
      "deduction_mbl": "Descuento MBL",
      "deduction_mbl_interest": "Descuento de intereses MBL",
      "deduction_consultant": "Consultor de descuentos",
      "deduction_consultant_interest": "Consultor de descuento de tipos de interés",
      "commission": "Comisión",
      "commission_standard": "Estándar",
      "commission_addition_deduction": "Marcar al alza / marcar a la baja",
      "commission_subsequent_scope_of_supply": "Siguiente. Volumen de suministro",
      "commission_total": "Total",
      "subsidy": "Subvención",
      "subsidy_contract_partner": "Subvención VP",
      "subsidy_factory": "Planta de subvenciones",
      "subsidy_mbvd": "Subvención MBVD",
      "shortcut_lp_kp": "LP/KP",
      "campaign": "Acción",
      "service_rate": "Tarifa de servicio",
      "residual_value": "Valor residual",
      "table_residual_value": "Valor residual de la tabla",
      "residual_value_change": "Variación del valor residual",
      "ref": "en relación con",
      "net": "red",
      "gross": "bruto",
      "list_price_short": "LP",
      "sales_price_short": "KP",
      "note_save": "Guardar nota",
      "notes": "Notas",
      "salesman": "Vendedor",
      "salesman_blacklist": "Vendedor (Lista negra)",
      "salesman_number": "Vendedor no.",
      "salesman_number_short": "No.",
      "enterprise_number": "Nº de empresa",
      "Mercedes": "Mercdes",
      "Smart": "Inteligente",
      "equipment_line": "Línea",
      "packages": "Paquetes",
      "upholstery": "Tapicería",
      "single_equipments": "Otros",
      "included_in": "contenida en",
      "store": "Rama",
      "save_filterset": "Guardar conjunto de filtros",
      "internal_activity": "Actividades internas",
      "offer_made": "Oferta creada",
      "in_progress": "En curso",
      "predecessor_offer": "Oferta predecesora / duplicado",
      "lost": "Negocio perdido",
      "ordered": "Pedido realizado",
      "canceled": "Pedido anulado"
    },
    "rad": {
      "earnings": "Rendimiento",
      "occupancy_rate": "Utilización",
      "amount": "Importe"
    },
    "external_account_status": {
      "login_fail": "Compruebe los datos de acceso. De lo contrario, no se podrán leer más datos.",
      "login_warning": "Compruebe los datos de acceso.",
      "login_valid": "Datos de acceso correctos",
      "last_successful_login": "Último inicio de sesión con éxito"
    },
    "categoryitems_stock": "Pieza",
    "initialize": "Init",
    "customer": "Empresa",
    "customer_": "Cliente",
    "select_mandant": "Seleccionar cliente",
    "initial_title": "Título inicial del soporte publicitario",
    "will_be_shown_to_the_user_in_frontend": "Se muestra a los usuarios en el frontend",
    "producttemplate_data_saved": "Datos de la plantilla guardados",
    "product_size": "Formato / tamaño del producto",
    "template_upload_files": "Seleccione los archivos que desea cargar",
    "example_of_product_size": "p. ej: DIN A4, DIN largo...",
    "output_format": "Formato de salida",
    "product_size_width": "Anchura en píxeles",
    "product_size_height": "Altura en píxeles",
    "template_folder": "Plantilla",
    "template_dpi": "PPP de salida",
    "template_upload": "Cargar",
    "template_pageSpace": "Espacios en cuadrícula",
    "output_exportType": "Tipo de salida",
    "template_fonts": "Fuentes",
    "template_fontSizeMin": "Tamaño de fuente mín.",
    "template_styles": "Datos CSS y LESS",
    "template_fontSizeMax": "Tamaño máximo de fuente",
    "template_init_amount_of_pages": "Número inicial de páginas",
    "template_page_add_increase": "Infidelidad",
    "template_pageMaxAmount": "Número máximo de páginas",
    "template_pageMinAmount": "Número de páginas mín.",
    "template_pageTop": "Acolchado superior",
    "template_pageBottom": "Fondo acolchado",
    "template_pageFirst": "Número de página Portada",
    "template_pageFirstFixed": "Página de inicio corregida",
    "template_pageLast": "Número de página Última página",
    "template_pageLastFixed": "Última página corregida",
    "template_endless": "Página sin fin",
    "template_fixed": "Página corregida",
    "template_sample_page": "Número de páginas Contenido estándar",
    "font_colors": "Colores del texto",
    "back_colors": "Colores de fondo",
    "client_logo": "Logotipo de la empresa",
    "template_pageNumber": "Número de páginas",
    "page_names": "Configuración de página",
    "add_contentBlock": "Añadir bloque de contenido",
    "css": "CSS general",
    "cssPDF": "CSS sólo para PDF",
    "cssPrintPDF": "CSS sólo para PDF imprimibles",
    "cssWebsite": "CSS para salida como página web",
    "chosenItem": "Selección actual de productos",
    "pdf_price": "Precio en PDF",
    "auflagen": "Condiciones",
    "print_prices": "Precios base",
    "proof_base_price": "Comprobación de los datos del precio base",
    "new_proof_base_price": "Comprobación de nuevos datos de precios base",
    "get_vendor_price": "Precios de llamada",
    "add_variant_line": "Añadir línea",
    "remove_all_item_variant": "Borrar todas las ediciones",
    "get_vendor_prices": "Precio proveedor",
    "get_vendor_id": "Consulta del ID de producto del proveedor",
    "no_vendor_id": "No hay ID de producto del proveedor.",
    "cc_item_id": "ID del producto CreaCheck",
    "vendor_item_id": "ID del producto Proveedor",
    "vendor_item_name": "Nombre del producto Proveedor",
    "vendor_item_missing_attributes": "No se puede encontrar ningún precio para esta combinación de atributos.",
    "vendor_item_save_attributes": "Seleccione los atributos",
    "vendor_item_must_define_attributes": "Para crear variantes es necesario definir atributos.",
    "vendor_item_has_no_attributes": "Este grupo de productos no tiene atributos",
    "vendor_item_all_n_quantities": "Muestra todas las cantidades {quantityCount}",
    "vendor": "Proveedor",
    "internal_item_id": "Identificación interna",
    "select_vendor_product": "Seleccionar grupo de productos",
    "item_type": "Tipo de producto",
    "print_item": "Imprimir producto",
    "website_item": "Página web",
    "normal_item": "Otro producto",
    "not_more_pages": "No se pueden crear más nombres de página que páginas posibles.",
    "info_title_page": "La portada no puede moverse ni borrarse",
    "info_last_page": "La última página no se puede mover ni borrar",
    "page_definition_page_count_info": "Número de página 0 = valor por defecto para todas las páginas sin definición, último para la última página",
    "padding_on_top": "Distancia superior",
    "padding_on_bottom": "Distancias inferiores",
    "padding_on_left": "Espacio a la izquierda",
    "padding_on_right": "Espaciado derecho",
    "delimitation_of_grid_blocks": "Se pueden añadir bloques de cuadrícula sin límite. Así, la página se hace cada vez más larga.",
    "fixed_page": "La página está fija y no se puede mover.",
    "page_definition": "Definición de la página",
    "add_definition": "Añadir definición",
    "page_default_value": "Número de página 0 = valor por defecto para todas las páginas sin definir",
    "page_count": "Número de páginas",
    "page_widths": "Anchuras laterales",
    "page_heights": "Alturas laterales",
    "no_special_formatting": "Sin formato especial",
    "min_max_font_size": "Tamaño de fuente mín./máx.",
    "activate_min_max_font_size": "Activar o desactivar Min-/Max-FontSize",
    "filename": "Nombre del fichero",
    "for_user": "Para los usuarios",
    "user_can_use_font": "El usuario puede utilizar esta fuente en el editor",
    "import_successful": "Importación completada, mira en las otras pestañas",
    "import_resets_template": "¡¡¡¡La importación resetea la plantilla, debe reiniciarse, todas las ediciones de cliente existentes con esta plantilla quedan inutilizables!!!!",
    "problem": "Se ha producido un problema",
    "reading_pages": "Páginas de lectura",
    "done": "Terminado",
    "versions": "Versiones",
    "template_not_edited": "Esta plantilla aún no ha sido editada.",
    "dataProof_infoText": "En una comprobación manual de datos, nuestros expertos comprueban por usted todos los criterios técnicos de la fase de preimpresión. Entre ellos se incluyen la imprimibilidad, el cumplimiento de todas las especificaciones técnicas y el efecto subjetivo del color. Encontrará información más detallada sobre la comprobación de datos en Preguntas frecuentes.",
    "media_library": "Mediateca",
    "count": "Cantidad",
    "welcome_message": "Hola, {nombre}",
    "email": "correo electrónico",
    "website": "Página web",
    "phone": "Teléfono",
    "fax": "fax",
    "password": "contraseña",
    "repeat_password": "Repetir contraseña",
    "company": "Empresa",
    "company_name": "Nombre de la empresa",
    "company_data_timeline_tab_title": "Cronología de los datos de la empresa",
    "company_data_success": "Los datos de la empresa se han modificado correctamente.",
    "company_data_error": "Error al modificar los datos de la empresa",
    "updated_by": "Actualizado por",
    "companies": "Empresas",
    "first_name": "Nombre",
    "last_name": "Apellido",
    "street": "Calle",
    "house_number": "Número de casa",
    "apartment_number": "Número plano",
    "address_line2": "Dirección línea 2",
    "zip_code": "Código postal",
    "city": "Ciudad",
    "country": "País",
    "currency": "Moneda",
    "previous_year": "Año anterior",
    "vat_name": "Nombre IVA",
    "vat_id_name": "VAT ID Nombre",
    "vat_id": "IVA - IdNr",
    "vat_rates": "Tipos de IVA",
    "coc_number": "Número COC",
    "registered_at": "Registrado en",
    "page": "Página",
    "page_of": "de",
    "save": "Guardar",
    "save_and_email": "Guardar y enviar por correo electrónico",
    "add": "Añadir",
    "add_client": "Añadir empresa",
    "update_company_data": "Cambiar el nombre de la empresa",
    "add_user": "Añadir usuario",
    "add_product": "Añadir producto",
    "add_producttemplate": "Añadir plantilla",
    "add_question": "Añadir pregunta",
    "add_option": "Añadir opción",
    "add_questionnaire": "Añadir cuestionario",
    "add_template": "Añadir plantilla",
    "add_position": "Añadir posición",
    "add_pipeline": "Añadir tubería",
    "add_quote": "Añadir oferta",
    "add_invoice": "Añadir factura",
    "add_stage": "Añadir nivel",
    "add_gallery": "Añadir galería",
    "add_job": "Añadir trabajo",
    "add_payment_schedule": "Añadir plan de pago",
    "add_payment": "Añadir pago",
    "add_location": "Añadir ubicación",
    "add_data_definition": "Añadir definición de datos",
    "add_car": "Añadir vehículo",
    "add_document": "Añadir documento",
    "add_document_type": "Añadir tipo de documento",
    "add_team": "Añadir equipo",
    "add_brand": "Añadir marca",
    "add_model": "Añadir modelo",
    "add_model_group": "Añadir grupo de modelos",
    "add_model_asset": "Añadir imagen del modelo",
    "add_category": "Añadir categoría",
    "add_attribute": "Añadir propiedad",
    "add_attribute_group": "Añadir grupo de atributos",
    "add_value": "Añadir valor",
    "add_variant": "Añadir variante",
    "add_lead": "Añadir plomo",
    "add_task": "Añadir tarea",
    "add_page": "Añadir página",
    "add_data_push": "Añadir push de datos",
    "add_white_label": "Añadir proyecto de marca blanca",
    "add_menu_element": "Añadir elemento de menú",
    "add_secondary_menu_element": "Añadir elemento de menú secundario",
    "add_cms_element": "Añadir elemento CMS",
    "add_lead_catcher": "Añadir captador de plomo",
    "add_white_label_plugin": "Añadir plugin de marca blanca",
    "add_lead_per_mail_rule": "Añadir regla de captación de clientes potenciales por correo electrónico",
    "add_message": "Añadir un mensaje",
    "add_car_service": "Añadir servicio",
    "add_car_service_station": "Añadir puesto de trabajo",
    "add_team_member": "Añadir miembro del equipo",
    "add_document_template": "Añadir plantilla de documento",
    "add_lead_autoresponse": "Añadir autorespuesta de clientes potenciales",
    "add_main_menu_version": "Añadir versión del menú principal",
    "add_lead_tagger": "Añadir etiquetador principal",
    "add_lead_assignment_strategy": "Añadir estrategia de asignación",
    "add_escalation_rule": "Añadir regla de escalada",
    "add_coupon": "Añadir vale",
    "add_company": "Añadir empresa",
    "add_item": "Añadir producto",
    "add_manual_entry": "Añadir entrada manual",
    "add_allowed_domain": "Añadir dominio autorizado",
    "update_allowed_domain": "Actualizar dominio autorizado",
    "send_message": "Enviar mensaje",
    "send": "Enviar",
    "details": "detalles",
    "delete": "Borrar",
    "undelete": "Restaurar",
    "undelete_user_confirm": "¿Restaurar el usuario?",
    "or": "o",
    "actions": "Acciones",
    "client": "Empresa",
    "job": "Empleo",
    "active_jobs": "Empleos activos",
    "questionnaire": "Cuestionario",
    "review_and_send": "Comprobar y enviar",
    "download": "Descargar",
    "duplicate": "Duplicar",
    "client_name": "Nombre",
    "address": "Dirección",
    "title": "Título",
    "uploading": "Cargar",
    "status": "Estado",
    "next_stage": "Siguiente nivel",
    "type": "Tipo",
    "schedule": "Horario",
    "location": "Ubicación",
    "sent_on": "Enviado el",
    "name": "Nombre",
    "description": "Descripción de la",
    "bill_number": "Número de factura",
    "quote_number": "Número de oferta",
    "do_sort": "Ordenar",
    "date": "fecha",
    "gallery": "Galería",
    "questionnaires": "Cuestionarios",
    "job_takes_whole_day": "Este trabajo ocupa todo el día",
    "from": "En",
    "to": "A",
    "question": "Pregunta",
    "subject": "Tema",
    "content": "Contenido",
    "template": "Plantilla",
    "stage_has_due_date": "Esta etapa tiene una fecha de vencimiento",
    "stage_sends_automated_email": "Esta etapa envía un correo electrónico automático",
    "day": "Día",
    "days": "Días",
    "hours": "Horas",
    "review": "Consulte",
    "timezone": "Huso horario",
    "client_since": "Empresa añadida el",
    "user_since": "Usuario desde",
    "payment_instructions": "Instrucciones de pago",
    "bank_name": "Nombre del banco",
    "debtor_number": "Identificación del deudor",
    "account_holder": "Titular de la cuenta",
    "account_number": "Número de cuenta",
    "bank_location": "Ubicación del banco",
    "bank_code_number": "Código bancario",
    "iban": "IBAN",
    "swift_bic": "SWIFT/BIC",
    "company_logo": "Logotipo de la empresa",
    "percent_of_order": "Porcentaje del pedido",
    "percent_of_remaining_balance": "Porcentaje del importe restante",
    "amount": "Importe",
    "due": "Debido",
    "payment_schedule": "Plan de pago",
    "record_payment": "Registrar el pago",
    "mark_as_unpaid": "Marcar como no pagado",
    "close": "Cerrar",
    "yes": "Sí",
    "want_to_mark_the_payment_as_paid": "¿Realmente desea marcar este pago como pagado?",
    "want_to_mark_the_payment_as_unpaid": "¿Realmente desea marcar este pago como impagado?",
    "payment_marked_as_paid": "Pago registrado",
    "payment_marked_as_unpaid": "Pago marcado como impagado",
    "overdue_payments": "Pagos atrasados",
    "next_payment": "Próximo pago",
    "quote_accepted": "Oferta aceptada",
    "quote_rejected": "Oferta rechazada",
    "todays_tasks": "Tareas de hoy",
    "overdue_tasks": "Tareas atrasadas",
    "overdue_and_upcoming_payments": "Pagos atrasados y próximos pagos",
    "jobs_in_progress": "Trabajos en curso",
    "appointments": "Fechas",
    "appointment": "Fecha",
    "last_update": "Última actualización",
    "mark_as_completed": "Marcar como completado",
    "want_to_mark_stage_complete": "¿Desea marcar la etapa como finalizada?",
    "stage_marked_complete": "Etapa marcada como completada",
    "gallery_download": "Descargar imagen",
    "gallery_sharing": "Compartir galería",
    "gallery_social_media": "Imágenes en las redes sociales",
    "preview": "Vista previa",
    "invoice_preview": "Vista previa de la factura",
    "preview_pdf": "Vista previa PDF",
    "message": "Mensaje",
    "signup": "Regístrese en",
    "continue": "Continúe en",
    "password_assistance": "Ayuda con la contraseña",
    "password_renewing": "Nueva contraseña",
    "not_any_user_information": "No hay información del usuario.",
    "failed_redirection": "Error de reenvío.",
    "renew_password": "Antes de poder conectarte, debes establecer una nueva contraseña. Acabamos de enviarle un correo electrónico con instrucciones.",
    "get_new_password": "Solicitar nueva contraseña",
    "set_new_password": "Establezca su nueva contraseña",
    "forgot_password": {
      "infotext": "Si ha olvidado su contraseña, puede restablecerla aquí. Introduzca su dirección de correo electrónico registrada en el siguiente formulario y haga clic en \"Continuar\". Le enviaremos un correo electrónico con más información.",
      "infotext_robethood": "Si ha olvidado su contraseña, puede restablecerla aquí. Introduzca la dirección de correo electrónico registrada en el siguiente formulario y haga clic en \"Continuar\". A continuación le enviaremos un correo electrónico con más información.",
      "info_email_not_used": "Si ya no utiliza la dirección de correo electrónico asociada a su cuenta Creacheck, póngase en contacto con nosotros a través de nuestro formulario de contacto: {enlace}.",
      "info_email_not_used_robethood": "Si ya no utiliza la dirección de correo electrónico asociada a su cuenta, póngase en contacto con nosotros a través de nuestro formulario de contacto: {enlace}.",
      "enter_new_password": "Sólo te queda este paso para iniciar sesión en Creacheck. Simplemente introduce tu nueva contraseña dos veces. Así de sencillo."
    },
    "thanks_for_signing_up": "Gracias por inscribirse",
    "confirm_your_email": "Confirme su dirección de correo electrónico",
    "signed_up_gratulations": "Bienvenido a CreaCheck. Le hemos enviado un correo electrónico para completar el proceso de registro. Por favor, confirme su registro.",
    "signed_up_gratulations_robethood": "Bienvenido a nuestro sitio web. Le hemos enviado un correo electrónico para completar el proceso de registro. Por favor, confirme su registro.",
    "check_email_for_message_from_us": "Compruebe su buzón de correo electrónico.",
    "check_email_for_message_from_us_robethood": "Compruebe su buzón de correo electrónico",
    "new_clients": "Nuevos clientes",
    "users": "Usuarios",
    "user": "Usuarios",
    "won_from_count_leads": "Ganado de {count} pistas",
    "deals_won_out_of_count": "Acuerdos ganados por {count}",
    "new_badge": "Nuevo",
    "answer_badge": "Respuesta",
    "accept": "Acepte",
    "reject": "Rechazar",
    "quote_rejected_on_date": "Oferta rechazada el {fecha}",
    "quote_accepted_on_date": "Oferta aceptada el {fecha}",
    "want_to_accept_the_quote": "¿Desea aceptar la oferta? El contratista será informado directamente de su decisión.",
    "want_to_reject_the_quote": "¿Desea rechazar la oferta? El contratista será informado directamente de su decisión.",
    "want_to_convert_quote_to_invoice": "¿Desea convertir esta oferta en una factura?",
    "convert_quote_to_invoice": "Convertir la oferta en factura",
    "convert_to_invoice": "Convertir en factura",
    "convert_quote_from_template": "Convertir la oferta en otra plantilla",
    "completed_on_date": "Finalizado el {fecha}",
    "due_date_on_date": "Fecha de vencimiento {fecha}",
    "due_date": "Fecha de vencimiento",
    "current_stages_due_date": "Fecha de vencimiento de la etapa actual",
    "go_pro": "Go Pro",
    "recent_activity": "Última actividad",
    "load_more": "Más información",
    "your_galleries": "Sus galerías",
    "unread_by_you": "No leído por usted",
    "count_images": "{contar} imágenes",
    "view": "Ver",
    "filled_out_on": "Completado en",
    "filled_out_by": "Rellenado por",
    "you_answered_the_questionnaire_on_date": "Respondió al cuestionario el {fecha}",
    "company_sent_a_questionnaire": "{empresa} ha enviado un cuestionario y pide respuestas",
    "allow_to_download_full_res": "Permitir al cliente descargar imágenes a resolución completa",
    "allow_to_share_with_friends": "Permitir al cliente compartir la galería con sus amigos",
    "allow_to_share_on_social_media": "Permitir al cliente compartir imágenes a través de las redes sociales",
    "got_it": "OK",
    "learn_more": "Más información",
    "this_website_uses_cookies": "Nuestro sitio web utiliza cookies para garantizarle la mejor experiencia en nuestro sitio web.",
    "are_you_sure": "¿Seguro?",
    "no": "No",
    "closed_please_add_timeframe": "<span><span className='text-danger semi-bold'>Cerrado</span> - por favor, añada un plazo</span>",
    "not_active_please_add_timeframe": "<span><span className='text-danger semi-bold'>No activo</span> - por favor, añada un plazo</span>",
    "note": "Nota",
    "change_password": "Cambiar contraseña",
    "roles": "Funciones y derechos",
    "active_set": "Suscripción actual",
    "infos": "Información sobre",
    "documents": "Documentos",
    "document": "Documento",
    "financial": "Finanzas",
    "bookingAccountNumber": "Cuenta de contabilización",
    "costAccountNumber": "Centro de costes",
    "locations": "Ubicaciones",
    "white_label": "Marca blanca",
    "show_password_text": "Mostrar texto de contraseña",
    "parent": "Padres",
    "change_parent": "Cambiar padre",
    "cancel": "Cancelar",
    "timeline_undo": "Deshacer",
    "confirm_action_ramifications": "Por favor, confirme que comprende las implicaciones de su acción",
    "client_will_not_be_visible_in_search_results": "El cliente seleccionado ya no está visible en su búsqueda de clientes",
    "changing_parent_takes_a_while": "Cambiar el precursor puede llevar un tiempo",
    "load_message_from_template": "Cargar mensaje desde la plantilla",
    "questions": "Preguntas",
    "search": "Buscar en",
    "search_for": "Buscar",
    "dynamic_data_definitions": "Definiciones dinámicas de datos",
    "dynamic_data_definition": "Definición dinámica de datos",
    "data_identifier": "Identificador de datos",
    "cars": "Vehículos",
    "rentals": "Alquiler",
    "no_car_available": "No hay carrito disponible",
    "file": "Archivo",
    "files": "Archivos",
    "teams": "Equipos",
    "team": "Equipo",
    "document_types": "Tipos de documentos",
    "document_type": "Tipo de documento",
    "system_type": "Tipo de sistema",
    "brands": "Marcas",
    "models": "Modelos",
    "model": "Modelo",
    "model_groups": "Grupos de modelos",
    "model_group": "Grupo de modelos",
    "model_assets": "Imágenes de modelos",
    "model_asset": "Modelo de imagen",
    "categories": "Categorías",
    "attributes": "Atributos",
    "attribute": "Atributo",
    "values": "Valores",
    "value": "Valor",
    "select_category": "Seleccionar categoría",
    "position": "Posición",
    "color": "Color",
    "car": "Producto",
    "category": "Categoría",
    "brand": "Marca",
    "required": "Campo obligatorio",
    "multilanguage": "Varios idiomas",
    "seo": "SEO",
    "page_title": "Título de la página",
    "keywords": "Palabras clave",
    "item": "Artículo",
    "sku": "SKU",
    "variants": "Variantes",
    "variant": "Variante",
    "price": "Precio",
    "prices": "Precios",
    "variant_attributes": "Atributos de las variantes",
    "no_value_set": "Ningún valor establecido",
    "identification": "Identificación",
    "templates": "Plantillas",
    "vin": "FIN",
    "leads": "Plomos",
    "lead": "Plomo",
    "order_it": "Pida",
    "order": "pedir",
    "orders": "Pedidos",
    "images": "Fotos",
    "image": "Fotografía",
    "upc": "UPC",
    "visibility": "Visibilidad",
    "show_item_online": "Producto visible en línea",
    "use_as_resource": "Utilización como recurso",
    "stock_and_prices": "Inventario y precios",
    "sales_price": "Precio de venta",
    "sales_point": "Distribución",
    "warehouse": "Almacén",
    "stock_level": "Stock",
    "rent_price": "Precio del alquiler",
    "pseudo_price": "Pseudoprecio",
    "express_price": "Precio exprés",
    "overnight_price": "Precio por noche",
    "sameday_price": "Precio de un día",
    "basic_price": "Precio base",
    "weekday_price": "Precio entre semana",
    "weekend_price": "Precio fin de semana",
    "week_price": "Precio semanal",
    "month_price": "Precio mensual",
    "unit_of_measure": "Unidad de medida",
    "basic_unit": "Unidad básica",
    "packaging_unit": "Unidad de envasado",
    "subscribe": "Suscríbase a",
    "all": "Todos",
    "pipeline": "Tuberías",
    "pipelines": "Tuberías",
    "default_team": "Equipo estándar",
    "select_pipeline": "Seleccione una tubería",
    "please_select_pipeline_first": "Seleccione primero una tubería",
    "view_type": "Ver",
    "stage": "Nivel",
    "stages": "Pasos",
    "properties": "Propiedades",
    "unassigned": "Sin asignación",
    "assignee": "Procesador",
    "order_type": "Tipo de pedido",
    "internal_comment": "Comentarios internos",
    "comments": "Comentarios",
    "comment": "Comentario",
    "no_comments_yet": "Aún no hay comentarios",
    "show_all_comments": "Mostrar todos los comentarios",
    "people": "Personas",
    "watchers": "Observador",
    "dates": "Fechas",
    "date_created": "Creado",
    "date_updated": "Actualizado",
    "closed": "Cerrado",
    "start_watching_lead": "Empieza a ver esta pista",
    "stop_watching_lead": "Deja de ver este plomo",
    "no_longer_watching_lead": "Ya no observa esta pista",
    "watching_lead": "Usted observa esta pista",
    "wrote_comment": "Ha escrito un comentario",
    "replied_to_comment": "Respuesta a un comentario",
    "language": "Idioma",
    "languages": "Idiomas",
    "primary_language": "Lengua principal",
    "secondary_languages": "Lenguas secundarias",
    "won": "Won",
    "lost": "Perdido",
    "change_pipeline": "Cambiar la tubería",
    "start_pipeline": "Iniciar canalización",
    "pipeline_started": "Lanzamiento del oleoducto",
    "change_stage": "Nivel de cambio",
    "switch_team": "Equipo de cambio",
    "open": "Abrir",
    "budget": "Presupuesto",
    "always_available": "Siempre disponible",
    "default_location": "Ubicación estándar",
    "selected_category_has_no_attributes": "La categoría seleccionada no tiene atributos",
    "car_overview": "Vista general del vehículo",
    "make_reservation": "Hacer una reserva",
    "reservation": "Reserva",
    "cms_pages": "Páginas",
    "grid_fluid": "Líquido",
    "grid_normal": "Normal",
    "cms_add_container": "Añadir contenedor",
    "cms_add_row": "Añadir línea",
    "cms_add_element": "Añadir elemento",
    "media_files": "Archivos multimedia",
    "upload": "Cargar",
    "page_design": "Diseño de página",
    "translations": "Traducciones",
    "translate": "Traducir",
    "category_filters": "Filtro de categoría",
    "attribute_groups": "Grupos de atributos",
    "select_attribute": "Seleccionar atributo",
    "display": "Mostrar",
    "select_multiple": "Selección múltiple",
    "use_null_as_min_val": "Fijar 0 como mínimo",
    "filter": "Filtros",
    "do_filter": "Filtro",
    "mobile_phone": "Teléfono móvil",
    "mark_lead_won": "Marcar pista como ganada",
    "mark_lead_lost": "Marcar pista como perdida",
    "mark_lead_deleted": "Marcar pista como eliminada",
    "reopen_lead": "Reabrir pista",
    "won_reason": "Motivo por el que ganó",
    "lost_reason": "Motivo de la pérdida",
    "delete_reason": "Motivo de la eliminación",
    "reopen_reason": "Motivo de la reapertura",
    "no_reason_specified": "No se ha dado ninguna razón",
    "lead_marked_won": "Ha ganado el plomo marcado. Enhorabuena",
    "lead_marked_lost": "Plomo marcado perdido",
    "lead_marked_deleted": "Plomo marcado como eliminado",
    "lead_reopened": "Plomo reabierto",
    "stage_changed": "El nivel de plomo ha cambiado",
    "pipeline_changed": "Cambios en la canalización de clientes potenciales",
    "team_changed": "Líder asignado a otro equipo",
    "current_pipeline": "Proyecto actual",
    "current_stage": "Nivel actual",
    "file_extension": "Extensión",
    "file_size": "Talla",
    "file_uploaded": "Subido a",
    "no_client_assigned": "Ningún cliente asignado",
    "no_products_assigned": "No hay productos asignados",
    "no_order_assigned": "Sin orden asignado",
    "planned_duration_in_days": "Duración prevista (días)",
    "lead_budget_must_be_set": "Debe establecerse un presupuesto líder",
    "client_must_be_assigned": "Se debe asignar al cliente",
    "order_must_be_assigned": "Debe asignarse un pedido",
    "product_must_be_assigned": "Debe asignarse un producto",
    "lead_has_to_have_following_documents": "El plomo debe tener los siguientes documentos (los documentos deben ser válidos)",
    "client_has_to_have_following_documents": "El cliente debe disponer de los siguientes documentos (los documentos deben ser válidos)",
    "count_days": "{cuenta, plural, un {# día} otros {# días}}",
    "longer": "Más largo",
    "date_from": "Fecha de",
    "time_from": "Tiempo de",
    "date_to": "Fecha hasta",
    "time_to": "Tiempo hasta",
    "rate": "Tarifa",
    "car_rental_weekday": "Alquiler - Tarifa por día de la semana",
    "car_rental_weekend": "Alquiler - Tarifa fin de semana",
    "car_rental_week": "Alquiler - cuota semanal",
    "car_rental_month": "Alquiler - Cuota mensual",
    "document_overdue_please_review": "Documento atrasado. Por favor, compruébelo",
    "count_cars": "{contar, plural, un {# vehículo} otros {# vehículos}}",
    "count_products": "{contar, plural, un {# producto} otros {# productos}}",
    "we_found_count_cars": "Hemos encontrado {contar, plural, un {# vehículo} otros {# vehículos}}",
    "we_found_count_products": "Hemos encontrado {contar, plural, un {# producto} otros {# productos}}",
    "delete_all_filters": "Borrar todos los filtros",
    "image_recognition_tags": "Etiquetas reconocidas a partir de la imagen",
    "username": "Nombre de usuario",
    "handover_must_be_performed": "Debe completarse el protocolo de entrega de vehículos",
    "document_pages": "Páginas del documento",
    "pages": "Páginas",
    "handover": "Entrega",
    "mileage": "Kilometraje",
    "client_documents": "Documentos del cliente",
    "lead_documents": "Documentos principales",
    "fuel_level": "Nivel de combustible",
    "start_editing": "Empezar a editar",
    "design": "Diseño",
    "responsive": "Respuesta",
    "back": "Volver",
    "use_text_description": "Utilizar descripción de texto",
    "use_visual_builder": "Utilizar el constructor visual",
    "set_image": "Establecer imagen",
    "select_image": "Seleccionar imagen",
    "uploaded_by_me": "Subido por mí",
    "current_item": "Artículo actual",
    "current_page": "Página actual",
    "difficulties": "Dificultades",
    "damages": "Daños",
    "damage_note": "Notificación de daños",
    "no_damages_in_protocol": "No hay daños en el tronco",
    "add_damage": "Añadir daños",
    "my_company": "Mi empresa",
    "user_administration": "Administración de usuarios",
    "client_administration": "Gestión de contactos",
    "crm_administraion": "Gestión CRM",
    "content_administration": "Gestión de contenidos",
    "product_administration": "Gestión de productos",
    "producttemplate_administration": "Gestión de plantillas",
    "car_service_administration": "Gestión de talleres",
    "site_administration": "Administración del sitio web",
    "data_push": "Introducción de datos",
    "client_has_no_ancestors": "El cliente no tiene antepasados",
    "domain": "Dominio",
    "entity": "Entidad",
    "event": "Evento",
    "endpoint_url": "URL del punto final",
    "authentication": "Autenticación",
    "headers": "Cabeceras",
    "none": "Ninguno",
    "social_media": "Redes sociales",
    "style": "Estilo",
    "html_header": "Cabecera HTML",
    "custom_html_header": "Encabezado HTML personalizado",
    "css_file_name": "Nombre de archivo CSS definido por el usuario",
    "custom_css": "CSS personalizado",
    "custom_less": "LESS definido por el usuario",
    "custom_font_folder": "Seleccione el directorio de fuentes",
    "custom_font": "Cargar fuente personalizada",
    "custom_css_mail": "CSS personalizado para correos electrónicos",
    "main_menu": "Menú principal",
    "menu_css_class": "Clase CSS del menú principal",
    "menu_css_class_scroll": "Clase CSS del menú principal al desplazarse",
    "white_label_projects": "Proyectos de marca blanca",
    "imprint": "Pie de imprenta",
    "terms_of_service": "GTC",
    "rental_terms_of_service": "Condiciones generales de alquiler",
    "car_sales_terms_of_service": "Condiciones generales de venta de vehículos usados",
    "item_sales_terms_of_service": "Condiciones generales de venta Productos",
    "privacy_policy": "Protección de datos",
    "cancellation_policy": "Política de anulación",
    "footer": "Pie de página",
    "hide_footer": "Ocultar pie de página",
    "unknown_property": "Parámetro desconocido",
    "unknown": "desconocido",
    "price_from": "de",
    "price_to": "hasta",
    "slider_from": "de",
    "slider_to": "hasta",
    "within": "en",
    "of": "de",
    "within_count_km_of_place": "a {contar} km de {lugar}",
    "click_to_start_geolocation": "Haga clic aquí para iniciar la geolocalización",
    "geolocation_error": "Hemos tenido un problema al reconocer su posición o su posición no es conocida. Por favor, introduzca su posición manualmente.",
    "count_km_from": "km de",
    "car_power": "Rendimiento",
    "car_power_unit": "PS",
    "car_gear": "Caja de cambios",
    "car_capacity": "Desplazamiento",
    "car_capacity_unit": "cm³",
    "car_fueltype": "Tipo de combustible",
    "car_type": "Tipo de vehículo",
    "car_num_previous_owners": "Número de propietarios de vehículos",
    "car_num_doors": "Número de puertas",
    "car_num_seats": "Número de plazas",
    "car_emission_class": "Clase de contaminante",
    "car_condition": "Estado del vehículo",
    "car_construction_year": "Año de construcción",
    "car_color_manufacturer": "Color (fabricante)",
    "car_parking_assistance": "Ayuda al aparcamiento",
    "car_interior_material": "Material del equipamiento interior",
    "car_airbags": "Airbags",
    "car_air_conditioning": "Aire acondicionado",
    "car_environment_badge": "Distintivo medioambiental",
    "car_co2_emissions": "Emisiones de CO2",
    "car_co2_emissions_unit": "g/km",
    "car_fuel_consumption": "Consumo",
    "car_fuel_consumption_unit": "l/100km",
    "combined": "combinado",
    "combined_short": "peine.",
    "urban": "urbano",
    "extra_urban": "fuera de la ciudad",
    "energy_efficiency_class": "Clase de eficiencia energética",
    "technical_data": "Datos técnicos",
    "first_registration": "Primera inscripción",
    "proceed_to_checkout": "Pedido",
    "home": "Página de inicio",
    "checkout": "Caja",
    "your_cart": "Su carrito de la compra",
    "your_car": "Tu coche",
    "your_data": "Sus datos",
    "services": "Servicio",
    "shipping_information": "Transferencia",
    "payment_details": "Pago",
    "summary": "Resumen",
    "find_out_more": "Más información",
    "qty": "Cantidad",
    "checkout_next": "Más información en",
    "checkout_previous": "Volver",
    "please_select": "Seleccione una opción",
    "enum_many_glue": ",",
    "enum_last_glue": " y",
    "enum_end": " seleccione",
    "show_details": "Mostrar detalles",
    "add_to_cart": "Añadir a la cesta",
    "show_cart": "Mostrar cesta de la compra",
    "cart": "Cesta de la compra",
    "missingQuantity": {
      "cart": "cesta de la compra"
    },
    "cart_is_empty": "La cesta de la compra está vacía",
    "item_already_in_cart": "Este artículo ya está en su cesta de la compra",
    "remove": "Borrar",
    "removed_from_cart": "se ha eliminado de la cesta de la compra",
    "continue_with_login": "Continuar con el inicio de sesión",
    "dont_have_an_account": "Aún no hay cuenta",
    "continue_without_login": "Continuar sin iniciar sesión",
    "already_have_an_account_enter_username_and_password": "¿Ya tiene una cuenta? Introduzca su nombre de usuario y contraseña en el siguiente formulario.",
    "continue_without_login_and_register": "Puede continuar el proceso de compra y crear su cuenta aquí.",
    "continue_booking_without_login_and_register": "Puede continuar el proceso de reserva y crear su cuenta aquí",
    "online_discount": "Descuento en línea",
    "your_personal_data": "Sus datos personales",
    "login_data": "Datos de acceso",
    "shipping": "Envío",
    "payment_cash": "Efectivo",
    "payment_creditCard": "Tarjeta de crédito",
    "payment_payPal": "PayPal",
    "payment_wire_transfer": "Transferencia bancaria",
    "payment_bill": "Factura",
    "choose_different_payment_method": "Seleccione otro método de pago",
    "i_will_pick_up_my_car": "Recogeré mi coche",
    "i_will_pick_up_my_order": "Recogeré mi pedido",
    "i_want_my_car_delivered": "Me gustaría que me entregaran mi vehículo.",
    "i_want_my_order_delivered": "Deseo recibir mi pedido",
    "we_could_not_calculate_route_to_address": "No hemos podido calcular una ruta a la dirección especificada. Por favor, compruébelo e inténtelo de nuevo.",
    "credit_card_holder": "Titular de tarjeta de crédito",
    "credit_card_number": "Número de tarjeta de crédito",
    "car_registration": "Matriculación de vehículos",
    "car_insurance": "Seguro de coche",
    "service_provided_in_country_by_company": "Servicio en {país} prestado por {empresa}",
    "i_wish_to_have_car_registered": "Sí, deseo utilizar el servicio de autorización",
    "i_wish_to_get_car_insurance": "Me gustaría contratar un seguro de coche",
    "today": "Hoy",
    "in_10_days": "En 10 días",
    "your_money_is_safe_with_us": "Su dinero está seguro con nosotros",
    "car_transport": "Transporte de vehículos",
    "delivery": "entrega",
    "decide_car_delivery_or_pickup": "Tú decides si quieres recoger el coche o que te lo entreguemos. En cualquier caso, el coche estará en perfecto estado, limpio y con el depósito lleno.",
    "secured_payment_until_car_is_delivered": "Proceso de pago seguro hasta la entrega del vehículo",
    "e_payment_to_reserve_car_and_secure_services": "Pago electrónico para reservar el coche y asegurar los servicios seleccionados",
    "e_payment_credit_card_paypal": "Pago electrónico (tarjeta de crédito o PayPal)",
    "multiple_secure_options_to_pay_for_the_car": "Opciones de pago seguras para el coche (transferencia bancaria, contra reembolso)",
    "buy_car_by_clicking_button": "Usted puede comprar este coche ahora haciendo clic en el botón de abajo",
    "payment_with_credit_card_ending_last4_expiring_exp_month_exp_year_issued_to_card_holder": "Pago con tarjeta de crédito que termina en {last4} y vence en {expMonth}/{expYear}, emitido a {cardHolder}.",
    "click_on_paypal_checkout_to_continue": "Haga clic en el botón Pago con Paypal para continuar.",
    "paypal_is_approved_continue_to_summary": "Su pago PayPal ha sido autorizado. Por favor, continúe con el resumen para completar el pedido y autorizar el pago.",
    "paypal_buttons_loading": "Cargar botones de PayPal...",
    "please_wait_saving_order": "Guarde el pedido. Por favor, espere...",
    "error_loading_paypal_scripts": "No se han podido cargar las secuencias de comandos de PayPal. Cierre esta ventana emergente y vuelva a abrirla.",
    "link": "Enlace",
    "hide_on_devices": "Ocultar en dispositivos",
    "mobile_phones": "Teléfonos móviles",
    "tablets": "Tabletas",
    "desktop_pcs": "Ordenadores de sobremesa",
    "show_content_on_mouse_over": "Mostrar contenido al pasar el ratón por encima",
    "menu_content": "Contenido del menú",
    "link_to_page": "Enlace a la página",
    "page_has_no_translations": "Esta página no tiene traducciones",
    "search_results": "Resultados de la búsqueda",
    "press_enter_to_search": "Pulse la tecla intro para buscar",
    "no_results_found": "No se han encontrado resultados",
    "create_new_entry_now": "Ahora puede crear una nueva entrada:",
    "no_results_for_your_search": "No hemos encontrado resultados para su búsqueda",
    "change_image": "Cambiar imagen",
    "employee_data": "Datos de los empleados",
    "newsletter": "Autorización para recibir boletines",
    "editorAutosave": "Guardado automático en el editor",
    "is_profile_public": "¿Es público el perfil?",
    "parent_page": "Página de los padres",
    "cms_elements": "Elementos CMS",
    "cms_element": "Elemento CMS",
    "new_lead_from_website": "Nueva pista del sitio web",
    "website_settings": "Configuración del sitio web",
    "profile": "Perfil",
    "my_bills": "Mis facturas",
    "integrations": "Integraciones",
    "email_templates": "Plantillas de correo electrónico",
    "cms_templates": "Plantillas CMS",
    "cms_template": "Plantilla CMS",
    "select_brand": "Seleccionar marca",
    "all_brands_visible": "Todas las marcas son visibles",
    "contact_us": "Póngase en contacto con nosotros",
    "thank_you": "Muchas Gracias",
    "thank_you_for_contacting_us": "Gracias por ponerse en contacto con nosotros. Hemos recibido su consulta y nos pondremos en contacto con usted lo antes posible.",
    "cms_standard": "Estándar",
    "select_element": "Seleccionar elemento CMS",
    "divider": "Divisor",
    "testimonial": "Recomendación",
    "hero_unit": "Elemento héroe",
    "image_carousel": "Deslizador",
    "call_to_action": "Llamada a la acción",
    "bar_counter": "Mostrador de bar",
    "products": "Productos",
    "producttemplates": "Plantillas",
    "single_product": "Producto único",
    "single_producttemplate": "Plantilla única",
    "lead_form": "Formulario",
    "image_carousel_element": "Imagen deslizante",
    "cms_grid": "Contenedor",
    "cms_row": "Línea",
    "cms_col": "Columna",
    "text_color": "Color del texto",
    "bar_counter_now": "Ahora",
    "shadow": "Sombra",
    "no_shadow": "Sin sombra",
    "small": "Pequeño",
    "big": "Grande",
    "rounded_corners": "Esquinas redondeadas",
    "no_rounded_corners": "Sin esquinas redondeadas",
    "image_position": "Posición de la imagen",
    "icon": "Icono",
    "icon_class": "Clase de icono (CSS)",
    "position_top": "Por encima de",
    "position_left": "Enlaces",
    "bar_color": "Color de la barra",
    "bar_background_color": "Color de fondo de la barra",
    "button_icon": "Símbolo del botón",
    "button_text": "Texto del botón",
    "full_height": "Altura total",
    "padding_top": "Distancia superior",
    "padding_bottom": "Distancia inferior",
    "lead_source": "Fuente principal",
    "change_item": "Cambiar artículo",
    "show_infos_on_top": "Mostrar info arriba",
    "show_infos_on_bottom": "Más información",
    "condensed": "Condensado",
    "assign_item": "Asignar producto",
    "image_animation": "Animación de imágenes",
    "no_animation": "Sin animación",
    "fade_in": "Fundido de entrada",
    "from_left": "Desde la izquierda",
    "from_right": "Desde la derecha",
    "from_top": "Desde arriba",
    "from_bottom": "Desde abajo",
    "image_gallery": "Galería de imágenes",
    "elements": "Elementos",
    "accordion": "Acordeón",
    "map": "Mapa",
    "height": "Altura",
    "video": "Vídeo",
    "link_to_video": "Enlace al vídeo",
    "video_possible_sources": "Se admiten vídeos de los siguientes portales:: YouTube, Vimeo, Dailymotion",
    "autoplay": "Reproducción automática",
    "aspect_ratio": "Relación de aspecto",
    "image_compare": "Comparar imágenes",
    "second_image": "Segunda foto",
    "login_form": "Formulario de acceso",
    "button_signin": "Conectarse",
    "alternative_login": "...o inicia sesión con:",
    "alternative_login_long": "Utilice nuestra función de inicio de sesión social",
    "login_with": "Iniciar sesión con",
    "show_facebook_login": "Mostrar inicio de sesión de Facebook",
    "show_google_login": "Mostrar inicio de sesión de Google",
    "signup_form": "Formulario de inscripción",
    "signup_legal_explanation": "Registro - declaración legal",
    "signup_agreement_text": "Al registrarme, acepto las {condiciones}.",
    "contact_agreement_text": "He leído y comprendido la {utilización_de_datos}.",
    "signup_agreement_text_terms": "Condiciones generales",
    "signup_agreement_text_data_use": "Política de privacidad",
    "signup_agreement_text_cancellation_policy_and_form": "Política de cancelación y formulario de cancelación",
    "will_become_link_with_title_title": "un enlace con el título: {title}",
    "show_only_when_user_logged_id": "Sólo se muestra si el usuario ha iniciado sesión",
    "car_rental_form": "Formulario de alquiler",
    "car_search_form": "Formulario de búsqueda de coches",
    "car_search_funnel": "Configurador de búsqueda de coches",
    "background_image": "Imagen de fondo",
    "background_color": "Color de fondo",
    "same_col_height": "Misma altura de columna",
    "pick_up_and_drop_off": "Recogida y devolución",
    "pick_up_time": "Hora de recogida",
    "drop_off_time": "Hora de regreso",
    "get_a_quote": "Determinar los precios",
    "select_location": "Seleccione una ubicación",
    "type_address_to_start_search": "Introduzca una dirección para iniciar la búsqueda",
    "menu_version": "Versión del menú",
    "menu_transparent": "Transparente",
    "menu_dark": "Oscuro",
    "menu_light": "Luz",
    "logo_version": "Versión del logotipo",
    "logo_default": "Utilizar el logotipo estándar",
    "logo_scroll": "Utilizar el logotipo de desplazamiento",
    "select_a_pick_up_location": "Seleccione primero el lugar de recogida",
    "could_not_find_location_please_enter_more_details": "No hemos podido encontrar una ubicación. Por favor, introduzca su lugar de recogida con más detalles",
    "client_not_found": "Cliente no encontrado",
    "order_will_directly_be_marked_paid": "El pedido se marca inmediatamente como pagado",
    "invoice_will_directly_be_marked_paid": "La factura se marca inmediatamente como pagada",
    "rental_period": "Periodo de alquiler",
    "change_booking_dates": "Modificar los datos de la reserva",
    "per_day": "por día",
    "buy_now": "Comprar ahora",
    "book_now": "Reserve ahora",
    "rent_car_now": "Alquilar ahora",
    "commit_to_buy": "Orden con obligación de pago",
    "back_to_cart": "Volver a la cesta de la compra",
    "equipment": "Equipamiento",
    "technical_specifications": "Datos técnicos",
    "cancel_checkout_question": "¿Cancelar la compra?",
    "how_it_works": "Cómo funciona",
    "secure_payment": "Pago seguro",
    "top_services": "Servicios principales",
    "if_you_buy_this_car_you_will_get_lots_of_services": "Cuando compres este coche, no tengas miedo al papeleo. Podemos organizar la matriculación, el seguro y el préstamo del coche si lo deseas. Solo tiene que seleccionar el servicio que desee al realizar la compra.",
    "need_more_info_question": "¿Necesita más información?",
    "contact_dealer": "Contactar con el distribuidor",
    "move_around_to_see_changes": "Muévase para ver los cambios",
    "lead_catchers": "Captadores de plomo",
    "lead_catcher": "Receptor principal",
    "advanced_routing": "Enrutamiento avanzado",
    "add_route": "Añadir ruta",
    "source": "Fuente",
    "can_be_ordered_online": "Se puede pedir por Internet",
    "count_years_warranty": "{contar} {contar, plural, un {año} otros {años}} Garantía",
    "amount_online_advantage": "{importe} Ventaja online",
    "your_order": "Su pedido",
    "file_name": "Nombre del fichero",
    "tags": "Etiquetas",
    "save_and_set_image": "Guardar y configurar",
    "choose_or_drop_file_to_upload": "Seleccione o arrastre un archivo sobre la página de carga.",
    "media_formats_supported": "Se admiten JPG y PNG.",
    "max_file_upload_size": "El tamaño máximo del archivo es de 15 MB",
    "no_files_found": "No se han encontrado archivos.",
    "upload_file_by_dragging_over": "Cargar un archivo simplemente arrastrándolo sobre la página",
    "your_car_is_ready_to_check_out": "El coche de tus sueños está listo",
    "choosing_one_of_our_cars_is_a_good_decision": "Sólo unos pasos más hacia el vehículo de sus sueños. Benefíciese de nuestras ventajas online.",
    "your_car_will_be_delivered_to_you": "Su vehículo se entrega en el lugar que desee",
    "your_will_get_a_amount_discount_for_ordering_online": "Para los pedidos en línea recibirá un descuento de {importe}.",
    "you_will_receive_extended_warranty": "Recibe una garantía ampliada",
    "you_will_get_exclusive_offers_when_using_our_app": "Recibe ofertas exclusivas al utilizar nuestra aplicación móvil",
    "you_dont_risk_when_ordering_online": "No arriesgas mucho cuando haces un pedido por Internet.",
    "your_car_rent_is_nearly_done": "Su vehículo de alquiler está casi listo.",
    "please_continue_with_booking_and_payment": "Por favor, proceda con la reserva y el pago. Nos aseguraremos de que su coche esté listo en la fecha de recogida seleccionada. La reserva online le ahorra tiempo.",
    "please_provide_address_delivery_method_payment": "Introduzca su dirección, seleccione el método de envío y los datos de pago.",
    "your_information_is_safe_with_us": "Su información está segura con nosotros.",
    "we_offer_parcel_shipment_for_just_amount": "Para su comodidad, ofrecemos la entrega de un paquete para todos nuestros pedidos por sólo {importe}.",
    "free_delivery_on_all_our_orders": "Ofrecemos entrega gratuita en todos nuestros pedidos.",
    "get_your_car_delivered": "entrega",
    "cant_pick_your_car_yourself_we_got_you_covered": "¿Qué ocurre si encuentra el coche de sus sueños y no puede recogerlo usted mismo? No se preocupe. Tenemos una solución. Una vez que su coche esté matriculado y asegurado, un conductor concertará una cita de entrega con usted y aparcará su coche en su entrada.",
    "we_are_more_than_happy_to_show_you_around_your_new_car": "Por supuesto, estamos deseando recibirte en persona y enseñarte tu nuevo coche.",
    "we_can_complete_your_order_in_shop_for_pickup": "Pero, si lo desea, podemos completar su pedido en nuestra tienda y puede recogerlo cuando tenga tiempo.",
    "we_will_inform_you_when_we_complete_order_for_pickup": "Le informaremos cuando hayamos completado su pedido. Después esperaremos pacientemente a que lo recojas.",
    "we_will_ship_your_order_to_provided_address": "Enviaremos su pedido con DHL a la dirección que nos indique. Le informaremos cuando el pedido haya sido enviado. También recibirá un número de seguimiento del pedido.",
    "we_calculated_route_from_car_to_you": "Hemos calculado una ruta desde donde se encuentra el coche hasta la dirección que nos has facilitado. Parece que nuestro conductor tiene que recorrer {distancia}km para llevar el coche hasta la puerta de tu casa.",
    "service_includes": "El servicio incluye:",
    "driver_will_call_you_and_schedule_delivery": "Nuestro conductor te llamará y te entregará el coche cuando mejor te venga",
    "your_car_will_be_washed": "Su coche está siendo lavado",
    "your_car_will_be_fully_tanked": "Su coche se llena de gasolina",
    "you_will_get_quick_tour_around_car": "Te dan una pequeña vuelta por el coche",
    "you_can_pick_up_car_yourself": "¿Tiene alguna pregunta? Nuestro equipo estará encantado de ayudarle.",
    "only_one_step_left": "¡Un paso más!",
    "check_if_everything_is_correct_and_provide_payment_data": "Compruebe que todo es correcto, facilítenos sus datos de pago y envíe el pedido.",
    "terms_of_contract_for_additional_services": "Condiciones contractuales para servicios adicionales",
    "terms_of_use_marketplace": "Condiciones de uso del mercado",
    "for_the_sales_of_used_vehicles": "Venta de vehículos usados",
    "for_the_rental_of_vehicles": "Alquiler de vehículos",
    "your_payment_schedule": "Ejecución del pago",
    "shipping_pickup": "Colección",
    "shipping_parcel": "Paquete",
    "shipping_transport": "Transporte",
    "car_interior": "Equipamiento interior",
    "car_exterior": "Equipamiento exterior",
    "we_are_processing_your_order_please_wait": "Estamos procesando su pedido. Por favor, espere...",
    "we_are_processing_your_booking_please_wait": "Estamos procesando su reserva. Por favor, espere...",
    "your_order_was_completed_successfully": "Su pedido se ha realizado correctamente.",
    "we_created_a_user_profile": "Hemos creado un perfil de usuario para usted",
    "check_your_email": "Compruebe su bandeja de entrada",
    "your_orders": "{contar, plural, uno {su número de pedido} otros {sus números de pedido}}:",
    "first_experience_with_our_shop_saved_profile": "Parece que esta es su primera experiencia con nuestra tienda online. Hemos guardado correctamente la información de su perfil en nuestra base de datos. Para continuar con la tramitación de su pedido, compruebe su buzón y confirme su dirección de correo electrónico.",
    "order_details_sent_to_email_check_history_for_details": "Todos los detalles del pedido se han enviado a su dirección de correo electrónico. Puede consultar el historial y el estado de su pedido en cualquier momento en su cuenta de usuario.",
    "timeline": "Cronología",
    "promotions": "Acciones",
    "payment_method": "Forma de pago",
    "first_payment": "1. pago",
    "second_payment": "2. pago",
    "shipping_method": "Forma de envío",
    "payment_1_e_payment_to_secure_your_order": "Deposite mediante pago electrónico para reservar su vehículo.",
    "payment_2_choose_payment_method_for_the_car": "Seleccione una forma de pago para su vehículo.",
    "payment_escrow": "Cuenta bloqueada",
    "payment_car_credit": "Financiación",
    "full_refund": "Reembolso íntegro",
    "partial_refund": "Reembolso parcial",
    "cancel_order": "Cancelar pedido",
    "create_shipping": "Crear envío",
    "schedule_car_registration": "Autorización",
    "schedule_transport": "Plan de transporte",
    "is_published": "Se publica",
    "no_salesman_available": "Actualmente no hay ningún vendedor disponible",
    "any_questions": "¿Preguntas?",
    "feel_free_to_ask_our_online_sales_team": "Póngase en contacto con nuestro equipo de ventas en línea",
    "please_complete_your_details": "Complete sus datos",
    "registration_is_stressfull_get_your_car_registered": "Para algunas personas, matricular un coche es estresante y lleva mucho tiempo. Matricule su coche a su nombre sin complicaciones.",
    "review_your_order": "Compruebe su pedido",
    "in_order_to_confirm_identity_please_complete_your_details": "Para confirmar su identidad, necesitamos información adicional:",
    "payment_instructions_will_be_provided_after_seller_accepts_order": "El vendedor le dará instrucciones detalladas sobre el pago una vez aceptado su pedido.",
    "nationality": "Nacionalidad",
    "date_of_birth": "Fecha de nacimiento",
    "place_of_birth": "Lugar de nacimiento",
    "discover_more": "Más información",
    "more_of_product": "Más {producto}",
    "more_of_product_in_city": "Más {producto} en {ciudad}",
    "order_placed": "Pedido realizado",
    "show_order_details": "Mostrar detalles del pedido",
    "white_label_plugins": "Plugins de marca blanca",
    "white_label_plugin": "Plugin de marca blanca",
    "enhance_chedri_project_with_plugins": "Mejore la experiencia creacheck con plugins",
    "plugin": "Plugin",
    "plugins": "Plugins",
    "enhance_your_project_with_plugins": "Puedes elegir cuánta funcionalidad de Creacheck necesitas. Consulta los plugins disponibles, sus funciones y precios.",
    "week": "semana",
    "month": "mes",
    "quarter": "Cuarto",
    "year": "Año",
    "more_infos": "Más información",
    "less_infos": "Menos información",
    "activate_plugin": "Activar plugin",
    "partially_activate_plugin": "Activación del plugin de firmas",
    "terminate_plugin": "Salir del plugin",
    "detailed_description": "Descripción detallada",
    "installation_instructions": "Instrucciones de instalación",
    "you_are_not_an_adminitrator": "Usted no es administrador",
    "creditor_id": "Identificación del acreedor",
    "i_understand_the_cost_and_want_activate_plugin": "Entiendo los costes y me gustaría activar el plugin",
    "i_understand_the_running_cost_when_terminating_plugin": "Entiendo los costes que se cobrarán al final del periodo de facturación",
    "are_you_sure_you_want_to_terminate_plugin": "¿De verdad quieres parar el plugin?",
    "security_settings": "Ajustes de seguridad",
    "limit_write_rights": "Restringir permisos de escritura",
    "limit_read_rights": "Restringir derechos de lectura",
    "edit_your_company_data": "Aquí puede modificar los datos de su empresa. Modificar los datos de contacto y financieros utilizados para la facturación.",
    "latest_order": "Último pedido",
    "latest_orders": "Últimos pedidos",
    "latest_invoices": "Últimas facturas",
    "latest_editions": "Última edición",
    "overdue_invoices": "Facturas pendientes",
    "view_all": "Mostrar todo",
    "i_accept": "Acepto",
    "accept_terms_before_continuing": "Antes de continuar, debe aceptar nuestras condiciones generales y nuestra política de privacidad.",
    "approve_car_sale": "Autorizar la venta de coches",
    "enter_vin_number": "Introduzca el número de bastidor del vehículo",
    "mark_as_paid": "Marcar como pagado",
    "enter_ein_number": "Introducir la confirmación electrónica del seguro",
    "send_payment_instructions": "Enviar instrucciones de pago",
    "submit_vin_number": "Enviar el número de bastidor del vehículo",
    "approve_and_inform_client": "Autorizar e informar al cliente",
    "submit_ein_number": "Enviar confirmación electrónica del seguro",
    "ein": "Confirmación electrónica del seguro",
    "payment_reference": "Referencia de pago",
    "upload_sales_cotract": "Cargar contrato de venta",
    "cancellation_reason": "Motivo de la anulación",
    "enter_bank_data": "Introducir datos bancarios",
    "pickup_invite": "Invitación a recoger",
    "mark_picked_up": "Marcar como recogido",
    "refunds": "Reembolsos",
    "sent_on_date": "Enviado el {fecha}",
    "not_sent_yet": "Aún no enviado",
    "pick_up_location": "Lugar de recogida",
    "emails": "Correos electrónicos",
    "use_following_list_to_personalize_your_emails": "Utilice la siguiente lista para personalizar los correos electrónicos:",
    "recipients_first_name": "Nombre del destinatario",
    "recipients_last_name": "Apellido del destinatario",
    "senders_company_name": "Nombre de la empresa remitente",
    "system_mail_registration_description": "Enviado cuando un nuevo usuario se registra en el sitio web",
    "system_mail_invitation_description": "Se envía cuando un empleado crea un nuevo usuario con una dirección de correo electrónico",
    "system_mail_car_rental_pickup_reminder_description": "Enviado un día antes de que el cliente recoja su coche de alquiler",
    "system_mail_car_rental_dropoff_reminder_description": "Enviado un día antes de que el cliente entregue su coche de alquiler",
    "terms_of_use": "Condiciones de uso",
    "contract": "Contrato",
    "order_data_processing": "Contrato de tramitación de pedidos",
    "i_read_and_accept_plugins_terms_of_use": "He leído y comprendido las condiciones de uso y las acepto expresamente.",
    "i_read_and_accept_plugins_contract": "He leído y comprendido el contrato y lo acepto expresamente.",
    "i_read_and_accept_plugins_order_data_processing": "He leído y comprendido el tratamiento de los datos del pedido y lo acepto expresamente.",
    "send_activation_request": "Enviar solicitud de activación",
    "missing_companys_bank_data": "Parece que nos faltan los datos bancarios de su empresa.",
    "you_can_fill_missing_data_in_settings": "Puede introducir los datos que faltan en {configuración}.",
    "not_sole_representative_click_below_to_request_plugin_activation": "No está autorizado a activar plugins usted mismo. Haga clic en el botón para activar el plugin. El usuario autorizado deberá completar el proceso de activación.",
    "not_authorized_click_below_to_request_plugin_activation": "No está autorizado a activar plugins. Haga clic en el botón para enviar una solicitud de activación a los usuarios autorizados.",
    "select": "Seleccione",
    "enter_app_secret": "Introduzca el secreto de la aplicación",
    "enter_page_access_token": "Introduzca el token de acceso a la página",
    "legal": "Legal",
    "activation_pending": "Pendiente de activación",
    "termination_pending": "Anulación pendiente",
    "form_contains_errors": "Hay algunos problemas en el formulario",
    "internal_car_reference": "Referencia interna del vehículo",
    "system_debtor_number": "Número de deudor del sistema",
    "internal_company_reference": "Referencia interna de la empresa",
    "internal_location_reference": "Referencia de ubicación interna",
    "show_notes": "Notas del programa",
    "show_my_notes": "Mostrar mis notas",
    "show_by_me": "Mostrar de mí",
    "items": "Productos",
    "send_text_message": "Enviar SMS",
    "wrote_message": "Mensaje escrito",
    "sent_text_message": "Un SMS enviado",
    "wrote_note": "Nota escrita",
    "please_check_document_check_expiration_date": "Compruebe el documento. Asegúrese de haber visto el documento. Compruebe la fecha de caducidad",
    "i_reviewed_the_document": "He comprobado el documento",
    "receive_updates_in_messenger": "¿Desea recibir actualizaciones en Messenger?",
    "weve_received_your_request_and_will_contact_you": "Hemos recibido su solicitud y nos pondremos en contacto con usted lo antes posible.",
    "check_your_email_to_confirm": "Compruebe su correo electrónico para confirmar el envío de este formulario de contacto.",
    "response_text": "Texto de respuesta",
    "entries": "Entradas",
    "timeline_block": "Bloque cronológico",
    "external_accounts": "Cuentas exteriores",
    "send_copy_to_me": "Envíeme una copia",
    "user_tracking": "Seguimiento de usuarios",
    "select_model": "Seleccionar modelo",
    "select_model_group": "Seleccionar grupo de modelos",
    "equipment_level_budget": "Presupuesto",
    "equipment_level_family": "Familia",
    "equipment_level_comfort": "Confort",
    "equipment_level_business": "Empresas",
    "salutation": "Saludo",
    "getting_started": "Primeros pasos",
    "set_your_locations": "Defina sus ubicaciones",
    "set_up_locations_for_your_car_business_set_services_opening_hours_upload_photos": "Cada vehículo requiere una ubicación entre las que los clientes pueden elegir. Vaya a los ajustes donde podrá definir todas las ubicaciones deseadas. Allí puedes decidir qué servicios ofreces en cada ubicación, establecer el horario de apertura y subir fotos para que los clientes encuentren tu negocio más fácilmente.",
    "now_that_locations_are_set_create_users_so_they_can_work_together": "Ahora que tus clientes saben dónde haces negocios, haznos saber quién trabaja para ti. Por favor, crea usuarios para que puedan colaborar utilizando las tecnologías de Creacheck",
    "cms_content": "Contenido CMS",
    "this_is_blank_page_use_editor_to_create_great_one": "Esta es una página en blanco. En nuestro editor visual puede crear una página hermosa con gráficos, tipografía hermosa y compatible con todos los dispositivos.",
    "cars_to_sell": "Vehículos en venta",
    "your_business_is_car_sales_but_there_are_no_cars_create_yourself_or_use_import": "Su negocio es vender vehículos. El nuestro es facilitarle las cosas. Aún no ha introducido ningún vehículo en nuestro sistema. Simplemente utilice la cómoda función de importación de vehículos o introduzca sus vehículos manualmente.",
    "create_manually": "Crear manualmente",
    "import": "Importar",
    "car_import": "Vehículos de importación",
    "most_car_dealers_use_mobilede_enter_your_interface_username_and_password_to_import": "Según hemos averiguado, la mayoría de los concesionarios utilizan mobile.de para presentar sus coches. Así que lo más probable es que tenga acceso al servicio web. Por favor, introduzca su nombre de usuario y contraseña. Importaremos todos sus coches automáticamente y mantendremos su listado sincronizado. Si tiene varios datos de acceso, puede introducir más en su perfil.",
    "we_will_import_count_cars_for_this_username": "Importaremos {count} coches para este nombre de usuario",
    "mobile_de_username": "Mobile.de Nombre de usuario de la API de vendedor",
    "mobile_de_password": "Mobile.de Vendedor API Contraseña",
    "missing_bank_data": "Faltan datos bancarios",
    "we_dont_have_your_bank_data": "Aparentemente no tenemos sus datos bancarios",
    "monthly_billing_relies_on_sepa_direct_debit_fill_in_bank_data_in_settings": "Nuestra facturación mensual se basa en la domiciliación bancaria SEPA. Como no disponemos de datos bancarios, no puedes utilizar muchos de nuestros plugins. Por favor, vaya a la configuración e introduzca sus datos bancarios.",
    "fill_in_bank_data": "Introduzca los datos bancarios",
    "sepa_mandate": "Mandato de la SEPA",
    "sepa_mandates": "Mandatos de la SEPA",
    "missing_sepa_mandate": "Nos falta un mandato de la SEPA",
    "changed_bank_data_please_generate_new_sepa_mandate": "Parece que ha cambiado recientemente su número de cuenta bancaria. Para automatizar nuestro proceso de facturación mensual y reducir su estrés, cree un nuevo mandato SEPA.",
    "generate_sepa_mandate_now": "Generar un nuevo mandato SEPA",
    "columns": "Columnas",
    "send_doc_as_message": "Enviar documento al cliente (correo electrónico)",
    "alternative_recepient": "Receptor alternativo",
    "enter_email_address": "Introduzca una dirección de correo electrónico válida",
    "job_title": "Puesto",
    "service_point": "Servicio",
    "rental_point": "Base de alquiler",
    "seller_data": "Datos del vendedor",
    "car_location": "Localización del vehículo",
    "save_data_before_nav_to_pathname": "Tus datos no se guardarán. ¿Desea cambiar a {nombre de usuario} sin guardar?",
    "share": "Compartir",
    "assignee_changed_from_assignee_to_assignee": "El cesionario ha cambiado de %antiguoCcesionario% a %nuevoCcesionario%.",
    "stage_changed_from_name_to_name": "El nivel ha cambiado de %oldName% a %newName%.",
    "handover_filled_out": "Se ha completado el protocolo de traspaso de vehículos",
    "data_changed": "Datos actualizados",
    "key": "clave",
    "old_value": "Valor antiguo",
    "new_value": "Nuevo valor",
    "no_details_available": "No se dispone de información detallada",
    "services_we_offer_in_city": "Servicios que ofrecemos para usted en {ciudad}",
    "damages_present": "Daños presentes",
    "user_is_online": "El usuario está en línea",
    "user_is_offline": "El usuario no está conectado",
    "click_to_start_chat": "Haga clic aquí para iniciar el chat",
    "click_to_view_chat_history": "Haga clic aquí para ver el historial del chat",
    "theme_colors": "Colores temáticos",
    "logos": "Logos",
    "send_invitation_with_username_and_password": "Enviar invitación con nombre de usuario y contraseña (sólo para nuevos usuarios)",
    "cookie_settings": "Detalles de la cookie",
    "accept_only_necessary_cookies": "Aceptar sólo las cookies necesarias",
    "accept_all_cookies": "Aceptar todas las cookies",
    "privacy_preference_center": "Centro de preferencias de protección de datos",
    "your_privacy": "Tu privacidad",
    "basic_information_on_cookies": "Cuando visita un sitio web, es posible que se almacene o recupere información en su navegador, normalmente en forma de cookies. Esta información puede ser sobre usted, sus preferencias o su dispositivo. Esta información se utiliza principalmente para que el sitio web funcione como se espera. La información no suele identificarle directamente, pero puede proporcionarle una experiencia web más personalizada. Como respetamos su derecho a la intimidad, puede optar por no permitir determinados tipos de cookies. Seleccione una categoría para obtener más información y cambiar nuestra configuración predeterminada. Sin embargo, bloquear algunos tipos de cookies puede afectar a su experiencia en el sitio web y a los servicios que podemos ofrecerle.",
    "necessary_cookies": "Cookies necesarias",
    "performance_cookies": "Cookies de rendimiento",
    "targeting_cookies": "Cookies de segmentación",
    "always_active": "Siempre activo",
    "cookies_used": "Cookies utilizadas",
    "necessary_cookies_information": "Estas cookies son necesarias para el funcionamiento del sitio web y no pueden desactivarse en nuestros sistemas. Normalmente sólo se instalan en respuesta a acciones que usted realiza relacionadas con una solicitud de servicio, como configurar sus preferencias de privacidad, iniciar sesión o rellenar formularios. Puede configurar su navegador para que bloquee o le avise de estas cookies, pero algunas partes del sitio web no funcionarán. Estas cookies no almacenan información personal identificable.",
    "save_settings": "Guardar ajustes",
    "save_and_continue": "Guardar y continuar",
    "priority": "Prioridad",
    "closed_leads": "Pistas cerradas",
    "show": "Mostrar",
    "hide": "Escondite",
    "first_registration_from": "Primer registro a partir de",
    "first_registration_to": "Primera inscripción hasta",
    "power_from": "Potencia del motor de",
    "power_to": "Potencia del motor hasta",
    "mileage_from": "Kilometraje desde",
    "mileage_to": "Kilometraje hasta",
    "included_mileage": "{kilometraje} kilómetros",
    "really_cancel": "¿Cancelar de verdad?",
    "unsaved_information_will_be_lost": "Se pierden los datos no guardados",
    "client_signature": "Firma del cliente",
    "another_photo": "Otra foto",
    "try_again": "Inténtalo de nuevo",
    "back_to_vehicle": "Volver al vehículo",
    "image_upload_finished": "La carga de las imágenes se ha completado",
    "you_have_no_internet_connection": "No dispone de conexión a Internet",
    "waiting_to_reconnect": "A la espera de la reconexión",
    "plus_count_more": "+ {cuenta} más",
    "document_is_missing": "Falta un documento",
    "upload_document_name_now": "Cargar documento '{nombre}' ahora",
    "lead_per_mail": "Regla del correo electrónico",
    "create_rules_to_forward_emails": "Cree reglas para reenviar correos electrónicos como copia a otros usuarios. Si el \"Complemento de correo electrónico de datos\" está activado, también puede reenviar correos electrónicos con información importante en formato XML directamente a Customer One.",
    "lead_per_mail_rules": "Reglas del correo electrónico",
    "include_xml_data_in_email": "Insertar datos en formato XML en el correo electrónico",
    "sad_activity": "Actividad SAD",
    "e_payments": "Pagos electrónicos",
    "stripe_connect": "Raya Connect",
    "we_will_handle_epayments_for_you": "Con Stripe Connect, podemos procesar pagos con tarjeta de crédito en tu nombre. Después de hacer clic en \"Conectar con Stripe\", puedes utilizar tu cuenta de Stripe existente para conectarte a la plataforma Creacheck o crear una nueva cuenta de Stripe si aún no tienes una. Con tu propia cuenta de Stripe, puedes ver todo tu historial de pagos y retirar dinero en cualquier momento.",
    "connect_with_stripe": "Conectar con Stripe",
    "stripe_accounts": "Cuentas de Stripe",
    "stripe_account_connected": "Su cuenta de Stripe ya está conectada",
    "stripe_accounts_are_connected": "Su cuenta de Stripe está ahora conectada a {projectName}. {projectName} ya puede aceptar pagos con tarjeta de crédito en su nombre.",
    "money_won": "Dinero ganado",
    "leads_won": "Clientes potenciales",
    "offered_by": "Su concesionario",
    "pay_with_saved_credit_card": "Pague con su tarjeta de crédito almacenada",
    "pay_with_new_credit_card": "Pagar con una nueva tarjeta de crédito",
    "settings": "Ajustes",
    "account": "Funciones",
    "clients": "Empresas",
    "payments": "Pagos",
    "calendar": "Calendario",
    "contact": "Póngase en contacto con nosotros",
    "login": "Conectarse",
    "logout": "Cierre de sesión",
    "previous_odometer_reading": "Lectura anterior del cuentakilómetros",
    "current_odometer_reading": "Kilometraje actual",
    "odometer_reading": "Kilometraje",
    "tank_volume": "Volumen del depósito",
    "vehicle_fuel_costs": "Costes de repostaje de los vehículos",
    "mileage_surcharge": "Recargo por kilómetro adicional",
    "insurance_self_participation": "Franquicia del seguro",
    "additional_costs_due": "Costes adicionales debidos",
    "no_additional_costs": "Sin costes adicionales",
    "charge_customer_additional_costs": "Cargar costes adicionales al cliente",
    "warning_you_chose_not_to_charge_customer_for_additional_costs": "Atención Ha decidido no cargar al cliente ningún coste adicional",
    "cancel_booking": "Cancelar reserva",
    "no_invoices_found": "No se han encontrado facturas",
    "upload_drivers_license": "Cargar permiso de conducir",
    "order_details": "Detalles del pedido",
    "shipping_address": "Dirección de envío",
    "order_overview": "Resumen de pedidos",
    "booked_by_mistake": "Reservado por error",
    "booked_wrong_dates": "Fechas incorrectas reservadas",
    "found_better_price": "Mejor precio encontrado",
    "change_of_plans": "Cambio de plan",
    "client_called_to_cancel": "El cliente llamó y pidió cancelar",
    "client_wrote_email_to_cancel": "El cliente ha escrito un correo electrónico y ha pedido la cancelación",
    "car_damaged_before_transport": "El vehículo resultó dañado antes del transporte",
    "car_damaged_in_transport": "El vehículo sufrió daños durante el transporte",
    "item_not_available": "Artículo no disponible",
    "other_reason": "Otros",
    "license_plate_number": "Matrícula",
    "open_in_google_maps": "Abrir en Google Maps",
    "access_identifier": "Código de acceso",
    "branch": "Rama",
    "new": "Nuevo",
    "in_progress": "En curso",
    "failed": "Fallido",
    "not_started": "No iniciado",
    "finished": "Terminado",
    "failed_refunds": "Reembolsos fallidos",
    "list_all_failed_refund_payments": "Enumere todas las devoluciones fallidas con detalles",
    "rent_sync_vamoso_reservation_success": "Reservado con éxito en el sistema Vamoso. El número de reserva es %reservationId%.",
    "rent_sync_vamoso_reservation_failure": "No fue posible reservar el coche en el sistema Vamoso. El sistema devolvía el siguiente mensaje de error: \"%errorText%\"",
    "rent_sync_vamoso_cancellation_success": "La reserva en el sistema Vamoso se ha cancelado correctamente",
    "rent_sync_vamoso_cancellation_failure": "No fue posible cancelar la reserva en el sistema Vamoso. El sistema devolvía el siguiente mensaje de error: \"%errorText%\"",
    "rent_sync_vamoso_booking_success": "Reservado con éxito en el sistema Vamoso",
    "rent_sync_vamoso_booking_failure": "No ha sido posible realizar la reserva en el sistema Vamoso. El sistema devolvía el siguiente mensaje de error: \"%errorText%\".",
    "rent_sync_vamoso_return_success": "El vehículo fue marcado con éxito como devuelto en el sistema Vamoso",
    "rent_sync_vamoso_return_failure": "No fue posible devolver el coche al sistema Vamoso. El sistema devolvió el siguiente mensaje de error: \"%errorText%\"",
    "view_lead_details": "Mostrar detalles del cliente potencial",
    "change_category": "Cambiar de categoría",
    "json_url_parameters": "Parámetros URL JSON",
    "layout_version": "Versión maqueta",
    "layout_wide": "Amplia",
    "layout_narrow": "Estrecho",
    "layout_detailed": "Detallado",
    "layout_slider": "Deslizador",
    "layout_list": "Lista",
    "search_version": "Versión del módulo de búsqueda",
    "not_sure_go_to_advisory_mode": "No estoy seguro: aquí está el {enlace}",
    "link_to_advisory_mode": "Modo de asesoramiento",
    "desired_pickup_location": "Lugar de recogida deseado",
    "incl_short": "incl.",
    "included": "Incluye",
    "included_services": "Servicios incluidos",
    "insurance": "Seguros",
    "liability_and_comprehensive_insurance_with_excess_amount_of_amount_per_claim": "Seguro de responsabilidad civil y a todo riesgo con una franquicia de {importe} por siniestro",
    "tyres_suitable_for_winter": "Neumáticos de invierno",
    "additional_driver": "Conductor adicional",
    "on_request": "a petición",
    "navigation_system": "Sistema de navegación",
    "child_seat": "Asiento infantil",
    "order_comments_and_special_wishes": "Otros deseos",
    "ordering_as_person": "Pido como particular",
    "ordering_as_company": "Pido como empresa",
    "filter_by_date": "Filtrar por fecha",
    "car_rental_max_days_count_exceeded": "Desafortunadamente, el periodo seleccionado excede nuestro periodo máximo de alquiler de {contar, en plural, un {# día} otros {# días}}.",
    "require_document_number": "Número del documento solicitado",
    "require_document_issuing_city": "Solicitar el lugar de expedición del documento",
    "require_document_issuing_authority": "Solicitar la autoridad emisora del documento",
    "require_document_issue_date": "Solicitar fecha de emisión del documento",
    "require_document_expiration_date": "Solicitar la fecha de caducidad del documento",
    "doc_number": "Número de documento",
    "doc_issuing_city": "Lugar de expedición del documento",
    "doc_issuing_authority": "Autoridad expedidora del documento",
    "doc_issue_date": "Fecha de expedición del documento",
    "doc_expiration_date": "Fecha de caducidad del documento",
    "chedri_plugins": "Plugins de Creacheck",
    "in_order_to_use_our_software_in_its_full_potential_you_need_to_activate_plugins_in_order_to_do_that_please_confirm_that_you_may_sign_contracts_on_behalf_of_your_company": "<p>Para poder utilizar nuestro software, debe activar las funciones deseadas. Cada función se entrega en forma de plugin, que suele ir acompañado de una obligación de pago (tarifa).<br/>Cuando active un plugin, se le pedirá que acepte los términos y condiciones del plugin. Sin embargo, antes de continuar, debe confirmar que está autorizado para actuar en nombre de su empresa.</p",
    "i_declare_that_i_am_entitled_to_legally_sign_contracts_on_behalf_of_my_company": "Declaro que estoy autorizado a firmar contratos jurídicamente vinculantes en nombre de mi empresa, {empresa}.",
    "your_company_was_successfully_registered": "Le hemos registrado a usted y a su empresa en nuestro sistema.",
    "check_email_for_more_instructions_about_your_company_registration": "Hemos enviado información sobre cómo proceder a la dirección de correo electrónico que nos ha facilitado. Por favor, compruebe su bandeja de entrada. Si tiene alguna duda o no sabe cómo proceder, póngase en contacto con nosotros.",
    "dependent_on": "En función de",
    "we_have_more_plugins_that_further_enhance_the_functionality": "Tenemos otros plugins que mejoran aún más la funcionalidad de {plugin}.",
    "finish_plugin_configuration": "Salir de la configuración del plugin",
    "it_seems_that_you_havent_finished_configuring_plugins": "Parece que aún no ha completado la configuración de los plugins activados. Para algunos plugins es necesario definir datos de acceso externos (por ejemplo, para la interfaz mobile.de o los datos de acceso de Vamoso). Por favor, ve a la configuración y añade las cuentas que faltan.",
    "lead_created_from_an_email": "Este contacto se creó a partir de un correo electrónico",
    "show_original_message": "Mostrar mensaje original",
    "we_found_count_similar_leads": "Hemos encontrado {contar, plural, una {# pista similar} otras {# pistas similares}}",
    "show_similiar_leads": "Mostrar clientes potenciales similares",
    "new_lead_from_failed_refund": "Nueva pista por devolución fallida",
    "you_can_apply_company_data_from_company_profile": "Puede transferir datos de la empresa desde el perfil de la empresa",
    "load_company_data": "Cargar datos de la empresa",
    "company_lookup": "Búsqueda de empresas",
    "match_lead_tags": "Sincronizar etiquetas de clientes potenciales",
    "is_active": "Está activo",
    "is_renew_password_at_next_login": "Renovar contraseña en el próximo inicio de sesión",
    "no_overdue_tasks": "No hay tareas pendientes",
    "great_job": "¡Bien hecho!",
    "no_leads_for_today": "No hay pistas para ti hoy",
    "address_not_set": "Dirección no fijada",
    "statistics": "Estadísticas",
    "select_assignee": "Seleccione un editor",
    "kroschke_order_in_progress": "El pedido de Kroschke con ID de vehículo %kroschkeVehicleId% se ha inicializado correctamente.",
    "in_original": "En el original",
    "a_copy": "Un ejemplar",
    "mark_documents_sent": "Marcar documentos como enviados",
    "i_sent_the_documents": "He enviado los documentos",
    "documents_were_sent": "Se han enviado los documentos",
    "documents_sent": "Documentos enviados",
    "define_custom_license_plate_number": "Definir la matrícula preferida",
    "about_car_registration": "El servicio de autorización incluye",
    "i_would_like_individual_license_plate_number": "Sí, me gustaría seleccionar el personaje deseado",
    "we_gather_necessary_documents": "Registro en su autoridad expedidora de licencias",
    "we_produce_license_plates": "Adquisición y montaje de placas de matrícula",
    "license_plate_reservation_number": "Número de reserva de la matrícula",
    "with_custom_license_plate_number": "Con matrícula personalizada",
    "after_the_order_reserve_desired_license_plate_at_local_registration_office": "Después de hacer el pedido, debe reservar la matrícula deseada en línea en la oficina de matriculación de su localidad.",
    "inform_us_about_your_license_plate_reservation_so_we_can_start_the_process": "Una vez hecho esto, indíquenos el número de matrícula que desea y el número de reserva (que recibió de la oficina de matriculación).",
    "clarify": "Aclare",
    "notification": "Comunicación",
    "license_plate_reservation_data_missing": "Faltan los datos de reserva de la matrícula",
    "license_plate_reservation_number_filled_out": "Datos de reserva de matrícula introducidos. Número de matrícula: %licensePlateNumber%. Número de reserva: %reservationNumber%.",
    "distance_planned": "Ruta prevista",
    "distance_driven": "Ruta recorrida",
    "planned_pickup_date": "Fecha de recogida prevista",
    "pickup_date": "Fecha de recogida",
    "planned_delivery_date": "Entrega prevista",
    "your_initial_offer": "Su primera oferta",
    "your_next_offer": "Su próxima oferta",
    "car_number": "Número de vehículo",
    "send_price_proposal": "Enviar propuesta de precio",
    "our_price": "Nuestro precio",
    "purchase_price": "Precio de compra",
    "our_prices_are_already_optimized_but_if_you_think_price_is_too_high_negotiate_it": "Intentamos optimizar nuestros precios para usted, pero si cree que el precio sigue siendo demasiado alto, díganos qué podemos hacer por usted.",
    "select_the_car_you_love": "Seleccione el coche deseado",
    "sign_up_or_log_in_if_you_already_have_an_account": "Regístrese o inicie sesión si ya tiene una cuenta",
    "enter_desired_price": "Introduzca el precio deseado",
    "send_the_form_our_employee_will_contact_you": "Enviar formulario. Nuestro empleado se pondrá en contacto con usted lo antes posible",
    "if_we_settle_on_a_price_you_can_buy_the_car_online": "Si acordamos un precio, tienes la opción de comprar el coche directamente en nuestro sitio web",
    "you_have_to_log_in_to_proceed": "Debe iniciar sesión para continuar",
    "rent_a_car": "Alquilar un vehículo",
    "pending_price_negotiations_count": "Pendiente {contar, plural, una {# propuesta de precio} otras {# propuestas de precio}}",
    "my_price_negotiation": "Mi sugerencia de precio",
    "price_negotiation": "Propuesta de precio",
    "price_negotiations": "Sugerencias de precios",
    "new_price_negotiation_request": "Nueva solicitud de negociación de precios",
    "expired": "Caducado",
    "first_offer": "Primera oferta",
    "current_offer": "Oferta actual",
    "expires": "Expira en",
    "accept_the_offer": "Aceptar oferta",
    "send_a_new_offer": "Enviar nueva oferta",
    "your_new_offer": "Su nueva oferta",
    "your_project_dashboard": "Su {proyecto} cuadro de mandos",
    "add_my_vehicle": "Añadir mi vehículo",
    "no_reply_yet": "Aún no hay respuesta",
    "employee_replied": "El empleado ha respondido",
    "price_negotiation_details": "Detalles de la propuesta de precios",
    "current_offer_is_amount_you_can_now_proceed_to_checkout": "Su oferta actual es de {importe}. Puede pasar por caja con el precio negociado.",
    "show_timeline": "Mostrar historial",
    "communities": "Comunidades",
    "gain_coverage_with_community": "Aumente su alcance con nuevas plataformas. Capte nuevos clientes con un solo clic.",
    "community": "Comunidad",
    "is_community": "Comunidad activa",
    "join_community": "Únete a la Comunidad",
    "leave_community": "Abandonar la comunidad",
    "join_jahreswagen_community_and_profit_from_additional_sales_channel": "<div><p>Una cosa que es completamente nueva en Creacheck son las comunidades. Gracias al software Creacheck, es posible crear plataformas para que múltiples empresas se unan.</p><p>Una de estas plataformas es Jahreswagen.de. Puedes echar un vistazo a las ofertas de Jahreswagen. Si quieres convertirte en miembro, ve a la configuración de la comunidad y únete a la comunidad Jahreswagen.</p><p>La membresía es gratuita. Sin embargo, se aplican tarifas de transacción.</p><p className='m-t-20 alert alert-info'>Si tienes tu propia idea para una comunidad, ponte en contacto con nosotros. Encontraremos una solución para usted.</p></div>",
    "car_description": "Descripción del vehículo",
    "marketplace_terms": "Condiciones de uso del mercado",
    "online_appointment_booking": "Reserva de citas en línea",
    "my_online_appointments": "Mis citas en línea",
    "employee": "Empleados",
    "choice_of_date": "Elección de la fecha",
    "works": "Trabajar en",
    "make_selection": "Seleccione",
    "remove_selection": "Eliminar",
    "tasks": "Tareas",
    "task": "Tarea",
    "car_service": "Servicio",
    "car_services": "servicios",
    "car_service_station": "Estación de trabajo",
    "car_service_stations": "Puestos de trabajo",
    "services_offered_at_your_locations": "Defina los servicios en sus centros.",
    "service_stations_where_your_employees_work": "Defina los puestos de trabajo para poder realizar un seguimiento de la utilización y ver los recursos ocupados y libres.",
    "team_members": "Miembros del equipo",
    "service_details": "Detalles del servicio",
    "service_location": "El lugar elegido para el servicio",
    "service_drop_off_time": "Hora de servicio seleccionada",
    "car_service_appointments": "Fechas de los talleres",
    "car_service_booked_at": "Cita concertada",
    "show_booking_details": "Mostrar detalles de la reserva",
    "saving_draft": "Se guarda el borrador",
    "draft_saved": "Borrador guardado",
    "define_custom_page_templates_to_meet_your_cd": "Suministramos el sistema Creacheck con plantillas integradas. Aquí puede crear sus propias plantillas para adaptarlas mejor a su diseño corporativo.",
    "edit_description": "Editar descripción",
    "edit_language": "Editar idioma",
    "create_blank_page": "Crear una página en blanco",
    "create_from_template": "Crear a partir de plantilla",
    "use_template": "Utilizar la plantilla",
    "time_left": "Tiempo restante",
    "animated_header": "Cabecera animada",
    "header_size": "Tamaño de la cabecera",
    "options": "Opciones",
    "effects": "Efectos",
    "animation": "Animación",
    "parallax": "Parallax",
    "animation_repeat": "Repetir animación",
    "animation_intensity": "Intensidad de la animación",
    "animation_duration": "Duración de la animación",
    "animation_delay": "Retraso de la animación",
    "animation_initial_opacity": "Opacidad inicial de la animación",
    "animation_speed_curve": "Curva de velocidad de animación",
    "parallax_intensity": "Intensidad de paralaje",
    "animation_direction": "Dirección de paralaje",
    "parallax_initial_opacity": "Poder de cobertura inicial del paralaje",
    "append_html": "Adjuntar HTML",
    "css_classes": "Clases CSS",
    "forward_url": "Redirigir a esta URL",
    "effect_fade": "Fundido de entrada",
    "effect_slide": "Diapositivas",
    "effect_zoom": "Zoom",
    "effect_bounce": "Rebote",
    "effect_flip": "Flip",
    "effect_fold": "Pliegues",
    "effect_roll": "Rodillos",
    "direction_center": "Centro",
    "direction_left": "Enlaces",
    "direction_right": "Derecha",
    "direction_top": "Por encima de",
    "direction_bottom": "Abajo",
    "repeat_once": "Una vez",
    "repeat_loop": "Bucle",
    "selected_template": "Plantilla seleccionada",
    "premade_layouts": "Diseños prefabricados",
    "your_existing_pages": "Sus páginas existentes",
    "count_templates": "{cuenta} Plantillas",
    "background_attachment": "Apéndice de antecedentes",
    "background_position": "Posición de fondo",
    "background_repeat": "Repetición de fondo",
    "background_size": "Tamaño del fondo",
    "edit_mode": "Modo edición",
    "effects_active": "Efectos activos",
    "document_templates": "Plantillas de documentos",
    "code_document_templates_for_print_use_the_templates_for_individual_quotes_and_invoices": "Codifique sus propias plantillas de documentos para imprimirlas para sus clientes. También puede diseñar sus propias versiones de presupuestos y facturas.",
    "currencies": "Divisas",
    "document_template": "Modelo de documento",
    "document_content": "Contenido del documento",
    "number_prefix": "Prefijo numérico",
    "sales_documents": "Documentos de venta",
    "theme": "Tema",
    "choose_template": "Seleccionar modelo de documento",
    "default": "Estándar",
    "favorites": "Favoritos",
    "favorites_list": "Lista de favoritos",
    "require_client_address": "Solicitar la dirección del cliente",
    "require_client_email": "Solicitar la dirección de correo electrónico del cliente",
    "require_client_phone": "Solicitar el número de teléfono del cliente",
    "require_message": "Solicitar mensaje",
    "subscription": "Suscripción",
    "subscription_plan": "Plan de suscripción",
    "main_plugin": "Plugin principal",
    "target_organisation": "Organización destinataria",
    "reference_number": "Número de referencia",
    "show_invoice": "Mostrar factura",
    "delivery_date": "Fecha de entrega",
    "test_drive": "Prueba de conducción",
    "find_your_perfect_car": "Encuentre el vehículo perfecto",
    "select_car_design": "Seleccionar diseño",
    "multiple_selection_possible": "Posibilidad de selección múltiple",
    "fuel_type": "Combustible",
    "select_equipment_level": "Seleccione el nivel de equipamiento",
    "engine": "Motorización",
    "like_budget": "Como presupuesto",
    "like_family": "Como la familia",
    "like_comfort": "Como la comodidad",
    "we_did_not_find_any_results_for_your_search": "Lo sentimos, su búsqueda no ha coincidido",
    "we_did_not_find_any_cars_at_this_location_for_the_selected_period": "No encontramos ningún coche en este lugar durante el periodo seleccionado.",
    "we_did_not_find_any_cars_for_your_seaech": "Desafortunadamente no hemos encontrado ningún coche para su búsqueda.",
    "all_cars": "Mostrar todos los coches",
    "search_for_cars": "Buscar coches",
    "used_car_search": "Búsqueda de vehículos usados",
    "dropdown": "Lista desplegable",
    "checkboxes": "Casilla de verificación",
    "multiple_choice": "Selección múltiple",
    "text_input": "Introducción de texto",
    "number_input": "Número de entrada",
    "multiline_input": "Introducción de texto multilínea",
    "paragraph": "Sección",
    "change_questionnaire": "Cambiar cuestionario",
    "answers_to_a_questionnaire": "Respuestas a un cuestionario",
    "request_filling_out_questionnaire": "Envíe un cuestionario a sus clientes",
    "send_questionnaire_as_message": "Enviar el cuestionario como mensaje",
    "ingoing": "Entrante",
    "outgoing": "Saliente",
    "vat_short": "IVA.",
    "vat": "Impuesto sobre el valor añadido",
    "price_net": "{precio} neto",
    "environment": "Medio ambiente",
    "inquire": "Consultas",
    "continue_to_product_name": "Continuar con {productName}",
    "you_can_find_this_and_other_vehicles_at_company": "Puede encontrar este y otros vehículos en {company}.",
    "more_vehicles_based_on_your_search": "Más vehículos según su búsqueda",
    "add_to_favorites": "Guardar como favorito",
    "remove_from_favorites": "Eliminar de favoritos",
    "share_the_offer": "Compartir oferta",
    "any_question_the_company_team_is_at_your_disposal": "¿Tiene alguna pregunta? El equipo de {company} está a su disposición.",
    "you_want_this_vehicle_but_the_price_isnt_right_submit_price_proposal": "¿Quiere este vehículo pero el precio no es el adecuado? Envíe su propuesta de precio.",
    "you_want_to_rent_this_vehicle_at_project_name_you_can": "Te gustaría conducir este vehículo durante más tiempo, en {projectName} tienes la posibilidad de alquilar el vehículo de tus sueños.",
    "uploaded_video": "Cargar vídeo",
    "lead_autoresponse": "Auto-respuesta",
    "automatically_respond_to_incoming_leads": "Responde automáticamente a los clientes potenciales entrantes. Defina los días de la semana y las horas en las que el autorespondedor está activo.",
    "client_gave_count_answers_to_the_name_questionnaire": "El cliente dio {count} respuestas al cuestionario \"{name}\"",
    "new_lead_from_comms_center": "Nueva pista tras una comunicación cerrada",
    "customer_chat_ended": "Chat con un cliente finalizado",
    "chatter_welcome_message": "No hay de qué. Gracias por probar nuestro chat. ¿En qué puedo ayudarle hoy?",
    "please_rate_us": "Califíquenos",
    "reservation_fee": "Tasa de reserva",
    "item_name": "Nombre del artículo",
    "deposit": "Anticipo",
    "balance_due": "Importe pendiente de pago",
    "authorize": "Autorizar",
    "authorized_by_name_on_date": "Aprobado por {nombre} el {fecha}",
    "auto_generated": "Generado automáticamente",
    "not_authorized": "No autorizada",
    "settings_instructions": "Instrucciones de configuración",
    "payment_config": "Configuración de pagos",
    "projects": "Proyectos",
    "invoice_address": "Dirección de facturación",
    "deliver_adress": "Dirección de entrega",
    "your_contract_partner": "Su socio contractual",
    "remaining_balance": "Importe restante",
    "item_filter_is_active": "Visible en línea",
    "item_filter_is_resource": "Es Recurso",
    "item_filter_is_subscription": "Financiación",
    "cancelled_by_name_on_date": "Cancelado por {nombre} el {fecha}",
    "add_credit_card_data_in_next_step": "En el siguiente paso puedes añadir los datos de la tarjeta de crédito",
    "upon_collection": "A la recogida",
    "leads_manually_set_to_high_priority": "Alta prioridad manual",
    "leads_with_no_reaction_for_2_hours_since_creation": "Clientes potenciales sin respuesta durante 2 horas desde su creación",
    "leads_that_were_not_updated_for_2_days": "Clientes potenciales que no se han actualizado en 2 días",
    "use_this_element_as_reference": "Utilice este elemento como referencia",
    "you_can_reuse_this_element_on_multiple_pages": "El contenido que se reutiliza en varios sitios web puede ser referenciado. Los elementos del Constructor de páginas Express no pueden definirse como referencia. Dichos elementos siempre se copian",
    "company_data": "Datos de la empresa",
    "company_singup_company_data_hint": "Necesitamos que nos facilite la siguiente información para poder crear su empresa y asignarle un dominio.",
    "company_singup_personal_data_hint": "<p>Para que pueda recibir su acceso con sus propios datos de usuario, necesitamos la siguiente información.<br/>A continuación, recibirá un correo electrónico con sus datos personales de acceso.</p> <p>Para que pueda recibir su acceso con sus propios datos de usuario, necesitamos la siguiente información.<br/>A continuación, recibirá un correo electrónico con sus datos personales de acceso.",
    "your_test_domaine": "Su nombre de dominio de prueba",
    "your_test_domaine_hint": "<p>Por favor, cree un nombre de dominio en el que se alojará su proyecto. El dominio estará disponible inmediatamente después del registro.<br/>Recuerde que también podemos alojar proyectos bajo un nombre de dominio especificado por usted. Sólo tiene que ponerse en contacto con nosotros más adelante.</p> <p>",
    "features": "Características",
    "forms": "Formularios",
    "pricings": "Precios",
    "testimonials": "Referencias",
    "show_your_page_structure_and_responsive_visibility": "Muestra la estructura de su página con etiquetas y visibilidad adaptable",
    "toggle_edit_mode_for_page_preview": "Cambiar el modo de edición de la vista previa de una página",
    "toggle_animation_and_parallax_effects": "Activar y desactivar efectos de animación y paralaje",
    "use_quick_page_builder_to_create_content_faster": "Utiliza el Constructor Rápido de Páginas para crear contenido mucho más rápido",
    "cancel_invoice": "Cancelar factura",
    "refunded": "Reembolsado",
    "invoice_mark_refunded": "Hacer que la factura sea reembolsable",
    "denied_to_pay": "Negativa a pagar",
    "invoice_mark_denied_to_pay": "No pagará",
    "registration_success": "Registrado con éxito",
    "no_images_yet": "Aún no hay fotos.",
    "sortingPrice": "Precio de clasificación",
    "main_menu_version": "Versión del menú principal",
    "main_menu_versions": "Versiones del menú principal",
    "administration_of_main_menu_versions": "Gestión de las versiones del menú principal",
    "featured": "Destacado",
    "list_element_class": "Elemento de lista (izquierda) Clase CSS",
    "link_class": "Clase CSS de enlace",
    "width": "Anchura",
    "primary_menu_elements": "Elementos del menú principal",
    "secondary_menu_elements": "Elementos del menú secundario",
    "has_secondary_elements": "Tiene elementos secundarios",
    "active": "activo",
    "company_singup_wizard_step_subscription_plan": "Seleccionar paquetes",
    "company_singup_wizard_step_subscription_plan_headline": "Seleccione el paquete que desea probar.",
    "company_singup_wizard_step_additional_plugins": "Paquetes adicionales",
    "company_singup_wizard_step_subscription_plan_main_plugin_headline": "Me gustaría probar las siguientes funciones:",
    "company_singup_wizard_step_company_data": "Datos de contacto de su empresa",
    "company_singup_wizard_step_company_data_headline": "Nombre y datos de contacto de su empresa",
    "company_singup_wizard_step_personal_data": "Su perfil de empleado",
    "company_singup_wizard_step_personal_data_headline": "Su perfil de empleado",
    "company_singup_wizard_step_personal_data_hint": "Para poder crearle un perfil de empleado, necesitamos sus datos personales. Esta cuenta le otorga derechos de administración para su proyecto %platformName%.",
    "company_singup_wizard_step_personal_data_domain_hint": "<p>Por favor, cree un nombre de dominio en el que se alojará su proyecto. El dominio estará disponible inmediatamente después del registro.<br/>Recuerde que también podemos alojar proyectos bajo un nombre de dominio que usted especifique. Sólo tiene que ponerse en contacto con nosotros más adelante.</p> <p>",
    "company_singup_wizard_free_trial_hint": "Pruebe nuestras ofertas durante un mes de forma gratuita y sin compromiso.",
    "company_signup_wizard_free_trial_plugin_hint": "Prueba un mes gratis, entonces:",
    "included_in_subscription_plan": "Incluido en el abono, en caso contrario",
    "mostly_asked": "Preguntas más frecuentes",
    "test_package": "Paquete de prueba",
    "active_since": "activo desde",
    "test_period_until": "Fase de prueba hasta",
    "navigate_to_plugins_and_configure": "Ir a la vista general y a la configuración de los plugins",
    "termination_date": "Fecha de finalización",
    "lead_taggers": "Etiquetadores principales",
    "lead_tagger": "Etiquetador principal",
    "share_with": "Compartir con",
    "password_weak": "Débil",
    "password_medium": "Medio",
    "password_strong": "Fuerte",
    "plugin_already_added_in_whitelabel_project": "El plugin ya está asignado al proyecto de marca blanca.",
    "lead_channel": "Canal",
    "lead_channel_web": "Web",
    "lead_channel_mobile": "Aplicación",
    "lead_channel_phone": "Teléfono",
    "lead_channel_email": "Correo electrónico",
    "lead_channel_personally": "Personal",
    "allow_others_to_share": "Permitir que otros compartan con más gente",
    "additional_imprint_information": "Información adicional sobre la impresión",
    "share_with_the_company_i_work_for": "Con la empresa para la que trabajo, comparto",
    "share_with_all_companies_in_my_holding_structure": "Con todas las empresas de mi holding, las acciones",
    "latest_lead": "Última pista",
    "latest_edit": "Última edición",
    "reaction_time": "Tiempo de respuesta",
    "no_reaction_time": "Ninguna reacción",
    "gt_2_days": "> 2 días",
    "lt_5_minutes": "< 5 minutos",
    "lt_1_hour": "< 1 hora",
    "lt_2_hours": "< 2 horas",
    "lt_6_hours": "< 6 horas",
    "lt_1_day": "< 1 día",
    "lt_2_days": "< 2 días",
    "by_employee": "Por empleado",
    "by_company": "Por empresa",
    "by_location": "Por ubicación",
    "by_source": "Según la fuente",
    "client_data": "Datos del cliente",
    "show_in_lead_description": "Mostrar en la descripción principal",
    "this_answer_will_be_shown_in_lead_description": "Esta respuesta aparece en la descripción de la pista",
    "this_answer_will_not_be_shown_in_lead_description": "Esta respuesta no aparece en la descripción de la pista",
    "lead_assignment_strategy": "Estrategia de asignación de liderazgo",
    "lead_assignment_strategies": "Estrategias de asignación de líderes",
    "change_the_lead_assignment_strategies_for_companies_and_pipelines": "Cambie la forma en que se asignan los clientes potenciales a sus empleados en función de sus organizaciones y sus canales de distribución.",
    "strategy": "Estrategia",
    "deleted": "Suprimido",
    "assign_to_me": "Asignarme",
    "responsibilities": "Responsabilidades",
    "escalation_rules": "Normas de escalada",
    "escalation_rule": "Regla de escalada",
    "define_rules_when_you_would_like_to_inform_users_about_overdue_leads": "Define reglas que sus equipos pueden utilizar para identificar clientes potenciales que han sido abandonados o a los que los miembros del equipo no están respondiendo.",
    "category_images": "Categoría de imágenes",
    "use_document_template": "Utilizar plantilla de documento",
    "escalation_level_one": "Primera fase: informar a los empleados",
    "escalation_level_two": "Segundo paso: informar al jefe de equipo",
    "automatically_terminate_the_lead": "Finalizar automáticamente la pista (marcar la pista como perdida)",
    "no_reaction": "Ninguna reacción",
    "no_reaction_for": "No hay reacción para",
    "no_update": "Sin actualización",
    "no_update_for": "No hay actualización para",
    "escalation_level": "Nivel de escalada",
    "time_settings": "Ajustes de tiempo",
    "pending": "Destaca",
    "skipped": "Saltar",
    "holding_structure": "Estructura del holding",
    "show_sibling_companies": "Las empresas hermanas muestran",
    "show_descendant_companies": "Mostrar empresas subordinadas",
    "subscription_type": "Modelo de financiación",
    "no_subscription_type_selected": "Debe seleccionarse al menos un modelo de financiación.",
    "financing": "Financiación",
    "monthly_payment": "Pago mensual",
    "final_payment": "Último plazo",
    "interest_rate": "Tipo de interés",
    "availability": "Disponibilidad",
    "delivery_time_month": "Plazo de entrega (en meses)",
    "delivery_time": "Plazo de entrega",
    "cancelation_period_month": "Plazo de anulación (en meses)",
    "enter_financing_data": "Introducir datos de financiación",
    "marketing_agency": "Agencia de marketing",
    "edit_company_read_and_write_rights": "Editar los permisos de lectura y escritura de la empresa",
    "grant_data_access": "Conceder acceso a los datos",
    "deny_data_access": "Denegar el acceso a los datos",
    "allow_data_access_for_a_single_company": "Permitir el acceso a los datos de una sola empresa",
    "allow_data_access_for_a_whole_level": "Permitir el acceso a los datos de todo un nivel",
    "deny_data_access_for_a_single_company": "Denegar el acceso a los datos de una sola empresa",
    "deny_data_access_for_a_whole_level": "Denegar el acceso a los datos de todo un nivel",
    "closing_costs": "Tasa de cierre",
    "nominal_interest_rate": "Tipo de interés objetivo",
    "has_rate_insurance": "Protección de pagos",
    "is_user_based": "Se basa en el usuario",
    "min_user_count": "Número mínimo de usuarios",
    "max_user_count": "Número máximo de usuarios",
    "price_per_user": "Precio por usuario",
    "monthly": "Mensualmente",
    "instantly": "Inmediatamente",
    "quarterly": "Trimestral",
    "annual": "Anualmente",
    "monthly_fee": "Cuota mensual",
    "starting_fee": "Cuota de inscripción",
    "included_templates_count": "Número de plantillas incluidas",
    "simple_templates_count": "Número de plantillas incluidas (una)",
    "middle_templates_count": "Número de plantillas incluidas (media)",
    "complex_templates_count": "Número de plantillas incluidas (Complejo)",
    "surcharge": "Recargo",
    "base_surcharge": "Recargo básico",
    "max_surcharge": "Recargo máximo",
    "dataproof_surcharge": "Recargo Dataproof",
    "aws_domain_hosting": "Alojamiento de dominios a través de AWS",
    "domain_hosting": "Alojamiento de dominios",
    "desired_domain": "Dominio deseado",
    "redirect_uri": "Reenvío",
    "check_availability": "Consultar disponibilidad",
    "cart_edition_title": "Designación:",
    "cart_recent_edition": "Última edición:",
    "cart_campaign_title": "Campaña:",
    "cart_last_saved": "Guardado por última vez:",
    "remove_item": "Retirar el producto",
    "config": "Configure",
    "submit_config": "Confirmar configuración",
    "complete_order": "Finalizar el pedido",
    "payment_method_description": "Seleccione la forma de pago deseada",
    "cart_hint": "Nota:",
    "cart_hint_description_part_1": "Introduzca aquí únicamente comentarios generales. Los comentarios sobre productos sólo se tendrán en cuenta si ha seleccionado la opción",
    "cart_hint_description_part_2": "Comprobación manual de datos",
    "cart_hint_description_part_3": "y han introducido el comentario para el producto correspondiente.",
    "your_message": "Su mensaje",
    "hint_dataproof_1": "Ha renunciado a la comprobación manual de datos por parte de nuestro experto para uno o varios artículos.",
    "hint_dataproof_2": "Le advertimos de que, en este caso, usted es plenamente responsable de su propio diseño. En caso de errores en los datos, colores incorrectos en las fotos, etc., queda excluida cualquier reclamación. Se aplican nuestras condiciones generales.",
    "hint_dataproof_3": "Si opta por la comprobación manual de datos, un experto comprobará su expediente para detectar posibles errores (comprobación técnica, no ortográfica). En caso de que pasemos por alto algún error, asumimos la responsabilidad. Sin riesgos para usted.",
    "hint_missing_order_quantity": "Falta la cantidad del pedido",
    "missing_order_quantity": "No ha seleccionado una cantidad de pedido para uno o varios artículos.",
    "missing_invoice_shipping_address": "Falta la dirección de facturación o de entrega. Por favor, seleccione una dirección.",
    "billing_address_form_is_not_submitted": "No se ha introducido la dirección de facturación. Por favor, rellene el formulario.",
    "shipping_address_form_is_not_submitted": "La dirección de entrega no ha sido enviada. Por favor, rellene el formulario.",
    "no_invoice_address": "No se ha creado ninguna dirección de facturación",
    "no_delivery_address": "No se ha creado ninguna dirección de entrega",
    "use_invoice_contact": "Utilizar el contacto de facturación",
    "order_item": "Artículo",
    "order_price": "Precio",
    "order_copies": "Edición",
    "order_totals": "Total de pedidos",
    "subtotal": "Subtotal",
    "invoice_amount": "Importe de la factura",
    "remainder_available": "Cantidad restante disponible no distribuida",
    "reduce_edition": "Para añadir una nueva dirección, primero debe reducir el número de copias de la dirección existente.",
    "split_delivery_item_hint": "La distribución de la cantidad total aún no se ha concretado. La especificación de la cantidad debe ser un valor comprendido entre 1 y la cantidad restante que queda por distribuir o la tirada; actualmente se utiliza un valor máximo de",
    "net_amount": "Importe neto",
    "gross_amount": "Importe bruto",
    "is_debtor": "Acreedor",
    "is_debtor_number_provider": "Número de deudor Proveedor",
    "is_never_remind": "No enviar recordatorios",
    "is_demo": "Modo demostración",
    "is_vendor": "Proveedor",
    "should_user_pay": "El usuario debe pagar",
    "receive_payment_from_users": "Recibir el pago de los usuarios",
    "use_payment_receiver": "Utilizar la opción de beneficiario",
    "payment_receiver": "Beneficiario",
    "use_summary_invoice": "Utilizar factura colectiva",
    "credit_limit": "Límite de crédito",
    "commission_receiver": "Beneficiario de la Comisión",
    "budget_value": "Valor presupuestario",
    "billing_value": "Valor de liquidación",
    "coupon_billing": "Valor de facturación para el periodo de facturación seleccionado",
    "coupon_billing_period": "Período de facturación",
    "coupon_recharge_period": "Vale de recarga",
    "coupon_print_type": "Restringir bono",
    "coupon_never": "Nunca",
    "coupon_unset": "Sin restricciones",
    "coupon_print_pdf": "Imprimir sólo PDF",
    "recurring": "Recurrente",
    "period": "Periodo",
    "without_end_date": "Sin fecha final",
    "budget_settings": "Ajustes presupuestarios",
    "no_budget_set": "Sin presupuesto",
    "current_period_budget_usage": "Utilización del presupuesto en el periodo en curso",
    "user_entered_no_message": "El usuario no ha introducido ningún mensaje",
    "coupons": "Vales",
    "coupon": "Vale",
    "create_discounts_and_subsidy_products": "Cree códigos de descuento y subvencione productos para sus socios",
    "authorization_status": "Estado de la autorización",
    "user_budget": "Presupuesto del usuario",
    "manual_vat_check": "Comprobación del tipo reducido del IVA",
    "manual_data_check": "Comprobación manual de datos",
    "completed": "Completado",
    "changes_requested": "Cambios solicitados",
    "rejected": "Rechazado",
    "request_changes": "Solicitar cambios",
    "reply": "Respuestas",
    "coupon_monetary": "Descuento fijo",
    "coupon_percentage": "Porcentaje de descuento",
    "calculations": "Cálculos",
    "percent_discount_value": "Porcentaje de descuento",
    "monetary_discount_value": "Descuento por pronto pago",
    "company_budget": "Presupuesto de la empresa",
    "enter_0_to_ignore_this_setting": "Introduzca 0 para ignorar este ajuste",
    "min_order_value": "Valor mínimo del pedido",
    "max_order_discount": "Descuento máximo por pedido",
    "coupon_budget_exceeded": "Presupuesto de vales superado",
    "billing_target": "Objetivo de facturación",
    "billing_settings": "Ajustes de facturación",
    "dataproof_textarea": "¿Necesitamos considerar algo?",
    "edit": "Editar",
    "edit_client": "Editar los datos del cliente",
    "edit_gallery": "Editar los detalles de la galería",
    "edit_job": "Editar detalles del trabajo",
    "edit_position": "Editar posición",
    "edit_product": "Editar producto",
    "edit_producttemplate": "Editar plantilla",
    "edit_question": "Editar pregunta",
    "edit_template": "Editar plantilla",
    "edit_step": "Editar etapa de canalización",
    "edit_payment": "Editar pago",
    "edit_team": "Editar equipo",
    "edit_document_type": "Editar tipo de documento",
    "edit_brand": "Editar marca",
    "edit_model": "Editar modelo",
    "edit_category": "Editar categoría",
    "edit_attribute": "Editar atributo",
    "edit_data_push": "Editar datos push",
    "edit_lead_catcher": "Editar receptor principal",
    "edit_external_account": "Editar cuenta externa",
    "edit_comment": "Editar comentario",
    "edit_delivery_date": "Editar fecha de entrega",
    "edit_lead_tagger": "Editar etiquetador principal",
    "edit_commission_receiver": "Editar el beneficiario de la comisión",
    "edit_payment_receiver": "Editar beneficiario",
    "edit_billing_target": "Editar objetivo de facturación",
    "create_client": "Crear una nueva empresa",
    "create_gallery": "Crear una nueva galería",
    "create_job": "Crear un nuevo empleo",
    "create_product": "Crear un nuevo producto",
    "create_producttemplate": "Crear nueva plantilla",
    "create_questionnaire": "Crear un nuevo cuestionario",
    "create_question": "Crear una nueva pregunta",
    "create_template": "Crear una nueva plantilla de correo electrónico",
    "create_cms_template": "Crear nueva plantilla CMS",
    "create_pipeline": "Crear una nueva canalización",
    "create_step": "Crear nueva etapa de canalización",
    "create_payment_schedule": "Crear un nuevo plan de pago",
    "create_payment": "Crear un nuevo pago",
    "create_message": "Escribir un mensaje",
    "create_text_message": "Escribir un mensaje de texto (SMS)",
    "create_note": "Escribir una nota",
    "create_user": "Crear un nuevo usuario",
    "create_users": "Crear usuario",
    "create_doc_type": "Crear un nuevo tipo de documento",
    "create_team": "Crear equipo",
    "create_brand": "Crear marca",
    "create_model": "Crear modelo",
    "create_category": "Crear categoría",
    "create_attribute": "Crear atributo",
    "create_attribute_group": "Crear grupo de atributos",
    "create_car": "Crear vehículo",
    "create_item": "Crear producto",
    "create_lead": "Crear plomo",
    "assign_lead": "Asignar plomo",
    "create_page": "Crear página",
    "create_data_push": "Crear un nuevo push de datos",
    "create_white_label_project": "Crear un nuevo proyecto de marca blanca",
    "create_cms_element": "Crear un nuevo elemento CMS",
    "create_lead_catcher": "Crear un nuevo captador de pistas",
    "create_white_label_plugin": "Crear un nuevo plugin de marca blanca",
    "create_dynamic_data_definition": "Crear una nueva definición de datos dinámicos",
    "add_account": "Añadir cuenta",
    "create_external_account": "Añadir nueva cuenta externa",
    "create_model_group": "Crear un nuevo grupo de modelos",
    "create_model_asset": "Crear una nueva imagen de modelo",
    "create_lead_per_mail_rule": "Crear un nuevo cliente potencial mediante una regla de correo electrónico",
    "duplicate_page": "Página duplicada",
    "duplicate_cms_element": "Duplicar elemento CMS",
    "duplicate_cms_template": "Plantilla CMS duplicada",
    "create_task": "Crear tarea",
    "create_car_service": "Crear servicio",
    "create_car_service_station": "Crear estación de trabajo",
    "create_doc_template": "Crear una nueva plantilla de documento",
    "create_lead_autoresponse": "Crear una nueva autorespuesta de clientes potenciales",
    "create_main_menu_version": "Crear versión del menú principal",
    "create_lead_tagger": "Crear etiquetador principal",
    "create_lead_assignment_strategy": "Crear una estrategia de asignación de clientes potenciales",
    "create_escalation_rule": "Crear regla de escalada",
    "create_coupon": "Crear vale",
    "client_data_saved": "Datos de clientes almacenados",
    "user_data_saved": "Datos del usuario guardados",
    "job_data_saved": "Datos de trabajo guardados",
    "product_data_saved": "Datos del producto guardados",
    "template_data_saved": "Datos de la plantilla guardados",
    "company_logo_saved": "Se ha guardado el logotipo",
    "payment_schedule_data_saved": "Se ha guardado el plan de pago",
    "questionnaire_filled_out": "Cuestionario cumplimentado",
    "your_message_has_been_sent": "Su mensaje ha sido enviado",
    "email_with_password_reset_instructions_sent": "Se ha enviado un correo electrónico con instrucciones para restablecer la contraseña",
    "password_changed_after_reset": "Su contraseña ha sido modificada. Bienvenido de nuevo",
    "quote_accepted_saved": "Oferta aceptada",
    "quote_rejected_saved": "Oferta rechazada",
    "quote_saved": "Oferta guardada",
    "message_sent": "Mensaje enviado",
    "note_saved": "Se ha guardado la nota",
    "holding_info_saved": "Información almacenada",
    "new_password_set": "Se ha establecido una nueva contraseña",
    "questionnaire_data_saved": "Cuestionario guardado",
    "doc_type_saved": "Tipo de documento guardado",
    "team_saved": "Equipo salvado",
    "document_saved": "Documento guardado",
    "brand_saved": "Marca guardada",
    "model_saved": "Modelo guardado",
    "category_saved": "Categoría guardada",
    "attribute_saved": "Atributo guardado",
    "item_data_saved": "Datos del producto guardados",
    "car_data_saved": "Datos del vehículo almacenados",
    "variant_saved": "Variante guardada",
    "pipeline_saved": "Tuberías guardadas",
    "comment_saved": "Comentario guardado",
    "lead_saved": "Plomo salvado",
    "order_saved": "Pedido guardado",
    "document_reviewed": "Documento revisado",
    "locations_saved": "Ubicaciones guardadas",
    "email_confirmed": "Correo electrónico confirmado",
    "page_saved": "Página guardada",
    "template_saved": "Plantilla guardada",
    "data_push_saved": "Datos push guardados",
    "white_label_saved": "Proyecto de marca blanca guardado",
    "attribute_group_saved": "Grupo de atributos guardado",
    "cms_element_saved": "Elemento CMS guardado",
    "lead_catcher_saved": "Receptor principal salvado",
    "image_data_saved": "Datos de imagen guardados",
    "white_label_plugin_saved": "Plugin de marca blanca guardado",
    "dynamic_data_definition_saved": "Definición de datos dinámicos guardados",
    "company_data_saved": "Datos de la empresa almacenados",
    "contact_data_saved": "Datos de contacto guardados",
    "no_changes_made": "No se han realizado cambios",
    "car_order_approved": "Venta de coches autorizada",
    "vin_number_updated": "Número de bastidor del vehículo actualizado",
    "ein_number_updated": "Confirmación electrónica del seguro actualizada",
    "order_marked_as_paid": "Pedido marcado como pagado",
    "payment_instructions_sent": "Instrucciones de pago enviadas",
    "order_cancelled": "Pedido anulado",
    "sales_contract_uploaded": "Contrato de venta cargado",
    "bank_data_updated": "Datos bancarios actualizados",
    "pickup_invite_sent": "Envío de la invitación de recogida",
    "order_picked_up": "Pedido recogido",
    "order_assignee_kroschke_documents_sent": "El concesionario ha enviado los documentos necesarios para matricular el vehículo",
    "order_client_kroschke_documents_sent": "El cliente ha enviado los documentos necesarios para la matriculación de su vehículo",
    "plugin_partial_activated": "Plugin parcialmente activado",
    "plugin_request_activate_sent": "Solicitud de activación del plugin enviada",
    "plugin_activated": "Plugin activado",
    "plugin_terminated": "Plugin terminado",
    "changes_saved": "Cambios guardados",
    "app_secret_saved": "Secreto de aplicación guardado",
    "page_access_token_saved": "Token de acceso a la página guardado",
    "text_message_sent": "SMS enviados",
    "account_saved": "Cuenta guardada",
    "model_group_saved": "Grupo de modelos guardado",
    "model_asset_saved": "Imagen del modelo guardada",
    "sepa_agreement_saved": "Salvado el mandato de la SEPA",
    "document_uploaded": "Se ha cargado un documento",
    "customer_sent_message": "El cliente ha enviado un mensaje",
    "lead_created": "Plomo creado",
    "lead_watching": "Comenzó con la observación de las pistas",
    "lead_unwatching": "Finaliza el seguimiento de las pistas",
    "lead_per_mail_rule_saved": "Regla del correo electrónico",
    "drivers_license_uploaded": "Permiso de conducir cargado",
    "plugin_saved": "Plugin guardado",
    "settings_saved": "Ajustes guardados",
    "page_duplicated": "Página duplicada",
    "cms_template_duplicated": "Plantilla CMS duplicada",
    "cms_element_duplicated": "Elemento CMS duplicado",
    "price_negotiation_started": "Inicio de la negociación del precio",
    "new_price_negotiation_message_offer_sent": "Envío de una nueva oferta de negociación de precios",
    "you_joined_community": "Te has unido a la comunidad",
    "you_left_community": "Ha abandonado la comunidad",
    "car_service_booked": "Cita previa en línea",
    "car_service_saved": "Servicio ahorrado",
    "car_service_station_saved": "Estación de trabajo guardada",
    "team_member_added": "Miembro del equipo añadido",
    "team_member_removed": "Miembro del equipo eliminado",
    "doc_template_saved": "Plantilla de documento guardada",
    "invoice_issued": "Factura emitida",
    "request_to_fill_out_questionnaire_saved": "Solicitud para rellenar un cuestionario guardado",
    "request_to_fill_out_questionnaire_name_saved": "Solicitud para rellenar un cuestionario \"{nombre}\" guardado",
    "lead_autoresponse_saved": "Autorespuesta de clientes potenciales guardada",
    "main_menu_version_saved": "Versión del menú principal guardada",
    "lead_tagger_saved": "Etiquetador principal guardado",
    "share_settings_updated": "Ajustes de liberación actualizados",
    "lead_assignment_strategy_saved": "Estrategia de asignación de plomo guardada",
    "escalation_rule_saved": "Regla de escalada guardada",
    "value_type": "Tipo",
    "value_precision": "Precisión",
    "category_already_in_attribute": "La categoría ya está asignada al atributo.",
    "no_attributes_for_variants": "Para crear variantes es necesario definir atributos.",
    "budget_saved": "Presupuesto ahorrado",
    "budget_removed": "Presupuesto suprimido",
    "coupon_saved": "Vale guardado",
    "authorization_data_saved": "Datos de autorización almacenados",
    "template_data": "Información general",
    "template_pages": "Configuración de página",
    "template_editor": "Editor y configuración de PDF",
    "template_timeline": "Cronología",
    "template_blocks": "Bloques de contenido",
    "template_import": "Importar",
    "template_export": "Exportar",
    "template_generate": "Generador (PRUEBA)",
    "template_print_data": "Imprimir datos",
    "template_import_select_template": "Seleccione la carpeta de plantillas",
    "template_import_select_print_data_document": "Seleccionar documento de datos de impresión",
    "template_upload_print_data_title": "Cargar los datos de impresión en el directorio de plantillas",
    "template_upload_print_data_actual_file_title": "Datos de impresión cargados",
    "template_upload_print_data_info": "Añade un archivo al directorio de plantillas S3. Este archivo se utiliza para los productos cuando se crean y no se generan datos de impresión.",
    "template_blocks_template": "Bloque HTML",
    "template_category_thumb": "Clase de icono de miniatura (por ejemplo: fad fa-circle)",
    "add_block_category": "Nueva categoría",
    "edit_block_category": "Editar categoría",
    "add_block_variant": "Nueva variante",
    "edit_block_variant": "Editar variante",
    "add_block": "Nuevo bloque",
    "copy_block": "Bloque de copia",
    "edit_block": "Editar bloque",
    "edit_takeit": "Hazte cargo",
    "edit_import": "Importar",
    "edit_import_warning": "¿Está seguro? Esto sobrescribirá todas las entradas no guardadas.",
    "template_has_content_blocks": "Contenido variable",
    "template_in_test": "Modo de prueba de pedidos",
    "placeholder": "Marcador de posición para la combinación de correspondencia",
    "select_customer": "Seleccionar clientes",
    "company_added": "Empresa añadida",
    "company_removed": "Empresa eliminada",
    "item_added": "Producto añadido",
    "item_removed": "Producto retirado",
    "category_added": "Categoría añadida",
    "category_removed": "Categoría eliminada",
    "select_user_set": "Seleccionar autorización de usuario",
    "sent_reminders": "Recuerdos enviados",
    "number_of_sent_reminders": "Número de recordatorios enviados",
    "set_number": "Número de serie",
    "email_to_the_customer_on_save": "Enviar al cliente por correo electrónico al guardar",
    "email_on_save": "Correo electrónico al guardar",
    "shown_name": "Mostrar nombre",
    "filename_thumbnail": "Nombre de archivo para la miniatura",
    "name_of_imagefile": "Nombre del archivo de imagen, sin directorio. Ejemplo: thumb1.jpg",
    "block_position": "Posición del bloque en las líneas de la cuadrícula, 0 para la parte superior, hace que el bloque sea absoluto",
    "defined_raster_space": "Ubicación predefinida de la cuadrícula",
    "needed_raster_space": "Espacio de rejilla necesario",
    "size_group_info_text": "Todos los bloques con el mismo SizeGroup pueden cambiarse de tamaño en el editor",
    "size_group": "Tamaño Grupo",
    "group_index_info_text": "Todos los bloques con el mismo SizeGroup y el mismo tamaño de bloque se pueden recorrer con el índice. Desde el índice 1.",
    "group_index": "Índice de grupo",
    "bottom_info_text": "ATENCIÓN: la parte inferior coloca el bloque absolutamente en la parte inferior de la página, con la distancia establecida en PX",
    "bottom_label": "Posicionar el bloque absolutamente en la parte inferior",
    "fixed_block_label": "Bloque fijo",
    "fixed_block_info_text": " El bloque no se puede mover",
    "once_per_document_label": "Una vez por documento",
    "once_per_document_info_text": "sólo se permite una vez en todo el documento",
    "no_copies_label": "No se puede copiar el bloque",
    "no_copies_info_text": "No se puede copiar el bloque",
    "no_options_label": "No mostrar ninguna opción",
    "no_options_info_text": "El bloque no tiene opciones en el editor cuando se inserta.",
    "once_per_page_label": "Una vez por página",
    "once_per_page_info_text": "sólo se permite una vez en un lado",
    "once_per_variant_label": "Una vez por Variante",
    "once_per_variant_info_text": "Sólo se permite una vez por variante",
    "once_per_category_label": "Una vez por categoría",
    "once_per_category_info_text": "sólo se permite uno por categoría",
    "only_whitelabel_admin_can_modify_label": "Sólo administrador de etiqueta blanca",
    "only_whitelabel_admin_can_modify_info_text": "Este campo sólo puede ser modificado por el administrador de la etiqueta blanca. Debe utilizarse cuando el administrador de la etiqueta blanca crea una plantilla.",
    "restricted_to_page_label": "Permitido en la página",
    "restricted_to_page_info_text": "Sólo disponible en esta página, -1 = permitido en todas partes",
    "exclude_pages_label": "Excluir páginas (por ejemplo: 1,2,3...)",
    "exclude_pages_info_text": "No permitido en estas páginas, separar con coma",
    "only_last_label": "Sólo la última página",
    "only_last_info_text": "El bloque sólo puede insertarse en la última página.",
    "container_block_label": "Bloque contenedor",
    "container_block_info_text": "Este bloque contiene un contenedor para otros bloques",
    "inline_block_label": "Bloque en línea",
    "inline_block_info_text": "El bloque se trata como display:inline-block",
    "inner_label": "Puede introducirse en el contenedor de bloques",
    "inner_info_text": "El bloque puede introducirse en el contenedor de bloques",
    "container_class_label": "Límite al bloque de contenedores",
    "container_class_info_text": "Nombre de clase del contenedor en el que se puede insertar el bloque.",
    "only_inside_block_label": "Sólo puede introducirse en contenedores en bloque",
    "only_inside_block_info_text": "El bloque sólo puede insertarse en contenedores de bloques",
    "container_space_label": "Espacios de rejilla en el bloque contenedor",
    "container_space_info_text": "Líneas de cuadrícula disponibles dentro del bloque (¡la misma cuadrícula que la página!)",
    "container_cols_label": "Columnas del bloque contenedor",
    "container_cols_info_text": "las líneas de cuadrícula disponibles se multiplican por este valor",
    "website_only": "Sólo para sitios web",
    "navName": "Nombre para la navegación",
    "nav_link_label": "Enlace para la navegación",
    "nav_link_info_text": "Enlace de anclaje al ID del bloque",
    "nav_options": "Opciones de navegación",
    "combi_parent_label": "Elemento combinado (padre)",
    "combi_parent_info_text": "Elemento padre, puede insertarse en cualquier momento",
    "combi_child_label": "Elemento combinado (Niño)",
    "combi_child_info_text": "Los elementos hijos sólo pueden fijarse en combinación con el elemento padre",
    "disable_group_label": "Opciones de grupo",
    "disable_group_info_text": "Aquí puede definir grupos que se excluyan mutuamente, por ejemplo P#Grupo1,Grupo2 y D#Grupo2,Grupo1. Parámetro de definición - P = válido para la página, D = válido para el documento.",
    "events": {
      "TimelineMailPasswordReset": "El cliente ha solicitado el cambio de contraseña",
      "TimelineMailRegistration": "El cliente se ha registrado en Creacheck.com",
      "TimelineMailOauthRegistration": "El cliente se ha registrado en Creacheck.com con inicio de sesión en redes sociales",
      "TimelineMailInvitation": "El cliente ha sido invitado a utilizar Creacheck.com",
      "TimelineMailContactForm": "El cliente ha enviado un mensaje a través del formulario de contacto",
      "TimelineMailInvitationAccepted": "El cliente ha aceptado la invitación",
      "TimelineMailNewInvoice": "Ha emitido una factura al cliente",
      "TimelineMailNewQuote": "Ha enviado una oferta al cliente",
      "TimelineMailNewMessage": "Ha enviado un mensaje al cliente",
      "TimelineQuoteAccepted": "El cliente ha aceptado su oferta",
      "TimelineQuoteRejected": "Lamentablemente, el cliente ha rechazado su oferta",
      "TimelineOrderCreated": "Nuevo pedido",
      "TimelineMailNewCustomerMessage": "Mensaje de correo electrónico del cliente",
      "TimelineMonitoringPaymentReceipt": "Controlar los pagos recibidos",
      "TimelineClosingManualDataCheck": "Comprobación manual de datos cerrada",
      "TimelineUpdatedByForeignStatus": "Estado del pedido actualizado",
      "TimelineMailedForeignOrderStatusOrderCanceled": "cancelado por el proveedor",
      "TimelineMailedForeignOrderStatusInDelivery": "en entrega",
      "TimelineMailedForeignOrderStatusInProduction": "en producción",
      "TimelineMailedForeignOrderStatusInReview": "en consulta",
      "TimelineMailedForeignOrderStatusInOrder": "en orden",
      "TimelineMailedOrderedWebAndDownloadPDF": "PDF descargable y apto para la web que se envía a los destinatarios de correo electrónico.",
      "TimelineMailedOrderedDownloadPDF": "Descarga de PDF enviados a destinatarios de correo electrónico.",
      "TimelineMailedOrderedWebPDF": "Se envía a los destinatarios por correo electrónico.",
      "TimelineDomainHostingHasBeenAuthorized": "Se ha autorizado el alojamiento de dominios",
      "TimelineDomainNotAvailable": "El dominio ya no está disponible",
      "TimelineDomainHostingDomainValidated": "Dominio validado",
      "TimelineExceptionCheckDomainAvailability": "Error al comprobar la disponibilidad de dominio de {0}. Error: {2}",
      "TimelineDomainFailRegistering": "Fallo en el registro del dominio {0}",
      "TimelineExceptionRegisterDomain": "El registro del dominio {0} ha fallado con el error: {2}",
      "TimelineDomainSuccessfullyRegistered": "El dominio {0} se ha registrado correctamente.",
      "TimelineDomainSuccessfullyRedirected": "Dominio redirigido correctamente",
      "TimelineDomainCreatingCertificate": "El certificado para el dominio {0} se ha creado con error: {1}",
      "TimelineDomainCreatingDistributionFailed": "La distribución del dominio {0} ha fallado con el error: {1}",
      "TimelineDomainCreatingSuccessfullyHosted": "El dominio {0} se ha alojado correctamente",
      "TimelineDomainCreatingFailedHosting": "El dominio {0} no ha podido ser alojado con error: {1}",
      "TimelineDomainHostingCompleted": "Completado",
      "TimelineMissingClientDataFirstAndLastName": "Falta el nombre y apellidos del cliente.",
      "TimelineMissingClientDataPhoneNumber": "Falta el número de teléfono del cliente",
      "TimelineDomainCreatingSuccessfullyFinished": "Dominio {0} completado con éxito"
    },
    "attributeName": {
      "select": "Campo de selección",
      "html": "Campo HTML",
      "checkbox": "Casilla de verificación",
      "text": "Campo de texto",
      "colors": "Elección de colores",
      "number": "Campo numérico",
      "boolean": "Sí/No",
      "date": "fecha"
    },
    "attributeType": {
      "select": "Puede definir varias opciones para seleccionar. El usuario puede seleccionar una de ellas",
      "html": "El usuario puede escribir un texto más largo con Rich Content Editor",
      "checkbox": "Puede definir varias opciones para seleccionar. A continuación, el usuario puede seleccionar varias de ellas",
      "text": "El usuario puede introducir un texto sencillo",
      "colors": "El usuario puede seleccionar uno de los colores definidos aquí",
      "number": "El usuario puede introducir un número",
      "boolean": "El usuario puede elegir entre sí o no",
      "date": "El usuario puede seleccionar una fecha"
    },
    "attributeValueType": {
      "string": "Texto",
      "number": "Número",
      "date": "fecha"
    },
    "attributePrecision": {
      "year": "Año",
      "month": "mes",
      "day": "Día",
      "time": "Hora del día"
    },
    "categoryDisplay": {
      "select": "Sí/no",
      "number_range": "Rango de números",
      "date_range": "Intervalo de fechas",
      "list": "Selección de listas"
    },
    "invoice_actions": {
      "tab_title": "Acciones sobre la factura",
      "refunded": {
        "title": "Reembolsado manualmente",
        "label": "Marcar como reembolsado",
        "info_text": "Marcado manual de la factura como ya reembolsada sin crear una factura de anulación"
      },
      "denied_to_pay": {
        "title": "Pago denegado",
        "label": "Marcar como denegado",
        "info_text": "Marcar que la factura nunca se pagará"
      },
      "amount_paid": {
        "title": "Introduzca el importe pagado",
        "placeholder": "Introduzca el importe pagado",
        "button": "Guardar el importe pagado"
      },
      "sent_reminders": {
        "title": "Recordatorios enviados",
        "placeholder": "Número de recordatorios enviados",
        "button": "Guardar número"
      }
    },
    "mail": {
      "registration": {
        "subject": "Gracias por registrarse en %company%.",
        "subject_robethood": "Robethood Creatives Portal: Registro realizado con éxito.",
        "explanation": "Gracias por registrarse. Confirme su dirección de correo electrónico en un plazo de 24 horas. Sólo con una dirección de correo electrónico confirmada podrá recibir una nueva contraseña de Internet o información sobre cambios.",
        "explanation_first_name_form": "<p>Gracias por registrarse. Por favor, confirme su dirección de correo electrónico en un plazo de 24 horas.</p><p>Sólo con una dirección de correo electrónico confirmada podrá recibir una nueva contraseña o información sobre cambios.</p> <p>Sólo con una dirección de correo electrónico confirmada podrá recibir una nueva contraseña o información sobre cambios.",
        "possible_actions": "Una vez confirmada su dirección de correo electrónico, tendrá acceso inmediato a todas las funciones que hemos preparado para usted.",
        "possible_actions_first_name_form": "Una vez confirmada su dirección de correo electrónico, tendrá acceso inmediato a todas las funciones que hemos preparado para usted.",
        "call_to_action": "Confirmar mi dirección de correo electrónico"
      },
      "ordered_web_pdf": {
        "subject": "PDF habilitado para web para el pedido %orderId% de %company%.",
        "explanation": "Gracias por su pedido. Puede abrir y descargar el PDF habilitado para Internet haciendo clic en el botón de abajo.",
        "explanation_first_name_form": "Gracias por su pedido. Puede abrir y descargar el PDF habilitado para Internet haciendo clic en el botón de abajo.",
        "possible_actions": "Puede iniciar sesión en el portal en línea en %domain% en cualquier momento y ver su pedido.",
        "possible_actions_first_name_form": "Puede iniciar sesión en el portal en línea en %domain% en cualquier momento y ver su pedido.",
        "call_to_action": "Orden de exposición",
        "download": "Descargar PDF habilitado para web"
      },
      "ordered_download_pdf": {
        "subject": "PDF para el pedido %orderId% de %company%.",
        "explanation": "Gracias por su pedido. Puede abrir y descargar el PDF haciendo clic en el botón de abajo.",
        "explanation_first_name_form": "Gracias por su pedido. Puede abrir y descargar el PDF haciendo clic en el botón de abajo.",
        "possible_actions": "Puede iniciar sesión en el portal en línea en %domain% en cualquier momento y ver su pedido.",
        "possible_actions_first_name_form": "Puede iniciar sesión en el portal en línea en %domain% en cualquier momento y ver su pedido.",
        "call_to_action": "Orden de exposición",
        "download": "Descargar PDF"
      },
      "order_vat_data_check_done": {
        "subject": "Su pedido para la comprobación manual de datos para el pedido %orderId% de %company%.",
        "explanation": "Gracias por su pedido y por encargar la comprobación manual de datos.",
        "possible_actions": "Puede iniciar sesión en el portal en línea en %domain% en cualquier momento y ver su pedido.",
        "call_to_action": "Mostrar estado"
      },
      "order_reduced_vat_authorized": {
        "subject": "Se ha aprobado su solicitud de tipo reducido de IVA para el pedido %orderId%.",
        "explanation": "Gracias por su pedido. Hemos autorizado el pedido para el tipo de IVA reducido.",
        "possible_actions": "Puede iniciar sesión en el portal en línea en %domain% en cualquier momento y ver su pedido.",
        "call_to_action": "Mostrar estado"
      },
      "order_reduced_vat_rejected": {
        "subject": "Su solicitud de un tipo de IVA reducido para el pedido %orderId% no ha sido aprobada.",
        "explanation": "Gracias por su pedido. Hemos comprobado que su pedido no puede acogerse al tipo reducido de IVA. Se aplicará el tipo normal de IVA.",
        "possible_actions": "Puede iniciar sesión en el portal en línea en %domain% en cualquier momento y ver su pedido.",
        "call_to_action": "Mostrar estado"
      },
      "chedri_registration": {
        "subject": "Su inscripción en la %empresa",
        "subject_fiendly": "Su inscripción en %company%.",
        "explanation": "<p>Hola %firstName% %lastName%,</p><p>Gracias por su registro.</p><p>Hemos creado con éxito una cuenta de prueba para usted y su empresa.</p> <p>Por favor, póngase en contacto con nosotros.",
        "possible_actions": "Una vez confirmada su dirección de correo electrónico, puede iniciar sesión con <strong>%username%</strong> como nombre de usuario y la contraseña que seleccionó durante el registro.",
        "call_to_action": "Confirmar mi dirección de correo electrónico"
      },
      "invitation": {
        "subject": "Invitación de %empresa",
        "explanation": "En nombre de %company%, nos complace invitarle a nuestro portal de diseño en línea.",
        "login_data": "Si acepta la invitación, utilice los siguientes datos para iniciar sesión: <strong>%username%</strong> como nombre de usuario y <strong>%password%</strong> como contraseña. Asegúrese de cambiar su contraseña después de iniciar sesión.",
        "call_to_action": "Aceptar invitación",
        "explanation_first_name_form": "En nombre de %company%, nos complace invitarle a nuestro portal de diseño en línea.",
        "login_data_first_name_form": "Si acepta la invitación, utilice los siguientes datos para iniciar sesión: <strong>%username%</strong> como nombre de usuario y <strong>%password%</strong> como contraseña. Asegúrese de cambiar su contraseña después de iniciar sesión."
      },
      "new_quote": {
        "subject": "Nueva oferta de %empresa",
        "explanation": "%empresa% le enviará un presupuesto para un servicio que ha solicitado.",
        "possible_actions": "Ahora tienes la opción de comprobar y aceptar la oferta. También puedes rechazar la oferta y dejar un mensaje explicando por qué.",
        "call_to_action": "Comprobar oferta"
      },
      "new_invoice": {
        "subject": "Nueva factura de %empresa",
        "explanation": "%empresa% le enviará una factura por un servicio que haya solicitado. Encontrará la factura en nuestro portal en línea. También hemos adjuntado la factura a este mensaje.",
        "explanation_first_name_form": "%empresa% le enviará una factura por un servicio que haya solicitado. Encontrará la factura en nuestro portal en línea. También hemos adjuntado la factura a este mensaje.",
        "possible_actions": "Puede conectarse en cualquier momento al portal en línea de %domain% y consultar sus facturas.",
        "possible_actions_first_name_form": "Puede conectarse en cualquier momento al portal en línea de %domain% y consultar sus facturas.",
        "call_to_action": "Mostrar factura"
      },
      "new_sales_commission": {
        "subject": "Nueva comisión de %empresa%.",
        "explanation": "%empresa% le enviará una comisión por un servicio que haya solicitado. Encontrará la comisión en nuestro portal en línea. También hemos adjuntado la comisión a este mensaje.",
        "possible_actions": "Puede iniciar sesión en el portal en línea en %domain% en cualquier momento y ver su comisión en línea.",
        "call_to_action": "Mostrar comisión"
      },
      "new_questionnaire": {
        "subject": "%empresa% desea que rellene un cuestionario",
        "explanation": "<p>Hola %firstName% %lastName%,</p><p>%company% le envía un nuevo cuestionario.</p>",
        "possible_actions": "Puede consultar y rellenar el cuestionario en línea.",
        "call_to_action": "Ver cuestionario"
      },
      "questionnaire_filled_out": {
        "subject": "Se acaba de rellenar el cuestionario",
        "explanation": "<p>Hola %firstName% %lastName%,</p><p>El cuestionario solicitado por %targetFirstName% acaba de ser completado.</p>",
        "possible_actions": "Puede ver las respuestas en la línea de tiempo principal.",
        "call_to_action": "Plomo abierto"
      },
      "contact_form_cms": {
        "subject": "Nuevo contacto de %name%.",
        "info": "Notificación del envío de un formulario de contacto. Por favor, tramítelo inmediatamente y asegúrese de que el cliente recibe la mejor asistencia posible."
      },
      "contact_form_edition": {
        "subject": "Nuevo contacto",
        "info": "<p>El formulario de contacto ha sido utilizado. Ahora asegúrate de ofrecer el mejor servicio de atención al cliente posible :) Buena suerte</p>",
        "form_fields": "Campos de formulario rellenados"
      },
      "password_renew": {
        "subject": "Renovar contraseña",
        "explanation": "<p>Hola %email%,<br><br>Ha intentado iniciar sesión en %platformName%. Su contraseña ha caducado. Necesitas establecer una nueva contraseña para poder iniciar sesión en el futuro. Haga clic en el enlace de abajo para introducir una nueva contraseña.</p>",
        "possible_actions": "Tiene la posibilidad de establecer una nueva contraseña. Basta con hacer clic en el botón.",
        "call_to_action": "Establecer nueva contraseña",
        "explanation_first_name_form": "<p>Hola %email%,<br><br>ha intentado iniciar sesión en %platformName%. Su contraseña ha caducado. Necesitas establecer una nueva contraseña para poder iniciar sesión en el futuro. Haga clic en el enlace de abajo para introducir una nueva contraseña.</p>",
        "possible_actions_first_name_form": "Tiene la posibilidad de establecer una nueva contraseña. Basta con hacer clic en el botón."
      },
      "password_reset": {
        "subject": "Restablecer contraseña",
        "explanation": "<p>Buenas tardes %email%,</p><br /><p>se acaba de solicitar una nueva contraseña a %platformName% a través de nuestra plataforma. Si ha realizado esta solicitud, por favor haga clic en el siguiente enlace.</p",
        "explanation_first_name_form": "<p>Hola %email%,</p><br /><p>se acaba de solicitar una nueva contraseña a %platformName% a través de nuestra plataforma. Si ha realizado esta solicitud, haga clic en el siguiente enlace.</p",
        "possible_actions": "Tiene la posibilidad de establecer una nueva contraseña. Basta con hacer clic en el botón.",
        "possible_actions_first_name_form": "Tiene la posibilidad de establecer una nueva contraseña. Basta con hacer clic en el botón.",
        "call_to_action": "Establecer nueva contraseña"
      },
      "message": {
        "explanation": "%empresa% le ha enviado un mensaje",
        "possible_actions": "Puede responder a este mensaje pulsando este botón:",
        "call_to_action": "Respuestas"
      },
      "customerMessage": {
        "explanation": "El cliente le ha enviado un mensaje"
      },
      "lead_comment_mention": {
        "subject": "Fueron mencionados en el %title% lead",
        "explanation": "%creator% te mencionó en un comentario.",
        "call_to_action": "Plomo abierto"
      },
      "lead_assignee_changed": {
        "subject_assigned": "Ahora estás asignado al cliente principal \"%title%\".",
        "subject_unassigned": "Ya no se asignan al lead \"%title%\".",
        "explanation_assigned": "Ahora estás asignado al lead '%title%'. El editor anterior era %oldAssignee%.",
        "explanation_unassigned": "Ya no están asignados al lead '%title%'. El nuevo asignado es %newAssignee%.",
        "call_to_action": "Plomo abierto"
      },
      "bank_data_reminder": {
        "subject": "Necesitamos sus datos bancarios para su pedido",
        "explanation": "Ha reservado servicios para los que se requieren datos bancarios.",
        "possible_actions": "Por favor, vaya al pedido e introduzca los datos que faltan.",
        "call_to_action": "Introducir datos bancarios"
      },
      "order_approved": {
        "subject": "Su pedido con %company% acaba de ser aprobado",
        "explanation": "Nos complace informarle de que su pedido %orderId% ha sido aprobado.",
        "possible_actions": "Puede ir a su pedido para ver los detalles.",
        "call_to_action": "Orden de exposición"
      },
      "sales_contract_uploaded": {
        "subject": "El contrato de compra de su pedido ya está disponible"
      },
      "order_cancelled": {
        "subject": "Su pedido con %company% ha sido cancelado",
        "explanation": "Lo sentimos, pero hemos tenido que cancelar su pedido %orderId%.",
        "possible_actions": "Vaya a su pedido para ver los detalles.",
        "call_to_action": "Orden de exposición"
      },
      "payment_instructions": {
        "subject": "Información de pago para su pedido en %company%.",
        "explanation": "A continuación encontrará la información de pago de su pedido %orderId%:",
        "possible_actions": "Vaya a su pedido para ver los detalles.",
        "call_to_action": "Orden de exposición"
      },
      "pickup_invite": {
        "subject": "Su pedido está listo para ser recogido",
        "explanation": "Su pedido con el %orderId% está listo para ser recogido.",
        "possible_actions": "Vaya a su pedido para ver los detalles.",
        "call_to_action": "Orden de exposición"
      },
      "order_picked_up": {
        "subject": "Gracias por su pedido"
      },
      "request_plugin_activation": {
        "subject": "Activación de plugin solicitada",
        "explanation": "<p>Hola %firstName% %lastName%,</p><p>%creatorFirstName% %creatorLastName% desea activar el plugin <strong>%pluginName%</strong> para el proyecto %platformName%.</p>",
        "possible_actions": "Puede conectarse y utilizar el formulario de activación pulsando el siguiente botón.",
        "call_to_action": "Activar el plugin"
      },
      "plugin_activated": {
        "subject": "Plugin activado",
        "explanation": "<p>Hola %firstName% %lastName%,</p><p>Nos gustaría informarle de que el plugin %pluginName% para el proyecto %platformName% ha sido activado.</p> <p><strong>Nombre de la plataforma%</strong>.",
        "call_to_action": "Véalo en acción"
      },
      "lead_data": {
        "subject": "Nueva pista: %title%.",
        "call_to_action": "Mostrar plomo"
      },
      "order": {
        "item_sales": {
          "title": "Su pedido",
          "explanation": "Los detalles de su pedido figuran de nuevo a continuación.",
          "subject": "Su pedido con %company%.",
          "possible_actions": "También puede encontrar toda la información sobre su pedido en su cuenta de cliente.",
          "call_to_action": "Ver en cuenta de cliente"
        }
      },
      "rent_sync_vamoso_error": {
        "subject": "Pedido #%orderId%: Error en Rent Sync para Vamoso Plugin: %errorType%.",
        "explanation": "Desafortunadamente, nuestro plugin no pudo realizar una tarea en tu sistema Vamoso. Por favor, comprueba el estado de la reserva manualmente. <br/>Tipo de error: %errorType% <br/>Mensaje de error: %errorText%."
      },
      "lead_deadline_reminder": {
        "subject": "Se asignan a las pistas que vencen mañana",
        "explanation": "Aquí encontrará una lista de las pistas que se le han asignado. Estas pistas siguen abiertas y vencen mañana."
      },
      "chat_history": {
        "subject": "Tu historial de chat",
        "explanation": "Gracias por ponerse en contacto con nosotros. Le adjuntamos su historial de chat con %firstName% %lastName%. Espero que hayamos podido ayudarle con todas sus preguntas o problemas. Si tiene más preguntas, no dude en volver a ponerse en contacto con nosotros en cualquier momento.",
        "greatingchat_history": "Historial del chat",
        "greating": "Gracias por usar el chat de %platformName%. Tu compañero de chat era %firstName% %lastName% A continuación puedes ver el historial de chat desde %date%.",
        "adaption": "Espero que hayamos podido ayudarle con todas sus preguntas o problemas. Si tiene más preguntas, no dude en volver a ponerse en contacto con nosotros en cualquier momento.",
        "privacy_policy": "Puede consultar nuestra política de privacidad en <a href=\"%link%\" target=\"_blank\">'%link%</a>.",
        "call_to_action": "Visítenos de nuevo"
      },
      "lead_pool_notification": {
        "subject": "Nueva pista en la piscina: %title%.",
        "explanation": "Hay un nuevo cliente potencial disponible en el sistema. Aún no se le ha asignado un empleado. Haga clic en el botón de abajo para ver los detalles.",
        "call_to_action": "Plomo abierto"
      },
      "lead_escalation_level1": {
        "subject": "[NIVEL1] %nombre",
        "explanation": "Está recibiendo este correo electrónico porque uno o más clientes potenciales han alcanzado el primer nivel de escalado definido en la regla %name%. Compruebe los clientes potenciales para ver qué hay que hacer."
      },
      "lead_escalation_level2": {
        "subject": "[NIVEL2] %nombre",
        "explanation": "Está recibiendo este correo electrónico porque uno o más clientes potenciales han alcanzado el segundo nivel de escalado definido en la regla %name%. Por favor, compruebe los leads e intente motivar a sus equipos para que trabajen más."
      },
      "campaign_budget_auth_request": {
        "subject": "Advertencia: Se ha superado el presupuesto de la campaña.",
        "explanation": "se superó el presupuesto de la campaña.",
        "call_to_action": "Orden de exposición"
      },
      "campaign_budget_auth_request_unsuccessful": {
        "subject": "Advertencia: Problema con la autorización del presupuesto de la campaña.",
        "explanation": "Se ha detectado un problema con la autorización del presupuesto de la campaña.",
        "call_to_action": "Orden de exposición"
      },
      "user_budget_auth_request": {
        "subject": "Advertencia: Se ha superado el presupuesto de usuario.",
        "explanation": "se ha superado el presupuesto del usuario.",
        "call_to_action": "Orden de exposición"
      },
      "user_budget_auth_request_unsuccessful": {
        "subject": "Advertencia: Problema con la autorización del presupuesto del usuario",
        "explanation": "Se ha detectado un problema con la autorización del presupuesto del usuario.",
        "call_to_action": "Orden de exposición"
      },
      "order_auth_changes_requested": {
        "subject": "Info: Cambios solicitados",
        "call_to_action": "Orden de exposición"
      },
      "order_auth_changes_implemented": {
        "subject": "Info: Cambios realizados",
        "call_to_action": "Orden de exposición"
      },
      "manage_notification": "Gestionar las notificaciones por correo electrónico",
      "unsubscribe": "Cancelar suscripción",
      "confidentiality_notice": "Aviso de confidencialidad: Este correo electrónico está destinado exclusivamente al destinatario especificado y puede contener información confidencial y/o legalmente protegida. Si usted no es el destinatario correcto o ha recibido este correo electrónico por error, informe inmediatamente al remitente y destruya este correo electrónico. No se permite la copia ni el reenvío no autorizados de este correo electrónico.",
      "no_legal_binding_notice": "Este mensaje tiene carácter meramente informativo y no es jurídicamente vinculante. Debido a la facilidad de manipulación de los correos electrónicos, no podemos aceptar ninguna responsabilidad por su contenido.",
      "invoice_due_reminder": {
        "subject": "Recordatorio de pago",
        "explanation": "Lamentablemente, aún no hemos podido constatar la recepción del pago de la siguiente factura.",
        "explanation_first_name_form": "Lamentablemente, aún no hemos podido constatar la recepción del pago de la siguiente factura.",
        "request_payment": "Para evitar costes adicionales, solicitamos el pago inmediato del importe total. Por favor, utilice los siguientes datos bancarios para el pago de esta factura:",
        "request_payment_first_name_form": "Para evitar costes adicionales, le rogamos que abone inmediatamente el importe total. Utilice los siguientes datos bancarios para pagar esta factura:",
        "contact": "Si tiene alguna pregunta sobre este recordatorio, póngase en contacto con nosotros por correo electrónico en %email% o por teléfono en la línea directa %phone%.",
        "contact_first_name_form": "Si tiene alguna pregunta sobre este recordatorio, póngase en contacto con nosotros por correo electrónico en %email% o por teléfono en la línea directa %phone%.",
        "bank_account": "Datos bancarios",
        "bank_name": "Nombre del banco",
        "account_holder": "Titular de la cuenta",
        "iban": "IBAN",
        "swift_bic": "SWIFT/BIC",
        "bank_code_number": "Código bancario",
        "account_number": "Número de cuenta",
        "invoice_number": "Nº de factura"
      },
      "last_seen_reminder": {
        "subject": "Recordatorio de última visita",
        "user_not_seen": "El usuario '%clientUsername%' no ha sido visto durante %daysNotSeen% días.",
        "contact_user": "Póngase en contacto con ellos para asegurarse de que todo está en orden.",
        "contact_user_first_name_form": "Póngase en contacto con ellos para asegurarse de que todo está en orden.",
        "customer_profil": "Perfil del cliente"
      },
      "order_foreign_status": {
        "do_contact_support": {
          "subject": "Pedido - Póngase en contacto con el servicio de atención al cliente",
          "explanation": "Gracias por su pedido. Tenemos una pregunta sobre su pedido. Póngase en contacto con nuestro servicio de atención al cliente.",
          "explanation_first_name_form": "Gracias por su pedido. Tenemos una pregunta sobre su pedido. Póngase en contacto con nuestro servicio de atención al cliente.",
          "possible_actions": "-",
          "contact": "Póngase en contacto con nosotros",
          "phone": "Teléfono:",
          "email": "correo electrónico:",
          "call_to_action": "-"
        },
        "in_delivery": {
          "subject": "El pedido se está enviando actualmente",
          "explanation": "Su pedido ha sido producido y entregado al mensajero para su entrega.",
          "explanation_first_name_form": "Su pedido ha sido producido y entregado al mensajero para su entrega.",
          "possible_actions_tracking": "Puede hacer un seguimiento de la entrega en el sitio web del servicio de mensajería.",
          "possible_actions_tracking_first_name_form": "Puede hacer un seguimiento de la entrega en el sitio web del servicio de mensajería.",
          "possible_actions": "Puede iniciar sesión en el portal en línea en %domain% en cualquier momento y ver su pedido.",
          "possible_actions_first_name_form": "Puede iniciar sesión en el portal en línea en %domain% en cualquier momento y ver su pedido.",
          "call_to_action": "Detalles del pedido",
          "tracking_numbers": "Números de seguimiento"
        },
        "in_production": {
          "subject": "El pedido se está produciendo actualmente",
          "explanation": "Gracias por su pedido. Hemos realizado su pedido. En estos momentos se está fabricando.",
          "explanation_first_name_form": "Gracias por su pedido. Hemos realizado su pedido. En estos momentos se está fabricando.",
          "possible_actions": "Puede iniciar sesión en el portal en línea en %domain% en cualquier momento y ver su pedido.",
          "possible_actions_first_name_form": "Puede iniciar sesión en el portal en línea en %domain% en cualquier momento y ver su pedido.",
          "call_to_action": "Detalles del pedido"
        },
        "invalid_print_data": {
          "subject": "Pedido - datos de impresión no válidos",
          "explanation": "Se han encontrado datos de impresión no válidos durante el pedido.",
          "call_to_action": "Detalles del pedido"
        },
        "missing_print_data": {
          "subject": "Pedido - faltan datos de impresión",
          "explanation": "Se han encontrado datos de impresión que faltaban al realizar el pedido.",
          "call_to_action": "Detalles del pedido"
        },
        "order_canceled": {
          "subject": "El pedido ha sido anulado",
          "explanation": "Su pedido ha sido cancelado con éxito. Sentimos que haya tenido que cancelar su pedido y esperamos poder ayudarle de nuevo en el futuro.",
          "explanation_first_name_form": "Su pedido ha sido cancelado con éxito. Sentimos que haya tenido que cancelar su pedido y esperamos poder ayudarle de nuevo en el futuro.",
          "possible_actions": "Puede iniciar sesión en el portal en línea en %domain% en cualquier momento y ver su pedido.",
          "possible_actions_first_name_form": "Puede iniciar sesión en el portal en línea en %domain% en cualquier momento y ver su pedido.",
          "call_to_action": "Detalles del pedido"
        },
        "payment_pending": {
          "subject": "Orden de compra - pago pendiente",
          "explanation": "Aún no hemos recibido el pago de su pedido.",
          "explanation_first_name_form": "Aún no hemos recibido el pago de su pedido.",
          "possible_actions": "Puede iniciar sesión en el portal en línea en %domain% en cualquier momento y ver su pedido.",
          "possible_actions_first_name_form": "Puede iniciar sesión en el portal en línea en %domain% en cualquier momento y ver su pedido.",
          "call_to_action": "Detalles del pedido"
        }
      },
      "contact_form_cms_confirm": {
        "subject": "Confirme su dirección de correo electrónico",
        "explanation": "<p>Su correo electrónico ha sido introducido en el formulario de contacto de nuestro sitio web.</p><p>Si ha rellenado nuestro formulario de contacto, haga clic en el botón Confirmar.</p><p>De lo contrario, simplemente ignore este mensaje.</p>",
        "call_to_action": "Confirme"
      },
      "contact_form_edition_confirm": {
        "subject": "Confirme su dirección de correo electrónico",
        "explanation": "<p>Su dirección de correo electrónico ha sido introducida en el formulario de contacto de nuestro sitio web.</p><p>Si ha completado nuestro formulario de contacto, haga clic en el botón Confirmar.</p><p>De lo contrario, simplemente ignore este mensaje.</p>",
        "call_to_action": "Confirme"
      },
      "order_content_check_status": {
        "subject": "la comprobación del contenido del pedido %orderId% fue %status%.",
        "explanation": "Gracias por su pedido. Hemos comprobado el contenido de su pedido y le hemos dado %status% .",
        "possible_actions": "Puede llamar a su pedido para ver los detalles.",
        "call_to_action": "Ver pedido",
        "reason": "Razón"
      },
      "lead_follow_up_reminder": {
        "subject": "Compruebe las tareas asignadas",
        "explanation": "Aquí encontrará una lista de las tareas que se le han asignado. Estas tareas siguen abiertas."
      }
    },
    "contact_form_cms_confirm": {
      "success": {
        "heading": "Su consulta ha sido enviada",
        "body": "<p>Gracias por ponerse en contacto con nosotros</p><p>Hemos recibido su consulta y nos pondremos en contacto con usted lo antes posible.</p> <p>Por favor, no dude en ponerse en contacto con nosotros."
      },
      "already_confirmed": {
        "heading": "Esta solicitud ya ha sido confirmada",
        "body": "<p>Hemos recibido su consulta y nos pondremos en contacto con usted lo antes posible.</p>"
      },
      "not_found": {
        "heading": "No se ha encontrado",
        "body": "<p>Por favor, asegúrese de que ha hecho clic en el botón de nuestro correo electrónico para llegar hasta aquí.</p> <p>Por favor, asegúrese de que ha hecho clic en el botón de nuestro correo electrónico para llegar hasta aquí."
      }
    },
    "contact_form_edition_confirm": {
      "success": {
        "heading": "Su consulta ha sido enviada",
        "body": "<p>Gracias por ponerse en contacto con nosotros</p><p>Hemos recibido su consulta y nos pondremos en contacto con usted lo antes posible.</p> <p>Por favor, no dude en ponerse en contacto con nosotros."
      },
      "already_sent": {
        "heading": "Esta solicitud ya ha sido confirmada",
        "body": "<p>Hemos recibido su consulta y nos pondremos en contacto con usted lo antes posible.</p>"
      },
      "not_found": {
        "heading": "No se ha encontrado",
        "body": "<p>Por favor, asegúrese de que ha hecho clic en el botón de nuestro correo electrónico para llegar hasta aquí.</p> <p>Por favor, asegúrese de que ha hecho clic en el botón de nuestro correo electrónico para llegar hasta aquí."
      },
      "expired": {
        "heading": "Código caducado",
        "body": "<p>El enlace de confirmación sólo era válido durante 2 días. Por favor, rellene el formulario de contacto de nuevo.</p> <p>"
      }
    },
    "formGroup": {
      "basic_information": "Información básica",
      "contact_person": "Persona de contacto",
      "address_information": "Dirección",
      "address_settings": "Configuración de la dirección",
      "bank_account": "Datos bancarios",
      "company_information": "Información sobre la empresa",
      "user_roles": "Funciones de los usuarios",
      "subscription_roles": "Suscripción del usuario",
      "opening_hours": "Horarios de apertura",
      "position_on_map": "Posición en el mapa",
      "white_label_parent": "Precursor",
      "user_teams": "Equipos de usuarios",
      "contact_data": "Datos de contacto",
      "permissions": "Autorizaciones",
      "person_information": "Información para el usuario"
    },
    "hints": {
      "small_hint_for_you": "Un pequeño consejo",
      "task_is_automated": "Esta tarea está automatizada. Debe esperar hasta que se ejecute la tarea automatizada. Después, la marcaremos como completada por ti.",
      "please_complete_previous_tasks": "No puede editar esta tarea ahora. Por favor, ocúpese primero de las tareas anteriores.",
      "companyLogoNew": "<p>Aún no ha cargado su logotipo. Para subir uno, basta con arrastrar y soltar una imagen (jpeg o png) en esta página.</p> <p><strong>Su logotipo</strong>.",
      "companyLogoChange": "<p>Este es el logo que has subido. Para cambiarlo, simplemente arrastre y suelte una imagen (jpeg o png) en esta página.</p>)",
      "companyFinancial": "<div><p>Por favor, rellene la información necesaria para la facturación</p><p>Los datos bancarios que rellene aquí se mostrarán a pie de página en sus presupuestos y facturas</p></div>",
      "companyPaymentInfo": "<p>Esta información se muestra en cada factura que creas.</p"
    },
    "vendors": {
      "association": "Asociación con los datos del cliente",
      "vendor_api": "Nombre del servicio",
      "pricing": "Precios",
      "operating_mode": "Modo de funcionamiento",
      "price_update_method": "Método de actualización de precios",
      "order_processing": "Tramitación de pedidos",
      "order_method": "Método de pedido",
      "order_email": "Dirección de correo electrónico para pedidos",
      "phone_number": "Número de teléfono",
      "web_shop_url": "URL de la tienda",
      "trial": "ensayo",
      "live": "en directo",
      "prices_by_vendor": "Los precios los gestiona el proveedor",
      "prices_by_employee": "El empleado comprueba y mantiene los precios",
      "prices_by_api": "Los precios se actualizan a través de una API",
      "notified_by_email": "Se le notificarán los pedidos recibidos por correo electrónico",
      "accepts_only_email": "Sólo acepta pedidos por correo electrónico",
      "use_webshop": "Pedidos a través de la tienda web",
      "order_by_api": "Pedidos vía API",
      "order_by_api_and_webshop": "Pedidos a través de API o tienda web"
    },
    "client_account": "Cuenta cliente",
    "deleted_campaigns": "Campañas suprimidas",
    "deleted_editions": "Ediciones suprimidas",
    "deleted_folders": "Carpetas eliminadas",
    "deleted_media": "Medios eliminados",
    "root": "Directorio raíz",
    "trash_bin": "Papelera",
    "delete_trash_bin": "Papelera vacía",
    "pls_wait": "Por favor, espere. Se ejecuta la acción deseada.",
    "go_back": "Volver",
    "restore": "Restaurar",
    "archived_campaigns": "Campañas archivadas",
    "load_all_campaigns": "Cargar todas las campañas",
    "load_creacheck_campaigns": "Cargar campañas Creacheck",
    "load_flyeralarm_campaigns": "Cargar campañas Flyeralarm",
    "load_designgenie_campaigns": "Cargar las campañas DesignGenie",
    "archived_editions": "Ediciones archivadas",
    "sorting_name": "Nombre",
    "sorting_date_ASC": "El más antiguo primero",
    "sorting_date_DESC": "Lo más nuevo primero",
    "sorting_owner": "Empresa",
    "sorting_creator": "Creado por",
    "campaign_title": "Campaña",
    "dataproof": "Comprobación de datos",
    "not_ordered": "no solicitado",
    "supplier_order": "Pedido al proveedor",
    "change_of_address": "Cambio de dirección",
    "not_available": "no disponible",
    "selected_shipping_method": "Método de envío seleccionado",
    "ordered_items": "Artículos pedidos",
    "standard": "Estándar",
    "express": "Express",
    "aws_iframe_hosting": "Alojamiento de AWS Iframe",
    "aws_hosting_html_file_for_iframe_downloaded": "AWS Hosting Archivo HTML para iframe con título: %title%.",
    "lead_stage_change_confirm_title": "Cambiar el estado del plomo",
    "lead_stage_change_confirm_body": "¿De verdad quieres cambiar el estado del plomo?",
    "iframe_implementation_file": "Archivo de implementación Iframe",
    "iframe_implementation_file_download_failed": "No se ha podido descargar el archivo de implementación Iframe: Error",
    "lead_stage_change_error_domain_hosting_in_progress": "La línea no puede modificarse si la fase actual está en curso o si la autorización no ha sido aprobada.",
    "lead_stage_change_error_domain_hosting_command_to_start_pipeline_has_been_sent": "La línea no puede modificarse si la fase actual está en curso o si la autorización no ha sido aprobada.",
    "allowed_domain_already_exists": "El dominio %dominio% ya está incluido en la lista de dominios autorizados.",
    "allowed_domain_domain_name_should_not_be_empty": "El nombre de dominio no debe estar vacío.",
    "allowed_domains_title": "Dominios permitidos",
    "allowed_domains_description": "Aquí puede añadir dominios que pueden utilizarse para la implementación de iframes.",
    "allowed_domains_add": "Añadir dominio",
    "allowed_domains_domain_name": "Nombre de dominio",
    "allowed_domains_default_src": "Origen por defecto",
    "allowed_domains_script_src": "Script-Src",
    "allowed_domains_connect_src": "Connect-Src",
    "allowed_domains_style_src": "Estilo-Src",
    "allowed_domains_img_src": "Img-Src",
    "allowed_domains_font_src": "Fuente",
    "allowed_domains_frame_src": "Frame-Src",
    "created_at": "Creado el",
    "created_by": "Creado por",
    "overnight": "Durante la noche",
    "create_printData": "Crear datos de impresión",
    "created_new_printData": "Se han creado nuevos datos de impresión",
    "uploaded_printData_successful": "Los datos de impresión se han cargado correctamente",
    "no_printData": "No hay datos de impresión",
    "no_images": "No hay archivos de imagen disponibles",
    "not_the_original_printFile": "Nota: Este no es el archivo de impresión original",
    "printDataFiles": "Imprimir archivo(s) de datos",
    "image_files": "Archivos de imagen",
    "customer_comment": "Comentario del cliente",
    "actions_": " Acciones",
    "import_success": "Su importación se ha ejecutado",
    "import_rejected": "Su importación ha sido cancelada",
    "import_exec": "Su importación se ejecuta",
    "import_successRE": "Su importación se ha restablecido",
    "import_rejectedRE": "El proceso se canceló",
    "import_execRE": "Su importación se restablecerá",
    "choice_csv": "Seleccione un archivo CSV",
    "csv_content": "Contenido CSV",
    "warning_and_notes": "Advertencias y notas",
    "start_remove": "Iniciar la retirada",
    "reverse_remove": "Deshacer la eliminación",
    "user_import_success": "Importación correcta",
    "user_duplicate_import": "Se ha encontrado una entrada duplicada",
    "start_import": "Iniciar importación",
    "reverse_import": "Deshacer la importación",
    "upload_local_changes": "Cargar cambios locales",
    "dataproof_master_copies_upload": "Aquí puede cargar los archivos de plantilla/datos editados localmente con [Cargar].",
    "edit_edition": "Editar material publicitario",
    "dataproof_master_copies_recreate": "Aquí, se puede utilizar [Crear datos de impresión] para iniciar una nueva creación de la plantilla/archivo(s) de datos de impresión basado en el original o se puede editar localmente la plantilla/archivo(s) de datos de impresión con [Editar].",
    "hint": "Nota",
    "dataproof_hint_master_copies_upload": "Los archivos de plantilla/datos de impresión modificados localmente deben cargarse con [Cargar] para que el cambio surta efecto una vez finalizado el postprocesamiento.",
    "edit_": "Editar",
    "send_invoice": "Enviar factura",
    "sended_invoice": "La factura se envió a",
    "send_email_tracking": "Seguimiento del envío por correo electrónico",
    "sended_email": "El correo electrónico ha sido enviado.",
    "tracking": "Seguimiento de envíos",
    "send_tracking_to_customer": "Enviar el seguimiento del envío a los clientes",
    "to_the_product": "Al producto",
    "order_sended": "El pedido ha sido enviado.",
    "failure_when_ordering": "Se ha producido un error durante el pedido.",
    "please_create_orderhistory_email": "El pedido se ha enviado por correo electrónico. Por favor, ¡crea un historial de pedidos!",
    "new_status_set": "¡Se ha establecido el nuevo estado!",
    "change_of_status": "Cambio de estado",
    "statusQuery": "Consulta de estado",
    "totalStatus": "Estado general:",
    "printData": "Imprimir datos",
    "current_printdata_sended": "Se han enviado los datos de impresión actuales.",
    "orderHistory": "Proceso de pedido",
    "orderHistory_created": "Se ha creado el historial de pedidos.",
    "order_created": "El pedido se ha creado correctamente.",
    "enter_trackingLink": "Introduzca el enlace de seguimiento",
    "query_orderStatus": "Consultar el estado del pedido",
    "foreignId": "Nº de pedido externo",
    "orderStatus": "Estado del pedido",
    "orderDate": "Fecha del pedido",
    "options_": "Opciones",
    "type_": "Amable",
    "comment_": "Comentario",
    "orderOptions": "Opciones de pedido",
    "ordering_by_webshop": "Pedidos a través de la tienda",
    "ordering_by_email": "Pedidos por correo electrónico",
    "info_to_the_emailOrder": "Información adicional para pedidos por correo electrónico",
    "add_or_edit_foreignId": "Añadir o editar el número de pedido externo",
    "create_trackingLink": "Crear enlace para el seguimiento del envío",
    "campaign": {
      "copy_edition": "Duplicar",
      "created_by": "Creado por:",
      "no_edition": "Aún no ha editado ningún material publicitario.",
      "configuration": "Opciones generales",
      "select_all": "Todos los productos",
      "budget": "Presupuesto de la campaña",
      "access": "Derechos de acceso",
      "inactive": "Campaña de bloqueo",
      "delete": "Eliminar campaña",
      "transfer_title": "Transferir la campaña a otro usuario",
      "campaign_owner": "Propietario de la campaña",
      "campaign_creator": "Creador de la campaña",
      "change_owner": "Cambiar el propietario de la campaña",
      "change_creator": "Cambiar creador de campaña",
      "order_all": "Encargar campaña completa",
      "share": "Compartir campaña",
      "archive": "Campaña de archivo",
      "recent_editions": "Última edición",
      "marked_editions": "Material publicitario marcado",
      "marked_campaigns": "Campañas etiquetadas",
      "own_campaigns": "Campañas propias",
      "shared_editions": "Material publicitario compartido",
      "last_edited": "Editado el:",
      "generated": "Creado el:",
      "edition_edit": "Editor de inicio",
      "edition_item": "Producto seleccionado",
      "submission_item": "Presentación seleccionada",
      "edition_configuration": "2. configuración",
      "edition_order": "Añadir a la cesta",
      "edition_reorder": "Reordenar",
      "ordered": "¡Pedido!",
      "edition_delete": "Borrar",
      "edition_preview": "Vista previa",
      "edition_edit_alt": "Editar",
      "edition_error_empty": "Tiene %s páginas sin editar",
      "submissions": "Plantillas",
      "new": "Crear una nueva campaña",
      "back_to_list": "Todas las campañas",
      "new_edition": "Crear nuevo material publicitario",
      "settings": "Gestionar campaña",
      "campaign_archived": "Su campaña ha sido archivada",
      "successfully_archived": "Archivar con éxito",
      "save_settings": "Guardar",
      "cancel_settings": "Cancelar",
      "title": "Nombre de la campaña",
      "edition_title": "Designación",
      "publisher": "Indicación del editor",
      "creator": "Campaña creada por:",
      "editions": "Material publicitario",
      "edition": "Material publicitario",
      "sidebar": "Campañas",
      "mark_edition": "Marcar el material publicitario",
      "unmark_edition": "Eliminar marca",
      "campaign_title_valid": "Introduzca un nombre para su campaña.",
      "campaign_publisher_valid": "Introduzca sus datos de copyright.",
      "choose_company_to_load_campaign": "Para cargar las campañas, seleccione una empresa o haga clic en el botón \"Cargar todas las campañas\".",
      "no_product_selected": "Ningún producto seleccionado",
      "new_edition_title": "Nueva edición",
      "added_to_cart": "Su campaña se ha añadido a la cesta de la compra.",
      "edition_add_to_cart_title": "Producto en la cesta de la compra",
      "edition_add_to_cart_button_label": "Genial",
      "edition_add_to_cart_label": "A la cesta de la compra",
      "edition_added_to_cart_message": " se ha añadido a su cesta de la compra.",
      "edit_disabled": "La edición de la edición se ha desactivado temporalmente debido a una actualización de la plantilla.",
      "export_image": "Exportar imagen",
      "unknown": "desconocido",
      "already_ordered": "Esta edición ya ha sido solicitada y no puede editarse. Sin embargo, puede duplicar la edición y editarla. Ver el menú",
      "modal_tabs": {
        "default_edition": "Material publicitario general",
        "edition_from_submission": "Material publicitario de plantilla"
      }
    },
    "uom": {
      "piece": "Pieza",
      "gram": "gramo",
      "kilogram": "Kilogramo",
      "running_meter": "Metro lineal",
      "liter": "Litros",
      "package": "Paquete",
      "kilometer": "Kilómetros"
    },
    "uom_symbole": {
      "gram": "g",
      "kilogram": "kg",
      "running_meter": "metros lineales",
      "liter": "l",
      "package": "Pkt.",
      "kilometer": "km"
    },
    "pipelineViews": {
      "self": "Asignado a mí",
      "unassigned": "Sin asignación",
      "pastdeadline": "Plazo superado",
      "duetoday": "Vence hoy"
    },
    "leadPriority": {
      "critical": "Crítica",
      "high": "Alta",
      "normal": "Normal",
      "low": "Bajo"
    },
    "orderTypes": {
      "item_sales": "Venta",
      "car_rent": "Alquiler de coches",
      "car_sales": "Venta de coches",
      "car_service": "Cita para el taller"
    },
    "genderOptions": {
      "male": "Hombre",
      "female": "Mujer"
    },
    "salutationOptions": {
      "mr": "Sr.",
      "mrs": "Sra."
    },
    "team_service": "Equipo de servicio",
    "team_dispo": "Equipo de eliminación",
    "team_rent": "Equipo de alquiler",
    "team_sales": "Equipo de ventas",
    "car_documents": "Documentación del vehículo",
    "nav_module_chip": "Módulo / chip de navegación por satélite",
    "safety_vest": "Chaleco de seguridad",
    "parking_disk": "Disco de aparcamiento",
    "ice_scraper": "Rascador de hielo",
    "key_supplement_heating_taxis": "Llave complementaria (calefactor de aparcamiento/señal de taxi)",
    "shelf_blind": "Estante para sombreros / estor enrollable",
    "onboard_folder": "Carpeta de a bordo",
    "first_aid_kit": "Botiquín de primeros auxilios",
    "warning_triangle": "Triángulo de advertencia",
    "tirefit_spare_wheel": "Neumáticos / rueda de repuesto",
    "handover_damage": {
      "front": "Frente",
      "back": "Trasera",
      "left": "Enlaces",
      "right": "Derecha",
      "top": "Por encima de",
      "interior": "Interior"
    },
    "dark": "Es oscuro",
    "garage": "En un garaje",
    "snow_ice": "Nieve / Hielo",
    "rain_wet": "Está lloviendo / el coche está mojado",
    "countries": {
      "de": "Alemania",
      "pl": "Polonia",
      "fr": "Francia"
    },
    "cookie_description": {
      "PHPSESSID": "Identifica la sesión actual del navegador",
      "tempTarget": "Utilizamos la cookie de objetivo temporal para identificar los productos que busca y sus intereses.",
      "chedriCookiesBoxClosed": "Utilizamos esta cookie para indicar que ha visto y aceptado nuestra información sobre cookies",
      "chedriCookiesPerformance": "Se utiliza para determinar si acepta nuestras cookies de rendimiento",
      "chedriCookiesTargeting": "Se utiliza para determinar si acepta nuestras cookies de segmentación",
      "ga": "Se utiliza para distinguir a los usuarios en el servicio Google Analytics",
      "gid": "Se utiliza para distinguir a los usuarios en el servicio Google Analytics",
      "act": "Se trata de una cookie de Facebook que se utiliza para sus análisis e investigaciones.",
      "c_user": "Inicio de sesión en Facebook para el registro de usuarios, que se utiliza para mostrar anuncios más relevantes para los usuarios y sus intereses.",
      "datr": "Utilizada por Facebook para identificar el navegador del usuario",
      "fr": "Contiene un identificador único de navegador y usuario que se utiliza para la publicidad dirigida.",
      "sb": "Utilizado por Facebook para mejorar las sugerencias de los amigos",
      "xs": "Cookie de Facebook que se utiliza para gestionar una sesión. Funciona junto con la cookie c_user para autenticar tu identidad en Facebook"
    },
    "cookie_ttl": {
      "6_months": "6 meses",
      "60_days": "60 días",
      "2_years": "2 años",
      "1_day": "1 día"
    },
    "upload_and_manage_media_files": "Aquí puedes subir y gestionar tus archivos multimedia.",
    "profile_information_avatar_contact_address": "Aquí puedes editar la información de tu perfil. Avatar, datos de contacto, dirección para facturación, información financiera.",
    "define_sales_points_address_position_map_opening_hours": "Defina sus puntos de venta: Dirección, posición en el mapa, horarios de apertura. Importante para gestionar los productos y los empleados.",
    "check_incoming_and_outgoing_invoices_check_details_and_download_as_pdf": "Puede comprobar las facturas entrantes y salientes generadas por el sistema o por otros usuarios. Vea los detalles y descárguelas en formato PDF.",
    "found_api_interesting_personal_api_key_here": "¿Le parece interesante nuestra API? ¿Le gustaría probarla? Aquí encontrará su clave personal de API.",
    "need_our_data_in_own_system_configure_data_push": "¿Necesita nuestros datos en su propio sistema? Aquí puede configurar servicios push de datos para suscribirse a eventos de datos.",
    "projects_running_on_different_domains": "Proyectos que se ejecutan en distintos dominios",
    "administration_of_your_website_logo_menu_pages": "Gestione su sitio web. Logotipo, menú principal, páginas del sistema",
    "create_users_manage_profile_financial_roles_teams": "Cree usuarios que trabajen en su organización, gestione su perfil e información financiera, revise y defina funciones de seguridad y asigne equipos.",
    "provision_service": "Servicios reservados",
    "provision_service_description": "Aquí puede crear, editar o eliminar nuevos tipos de servicios",
    "provision_service_title": "Título",
    "provision_service_type": "Nombre",
    "provision_service_description_text": "Descripción de la",
    "provision_service_primary_rate": "Comisión en porcentaje para prim. propietario",
    "provision_service_secondary_rate": "Comisión en porcentaje para el propietario sec.",
    "provision_service_type_status": "Estado",
    "add_provision_type": "Añadir tipo de comisión",
    "edit_provision_type": "Editar",
    "create_provision_type": "Crear tipo de comisión",
    "service_type_onboarding": "Incorporación (instalación)",
    "service_type_subscriptions": "Suscripciones (MRR, suscripciones && cuotas de usuario)",
    "service_type_additional_services": "Servicios adicionales (plantillas)",
    "service_type_product_sales": "Venta de productos",
    "sales": "Distribución",
    "primary_owner": "Propietario principal",
    "secondary_owner": "Propietario secundario",
    "provision_start": "Inicio",
    "provision_ending": "Fin",
    "provision_actions": "Acciones",
    "add_provision_service": "Crear comisión",
    "edit_provision_service": "Editar comisión",
    "save_provision_type": "Guardar",
    "prov_service_client_name": "Nombre",
    "provision_service_documents": "Comisiones",
    "commission_service_documents": "Comisiones - Documentos",
    "assign_service_type": "Asignar un nuevo tipo de servicio",
    "provision_service_documents_description": "Puede consultar todas las comisiones aquí",
    "provision_service_documents_download": "Descargar documentos",
    "provision_service_documents_download_start": "descargar",
    "provision_service_documents_name": "Nombre",
    "provision_service_documents_title": "Título",
    "provision_service_documents_description_text": "Descripción de la",
    "provision_service_documents_serviceType": "Tipo",
    "provision_service_documents_provisionRate": "Comisión",
    "provision_service_documents_provisionValue": "Valor de la comisión",
    "provision_service_documents_provisionAssignee": "Receptor",
    "provision_service_documents_provisionPayedDate": "pagado el",
    "provision_service_documents_IsActive": "Estado",
    "provision_service_documents_Client": "Cliente",
    "provision_service_documents_choose_month": "Seleccione el mes deseado",
    "commission_service_documents_description": "Puede ver y descargar todas las facturas de comisiones y detalles aquí",
    "commission_service_documents_download": "Descargar documentos",
    "commission_service_documents_download_start": "descargar",
    "commission_client": "Cliente",
    "commission_invoice_no": "Número de factura",
    "commission_payed_data": "pagado el",
    "commission_service_type": "Tipo de servicio",
    "commission_amount": "Importe",
    "commission_provision": "Comisión",
    "create_update_teams_assign_system_types": "Cree y edite equipos dentro de su empresa. Asigne tipos de sistema a sus equipos.",
    "create_document_types_use_for_process_automation": "Cree sus propios tipos de documentos para poder utilizarlos en integraciones o para la automatización de procesos.",
    "write_email_templates_send_to_clients": "Cree plantillas de correo electrónico que puedan utilizarse para enviar mensajes a los clientes o para automatizar algunos flujos de trabajo.",
    "define_reusable_cms_elements": "Defina elementos reutilizables que puedan usarse en cualquier página.",
    "manage_brands_edit_name_description": "Gestione marcas de productos, edite sus nombres y descripciones en varios idiomas.",
    "manage_car_models_and_aliases": "Gestionar los modelos de coche entre los que pueden elegir los usuarios. Defina alias para la importación.",
    "display_and_manage_category_tree": "Visualizar y manipular el árbol de categorías. Puede cambiar los nombres y las descripciones.",
    "display_and_manage_product_templates": "Cree y gestione plantillas y permita a los usuarios dar rienda suelta a su creatividad.",
    "define_product_attributes_search_and_variants": "Defina atributos adicionales que puede especificar en sus productos. Utilice estos atributos para ayudar a los usuarios a buscar productos. Cree variantes de productos basadas en atributos.",
    "group_attributes_to_better_manage_and_inform": "Puede agrupar atributos para gestionar mejor la experiencia del usuario en el backend y presentar los atributos de los artículos a sus clientes de forma más clara.",
    "entity_fields_missing_add_new": "Si le faltan algunos campos en sus formularios, puede añadirlos aquí. Útil para campañas de marketing e integraciones.",
    "define_manage_pipelines_and_stages": "Defina y personalice canalizaciones para sus flujos de trabajo. Añada, edite y elimine etapas.",
    "create_questionnaires_for_workflows_and_campaigns": "Cree cuestionarios que puedan utilizarse en flujos de trabajo o en diversas campañas de marketing.",
    "catch_leads_from_other_sources_with_custom_email": "Capture clientes potenciales de múltiples fuentes con nuestro Lead Catcher. Configure direcciones de correo electrónico individuales para cada fuente deseada.",
    "group_car_models_into_families_for_users_to_easily_find": "Agrupe los modelos de vehículos en familias para facilitar aún más la búsqueda a los usuarios.",
    "enrich_car_search_functionality_with_own_model_images": "Enriquezca la función de búsqueda de coches con sus propias fotos de modelos. Asigna fotos a modelos o grupos de modelos.",
    "tag_leads_with_custom_regular_expressions": "Amplíe la funcionalidad de nuestro sistema de etiquetas con etiquetas definidas por el usuario. Se requieren conocimientos de expresiones regulares.",
    "unknown_error": "Se ha producido un error desconocido",
    "username_required": "Introduzca su nombre de usuario",
    "email_required": "Introduzca su dirección de correo electrónico",
    "email_invalid": "El correo electrónico no es válido",
    "client_name_required": "Introduzca su nombre",
    "password_required": "Introduzca su contraseña",
    "password_length": "La contraseña debe constar de al menos 8 caracteres",
    "repeat_password_required": "Repita su contraseña",
    "repeat_password_length": "La contraseña repetida debe constar de al menos 8 caracteres",
    "message_required": "Escriba un mensaje",
    "second_password_incorrect": "La segunda contraseña no es la misma que la primera",
    "registration_not_confirmed": "Su inscripción aún no ha sido confirmada. Compruebe su buzón y haga clic en el botón para confirmar su dirección de correo electrónico.",
    "account_deleted": "Esta cuenta ha sido eliminada.",
    "account_inactive": "La suscripción a esta cuenta ha sido cancelada.",
    "company_required": "Introduzca el nombre de la empresa",
    "first_name_required": "Introduzca el nombre",
    "last_name_required": "Introduzca el apellido",
    "country_required": "Seleccione un país",
    "phone_required": "Introduzca el número de teléfono",
    "street_required": "Introduzca el nombre de la calle",
    "house_number_required": "Introduzca el número de la casa",
    "zip_code_required": "Introduzca el código postal",
    "zip_code_invalid": "El código postal '{{ value }}' no es válido.",
    "city_required": "Introduzca el nombre de la ciudad",
    "vat_id_required": "Introduzca el número de identificación fiscal",
    "timezone_required": "Seleccione una zona horaria",
    "select_job": "Seleccione un trabajo",
    "title_required": "Introduzca el título",
    "type_required": "Seleccione un tipo",
    "location_required": "Por favor, introduzca la ubicación",
    "questionnaire_required": "Seleccione un cuestionario",
    "subject_required": "Introduzca el asunto",
    "amount_required": "Introduzca el importe",
    "time_count_required": "Introduzca la cantidad de tiempo",
    "name_required": "Introduzca el nombre",
    "price_required": "Introduzca el precio",
    "description_required": "Introduzca la descripción",
    "question_required": "Introduzca la pregunta",
    "content_required": "Introduzca el contenido",
    "template_required": "Seleccione una plantilla",
    "payment_schedule_required": "Seleccione un plan de pago",
    "answer_required": "Introduzca la respuesta",
    "website_required": "Introduzca la dirección del sitio web",
    "fax_required": "Introduzca el número de fax",
    "currency_required": "Seleccione una moneda",
    "vat_name_required": "Introduzca el nombre del IVA",
    "vat_id_name_required": "Introduzca el nombre del CIF",
    "vat_rates_required": "Introduzca los tipos de IVA",
    "coc_number_required": "Introduzca el número de COC",
    "registered_at_required": "Introduzca los datos de registro de su empresa",
    "bank_name_required": "Introduzca el nombre del banco",
    "account_holder_required": "Introduzca el nombre completo del titular de la cuenta",
    "account_number_required": "Introduzca el número de cuenta",
    "bank_location_required": "Por favor, introduzca la ubicación del banco",
    "bank_code_number_required": "Introduzca el código bancario",
    "iban_required": "Introduzca el IBAN",
    "min_message": "Este valor es demasiado corto. Debería tener {{ limit }} caracteres o más",
    "max_message": "Este valor es demasiado largo. Debería tener {{ limit }} caracteres o menos",
    "min_max_length": "Este valor debe estar entre {{ min }} y {{ max }} caracteres de longitud",
    "greater_than_or_equal": "Este valor debe ser mayor o igual que {{ valor_comparado }}.",
    "price_type_invalid": "El tipo de precio no es válido",
    "This value should not be blank": "Este valor no debe estar vacío",
    "required_message": "Este valor no debe estar vacío",
    "This value is not a valid URL": "Este valor no es una URL válida",
    "must_upload_a_file": "Debe cargar un archivo",
    "file_format_not_supported": "El archivo que está intentando cargar no es compatible.",
    "category_not_empty": "La categoría no está vacía",
    "brand_has_items": "La marca ha asignado productos",
    "template_has_items": "Los productos se asignan a la plantilla",
    "item_has_variants": "El producto tiene variantes asignadas",
    "no_valid_variant": "Ninguna variante válida",
    "no_stage_set": "No se ha establecido ningún nivel",
    "no_pipeline_set": "No se ha establecido ninguna tubería",
    "no_users_found": "No se han encontrado usuarios",
    "no_team_set": "Ningún equipo establecido",
    "budget_required": "Defina el presupuesto",
    "client_required": "Por favor, asigne un cliente",
    "order_required": "Asigne un pedido",
    "item_required": "Asigne un producto",
    "document_required": "Asegúrese de que los documentos están disponibles y son válidos",
    "brand_required": "Seleccione una marca",
    "model_required": "Seleccione un modelo",
    "order_type_invalid": "Tipo de pedido no válido",
    "item_already_reserved": "Algunos productos no están disponibles en determinados días",
    "wrong_credentials": "Su nombre de usuario o contraseña se han introducido incorrectamente. Por favor, inténtelo de nuevo o haga clic en \"Contraseña olvidada\".",
    "no_car_found": "No se ha encontrado ningún vehículo para esta pista",
    "data_push_event_invalid": "El evento de envío de datos no es válido",
    "data_push_auth_invalid": "El tipo de autenticación Data Push no es válido",
    "cart_type_invalid": "El tipo de cesta no es válido",
    "system_type_invalid": "El tipo de sistema no es válido",
    "menu_version_invalid": "La versión del menú no es válida",
    "logo_version_invalid": "La versión del logotipo no es válida",
    "payment_method_invalid": "No hemos podido procesar el método de pago especificado",
    "nationality_required": "Introduzca su nacionalidad",
    "place_of_birth_required": "Introduzca su lugar de nacimiento",
    "date_of_birth_required": "Introduzca su fecha de nacimiento",
    "id_required": "Introduzca el ID",
    "meta_identifier_required": "Seleccione un identificador",
    "payment_status_invalid": "El estado del pago no es válido",
    "iban_invalid": "El valor introducido no es un número de cuenta válido",
    "swift_bic_required": "Introduzca un número SWIFT/BIC válido",
    "reference_required": "Introduzca una referencia",
    "service_status_invalid": "El estado del servicio no es válido",
    "date_proposal_type_invalid": "El tipo de fecha sugerido no es válido",
    "mobile_phone_invalid": "El número de teléfono móvil no es válido",
    "mobile_phone_required": "Introduzca su número de móvil",
    "phone_invalid": "El número de teléfono no es válido",
    "fax_invalid": "El número de fax no es válido",
    "salutation_required": "Seleccione un saludo",
    "gender_required": "Seleccione un sexo",
    "stripe_error": "Error al conectar con Stripe",
    "stripe_connect_error_with_type": "No hemos podido conectar CreaCheck con su cuenta de Stripe. Código de error: {tipo}.",
    "car_rental_pickup_time_invalid": "La hora de recogida deseada está fuera del horario de apertura del local",
    "car_rental_drop_off_time_invalid": "La hora solicitada para dejar el coche está fuera del horario de apertura del local",
    "accept_terms_and_cancellation_before_continuing": "Confirme que ha leído nuestras Condiciones Generales y la política de cancelación.",
    "problem_processing_your_credit_card": "Tenemos un problema para procesar su tarjeta de crédito",
    "invoice_type_invalid": "El tipo de factura no es válido",
    "doc_number_required": "Introduzca el número de documento",
    "doc_issuing_city_required": "Indique la ciudad emisora",
    "doc_issuing_authority_required": "Indique la autoridad expedidora",
    "doc_issue_date_required": "Introduzca la fecha de expedición",
    "doc_expiration_date_required": "Introduzca la fecha de caducidad",
    "test_domain_required": "Introduzca un nombre de dominio de prueba",
    "iban_has_wrong_format": "El formato IBAN no es correcto",
    "car_cash_payment_data_missing_or_invalid": "Faltan datos sobre el pago en efectivo o no son válidos",
    "car_registration_service_only_available_in_germany": "El servicio de autorización sólo está disponible en Alemania",
    "car_transport_only_available_in_germany": "Nuestro servicio de transporte sólo está disponible en Alemania",
    "target_type_invalid": "Tipo de destino no válido",
    "company_email_must_be_different_from_users_email": "La dirección de correo electrónico de su empresa debe ser diferente de su dirección de correo electrónico",
    "car_transport_distance_too_short": "El transporte en coche sólo es posible a más de 100 km de distancia.",
    "first_offer_price_too_low": "Su primera oferta es demasiado baja y no fue aceptada",
    "first_offer_price_too_high": "Su primera oferta es superior al precio original y no fue aceptada",
    "current_offer_too_low": "Su oferta actual es demasiado baja",
    "not_your_turn_to_negotiate_the_price": "No puede actualizar su oferta ahora. Por favor, espere una respuesta de nuestro empleado",
    "the_question_must_be_answered": "Se debe responder a la pregunta \"{{ question }}\".",
    "validation_problems_with_item_attributes": "Compruebe si los atributos se han rellenado correctamente. Los problemas que se han producido se marcan en rojo",
    "the_field_name_contains_following_errors": "El campo {fieldName} contiene el siguiente error: {helpText}",
    "plugin_required": "Seleccione un plan de suscripción.",
    "invalidRegex": "La expresión regular especificada no es válida",
    "assignment_strategy_type_invalid": "El tipo de estrategia de asignación no es válido",
    "cannot_remove_role_company": "No es posible eliminar el rol de usuario ROLE_COMPANY",
    "coupon_type_invalid": "Seleccione un tipo de vale válido",
    "subscription_starting_fee": "Cuota de inscripción para el abono",
    "comms_center_monthly_fee": "Centro de Comunicaciones de Creacheck",
    "comms_center_chat": "Centro de comunicación Creacheck - Chat para clientes",
    "comms_center_call": "Centro de Comunicación de Creacheck - Llamada al cliente",
    "0": "<strong>Fehler beim Einloggen</strong>",
    "You are not authenticated": "Lamentablemente no sabemos el correo electrónico y la contraseña",
    "access_denied": "Para iniciar sesión, debe aceptar el acceso para Creacheck.com",
    "oauth_email_missing": "No podemos iniciar sesión a través de las redes sociales. Inicie sesión con su dirección de correo electrónico.",
    "User already exists": "La dirección de correo electrónico que ha introducido ya existe en nuestra base de datos. Ha olvidado su contraseña?",
    "next": "Más información en",
    "create_comment": "Escriba un comentario",
    "new_pipeline": "Nuevo gasoducto",
    "new_stage": "Nuevo nivel",
    "quantity": "Cantidad",
    "login_error": "Error al iniciar sesión",
    "not_found": "No se ha encontrado",
    "deal_information": "Información sobre la operación",
    "login_domain": "Dominio de inicio de sesión",
    "login_domain_successfully_changed": "El dominio de inicio de sesión se ha cambiado correctamente.",
    "missing_lead_information": "Por favor, introduzca la información que falta",
    "missing_informations": "Información que falta",
    "missing_client_signature": "Falta la firma del cliente",
    "previous": "Anteriormente",
    "current": "Actual",
    "customCssInvalid": "El CSS personalizado no es válido ({{ error }})",
    "accountAndLogin": "Cuenta e inicio de sesión",
    "invalidEIN": "Número eVB (confirmación electrónica de seguro) no válido",
    "radio": "Radio",
    "airConditioning": "Aire acondicionado",
    "parkingAssistanceRear": "Ayuda al aparcamiento trasero",
    "airConditioningAutomatic": "Climatizador automático",
    "navigation": "Navegador por satélite",
    "alloyRims": "Llantas de aluminio",
    "parkingAssistanceFrontRear": "Ayuda al aparcamiento delantera y trasera",
    "metallicPaint": "Pintura metalizada",
    "cruiseControl": "Control de crucero",
    "seatHeating": "Calefacción de asiento",
    "leatherSeats": "Asientos de cuero",
    "equipmentLevel": "Nivel de equipamiento",
    "motorPower": "Rendimiento",
    "currentLocation": "Situación actual",
    "distance": "Distancia",
    "selected": "seleccionado",
    "noBrandSelected": "Ninguna marca seleccionada",
    "showMore": "Ver más",
    "showLess": "Mostrar menos",
    "optional": "Opcional",
    "altogether": "En total",
    "monday": "Lunes",
    "tuesday": "Martes",
    "wednesday": "Miércoles",
    "thursday": "Jueves",
    "friday": "Viernes",
    "saturday": "Sábado",
    "sunday": "Domingo",
    "payment_data_missing_or_invalid": "Los datos de pago faltan o no son válidos",
    "previousSearchRequests": "Búsquedas anteriores",
    "reset": "Restablecer",
    "number_days": "{días, plural, cero {# días} uno {# día} otros {# días}}",
    "number_months": "{meses, plural, cero {# meses} uno {# mes} otros {# meses}}",
    "outdated_browser": "Tu navegador no está actualizado.",
    "update_browser_text": "Actualice su navegador para visualizar correctamente este sitio web.",
    "update_browser_btn_text": "Actualizar el navegador ahora",
    "save_successfully": "Guardado correctamente",
    "add_quickly": "Añadir rápidamente",
    "load_data": "Datos de carga",
    "car_data_import_vin_info": "Introduzca el número de bastidor válido para completar automáticamente el formulario de datos del vehículo.",
    "car_data_import_missing_attr_values": "Faltan algunos valores de atributos: están marcados en el formulario.",
    "vin_invalid": "FIN no es válido",
    "others": "Otros",
    "activate_plugin_to_enable_feature": "Esta función sólo está disponible con el siguiente plugin. Active el plugin para seguir utilizando esta funcionalidad.",
    "car_data_import_failed": "No ha sido posible importar los datos del vehículo para este VIN. Compruebe si el número de bastidor especificado es correcto.",
    "send_mail_via_outlook": "El correo electrónico se envía con su cuenta de Microsoft Outlook ({email}) y posteriormente está disponible en su carpeta \"Enviados\".",
    "permission_required": "Autorizaciones necesarias",
    "phone_call_permissions_required": "Esta aplicación debe acceder a tus cuentas telefónicas para poder realizar llamadas",
    "calling": "Llámame",
    "callingTo": "Llamar al",
    "startingCall": "Iniciar una llamada",
    "callEnded": "Llamada finalizada",
    "inCall": "En llamada",
    "callCancelled": "Llamada anulada",
    "callRejected": "Llamada rechazada",
    "is_calling": "%nombre% llama...",
    "contacts": "Contactos",
    "addresses": "Agenda",
    "chat": "chat",
    "calls": "Llamadas",
    "endCustomerNewMessage": "Mensaje de un nuevo cliente",
    "returnToTheActiveCall": "Volver a la llamada activa",
    "agentStatus": "Estado del agente",
    "switchOnAgentNote": "Activar para aceptar chats y videollamadas de clientes",
    "switchOffAgentNote": "Desactivar para no aceptar chats y videollamadas de clientes",
    "activateAgent": "Activar la aceptación de chats de clientes",
    "deactivateAgent": "Desactivar la aceptación de chats de clientes",
    "startAudioCallWith": "Iniciar llamada telefónica con {{to}}",
    "startVideoCallWith": "Iniciar videollamada con {{to}}",
    "terms_and_conditions": "Condiciones generales",
    "form_incorrect_data": "Los datos que ha introducido no son correctos. Por favor, compruebe sus datos y envíelos de nuevo.",
    "accept_terms_and_condition_start_chat": "Si hace clic en \"Iniciar chat\", acepta los términos y condiciones",
    "start_chat": "Iniciar chat",
    "connecting_with": "Conectar con",
    "connected_with": "Conectado con",
    "do_calling": "llama a",
    "chat_inactivity_warning": "El chat lleva demasiado tiempo inactivo y se cerrará en breve.",
    "get_in_contact_with_us": "Póngase en contacto con nosotros",
    "no_agent_available": "Lamentablemente, ninguno de los agentes está en línea. Deje un mensaje y nos pondremos en contacto con usted lo antes posible.",
    "hello": "¡Hola!",
    "we_are_here_for_you": "Estamos a su disposición y esperamos sus preguntas o comentarios.",
    "start_conversation": "Iniciar conversación",
    "welcome_customer": "Me llamo {firstName} {lastName}. ¿En qué puedo ayudarle?",
    "end_chat": "Salida",
    "send_request": "Enviar solicitud",
    "checkout_payment_agreement": "Consiento que {empresa} comience la prestación de los servicios adicionales antes del inicio del periodo de cancelación y entiendo que perderé mi derecho de cancelación una vez que dichos servicios adicionales hayan sido prestados en su totalidad.",
    "checkout_buy_order_legal_text": "Las {condiciones_generales} y el {formulario_de_cancelación} de {empresa} ({política_de_privacidad}) se aplican a la compra.",
    "checkout_services_terms_cancelation_legal_text": "Para los servicios adicionales reservados ({booked_services}), se aplican {general_terms_and_conditions} y la {cancellation_policy_and_cancellation_form} de {company} ({privacy_policy}).",
    "no_credit_cards": "Sin tarjetas de crédito",
    "minimize_video": "Minimizar la vista de vídeo",
    "maximize_video": "Maximizar la visualización del vídeo",
    "enable_fullscreen": "Activar el modo de pantalla completa",
    "disable_fullscreen": "Desactivar el modo de pantalla completa",
    "enable_screensharing": "Compartir escritorio",
    "disable_screensharing": "Poner fin al uso compartido del escritorio",
    "mute_microphone": "Silenciar el micrófono",
    "unmute_microphone": "Desactivar micrófono",
    "enable_camera": "Encender la cámara",
    "disable_camera": "Apagar la cámara",
    "end_call": "Finalizar la llamada",
    "end_videocall": "Finalizar el flujo de vídeo",
    "muted": "Silenciado",
    "overall_feedback": "¿Cómo calificaría su contacto con nosotros?",
    "connection_quality_feedback": "¿Cómo calificaría la calidad de la conexión?",
    "send_feedback": "Enviar comentarios",
    "customer_chat_ended_info": "Su chat ha finalizado",
    "checking_connection": "Se comprueba la conexión",
    "call_again": "Volver a llamar",
    "end_call_local_disconnected": "Finalización de la llamada por problemas de conexión",
    "end_call_remote_disconnected": "Finalización de llamada por problemas de conexión en la página {remoteUserName}.",
    "network_disconnected_warning": "Actualmente no hay conexión a Internet.",
    "network_disconnected": "Conexión interrumpida",
    "network_want_reconnect": "Su conexión se ha interrumpido. ¿Desea volver a conectarse?",
    "leave_as_an_email_message": "Déjenos un mensaje de correo electrónico",
    "continue_call": "Continuar llamada",
    "continue_text_chat": "Continuar chat de texto",
    "continue_conversation": "Continuar la conversación",
    "disconnected": "Separe",
    "disconnect_reason": "Posible motivo",
    "disconnect_reason_transport_close": "Salir de esta página",
    "disconnect_reason_ping_timeout": "Sin conexión a Internet",
    "customer_may_return": "El cliente puede volver pronto.",
    "mobile": "Teléfono móvil",
    "headquarter": "Centro",
    "extension-number": "Número de extensión",
    "add_contact": "Añadir contacto",
    "add_address": "Añadir dirección",
    "choose_contact": "Seleccionar contacto",
    "number": "Número",
    "new_number": "Nuevo número",
    "address_mail": "Dirección postal",
    "address_delivery": "Dirección de entrega",
    "address_invoice": "Dirección de facturación",
    "new_address": "Nueva dirección",
    "find_address": "Buscar dirección",
    "searching": "Busca...",
    "address_change": "Cambiar dirección",
    "address_ask_change": "El cambio de esta dirección afectará a todos los contactos con esta dirección. Desea cambiar la dirección?",
    "designation": "Designación",
    "net": "Red",
    "gross": "Bruto",
    "exp_delivery_time": "plazo de entrega previsto",
    "workdays": "Días laborables",
    "special_offer": "Acción",
    "transportation_cost": "Gastos de envío",
    "discount": "Descuento",
    "total": "Total",
    "total_gross": "Total (IVA incluido)",
    "product_details": "Detalles del producto",
    "type_of_order": "Tipo de pedido",
    "number_of_copies": "Edición",
    "type_of_order_choices": "Producción de impresión normal o descarga de los datos de impresión como archivo PDF",
    "type_of_order_website": "Prestación de este sitio web",
    "print_production": "Producción impresa",
    "pdf_download": "PDF-Descargar",
    "website_upload": "Cargar el sitio web",
    "production_time": "Tiempo de producción",
    "production_time_description": "Seleccione la opción deseada",
    "production_standard": "Producción estándar",
    "production_express": "Producción exprés",
    "production_overnight": "Producción nocturna",
    "choose_edition": "Seleccionar edición",
    "choose_edition_description": "Ediciones con precios escalonados. Los descuentos y promociones se destacan si están disponibles.",
    "more_options": "Otras opciones",
    "more_options_description": "Añada más opciones a su pedido.",
    "manual_data_review": "Comprobación manual de datos (seguro incluido)",
    "manual_item_entry": "Introducción manual de la posición",
    "web_enabled_pdf": "Los datos impresos también están disponibles en formato PDF.",
    "cart_production_type_change_warning_title": "La circulación se ajusta",
    "cart_production_type_change_warning": "La tirada seleccionada no está disponible para el {{tipo de envío}}. Se cambiará a la tirada máxima disponible. Está seguro de que desea continuar?",
    "cart_no_warranty_without_manual_data_check": "Nota: Comprobación manual de datos no seleccionada.",
    "campaign_budget_exceed_warning": "Se ha superado el presupuesto de la campaña. Se requiere aprobación.",
    "user_budget_exceed_warning": "Presupuesto de usuario excedido. Liberación requerida.",
    "order_quantity": "Cantidad del pedido",
    "data_check": "Comprobación de datos",
    "split_shipping": "Entrega parcial",
    "cart_not_logged_in": "Ningún usuario conectado",
    "cart_contact_or_address_not_found_invoice": "Seleccione una dirección de facturación",
    "cart_contact_or_address_not_found_shipping": "Seleccione una dirección de entrega",
    "cart_edtion_item_or_variant_not_found": "Número, artículo o variante no encontrados",
    "cart_edtion_item_not_available": "El artículo %item_name% ya no está disponible",
    "cart_no_print_type_found": "No se ha encontrado ningún tipo de impresión",
    "cart_print_type_invalid": "Tipo de impresión no válido:",
    "cart_no_shipping_type_found": "No se ha encontrado ningún método de envío",
    "cart_checkout_info_missing": "Falta información de la casilla de verificación:",
    "cart_reduced_vat_rate_check_not_supported": "Solicitud de comprobación del tipo reducido de IVA para los artículos que no lo admiten:",
    "cart_no_price_found": "No se ha encontrado el precio",
    "cart_price_mismatch": "El precio no se corresponde con el que presentamos al usuario",
    "cart_web_pdf_price_mismatch": "El precio en PDF de la web no se corresponde con el precio que hemos presentado al usuario",
    "cart_contact_or_address_not_found_split_shipping": "Seleccione una dirección para el envío fraccionado",
    "cart_quantity_not_found_split_shipping": "Parámetros de cantidad no encontrados para el envío fraccionado",
    "cart_split_shipping_quantity_mismatch": "La cantidad de envío dividida no coincide con la cantidad de producción",
    "cart_coupons_mismatch": "Los cupones utilizados no coinciden",
    "cart_coupon_cant_be_used": "El cupón solicitado ha caducado o no puede utilizarse",
    "cart_coupon_value_mismatch": "El valor del cupón no es correcto",
    "cart_camaign_budget_processing_error": "Se ha producido un problema inesperado al procesar el presupuesto de la campaña",
    "cart_campaign_budget_has_changed": "El presupuesto de la campaña ha cambiado",
    "cart_user_budget_has_changed": "El presupuesto del usuario ha cambiado",
    "cart_user_budget_processing_error": "Se ha producido un problema inesperado al procesar el presupuesto del usuario",
    "cart_domain_not_active": "El dominio no está activo",
    "cart_missing_desired_domain": "Falta la especificación del dominio deseado",
    "lead_monitor_order_payment_receipt": "Controlar los pagos recibidos",
    "lead_format_message_description_route53_register_domain": "Registrar dominio {0} y redirigir a {1}",
    "lead_title_route53_register_domain": "Registrar dominio AWS",
    "domain_not_available": "Dominio no disponible",
    "invoice_payment_option": {
      "enabled": "Activado",
      "disabled": "Desactivado",
      "inherited": "Heredado",
      "title": "Pago de la factura",
      "loading": "Cargar configuración heredada",
      "effective_setting": "Ajuste eficaz"
    },
    "invoice_payment_threshold": {
      "title": "Valor umbral",
      "placeholder": "en el ejemplo, 100",
      "no_effect": "Sin efecto",
      "loading": "Carga de los ajustes heredados",
      "info_text": "Valor mínimo del pedido para el pago mediante factura",
      "effective_threshold": "Umbral efectivo",
      "not_defined": "no definido"
    },
    "remind_period": {
      "days": "Días",
      "inherited_setting": "Actitud heredada",
      "title": "Fecha de vencimiento de la factura Período de recordatorio"
    },
    "sellDownloadPdf": "Descargar el PDF",
    "success_management": {
      "title": "Gestión del éxito",
      "manager": "Director de Éxito",
      "switch_title": "Activar memorias vistas recientemente",
      "interval": "Último periodo consultado, días",
      "last_seen": "Visto por última vez",
      "error_no_self": "No puede asignarse a sí mismo como Gestor de Éxito",
      "error_no_same_parent": "Success Manager debe estar subordinado a otra empresa"
    },
    "copy": "Copia",
    "is_client_registration_allowed": "Activar el registro",
    "is_email_validation_enabled": "Validar correo electrónico",
    "email_validation_pattern": "Regex para validación de correo electrónico",
    "confirmation_email_cc": "CC para confirmación de inscripción",
    "client_registration_title": "Ajustes de registro",
    "registration_fields_show": "Mostrar campos",
    "registration_fields_require": "Campos obligatorios",
    "accept_terms_of_use_before_continuing": "Antes de continuar, debe aceptar nuestras condiciones de uso",
    "subscription_settings": "Suscripciones",
    "client_subscription_settings": "Suscripciones de clientes y productos",
    "subscription_settings_description": "Aquí puede crear una suscripción básica y asignarle un tipo",
    "client_subscription_settings_description": "Aquí puede asignar la suscripción básica a un cliente o a un artículo según el tipo y ajustar los precios",
    "subscriptions_": "Suscripciones",
    "subscription_name": "Nombre",
    "subscription_title": "Título",
    "subscription_description": "Descripción de la",
    "subscription_fee": "Tasa",
    "subscription_startingFee": "Cuota de inscripción (opcional)",
    "subscription_start": "Inicio",
    "subscription_end": "Fin",
    "subscription_status": "Estado",
    "subscription_action": "Acciones",
    "subscription_items": "Productos",
    "subscription_clients": "Clientes",
    "subscription_companies": "Empresas",
    "subscription_companies_detailed_role": "Empresa Usuario/Role",
    "subscription_basic": "Suscripción básica",
    "add_subscription": "Añadir suscripción",
    "create_subscription": "Crear suscripción",
    "edit_subscription": "Ahorrar suscripción",
    "subscription_language": "Selección de idioma",
    "period_status": "Desactivar al final del periodo",
    "subscription_edit": "Editar",
    "omit_subscription_fee": "Omitir la cuota de inscripción",
    "invoice_email": "Dirección de correo electrónico para las facturas",
    "order_processing_contract": "Contrato de tramitación de pedidos",
    "accept_order_processing_contract_before_continuing": "Antes de continuar, debe aceptar nuestro contrato de tramitación de pedidos",
    "no_image_title": "No se ha encontrado el título de la imagen. Haga clic aquí para editar.",
    "no_image_description": "No se ha encontrado la descripción de la imagen. Haga clic aquí para editar",
    "no_image_copyright": "No se ha encontrado información sobre derechos de autor. Haga clic aquí para editar",
    "no_text": "No se ha encontrado ningún texto. Haga clic aquí para editar",
    "subscription_clients_and_companies": "Clientes y empresas",
    "save_subscription": "Guardar",
    "go_to": "Ir a",
    "no_invoices_to_display": "No hay facturas que mostrar",
    "url_settings": "Configuración de url",
    "update_notification": "Gestión de la actualización",
    "update_notification_description": "Descripción de la",
    "update_notification_settings_description": "Puede gestionar las notificaciones de actualizaciones aquí",
    "update_name": "Nombre",
    "update_notification_meta": {
      "title": "Gestión de la actualización",
      "description": "Gestione aquí sus notificaciones de actualización",
      "name": "Gestión de la actualización"
    },
    "new_update_notification": "Crear nueva notificación de actualización",
    "update_from": "de",
    "update_to": "hasta",
    "edit_update_notification": "Notificación de actualización de edición",
    "individual_prices": "Precios personalizados",
    "template_pdf_profile_x3": "Crear PDF en formato X-3",
    "countries_for_prices": {
      "de": "Alemania",
      "ch": "Suiza",
      "at": "Austria",
      "es": "España"
    },
    "choose_country": "Seleccione un país",
    "product_groups": "Grupos de productos - Proveedores",
    "product_groups_description": "Aquí puede gestionar los grupos de productos de los proveedores",
    "new_product_group": "Añadir nuevo grupo de productos",
    "edit_product_group": "Editar",
    "create_product_group": "Cree",
    "product_group_number": "Número de grupo de productos",
    "save_product_group": "Guardar",
    "product_group_actions": "Acciones",
    "product_group_status": "Estado",
    "hide_preview": "Desactivar la vista previa en el editor",
    "editor_settings": "Editor de ajustes",
    "share_edition": "Compartir edición",
    "canceled_quote": "Oferta rechazada",
    "active_quote": "Oferta pendiente",
    "manual_quote_status": "Estado de las ofertas",
    "invalid_manual_quote_status": "Estado no válido de la oferta",
    "internal_status": "Estado interno",
    "edit_quote": "Editar oferta",
    "copy_quote": "Copiar oferta",
    "open_lead": "Plomo abierto",
    "upload_pdf": "Cargar y enlazar PDF",
    "upload_pdf_title": "Subir PDF",
    "upload_pdf_status_done": "Su archivo PDF se ha cargado correctamente",
    "upload_pdf_status_error": "Error al cargar el PDF.",
    "placeholder_upload_error": "Se ha producido un problema durante la carga.",
    "linkedin_insight_tag": "Etiqueta LinkedIn Insight",
    "linkedin_partner_id": "Id de socio",
    "tracking_pixel": "Píxel de seguimiento, p. ej. Meta píxel",
    "tracking_pixel_data": "Código de aplicación",
    "jsWebsite": "Javascript",
    "upload_website_favicon": "Favicon",
    "upload_website_favicon_label": "Carga y previsualización de imágenes",
    "upload_website_favicon_info": "Atención El formato de archivo requerido es PNG. Dependiendo del dispositivo utilizado, la resolución óptima oscila entre 32 x 32px y un máximo de 64 x 64px. También puede utilizar otros tamaños siempre que sean cuadrados y no superen la resolución máxima.",
    "upload_favicon_error": "La imagen no tiene la resolución requerida",
    "go_back_to_campaign": "Volver a la campaña",
    "no_orders_to_display": "Keine Bestellungen zum Anzeigen",
    "no_editions_to_display": "Keine Editionen zum Anzeigen",
    "google_tag_manager": "Google Tag Manager",
    "google_tag_manager_id": "ID GTM",
    "website_analyse": "Análisis del sitio web",
    "legal_information": "Información jurídica",
    "contact_info": "Información de contacto",
    "import_users_via_csv": "Crear usuarios importando un archivo CSV",
    "remove_users_via_csv": "Eliminar usuarios importando un archivo CSV",
    "import_users": "Importar usuarios",
    "remove_users": "Eliminar usuario",
    "fill_data_policy_before_autosave": "Ha fallado el guardado automático. Por favor, complete la información sobre protección de datos y aviso legal. Puede encontrarlos en los ajustes",
    "invoice_customer_info": "ID - Empresa / Nombre",
    "invoice_search_debtor": "Número de deudor",
    "invoice_search_vat": "IVA",
    "invoice_search_amount": "Importe",
    "search_field_name": "Seleccione un campo de búsqueda",
    "search_field_info_text": "Si desea buscar un valor numérico en el campo de búsqueda, debe seleccionar un campo",
    "your_export": "Su exportación",
    "export_download_info": "Su fichero ha sido creado y ya puede ser utilizado.",
    "export_download": "descargar",
    "export_file": "Archivo",
    "export_share": "Compartir",
    "image_permission_title": "¡Atención!",
    "image_permission_warning": "¿Derechos de imagen disponibles/consentimiento obtenido de todas las personas?",
    "other_settings": "Otros ajustes",
    "image_rights_query": "Consulta de derechos de imagen",
    "direct_ordering_label": "Pedido directo",
    "direct_ordering_info_text": "En el caso de un pedido directo, la edición creada ya no puede editarse. Se coloca directamente en la cesta de la compra.",
    "subscription_automation": {
      "title": "Objetivo de facturación para las suscripciones de nuevos usuarios",
      "payer": "Pagador",
      "fee_for_role_user": "Tarifa para ROLE_USER",
      "fee_for_role_admin": "Tasa por ROLE_ADMIN",
      "fee_for_role_wl_admin": "Tarifa para ROLE_WL_ADMIN",
      "hint_title": "Nota",
      "hint_body": "Por favor, cree y active una suscripción de empresa para esta empresa para cubrir todos los usuarios creados posteriormente.",
      "debtor_ancestor": "Cada suscripción de usuario se factura al %billingTargetName%.",
      "debtor_ancestor_missing": "No hay ninguna empresa predecesora marcada como deudora. Por favor, corríjalo."
    },
    "need_content_authorization": "Se requiere autorización de contenidos",
    "publisher_info_text": "El editor se inserta automáticamente en todos los soportes publicitarios necesarios de acuerdo con las directrices legales.",
    "mark_campaign": "Marca campaña",
    "template_exports": {
      "download_complete": "Descarga completa",
      "export": "Exportar",
      "download_files": "Descargar archivos",
      "synchronize": "Sincronice"
    },
    "info_import_and_export": {
      "note": "Información general sobre importación y exportación",
      "import": "Importar: Importa toda la información sobre la configuración de la plantilla (CSS, bloques de contenido, otros ajustes) y los archivos. Crea un archivo zip inicial para la exportación. Los archivos zip existentes se sobrescriben.",
      "upload": "Cargar: Añade más archivos al directorio de plantillas S3. Estos archivos se añaden al archivo zip para la exportación.",
      "sync": "Sincronizar: Sincroniza los ajustes de la plantilla (CSS, bloques de contenido, otros ajustes) con los archivos (index.html, page.css, pdf.css, printpdf.css, website.css, website.js) en el directorio de plantillas S3 y el archivo zip (exportar).",
      "export": "Exportar: Crea la estructura de un index.html para copiar y pegar",
      "download": "Descargar archivos: Descarga el archivo zip existente. (Realice una sincronización antes de la descarga para obtener los datos más recientes.",
      "copy": "Copiar plantilla: La función \"Copiar plantilla\" sólo copia la configuración de la plantilla, pero no los directorios y archivos."
    },
    "template_upload_title": "Cargar archivos en el directorio de plantillas",
    "pls_choose_text": "Seleccione un texto",
    "discounts": {
      "discount_type_invalid": "El tipo de descuento no es válido",
      "discounts_tab_title": "Descuentos",
      "intro_text": "Introduzca el número de grupo de productos FlyerAlarm y el tipo y valor de descuento correspondientes. Estos se aplicarán a todos los usuarios de la organización actual durante el proceso de pago.",
      "none_yet_add_some": "Aún no se han definido descuentos. Por favor, añada algunos.",
      "number_must_be_unique": "El número debe ser único",
      "must_be_0_to_100": "Debe ser de 0 a 100"
    },
    "link_to_uploaded_pdf": "Url del archivo PDF cargado",
    "upload_qrcode_logo": "Cargar logotipo para código QR",
    "choose_file": "Seleccionar archivo",
    "upload_successful": "Carga correcta",
    "upload_failed": "Error de carga",
    "qr_logo": "Logotipo",
    "qr_example": "Ejemplo",
    "qr_settings": "Configuración del código QR",
    "qr_margin": "Distancia",
    "qr_upload": "Cargar logotipo QRCode",
    "google_search_console": "Consola de búsqueda de Google",
    "gsc_html_tag": "Verificación HTML-TAG",
    "content_id": "Content-Id",
    "gsc_html_tag_example": "Utilice la verificación de etiquetas HTML. Copie el Id, por ejemplo Xu9c238409d8JDSF8... del atributo de contenido de la etiqueta meta que recibe de Google para la verificación y pegue el ID aquí. Ejemplo: <meta name='google-site-verification' content='Xu9c238409d8JDSF8...' />",
    "order_complete": {
      "good_luck": "Buena suerte con su campaña.",
      "order_complete": "Su pedido se ha completado con éxito",
      "pls_check_inbox": "Compruebe su bandeja de entrada.",
      "order_info": "Todos los detalles del pedido se han enviado a la dirección de correo electrónico que tenemos registrada para usted. Puede consultar su historial de pedidos en cualquier momento en su cuenta de usuario en",
      "orders": "Pedidos",
      "view": "vista.",
      "start_next_campaign": "Iniciar otra campaña ahora"
    },
    "no_product_selected": "Ningún producto seleccionado",
    "mail_messages": {
      "header": {
        "header_title": "El portal de diseño inteligente en línea",
        "support": "Ayuda y FAQ",
        "login": "INICIAR SESIÓN"
      },
      "footer": {
        "email_info": "Está recibiendo este correo electrónico porque se ha registrado en %company%.",
        "email_info_first_name_form": "Está recibiendo este correo electrónico porque se ha registrado en %company%.",
        "dont_reply": "Este correo electrónico se envió desde una dirección que no puede recibir correos electrónicos entrantes. </br>Por favor, no responda a este mensaje. Si tiene alguna pregunta o duda, póngase en contacto con nosotros en",
        "agb": "GTC",
        "privacy_policy": "Protección de datos",
        "imprint": "Pie de imprenta"
      }
    },
    "content_check": "Revisión de contenidos",
    "terms_of_payment": "Condiciones de pago",
    "terms_of_payment_edit": "Editar las condiciones de pago",
    "cancel_impersonation": "Poner fin a la inversión de papeles",
    "impersonate": "Aceptar el papel",
    "template_deleted_warning": "Atención La plantilla de la edición ya no está disponible.",
    "click_to_enable_editing": "Haga clic aquí para activar la edición en la pestaña actual.",
    "click_to_send_a_request_to_enable_editing": "Haga clic aquí para enviar una solicitud de activación del tratamiento",
    "waiting_for_form_release": "Esperar a la liberación",
    "already_edited_by_another_user": "Es posible que esta edición ya haya sido editada por otro usuario.",
    "please_wait": "Por favor, espere.",
    "user_already_requested_release": "ya ha solicitado la autorización",
    "user_requested_that_you_give_up_the_editing": "pide permiso para editar.",
    "emergency_release": "Haga cumplir la normativa. Utilícelo bajo su propia responsabilidad. La edición podría ser sobrescrita por otro usuario si está trabajando en ella.",
    "alt_debtor_number": "Identificación alternativa del deudor",
    "use_bootstrap_label": "Bootstrap",
    "use_bootstrap_infotext": "Si activa esta opción, las bibliotecas bootstrap se integran",
    "last_queried_attributes": "Últimos precios solicitados",
    "transfer_campaign": "Transferencia",
    "duplicate_campaign_description": "Duplicar la campaña y asignarla a usuarios individuales o a todos los usuarios de una empresa",
    "duplicate_campaign": "Duplicar",
    "clone_to_single_user": "Crear duplicado para uno o varios usuarios",
    "clone_to_all_users_of_company": "Crear duplicados para todos los usuarios de una empresa",
    "enum_option_invalid": "Opción no válida",
    "pdf_download_option": {
      "enabled": "Activado",
      "disabled": "Desactivado",
      "inherited": "Heredado",
      "title": "Se permite la descarga de PDF",
      "loading": "Cargar configuración heredada",
      "effective_setting": "Ajuste eficaz"
    },
    "choose_image_for_filter": "Seleccione una imagen",
    "invoice_email_bcc": {
      "send_new_invoice_email_bcc": "Enviar nueva factura por correo electrónico CCO",
      "email_address_bcc": "Dirección de correo electrónico CCO",
      "info_text": "También se enviará a esta dirección una copia oculta del correo electrónico con la nueva factura."
    },
    "reduced_vat_rate": {
      "cart_item_hint": "Este artículo puede beneficiarse de un tipo de IVA reducido tras una comprobación manual realizada por un empleado de Creacheck"
    },
    "forgot_password_infotext": "Si ha olvidado su contraseña, puede restablecerla aquí. Introduzca su dirección de correo electrónico registrada en el siguiente formulario y haga clic en \"Continuar\". Le enviaremos un correo electrónico con más información.",
    "forgot_password_infotext_robethood": "Si ha olvidado su contraseña, puede restablecerla aquí. Introduzca la dirección de correo electrónico registrada en el siguiente formulario y haga clic en \"Continuar\". A continuación le enviaremos un correo electrónico con más información.",
    "forgot_password_info_email_not_used": "Si ya no utiliza la dirección de correo electrónico asociada a su cuenta Creacheck, póngase en contacto con nosotros a través de nuestro formulario de contacto:",
    "forgot_password_info_email_not_used_robethood": "Si ya no utiliza la dirección de correo electrónico asociada a su cuenta, póngase en contacto con nosotros a través de nuestro formulario de contacto:",
    "invoice_greeting_male": "Estimado Sr.",
    "invoice_greeting_female": "Estimada Sra.",
    "invoice_greeting_text": "Muchas gracias por su pedido a través de %company%. Le cobraremos por los siguientes servicios:",
    "bank_account": "Datos bancarios",
    "mandatory_field": "Declarar este campo como obligatorio",
    "custom_checkbox": "Casilla personalizada",
    "set_label_checkbox": "Asigne una etiqueta a la casilla de verificación",
    "set_checkbox_link": "Puede enlazar la etiqueta aquí",
    "change_checkbox_link": "Cambiar enlace",
    "checkbox_infotext": "Puede vincular la etiqueta colocando el área correspondiente en caracteres $. Por ejemplo: Acepto la $Política de privacidad$.",
    "perforation": {
      "cart_radiobox_title": "Opciones para limar la perforación",
      "attribute_is_custom_fa_setting": "Se personaliza FA opciones de perforación preseleccionadas",
      "set_perforation_options": "Opciones de perforación",
      "preset": "Ajuste por defecto",
      "how_to": "Busque el identificador del grupo de productos, seleccione algunas variantes, pruebe diferentes cantidades y métodos de envío. A continuación, seleccione la opción de perforación que se preseleccionará con este atributo. Se pueden añadir varias opciones.",
      "select_variant": "Seleccione una variante para ver sus cantidades y métodos de envío.",
      "select_shipping_type": "Haga clic en un método de envío para ver las opciones disponibles.",
      "select_option_value": "Haga clic en una opción para añadirla a la selección.",
      "please_try_other": "Pruebe otra variante, cantidad o método de envío",
      "options": "Opciones",
      "other_options": "Otras opciones",
      "selected_options": "Opciones seleccionadas",
      "perforation_options": "Opciones de perforación",
      "product_group": "Grupo de productos",
      "variants": "Variantes",
      "quantities_in_variant": "Cantidades en variante"
    },
    "textile_select_exactly_n_items": "Seleccione exactamente {total}",
    "textile_exact_number_selected": "Todo bien: exactamente {total} artículos seleccionados",
    "textile_order_quantity_mismatch": "El número de tallas de tejido debe coincidir con la edición seleccionada.",
    "tracking_pixel_help_text": "Tenga en cuenta que la implementación puede variar en función del proveedor. En nuestro ejemplo, utilizamos el píxel de seguimiento de Meta. La integración requiere principalmente la verificación del dominio. Para ello, es necesario integrar una metaetiqueta, que en nuestro ejemplo fue proporcionada por Meta. Vea el código de ejemplo a continuación. Tenga en cuenta que la verificación puede tardar hasta 72 horas, dependiendo del proveedor. En el segundo paso, añada el código base para la integración del píxel de seguimiento. Para más información, por favor lea la documentación proporcionada por su respectivo proveedor.",
    "reg_greeting_male": "Estimado Sr.",
    "reg_greeting_female": "Estimada Sra.",
    "reg_gender_male": "Sr.",
    "reg_gender_female": "Sra.",
    "reg_name": "Saludo y nombre",
    "reg_phone_number": "Número de teléfono",
    "reg_domain": "Dominio",
    "reg_ancestors": "ID de estructura",
    "reg_explanation": "<p>Gracias por registrarse. Por favor, confirme su dirección de correo electrónico en un plazo de 24 horas.</p><p>Sólo con una dirección de correo electrónico confirmada podrá recibir una nueva contraseña o información sobre cambios.</p> <p>Sólo con una dirección de correo electrónico confirmada podrá recibir una nueva contraseña o información sobre cambios.",
    "widget_order_stats": {
      "title": "Total pedidos",
      "orders_count": "Contar pedidos",
      "orders_gross_total": "Total pedidos brutos",
      "no_orders_in_date_range": "Ningún pedido en el periodo seleccionado",
      "loading": "Cargando",
      "this_month": "Este mes",
      "previous_month": "El mes pasado",
      "last_3_months": "Últimos 3 meses",
      "last_6_months": "Últimos 6 meses",
      "last_12_months": "Últimos 12 meses",
      "for_companies": "para empresa(s)",
      "for_clients": "para los clientes",
      "title1": "Total pedidos",
      "title2": "Los 10 productos más pedidos",
      "items_count": "Recuento de productos",
      "item_types_count": "Productos (tipos)"
    },
    "cart_domain": "El dominio que ha especificado",
    "cart_domain_available": "¡está disponible!",
    "cart_domain_not_available": "¡ya no está disponible!",
    "cart_domain_not_available_title": "Dominio no disponible",
    "pages_to_delete": "Se eliminarán las siguientes páginas:",
    "page_to_delete": "Página",
    "sales_doc_reverse_charge": "La factura se emite sin IVA, ya que en este caso se aplica el procedimiento de inversión del sujeto pasivo. El IVA debe ser declarado y pagado por el destinatario del servicio.",
    "invoice_for_service": "Factura del servicio",
    "phone_invalid_country": "El código de país del número de teléfono no es válido",
    "mobile_phone_invalid_country": "El código de país del número de teléfono móvil no es válido",
    "fax_invalid_country": "El código de país del número de fax no es válido",
    "search_campaign": "Búsqueda de campañas",
    "coupon_usage": "Uso del vale",
    "coupon_balance_title": "Balance del cupón",
    "get_coupon_balance": "Consulta del importe restante",
    "total_coupon_budget": "Presupuesto total:",
    "remaining_coupon_budget": "Presupuesto restante:",
    "spent_coupon_budget": "Presupuesto gastado:",
    "coupon_balance_success": "Consulta realizada",
    "upload_print_data_previews": "Cargar imágenes de previsualización de datos de impresión",
    "uploaded_print_data_previews": "Datos de impresión cargados Vista previa de imágenes",
    "confirm_upload": "Confirme",
    "image_minimum_size": "La imagen no corresponde al tamaño mínimo.",
    "data_policy_info": "Protección de datos e impronta",
    "fill_data_policy_before_save": "Por favor, complete la información sobre protección de datos y aviso legal antes de guardar. Los encontrará en los ajustes",
    "subscription_liu_subscribe": "Suscríbase a",
    "subscription_liu_cancel": "Anulación",
    "subscription_liu_extend": "Ampliar",
    "subscription_status_changed_to_active": "¡Estado cambiado a activo!",
    "subscription_status_changed_to_inactive": "Estado cambiado a inactivo.",
    "sub_start_end": "La fecha de inicio no puede ser mayor que la fecha final.",
    "subscription_liu_send": "Envío de",
    "subscription_to_client": {
      "subscription_not_available": "Elija un abono",
      "type_parameter_not_present": "Falta el parámetro %type",
      "date_parameter_not_present": "Falta el parámetro %type",
      "end_date_greater_then_start_date": "La fecha final no puede ser mayor que la fecha inicial.",
      "end_date_less_then_first_day_of_next_month": "La fecha final no puede ser anterior a la primera fecha del mes siguiente."
    },
    "subscription_billing_period": "Período contable",
    "mail_greeting_female": "Hola Sra.",
    "mail_greeting_male": "Hola Sr.",
    "mail_greeting_first_name_form": "Hola",
    "mail_preview": "Vista previa por correo electrónico",
    "mail_preview_update": "Vista previa de inicio/actualización",
    "mail_preview_close": "Cerrar",
    "mail_preview_hint": "Tenga en cuenta que la vista previa sólo sirve para comprobar el diseño y los mensajes. Todas las plantillas contienen datos ficticios.",
    "tracking_details": "Haga clic en el número de seguimiento para obtener más información",
    "ups_tracking_title": "Seguimiento de envíos mediante API (UPS)",
    "ups_tracking_button": "Estado de la consulta",
    "tracking_informations": "Información sobre la consignación",
    "ups_tracking_status": "Estado de su envío (UPS)",
    "shipment_package": "Paquete",
    "shipment": "Programa",
    "shipment_package_current_status": "Situación actual",
    "shipment_package_current_status_description": "Descripción de la",
    "shipment_package_delivery_date": "Entregado el",
    "shipment_package_delivery_location": "Recibir donde",
    "shipment_package_delivery_receivedBy": "Recepción de",
    "tracking_error": "Se ha producido un error durante la consulta.",
    "1": "<strong>Fehler beim Einloggen</strong>",
    "website_title": "Título para el sitio web (motores de búsqueda)",
    "basic_billing_settings": "Configuración básica de facturación",
    "currency_and_vat": "Moneda e IVA",
    "basic_client_settings": "Ajustes básicos",
    "client_settings_info_text": {
      "info_text_phone": "Para introducir correctamente su número de teléfono fijo, introduzca el número completo, incluido el prefijo del país. Asegúrese de no utilizar espacios ni caracteres especiales. Ejemplo para Alemania: +49XXXXXXXXXXX.",
      "info_text_mobile": "Para introducir correctamente su número de móvil, introduzca el número completo, incluido el prefijo del país. Asegúrese de no utilizar espacios ni caracteres especiales. Ejemplo para Alemania: +49XXXXXXXXXXX.",
      "info_text_demo": "Activar el modo demo, por ejemplo, para realizar pedidos de prueba.",
      "info_text_image_rights_query": "Si activa esta opción, aparecerá una pregunta adicional cada vez que cargue una imagen. Se le pedirá que confirme que posee los derechos de uso de la imagen cargada. Esto nos ayuda a garantizar que todas las imágenes cargadas son legalmente válidas y no infringen ningún derecho de autor.",
      "info_text_pdf_download_option": "Activa la opción de descarga de PDF en el editor.",
      "info_text_needs_content_authorization": "Si activa la comprobación de contenido, todos los pedidos realizados por los miembros de su empresa deberán comprobarse y confirmarse manualmente.",
      "info_text_main_language": "Seleccione el idioma en el que desea utilizar principalmente la aplicación. Al seleccionar un idioma, personalizamos la interfaz de usuario según sus necesidades.",
      "info_text_password_renew": "Si activa esta opción, enviaremos al usuario un correo electrónico de renovación de contraseña la próxima vez que se conecte.",
      "info_text_editor_auto_save": "Si activa esta opción, el editor guardará automáticamente sus cambios cada 5 minutos."
    },
    "content_check_status_rejected": "Rechazado",
    "content_check_status_approved": "autorizado",
    "cart_remaining_user_budget": "Presupuesto restante del usuario",
    "cart_remaining_user_budget_after_order": "Presupuesto restante del usuario tras el pedido",
    "cart_budget_user": "Presupuesto del usuario",
    "archived_product_templates": "Plantillas archivadas",
    "archived_product_templates_description": "Aquí puede encontrar todas las plantillas archivadas",
    "archive_template": "Archivo",
    "restore_template": "Restaurar",
    "budget_value_used": "Utilizado",
    "budget_value_reserved": "Reservado",
    "budget_value_residual": "Valor residual",
    "double_opt_in": "Procedimiento de consentimiento doble"
  }
}
