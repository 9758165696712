import React, { Component } from 'react';
import _, { cloneDeep as _cloneDeep, find as _find, forEach as _forEach, findIndex as _findIndex } from 'lodash';
import { GrowlComponent } from '@crystallize/react-growl';

import { Button, ButtonGroup, MenuItem } from 'react-bootstrap';
import ReactTooltip from 'react-tooltip';
import { confirmAlert } from 'react-confirm-alert';
import Tour from 'reactour';
import ProgressBar from 'react-bootstrap/lib/ProgressBar';
import { getNameInCurrentLanguage } from 'helpers/intl';
import Axios from 'axios';
import { Beforeunload } from 'react-beforeunload';
import { injectIntl } from 'react-intl';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { ROLE_ROOT_EMPLOYEE, S3_DOMAIN, S3_LINKS, FILTERS } from 'js/constants';
import EditorToolbar from './components/EditorToolbar';
import EditorApplyStyles from './components/EditorApplyStyles';
import EditorCanvas from './components/EditorCanvas';
import { getBlockTopFromRaster } from './components/actions/Raster';
import {
  changeBlock,
  copyBlock,
  createTimelineBlockEntry,
  getBlockResizers,
  insertBlock,
  pasteBlock,
  removeBlock,
  resetBlock,
  resizeBlock,
} from './components/actions/Blocks';
import { getPageNameIndex } from './components/actions/TemplateSettings';
import PreviewBlock from './components/PreviewBlock';
import { tourSteps } from './helpers/tour';
import { checkIfMediaBlockExists, checkIfMediaIsUnused, checkIfMediaStaysSame } from './components/actions/UsedMedia';
import may from '../security';
import { blockForm, releaseForm } from '../actions/ActiveFormsActions';
import ActiveFormsToolbar from '../components/forms/ActiveFormsToolbar';

/**
 * Extracts attribute value
 * from a HTML code string like
 * <tagName attributeName=" 123 " />
 * @param {string} str HTML code to parse
 * @param {string} attributeName to look for
 * @returns number
 */
const getNumericValue = (str, attributeName) => {
  const re = new RegExp(`${attributeName}="\\s*([^"\\s]*)`);
  const match = re.exec(str);
  return match ? Number(match[1]) : 0;
};

class Editor extends Component {
  constructor(props) {
    super(props);
    const { edition } = props;
    const contentPages = edition.pages;
    const templateBlocks = edition.template.template_block_category;
    const savedBlocks = [];
    this.uploadCheck = false;
    this.fontCheck = false;
    this.autosave = false;
    this.waitForSaving = false;

    _.forEach(contentPages, (contentPage, i) => {
      _.forEach(contentPage.blocks, (block, j) => {
        savedBlocks.push({
          id: block.id,
          i,
          j,
          template_id: block.template_id,
          top: block.top,
          params: block.params,
          errors: block.errors,
          blocks: block.blocks,
          space_consumed: block.space_consumed,
          position_x: block.position_x,
          position_y: block.position_y,
        });
      });
    });
    _.forEach(templateBlocks, category => {
      _.forEach(category.variants, variant => {
        _.forEach(variant.blocks, templateBlock => {
          _.forEach(savedBlocks, block => {
            if (block.blocks) {
              _.forEach(block.blocks, (innerBlock, n) => {
                if (innerBlock.template_id === templateBlock.id) {
                  const newInnerBlock = _.cloneDeep(templateBlock);
                  newInnerBlock.id = innerBlock.id;
                  newInnerBlock.template_id = innerBlock.template_id;
                  newInnerBlock.top = innerBlock.top;
                  newInnerBlock.params = innerBlock.params;
                  newInnerBlock.errors = innerBlock.errors;
                  newInnerBlock.position_x = innerBlock.position_x;
                  newInnerBlock.position_y = innerBlock.position_y;
                  block.blocks[n] = newInnerBlock;
                }
              });
            }
            if (block.template_id === templateBlock.id) {
              const newBlock = _.cloneDeep(templateBlock);
              newBlock.id = block.id;
              newBlock.template_id = block.template_id;
              newBlock.top = block.top;
              newBlock.params = block.params;
              newBlock.errors = block.errors;
              newBlock.blocks = block.blocks;
              newBlock.space_consumed = block.space_consumed;
              newBlock.position_x = block.position_x;
              newBlock.position_y = block.position_y;
              contentPages[block.i].blocks[block.j] = getBlockResizers(newBlock, templateBlocks);
            }
          });
        });
      });
    });

    this.state = {
      currentPage: contentPages && contentPages.length >= edition.shown ? edition.shown : 0,
      pageAmount: edition.pages.length,
      templateSettings: edition.template,
      templateBlocks: edition.template.template_block_category,
      editionSettings: edition.settings,
      item: edition.item,
      contentPages,
      raster: edition.raster.pages,
      usedMedia: edition.used_media,
      totalBlockCount: edition.raster.block_count,
      pasteData: 0,
      copiedBlock: null,
      showTour: false,
      magneticBlocks: true,
      systemProgress: <ProgressBar bsStyle="danger" now={0} />,
      filesToUpload: [],
      filesUploading: [],
      saving: false,
      editionId: edition.id,
      editionTitle: edition.title,
      campaign: edition.campaign,
      publisherInfo: edition.campaign ? edition.campaign.publisher_info : '',
      itemName: '',
      unsaved: false,
      blockContainer: null,
      activeHeight: 100,
      navEntry: [],
      saveCancel: null,
      goToLastPageState: false,
      resetPageState: false,
      updateBlocksState: false,
      uploadError: true,
      fontsToLoad: 0,
      showSettings: false,
      colorSettings: [],
      fontSizeSettings: {},
    };
  }

  resetNavEntry = () => {
    this.setState({ navEntry: [] });
  };

  webPreview = () => {
    const { edition } = this.props;
    const preview = window.open(
      window.location.protocol + '//' + window.location.hostname + '/de/website/' + edition.id,
      '_blank'
    );
    preview.focus();
  };

  goToLastPage = isLastPage => {
    const { contentPages, currentPage, templateSettings } = this.state;
    const pageNameIndex = getPageNameIndex(currentPage, templateSettings, contentPages.length);
    if (templateSettings.pages[pageNameIndex].endless) {
      this.setState({ goToLastPageState: isLastPage });
    }
  };

  resetPage = isReset => {
    const { contentPages, currentPage, templateSettings, updateBlocksState } = this.state;
    const pageNameIndex = getPageNameIndex(currentPage, templateSettings, contentPages.length);
    if (templateSettings.pages[pageNameIndex].endless) {
      this.setState({ resetPageState: isReset, updateBlocksState });
    }
  };

  insertContentBlock = (blockId, index, currentPagesNew, optionAl, indexTwo) => {
    const {
      templateBlocks,
      contentPages,
      currentPage,
      templateSettings,
      pageAmount,
      raster,
      totalBlockCount,
      blockContainer,
    } = this.state;
    const {
      changesR,
      setChangesR,
      intl: { messages },
    } = this.props;
    let result;
    this.goToLastPage(true);
    if (currentPage === templateSettings.sample_page_number - 1 && !templateSettings.initialized) {
      result = insertBlock(
        _.cloneDeep(contentPages),
        raster,
        totalBlockCount + 1,
        index.toString().split('.'),
        templateBlocks,
        currentPage,
        templateSettings,
        pageAmount,
        blockContainer,
        'samplePage'
      );
      this.setState(result, () => {});

      ReactTooltip.rebuild();
    } else if (currentPage === templateSettings.sample_page_number_two - 1 && !templateSettings.initialized) {
      result = insertBlock(
        _.cloneDeep(contentPages),
        raster,
        totalBlockCount + 1,
        index.toString().split('.'),
        templateBlocks,
        currentPage,
        templateSettings,
        pageAmount,
        blockContainer,
        'samplePageTwo'
      );
      this.setState(result, () => {});

      ReactTooltip.rebuild();
    } else if (optionAl === 'addPage') {
      result = insertBlock(
        _.cloneDeep(contentPages),
        raster,
        totalBlockCount + 1,
        index,
        templateBlocks,
        currentPagesNew,
        templateSettings,
        pageAmount,
        blockContainer,
        'addPage',
        indexTwo
      );
      this.setState(result, () => {});
      ReactTooltip.rebuild();
    } else {
      result = insertBlock(
        _.cloneDeep(contentPages),
        raster,
        totalBlockCount + 1,
        index.toString().split('.'),
        templateBlocks,
        currentPage,
        templateSettings,
        pageAmount,
        blockContainer,
        'single'
      );
      this.setState(result, () => {});
      ReactTooltip.rebuild();
    }
    setChangesR(
      createTimelineBlockEntry(changesR, result.newId, currentPage, messages.editor.content_block_inserted, 'insert')
    );
  };

  copyContentBlock = (e, type = '') => {
    const { currentPage, contentPages, raster } = this.state;
    const { target } = e;

    this.setState({
      copiedBlock: copyBlock(target, contentPages[parseInt(currentPage, 10)], raster, type),
      pasteData: 1,
    });
  };

  pasteContentBlock = (e, type = '') => {
    const { copiedBlock, contentPages, currentPage, raster, totalBlockCount, usedMedia } = this.state;
    const {
      setVariables,
      changesR,
      setChangesR,
      intl: { messages },
    } = this.props;
    let targetIndex;
    let innerTargetIndex;
    if (type === 'inner') {
      targetIndex =
        e.target.getAttribute('data-parent') === -1
          ? -1
          : _.findIndex(contentPages[currentPage].blocks, { id: e.target.getAttribute('data-parent') });
      innerTargetIndex = _.findIndex(contentPages[currentPage].blocks[targetIndex].blocks, {
        id: e.target.getAttribute('data-block'),
      });
    } else {
      targetIndex =
        e.target.getAttribute('data-block') === -1
          ? -1
          : _.findIndex(contentPages[currentPage].blocks, { id: e.target.getAttribute('data-block') });
    }
    setVariables('unsavedtrue');
    const pasteResult = pasteBlock(
      totalBlockCount,
      targetIndex,
      currentPage,
      contentPages[currentPage],
      raster,
      copiedBlock,
      usedMedia,
      type,
      innerTargetIndex
    );
    contentPages[currentPage] = pasteResult.contentPage;

    setChangesR(
      createTimelineBlockEntry(changesR, pasteResult.newId, currentPage, messages.editor.content_block_copied, 'copy')
    );

    this.setState(
      {
        contentPages,
        copiedBlock: null,
        pasteData: 0,
        raster: pasteResult.changedRaster,
        totalBlockCount: pasteResult.newTotalBlockCount,
        usedMedia: pasteResult.usedMedia,
        unsaved: true,
        navEntry: pasteResult.navEntry,
      },
      () => {}
    );
    this.resetPage(true);
  };

  changeContentBlock = e => {
    const { currentPage, contentPages, raster, templateSettings } = this.state;
    const {
      changesR,
      setChangesR,
      setVariables,
      intl: { messages },
    } = this.props;
    const sourceId = e.target.getAttribute('data-block');
    const targetId = e.target.getAttribute('data-target');
    setVariables('unsavedtrue');
    const result = changeBlock(
      sourceId,
      targetId,
      contentPages,
      raster,
      currentPage,
      templateSettings.export_type.value
    );
    setChangesR(
      createTimelineBlockEntry(changesR, sourceId, currentPage, messages.editor.content_blocks_switched, 'shift')
    );

    this.setState(
      {
        raster: result.raster,
        contentPages: result.contentPages,
        unsaved: true,
      },
      () => {}
    );
    this.resetPage(true);
  };

  resetContentBlock = e => {
    const { templateBlocks, currentPage, contentPages, usedMedia } = this.state;
    const {
      changesR,
      setVariables,
      setChangesR,
      intl: { messages },
    } = this.props;
    setVariables('unsavedtrue');

    const rasterId = e.getAttribute('data-block');
    const blockId = e.getAttribute('data-template');
    setChangesR(
      createTimelineBlockEntry(changesR, rasterId, currentPage, messages.editor.content_block_reset, 'reset')
    );
    this.setState(resetBlock(rasterId, blockId, contentPages, currentPage, templateBlocks, usedMedia), () => {});
    this.resetPage(true);
  };

  resizeContentBlock = (e, dummy) => {
    const { templateBlocks, currentPage, contentPages, raster } = this.state;
    const {
      changesR,
      setVariables,
      setChangesR,
      intl: { messages },
    } = this.props;
    let blockId;
    let changeId;
    if (dummy !== undefined) {
      blockId = e;
      changeId = dummy;
    } else {
      blockId = e.getAttribute('data-block');
      changeId = e.getAttribute('data-template');
    }

    const resizeResult = resizeBlock(templateBlocks, contentPages[currentPage], raster, currentPage, blockId, changeId);
    contentPages[currentPage] = resizeResult.contentPage;
    setVariables('unsavedtrue');
    setChangesR(
      createTimelineBlockEntry(changesR, blockId, currentPage, messages.editor.content_block_resize, 'resize')
    );
    this.setState(
      {
        contentPages,
        raster: resizeResult.changedRaster,
        unsaved: true,
      },
      () => {}
    );
    return true;
  };

  deleteChild = parent => {
    const { contentPages, currentPage, raster, magneticBlocks, usedMedia } = this.state;
    const {
      changesR,
      setChangesR,
      intl: { messages },
    } = this.props;
    if (parent !== undefined && parent !== '') {
      _forEach(contentPages[currentPage].blocks, block => {
        const blockCombinationChild = block.block_combination_child.split(',');
        _forEach(blockCombinationChild, blockCC => {
          if (blockCC === parent) {
            setChangesR(
              createTimelineBlockEntry(changesR, block.id, currentPage, messages.editor.content_block_removed, 'delete')
            );
            this.setState(
              removeBlock(
                currentPage,
                contentPages,
                raster,
                block.id,
                block.page_space_needed,
                magneticBlocks,
                usedMedia
              ),
              () => {
                this.deleteSelectedColor(block.id);
              }
            );
          }
        });
      });
    }
  };

  removeContentBlock = e => {
    const { contentPages, currentPage, raster, magneticBlocks, usedMedia } = this.state;
    const {
      changesR,
      setVariables,
      setChangesR,
      intl: { messages },
    } = this.props;
    const sourceRasterId = e.getAttribute('data-block');
    const sourceSpace = parseInt(e.getAttribute('data-space'), 10);
    /* cross-page auto-delete of the child elements is not necessary at the moment,
     since the parent - child combinations only work on a page-by-page basis
     */

    const indexOfParent = _.findIndex(contentPages[currentPage]?.blocks, ['id', sourceRasterId]); // needed for delete child
    const parent = contentPages[currentPage]?.blocks[indexOfParent]?.block_combination_parent; // needed for delete child
    const allParent = [];

    allParent.push(parent);

    _forEach(allParent, _parent => {
      if (_parent !== undefined && _parent !== '') {
        _forEach(contentPages[currentPage].blocks, block => {
          const blockCombinationChild = block.block_combination_child.split(',');
          _forEach(blockCombinationChild, blockCC => {
            if (blockCC === parent) {
              allParent.push(block.block_combination_parent);
            }
          });
        });
      }
    });

    const reversedParent = allParent.reverse();
    _forEach(reversedParent, parent => {
      this.deleteChild(parent);
    });

    setVariables('unsavedtrue');
    setChangesR(
      createTimelineBlockEntry(changesR, sourceRasterId, currentPage, messages.editor.content_block_removed, 'delete')
    );
    this.setState(
      removeBlock(currentPage, contentPages, raster, sourceRasterId, sourceSpace, magneticBlocks, usedMedia),
      () => {
        this.deleteSelectedColor(sourceRasterId);
      }
    );

    this.resetPage(true);
  };

  selectBlockContainer = blockId => {
    this.setState({
      blockContainer: blockId,
    });
  };

  unselectBlockContainer = () => {
    this.setState({
      blockContainer: null,
    });
  };

  updateCurrentPage = pageNumber => {
    this.setState({
      currentPage: pageNumber,
      blockContainer: null, // unselect inner container when changing page
    });
  };

  setCurrentPage = e => {
    this.updateCurrentPage(parseInt(e.target.innerText, 10) - 1);
  };

  selectPage = e => {
    let nextPage = parseInt(e.target.getAttribute('data-page'), 10);
    if (isNaN(nextPage)) {
      nextPage = 0;
    }
    this.updateCurrentPage(nextPage);
    this.setState({ updateBlocksState: true });
  };

  setToolbarOptions = toolbar => {
    const { toolbar: oldState } = this.state;

    this.setState({
      toolbar: {
        ...oldState,
        ...toolbar,
      },
    });
  };

  updateInnerBlock = (
    idPageBlock,
    blocks,
    spaceConsumed,
    usedMedia,
    page = null,
    drag,
    idInnerBlock,
    initialUpdate,
    paramList
  ) => {
    const { currentPage, contentPages } = this.state;
    const { setVariables } = this.props;
    let onPage = currentPage;
    if (page) {
      onPage = page;
    }

    if (initialUpdate) {
      this.setState(prevState => {
        const dataContentPages = _cloneDeep(prevState.contentPages);
        const elementOnPage = _find(dataContentPages[onPage].blocks, { id: idPageBlock });
        const innerBlockElement = _find(elementOnPage.blocks, { id: idInnerBlock });
        const { params } = innerBlockElement;
        _forEach(paramList, ({ name, value, error, filter, url }) => {
          const paramIndex = _findIndex(params, ['name', name]);
          const paramValue = { name, value, errors: error, filter, url };
          if (paramIndex >= 0) {
            params[paramIndex] = paramValue;
          } else {
            params.push(paramValue);
          }
        });
        elementOnPage.space_consumed = spaceConsumed;
        setVariables('unsavedtrue');
        return {
          contentPages: dataContentPages,
          unsaved: true,
          usedMedia,
        };
      });
    } else {
      const data = _.cloneDeep(contentPages);
      const element = _.find(data[onPage].blocks, { id: idPageBlock });
      element.blocks = blocks;

      if (drag === true) {
        setVariables('unsavedtrue');
        this.setState({ contentPages: data, unsaved: true });
      } else {
        element.space_consumed = spaceConsumed;
        setVariables('unsavedtrue');
        this.setState({ contentPages: data, unsaved: true, usedMedia });
      }
    }
  };

  updateBlock = (id, blocks, drag) => {
    const { currentPage, contentPages } = this.state;
    const onPage = currentPage;
    const data = _.cloneDeep(contentPages);
    const { setVariables } = this.props;
    data[onPage].blocks = blocks;
    if (drag === true) {
      setVariables('unsavedtrue');
      this.setState({
        contentPages: data,
        unsaved: true,
      });
    }
  };

  initDefaultPosition = () => {
    const { contentPages } = this.state;
    const _contentPages = _.cloneDeep(contentPages);
    _.forEach(_contentPages, pages => {
      _.forEach(pages.blocks, block => {
        const { position_x, position_y, template } = block;
        const isNotSet = value => value === undefined || value === null;
        const bothPositionsNotSet = isNotSet(position_x) && isNotSet(position_y);

        const validTemplates = ['draggable', 'allowdraganddrop'];
        const isTemplateValid = template && validTemplates.some(templateName => template.includes(templateName));

        if (bothPositionsNotSet && isTemplateValid) {
          let blockTemplate = _.cloneDeep(block.template);
          blockTemplate = blockTemplate.toLowerCase();
          block.position_x = getNumericValue(blockTemplate, 'defaultpositionx') || 0;
          block.position_y = getNumericValue(blockTemplate, 'defaultpositiony') || 0;
        }
      });
    });

    this.setState({
      contentPages: _contentPages,
    });
  };

  updateParam = (id, name, value, errors, name2 = '', value2 = '', _currentPage = null) => {
    const { currentPage } = this.state;
    const error = {};
    if (errors !== null) {
      _.forEach(errors, ({ overflow, default_image }) => {
        error.overflow = overflow;
        error.default_image = default_image;
      });
    }
    let params = [{ name, value, error }];
    if (name2 !== '' && value2 !== '') {
      params = [{ name, value, error }, { name: name2, value: value2 }];
    }

    let cPage = currentPage;
    if (_currentPage !== null) {
      cPage = _currentPage;
    }

    this.updateParams(id, params, errors, cPage);
    return true;
  };

  updateSpecialBlockAttributes = (id, name, value, thumb, errors, filter, url) => {
    const { currentPage } = this.state;
    const error = {};
    if (errors !== null) {
      error.overflow = errors?.overflow;
      error.default_image = errors?.default_image;
    }
    const params = [{ name, value, thumb, error, filter, url }];
    this.updateParams(id, params, errors, currentPage);
    return true;
  };

  updateParams = (id, paramList, errors, currentPage) => {
    const { contentPages, raster } = this.state;
    const {
      changesR,
      setChangesR,
      intl: { messages },
      setVariables,
    } = this.props;

    this.setState(
      prevState => {
        const data = _.cloneDeep(prevState.contentPages);
        const data2 = _.cloneDeep(contentPages);
        let element = _.find(data[currentPage].blocks, { id });

        if (element === undefined) {
          currentPage = _.findIndex(raster, innerArray => {
            return _.includes(innerArray, id);
          });
          element = _.find(data[currentPage].blocks, { id });
        }

        const { params } = element;
        const pIndex = (currentPage + 1).toString();

        if (typeof changesR === 'undefined' || typeof changesR[pIndex] === 'undefined') {
          changesR[pIndex] = [];
        }

        _.forEach(paramList, ({ name, value, error, filter, url, thumb }) => {
          const paramIndex = _.findIndex(params, ['name', name]);
          const paramValue = { name, value, errors: error, filter, url, thumb };
          let newChanges;
          if (value.includes(S3_DOMAIN)) {
            newChanges = {
              text: messages.editor.new_picture_inserted,
              action: 'upload',
            };
          } else {
            newChanges = {
              text: messages.editor.text_changed,
              action: 'text',
            };
          }
          changesR[pIndex][id] = newChanges;
          if (paramIndex >= 0) {
            // parameter was found
            params[paramIndex] = paramValue;
          } else {
            // parameter was not found, append the parameter with its value
            params.push(paramValue);
          }
        });
        return {
          contentPages: data,
          unsaved: true,
          updateBlocksState: true,
        };
      },
      () => {
        setVariables('unsavedtrue');
        setChangesR(changesR);
      }
    );
  };

  saveDescription = (data, index) => {
    const { usedMedia } = this.state;
    const { setVariables } = this.props;
    const usedMediaClone = _.cloneDeep(usedMedia);
    usedMediaClone[index].imgDescription = data;
    this.setState({ usedMedia: usedMediaClone });
    setVariables('unsavedtrue');
  };

  uploading = () => {
    const { filesToUpload, editionId, usedMedia } = this.state;
    const { setVariables } = this.props;
    const filesUploading = _.cloneDeep(filesToUpload);
    const { CancelToken } = Axios;
    _.forEach(filesUploading, (file, i) => {
      filesUploading[i].completed = 0;
      const imageData = new FormData();
      let thumb = '';
      let originalName = '';
      let copyright = '';
      let id = 0;
      if (file.imageKey === null || file.imageKey === 'video') {
        imageData.append('file', file.image);
      } else {
        const imageHelper = _.findIndex(usedMedia, function(o) {
          return o.key === file.imageKey;
        });
        if (imageHelper !== -1) {
          // usedMedia
          thumb = usedMedia[imageHelper].thumb;
          originalName = usedMedia[imageHelper].originalname;
          copyright = usedMedia[imageHelper].copyright;
          const mediaHelper = _.findIndex(usedMedia, function(o) {
            return o.key === file.imageKey && o.blockid === file.id && o.param === file.name;
          });
          if (mediaHelper >= 0) {
            id = usedMedia[mediaHelper].id;
          }
        } else {
          // must be from mediaLibrary then
          originalName = file.filename;
          thumb = file.thumb;
          copyright = file.copyright;
        }
      }
      const args = {
        id,
        uploadType: file.imageKey === 'video' ? 'userVideo' : 'userImage',
        data: file.data,
        blockId: file.id,
        name: file.name,
        imageKey: file.imageKey === 'video' ? null : file.imageKey,
        thumb,
        originalname: originalName,
        width: file.width,
        height: file.height,
        copyright,
        imgDescription: file.imgDescription,
      };
      imageData.append('json', JSON.stringify(args));

      Axios.post('/api/editions/' + editionId + '/images.json', imageData, {
        cancelToken: new CancelToken(function executor(c) {
          // An executor function receives a cancel function as a parameter
          filesUploading[i].cancel = c;
        }) /* ,
        onUploadProgress: (progressEvent) => {
          const progress = (progressEvent.loaded / progressEvent.total) * 100;

          filesUploading[i].completed = progress;
          this.setState({
            filesUploading: filesUploading,
          });
        } */,
      })
        .then(response => {
          // handle success
          setVariables('unsavedtrue');
          const { usedMedia } = this.state;
          const {
            intl: { messages },
          } = this.props;
          let value = response.data.path + response.data.file;
          const pathOfThumb = S3_LINKS.MEDIA_IMGS_THUMB;
          const thumb = pathOfThumb + response.data.thumb;

          if (file.imageKey === 'video') {
            value = 'link#aaa#' + response.data.path + response.data.file + '#aaa#';
          }

          if (file.ownKey === 'sliderImageDefault') {
            const cutImage = response.data.path + response.data.file;
            const copyrightSliderImage = response.data.usedMedia ? response.data.usedMedia.copyright : '';
            const IMG_ARR = [cutImage, '', '', copyrightSliderImage];
            file.sliderImage.push(IMG_ARR);

            let param = '';
            _.forEach(file.sliderImage, (sliderData, i) => {
              param += sliderData[0] + '#aaa#' + sliderData[1] + '#aaa#' + sliderData[2] + '#aaa#' + sliderData[3];
              if (i < file.sliderImage.length - 1) {
                param += '#cc#';
              }
            });
            value = param + '#opt#' + file.autoplay;
          }
          if (file.ownKey === 'sliderImageReverse') {
            const cutImage = response.data.path + response.data.file;
            const copyrightSliderImage = response.data.usedMedia ? response.data.usedMedia.copyright : '';
            const IMG_ARR = [cutImage, '', '', copyrightSliderImage];
            file.sliderImage.unshift(IMG_ARR);

            let param = '';
            _.forEach(file.sliderImage, (sliderData, i) => {
              param += sliderData[0] + '#aaa#' + sliderData[1] + '#aaa#' + sliderData[2] + '#aaa#' + sliderData[3];
              if (i < file.sliderImage.length - 1) {
                param += '#cc#';
              }
            });
            value = param + '#opt#' + file.autoplay;
          }
          const copyright = response.data.usedMedia ? response.data.usedMedia.copyright : null;
          const { name } = file;
          const copyName = 'copyright_' + file.name;
          const { id } = file;
          const { page } = file;
          const currentUploadOfThisImage = _.findIndex(filesUploading, { id, name });
          if (currentUploadOfThisImage >= 0) {
            filesUploading.splice(currentUploadOfThisImage, 1);
          }
          const usedImage = checkIfMediaBlockExists(
            usedMedia,
            response.data.usedMedia.blockid,
            response.data.usedMedia.param
          );

          let newCut = -1;
          if (usedImage >= 0) {
            // there is a media entry for this param of block already
            if (file.imageKey !== null) {
              // check if we use an image from mediathek or document medias
              newCut = checkIfMediaStaysSame(
                usedMedia,
                response.data.usedMedia.blockid,
                response.data.usedMedia.param,
                response.data.usedMedia.key
              );
            }
            if (file.imageKey === null || newCut < 0) {
              // if it is a new upload or a different existing media entry was selected
              // check if the new media was in unused blocks before, if so use this instead of taking over block
              const wasUnused = checkIfMediaIsUnused(usedMedia, response.data.usedMedia.key);

              // check if old media already has an unused media entry, to avoid thousands of unused entries
              const alreadyUnused = checkIfMediaIsUnused(usedMedia, usedMedia[usedImage].key);

              if (wasUnused >= 0) {
                // unused media entry exists, so we just set the new connections
                usedMedia[wasUnused].blockid = response.data.usedMedia.blockid;
                usedMedia[wasUnused].param = response.data.usedMedia.param;
                if (alreadyUnused >= 0) {
                  // old media entry already in unused entries, so just cut it out
                  usedMedia.splice(usedImage, 1);
                } else {
                  // otherwise set it unused
                  usedMedia[usedImage].blockid = 'unused';
                }
              } else {
                // take over the media entry
                if (alreadyUnused < 0) {
                  // no unused entry for this image, so we create one
                  const saveMedia = _.cloneDeep(usedMedia[usedImage]);
                  saveMedia.blockid = 'unused';
                  usedMedia.push(saveMedia);
                }
                usedMedia[usedImage].key = response.data.usedMedia.key;
                usedMedia[usedImage].originalname = response.data.usedMedia.originalname;
                usedMedia[usedImage].thumb = response.data.usedMedia.thumb;
                usedMedia[usedImage].width = response.data.usedMedia.width;
                usedMedia[usedImage].height = response.data.usedMedia.height;
                usedMedia[usedImage].path = response.data.path;
                usedMedia[usedImage].copyright = copyright;
              }
            }
          } else {
            // if the param of block had no image before, just push a new document media entry
            usedMedia.push(response.data.usedMedia);
          }
          this.setState(
            {
              filesUploading,
              usedMedia,
              uploadError: true,
            },
            () => {
              if (copyright) {
                let _copyright;
                if (copyright.includes('<p>')) {
                  _copyright = copyright;
                } else {
                  _copyright = '<p>' + copyright + '</p>';
                }
                if (!file.updateF) {
                  this.updateParams(id, [{ name, value, thumb }, { name: copyName, value: _copyright }], null, page);
                } else {
                  file.updateF(id, [{ name, value, thumb }, { name: copyName, value: _copyright }], null, page);
                }
              } else if (!file.updateF) {
                this.updateParams(id, [{ name, value, thumb }], null, page);
              } else {
                file.updateF(id, [{ name, value, thumb }], null, page);
              }
              this.resetPage(true);
              this.props.setVariables('waitForSavingFalse');
            }
          );
        })
        .catch(error => {
          const {
            intl: { messages },
          } = this.props;

          let errorMessage = messages.editor.try_again;
          if (error.response && error.response.data && error.response.data.message) {
            errorMessage = error.response.data.message;
          }

          const options = {
            customUI: ({ onClose }) => {
              return (
                <div className="react-confirm-alert-body">
                  <h1>{messages.editor.upload_failed}</h1>
                  <p>{errorMessage}</p>
                  <div className="react-confirm-alert-button-group" style={{ width: '100%', margin: '0 auto' }}>
                    <Button
                      key="ButtonOnClose"
                      style={{ margin: '0 auto', marginTop: '20px', width: '100px' }}
                      bsStyle="primary"
                      onClick={onClose}
                    >
                      OK
                    </Button>
                  </div>
                </div>
              );
            },
            childrenElement: () => <div />,
            closeOnEscape: true,
            closeOnClickOutside: true,
          };
          confirmAlert(options);
          this.setState({
            uploadError: true,
          });
          // handle error
          if (Axios.isCancel(error)) {
            console.log('post Request canceled');
          } else {
            console.log('cancel', error);
          }
          filesUploading.splice(i, 1);
          this.setState({
            filesUploading,
          });
        });
    });
    this.setState({
      filesToUpload: [],
      filesUploading,
      uploadError: false,
    });
  };

  uploadImage = (
    id,
    image,
    imageKey,
    name,
    data,
    width,
    height,
    filename,
    thumb,
    copyright,
    updateF = null,
    sliderImage,
    ownKey,
    imgDescription,
    autoplay
  ) => {
    const { filesToUpload, filesUploading, currentPage } = this.state;
    const currentUploadOfThisImage = _.findKey(filesUploading, { id, name });
    if (typeof currentUploadOfThisImage !== 'undefined') {
      filesUploading[currentUploadOfThisImage].cancel();
      // filesUploading.splice(currentUploadOfThisImage, 1)
    }
    const currentScheduleOfThisImage = _.findKey(filesToUpload, { id, name });
    if (typeof currentScheduleOfThisImage !== 'undefined') {
      filesToUpload[currentScheduleOfThisImage] = {
        id,
        name,
        image,
        imageKey,
        data,
        page: currentPage,
        completed: 0,
        width,
        height,
        filename,
        thumb,
        copyright,
        updateF,
        sliderImage,
        ownKey,
        imgDescription,
        autoplay,
      };
    } else {
      filesToUpload.push({
        id,
        name,
        image,
        imageKey,
        data,
        page: currentPage,
        completed: 0,
        width,
        height,
        filename,
        thumb,
        copyright,
        updateF,
        sliderImage,
        ownKey,
        imgDescription,
        autoplay,
      });
    }
    this.setState({
      filesToUpload,
    });
  };

  saveTo = (campaignId, editionTitle) => {
    const { raster, contentPages, totalBlockCount, currentPage, usedMedia, filesUploading } = this.state;
    const { savingR, setVariables, saveToR, edition } = this.props;

    const currentEdition = _.cloneDeep(edition);

    if (!savingR) {
      if (filesUploading.length === 0) {
        setVariables('savingtrue');
        this.setState(
          {
            saving: true,
          },
          () => {
            const rasterToSave = {
              block_count: totalBlockCount,
              pages: raster,
            };
            currentEdition.raster = rasterToSave;
            currentEdition.pages = contentPages;
            currentEdition.shown = currentPage;
            currentEdition.marked = false;
            currentEdition.usedMedia = usedMedia;
            currentEdition.title = editionTitle;
            currentEdition.campaign = campaignId;
            currentEdition.template = edition.template.id;
            currentEdition.item = edition.item.id;
            currentEdition.action = 'copy';
            delete currentEdition.id;
            delete currentEdition.cdate;
            delete currentEdition.mdate;

            saveToR(currentEdition);
          }
        );
      } else {
        this.waitForSaving = window.setTimeout(() => {
          this.saveTo(campaignId);
        }, 1000);
      }
    }
  };

  initTemplate = () => {
    const { raster, contentPages, totalBlockCount, templateSettings } = this.state;
    const { initTemplateR, setVariables } = this.props;
    setVariables('savingtrue');
    this.setState(
      {
        saving: true,
      },
      () => {
        const rasterToSave = {
          blockCount: totalBlockCount,
          pages: raster,
        };
        const contentToSave = {
          initialRaster: rasterToSave,
          initialPages: contentPages,
        };

        initTemplateR(templateSettings, contentToSave);
      }
    );
  };

  saveContent = () => {
    const {
      editionId,
      raster,
      contentPages,
      totalBlockCount,
      currentPage,
      usedMedia,
      editionTitle,
      templateSettings,
      editionSettings,
      filesUploading,
    } = this.state;
    const {
      save_ContentR,
      savingR,
      setVariables,
      changesR,
      intl: { messages },
    } = this.props;
    if (!templateSettings.initialized) {
      return;
    }
    if (filesUploading.length === 0 && !savingR) {
      setVariables('savingtrue');

      const rasterToSave = {
        block_count: totalBlockCount,
        pages: raster,
      };

      let changeString = messages.editor.no_changes;
      if (Object.keys(changesR).length > 0) {
        changeString = '';
        _.forEach(changesR, (changedPage, key) => {
          if (key === 0 && Object.keys(changedPage).length > 0) {
            changeString += '<b>' + messages.editor.changes_on_document + '</b><br/>';
          } else if (Object.keys(changedPage).length > 0) {
            changeString += '<b>' + messages.editor.changes_on_page + ' ' + key + ':</b><br/>';
          }
          _.forIn(changedPage, changedBlock => {
            changeString += changedBlock.text + '<br/>';
          });
          changeString += '<br/>';
        });
      }
      const contentToSave = {
        raster: rasterToSave,
        pages: contentPages,
        shown: currentPage,
        usedMedia,
        title: editionTitle,
        settings: editionSettings,
        changes: changeString,
        pageAmount: contentPages.length,
      };
      setVariables(false);
      // setChangesR({}); // set changesR into reducer to {}
      save_ContentR(editionId, contentToSave);
    } else if (filesUploading.length > 0) {
      setVariables('waitForSavingTrue');
    }
  };

  changePages = e => {
    // todo check for oversized blocks
    const { contentPages, raster } = this.state;
    const sourceIndex = parseInt(e.target.getAttribute('data-page'), 10);
    const sourcePage = _.cloneDeep(contentPages[sourceIndex]);
    const sourceRaster = _.cloneDeep(raster[sourceIndex]);
    const targetIndex = parseInt(e.target.getAttribute('data-target'), 10);
    const targetPage = _.cloneDeep(contentPages[targetIndex]);
    const targetRaster = _.cloneDeep(raster[targetIndex]);
    const {
      setVariables,
      intl: { messages },
      changesR,
      setChangesR,
    } = this.props;
    targetPage.page_number = sourceIndex + 1;
    sourcePage.page_number = targetIndex + 1;
    contentPages[sourceIndex] = targetPage;
    contentPages[targetIndex] = sourcePage;
    raster[sourceIndex] = targetRaster;
    raster[targetIndex] = sourceRaster;
    setVariables('unsavedtrue');
    const newChanges = {
      text:
        messages.editor.page + targetPage.page_number + messages.editor.exchanged_with_page + sourcePage.page_number,
      action: 'add',
    };

    if (typeof changesR[0] === 'undefined') {
      changesR[0] = [];
    }
    const timestamp = Date.now();
    changesR[0][timestamp] = newChanges;
    setVariables('unsavedtrue');
    setChangesR(changesR);

    /** delete selected color on swapped page */

    const { colorSettings } = this.state;
    let _colorSettings = _.cloneDeep(colorSettings);

    // Find and delete color swapped page
    _colorSettings = _.filter(_colorSettings, x => x.page !== sourceIndex && x.page !== targetIndex);

    this.setState({ colorSettings: _colorSettings }, () => {
      this.setState({
        contentPages,
        raster,
        unsaved: true,
      });
    });
  };

  addPages = e => {
    // todo check for oversized blocks
    const positionPage = parseInt(e.target.getAttribute('data-page'), 10) + 1;
    const { templateSettings, pageAmount, contentPages, raster } = this.state;
    const addStep = templateSettings.page_add_step;
    const newPageAmount = pageAmount + addStep;
    const newPages = [];
    const newPagesRaster = [];
    let pageSpace = 0;
    const pageNumbersForInsertBlock = [];
    _.forEach(contentPages, (contentPage, i) => {
      if (i === positionPage) {
        for (let j = 0; j < addStep; j++) {
          pageNumbersForInsertBlock.push(i + j + 1);

          newPages.push({
            page_number: i + j + 1,
            space_consumed: 0,
            blocks: [],
          });

          pageSpace = templateSettings.pages[getPageNameIndex(i + j, templateSettings)].page_space;

          const newSlots = [];
          for (let slots = 0; slots < pageSpace; slots++) {
            newSlots[slots] = '';
          }
          newPagesRaster[j] = newSlots;
        }
        contentPage.page_number = i + addStep + 1;
      } else if (i > positionPage) {
        contentPage.page_number = i + addStep + 1;
      }
      newPages.push(contentPage);
      if (positionPage === contentPages.length && i === contentPages.length - 1) {
        // add pages at the end
        for (let j = 0; j < addStep; j++) {
          pageNumbersForInsertBlock.push(i + j + 2);
          newPages.push({
            page_number: i + j + 2,
            space_consumed: 0,
            blocks: [],
          });
          pageSpace = templateSettings.pages[getPageNameIndex(i + j + 1, templateSettings)].page_space;

          const newSlots = [];
          for (let slots = 0; slots < pageSpace; slots++) {
            newSlots[slots] = '';
          }
          newPagesRaster[j] = newSlots;
        }
      }
      if (newPages.length === newPageAmount) {
        const changedRaster = [...raster.slice(0, positionPage), ...newPagesRaster, ...raster.slice(positionPage)];
        const {
          setVariables,
          intl: { messages },
          changesR,
          setChangesR,
          edition,
        } = this.props;
        setVariables('unsavedtrue');
        const newChanges = {
          text: addStep + messages.editor.new_page_inserted + (positionPage + 1),
          action: 'add',
        };

        if (typeof changesR[0] === 'undefined') {
          changesR[0] = [];
        }
        const timestamp = Date.now();
        changesR[0][timestamp] = newChanges;
        setVariables('unsavedtrue');
        setChangesR(changesR);
        this.setState(
          {
            pageAmount: newPageAmount,
            contentPages: newPages,
            currentPage: positionPage,
            raster: changedRaster,
            unsaved: true,
          },
          () => {
            // if samplePage declared start
            if (
              (typeof edition.template.sample_page_number !== undefined && edition.template.sample_page_number > 0) ||
              (typeof edition.template.sample_page_number_two !== undefined &&
                edition.template.sample_page_number_two > 0)
            ) {
              let blockIds;
              let blockIndex;
              const blockIndexNew = []; // "2.1.0";
              let blockIndexTwo;
              const blockIndexNewTwo = []; // "2.1.0";

              const initialPages = templateSettings.initialized ? edition.template.initial_pages : contentPages;

              for (let it = 0; it < initialPages[edition.template.sample_page_number - 1].blocks.length; it++) {
                for (let xbc = 0; xbc < edition.template.template_block_category.length; xbc++) {
                  for (let xbcv = 0; xbcv < edition.template.template_block_category[xbc].variants.length; xbcv++) {
                    for (
                      let xbcvb = 0;
                      xbcvb < edition.template.template_block_category[xbc].variants[xbcv].blocks.length;
                      xbcvb++
                    ) {
                      if (
                        edition.template.template_block_category[xbc].variants[xbcv].blocks[xbcvb].id ===
                        initialPages[edition.template.sample_page_number - 1].blocks[it].template_id
                      ) {
                        blockIndex = xbc + '.' + xbcv + '.' + xbcvb;
                        blockIndexNew.push(blockIndex);
                      }
                    }
                  }
                }
              }
              if (edition.template.sample_page_number_two > 0) {
                for (let it = 0; it < initialPages[edition.template.sample_page_number_two - 1].blocks.length; it++) {
                  for (let xbc = 0; xbc < edition.template.template_block_category.length; xbc++) {
                    for (let xbcv = 0; xbcv < edition.template.template_block_category[xbc].variants.length; xbcv++) {
                      for (
                        let xbcvb = 0;
                        xbcvb < edition.template.template_block_category[xbc].variants[xbcv].blocks.length;
                        xbcvb++
                      ) {
                        if (
                          edition.template.template_block_category[xbc].variants[xbcv].blocks[xbcvb].id ===
                          initialPages[edition.template.sample_page_number_two - 1].blocks[it].template_id
                        ) {
                          blockIndexTwo = xbc + '.' + xbcv + '.' + xbcvb;
                          blockIndexNewTwo.push(blockIndexTwo);
                        }
                      }
                    }
                  }
                }
              }
              // this.props.edition.template.initialPages[edition.template.sample_page_number - 1].blocks[]
              // pageNumbersForInsertBlock (currentpages)
              /* blockIndexOfSamplePages */

              blockIds = 'noIds'; // "60fa834f0e04f776d95b7dec";

              this.setState(
                {
                  blockContainer: null,
                },
                () => {
                  this.insertContentBlock(
                    blockIds,
                    blockIndexNew,
                    pageNumbersForInsertBlock,
                    'addPage',
                    blockIndexNewTwo
                  );
                }
              );
            }
          }
        );
      }
    });
  };

  removePages = e => {
    // todo check for oversized blocks
    const { contentPages, templateSettings, pageAmount, raster } = this.state;
    const positionPage = parseInt(e.target.getAttribute('data-page'), 10);
    const addStep = templateSettings.page_add_step;
    const newPages = [];
    const newPageAmount = pageAmount - addStep;
    const {
      intl: { messages },
      setVariables,
      changesR,
      setChangesR,
    } = this.props;

    let pagesToDelete = '';
    for (let i = 1; i <= addStep; i++) {
      const page = positionPage + 1 + i;
      pagesToDelete += messages.page_to_delete + page;
      if (i < addStep) {
        pagesToDelete += ', ';
      }
    }

    const options = {
      title: messages.editor.delete_pages,
      message:
        addStep + ' ' + messages.editor.all_content_deleted + messages.pages_to_delete + '(' + pagesToDelete + ')',
      buttons: [
        {
          label: messages.editor.yes_delete,
          onClick: () => {
            _.forEach(contentPages, (contentPage, i) => {
              if (i <= positionPage) {
                newPages.push(contentPage);
              } else if (i > positionPage + addStep) {
                contentPage.page_number = i - addStep + 1;
                newPages.push(contentPage);
              }

              if (newPages.length === newPageAmount) {
                const changedRaster = [
                  ...raster.slice(0, positionPage + 1),
                  ...raster.slice(positionPage + 1 + addStep),
                ];
                setVariables('unsavedtrue');
                const newChanges = {
                  text: addStep + messages.editor.pages_deleted + (positionPage + 1),
                  action: 'remove',
                };

                if (typeof changesR[0] === 'undefined') {
                  changesR[0] = [];
                }
                const timestamp = Date.now();
                changesR[0][timestamp] = newChanges;
                setVariables('unsavedtrue');
                setChangesR(changesR);
                this.setState(
                  {
                    pageAmount: newPageAmount,
                    contentPages: newPages,
                    currentPage: positionPage,
                    raster: changedRaster,
                    unsaved: true,
                  },
                  () => {}
                );
              }
            });
          },
        },
        {
          label: messages.editor.rather_not,
          onClick: () => {},
        },
      ],
      childrenElement: () => <div />,
      closeOnEscape: true,
      closeOnClickOutside: true,
    };

    confirmAlert(options);
  };

  checkErrors = () => {};

  componentDidMount = () => {
    const {
      edition,
      showTour,
      setVariables,
      intl: { messages },
      appIntl,
      doBlockForm,
      user,
    } = this.props;

    if (!may('ROLE_ROOT_EMPLOYEE', user.roles)) {
      doBlockForm(edition?.id);
    }

    setVariables('resetTimeline');
    let _itemName;
    typeof edition.item.name === 'object'
      ? (_itemName = getNameInCurrentLanguage(edition.item.name, appIntl))
      : (_itemName = edition.item.name);
    this.setState({ itemName: _itemName });

    if (edition.template.target && edition.template.target.fonts && edition.template.target.fonts.length > 0) {
      const { fontsToLoad } = this.state;
      this.setState({
        fontsToLoad: fontsToLoad + edition.template.target.fonts.length,
      });
      _.forEach(edition.template.target.fonts, font => {
        const junction_font = new FontFace(
          font.css_path,
          'url(' + S3_LINKS.CLIENTS + edition.template.target.username + '/structure/' + font.file_name + ')'
        );
        junction_font
          .load()
          .then(function(loaded_face) {
            document.fonts.add(loaded_face);
            this.setState({
              fontsToLoad: this.state.fontsToLoad - 1,
            });
          })
          .catch(function() {
            // error occurred
          });
      });
    }
    if (edition.template.fonts.length > 0) {
      const { fontsToLoad } = this.state;
      this.setState({
        fontsToLoad: fontsToLoad + edition.template.fonts.length,
      });
      _.forEach(edition.template.fonts, font => {
        const junction_font = new FontFace(
          font.css_path,
          'url(' + S3_LINKS.TEMPLATES + edition.template.id + '/structure/' + font.file_name + ')'
        );
        junction_font
          .load()
          .then(function(loaded_face) {
            document.fonts.add(loaded_face);
            this.setState({
              fontsToLoad: this.state.fontsToLoad - 1,
            });
          })
          .catch(function() {
            // error occurred
          });
      });
    }

    if (showTour === true) {
      const options = {
        title: messages.editor.new_here,
        message: messages.editor.first_start,
        buttons: [
          {
            label: messages.editor.yes_absolutely,
            onClick: () => {
              this.setState({
                showTour: true,
              });
            },
          },
          {
            label: messages.editor.im_fine,
            onClick: () => {},
          },
        ],
        childrenElement: () => <div />,
        closeOnEscape: true,
        closeOnClickOutside: true,
      };
      confirmAlert(options);
    }
    this.fontCheck = window.setInterval(() => {
      const { fontsToLoad } = this.state;
      if (fontsToLoad <= 0) {
        window.clearInterval(this.fontCheck);
        this.forceUpdate();
      }
    }, 1000);
    this.uploadCheck = window.setInterval(() => {
      const { filesUploading, filesToUpload } = this.state;
      const { savingR } = this.props;

      if (filesToUpload.length > 0 && filesUploading.length === 0 && !savingR) {
        this.uploading();
      }
    }, 5000);

    const { savingR } = this.props;
    if ('virtualKeyboard' in navigator) {
      navigator.virtualKeyboard.overlaysContent = false;
      navigator.virtualKeyboard.addEventListener('geometrychanged', () => {
        const it = document.getElementsByClassName('ScrollbarsCustom-Scroller');
        it[0].scrollBy(-30, -40);
      });
    }

    let disableAutosave = false;
    if (typeof edition?.placed_orders && edition?.placed_orders && edition?.placed_orders?.length > 0) {
      disableAutosave = true;
    }

    if (user.profile && user.profile.editor_autosave !== false && !disableAutosave) {
      this.autoSave = window.setInterval(() => {
        const { unsavedR } = this.props;
        if (unsavedR && !savingR) {
          /* warning should not be displayed with autosave
            const { templateSettings } = this.state;
            if (this.checkDataPolicy() === false && templateSettings?.export_type?.value === '000000000000000000000003') {
              this.getDataPolicyWarning('auto');
            } else {
              this.saveContent();
            }
          */
          this.saveContent();
        }
      }, 300000); // default 300000
    }

    window.addEventListener('beforeunload', () => {
      this.props.doReleaseForm(edition.id);
      if (this.autoSave) {
        window.clearInterval(this.autoSave);
      }
      if (this.fontCheck) {
        window.clearInterval(this.fontCheck);
      }
      if (this.uploadCheck) {
        window.clearInterval(this.uploadCheck);
      }
      if (this.waitForSaving) {
        window.clearTimeout(this.waitForSaving);
      }
    });
  };

  componentDidUpdate = prevProps => {
    const {
      unsavedR,
      linkDataR,
      history,
      appIntl,
      forwardingR,
      setUnsaved,
      setVariables,
      showErrorMessage,
      intl: { messages },
    } = this.props;
    if (prevProps.unsavedR !== unsavedR) {
      setUnsaved(unsavedR);
    }

    if (linkDataR !== prevProps.linkDataR) {
      // setVariables('linkdata');
      history.push(appIntl.urlPrefix + 'editor/' + linkDataR);
      window.location.reload(false);
    }

    if (forwardingR === true) {
      setVariables('forwardingfalse');
      history.push(appIntl.urlPrefix + 'account/settings/producttemplates');
    }

    if (prevProps.waitForSaving !== this.props.waitForSaving && this.props.waitForSaving === false) {
      this.saveContent();
    }

    if (prevProps.showErrorMessage !== showErrorMessage && showErrorMessage === true) {
      const options = {
        customUI: ({ onClose }) => {
          return (
            <div className="react-confirm-alert-body">
              <h1>{messages.editor.saving_failed}</h1>
              <p>{messages.editor.try_again}</p>
              <div className="react-confirm-alert-button-group" style={{ width: '100%', margin: '0 auto' }}>
                <Button
                  key="ButtonOnClose2"
                  style={{ margin: '0 auto', marginTop: '20px', width: '100px' }}
                  bsStyle="primary"
                  onClick={onClose}
                >
                  OK
                </Button>
              </div>
            </div>
          );
        },
        childrenElement: () => <div />,
        closeOnEscape: true,
        closeOnClickOutside: true,
      };
      confirmAlert(options);
    }
  };

  componentWillUnmount = () => {
    const { doReleaseForm } = this.props;
    const { editionId } = this.state;
    // release the block so that other users may work on the lead data
    doReleaseForm(editionId);
    if (this.autoSave) {
      window.clearInterval(this.autoSave);
    }
    if (this.fontCheck) {
      window.clearInterval(this.fontCheck);
    }
    if (this.uploadCheck) {
      window.clearInterval(this.uploadCheck);
    }
    if (this.waitForSaving) {
      window.clearTimeout(this.waitForSaving);
    }
  };

  setActiveHeight = height => {
    const { activeHeight } = this.state;
    if (activeHeight !== height) {
      this.setState({
        activeHeight: height,
      });
    }
  };

  showTour = () => {
    this.setState({
      showTour: true,
    });
  };

  closeTour = () => {
    this.setState({
      showTour: false,
    });
  };

  changeEditionTitle = newTitle => {
    const { setVariables } = this.props;
    setVariables('unsavedtrue');
    this.setState(
      {
        editionTitle: newTitle,
        unsaved: true,
      },
      () => {}
    );
  };

  changeEditionSettings = newSettings => {
    const { setVariables } = this.props;
    setVariables('unsavedtrue');
    this.setState(
      {
        editionSettings: newSettings,
        unsaved: true,
      },
      () => {}
    );
  };

  getCampaigns = () => {
    const { getCampaignsR, user } = this.props;
    let filters = '';
    if (may(ROLE_ROOT_EMPLOYEE, user.roles)) {
      filters = FILTERS.SAVE_TO_FOR_ROOT_EMPLOYEES;
    }
    getCampaignsR(filters);
  };

  getTourSteps = () => {
    const {
      intl: { messages },
    } = this.props;
    return tourSteps(messages);
  };

  checkDataPolicy = () => {
    const { editionSettings } = this.state;
    const responsible = _.find(editionSettings, ['name', 'datenschutzverantwortlicher'])?.value || '';
    const companyAssociation = _.find(editionSettings, ['name', 'datenschutzfirma'])?.value || '';
    const street = _.find(editionSettings, ['name', 'datenschutzstrasse'])?.value || '';
    const zipCode = _.find(editionSettings, ['name', 'datenschutzplz'])?.value || '';
    const city = _.find(editionSettings, ['name', 'datenschutzort'])?.value || '';
    const email = _.find(editionSettings, ['name', 'datenschutzmail'])?.value || '';
    const phoneNumber = _.find(editionSettings, ['name', 'datenschutztelefon'])?.value || '';
    const dataProtectionAuthority = _.find(editionSettings, ['name', 'datenschutzbehoerde'])?.value || '';

    return (
      responsible !== '' &&
      companyAssociation !== '' &&
      street !== '' &&
      zipCode !== '' &&
      city !== '' &&
      email !== '' &&
      phoneNumber !== '' &&
      dataProtectionAuthority !== ''
    );
  };

  checkImpressum = () => {
    const { editionSettings } = this.state;
    const responsibleForContent = _.find(editionSettings, ['name', 'impressumverantwortlicher'])?.value || '';
    const companyAssociation = _.find(editionSettings, ['name', 'impressumfirma'])?.value || '';
    const street = _.find(editionSettings, ['name', 'impressumstrasse'])?.value || '';
    const zipCode = _.find(editionSettings, ['name', 'impressumplz'])?.value || '';
    const city = _.find(editionSettings, ['name', 'impressumort'])?.value || '';
    const email = _.find(editionSettings, ['name', 'impressummail'])?.value || '';
    const phoneNumber = _.find(editionSettings, ['name', 'impressumtelefon'])?.value || '';

    return (
      responsibleForContent !== '' &&
      companyAssociation !== '' &&
      street !== '' &&
      zipCode !== '' &&
      city !== '' &&
      email !== '' &&
      phoneNumber !== ''
    );
  };

  getDataPolicyWarning = option => {
    const {
      intl: { messages },
    } = this.props;
    const options = {
      customUI: ({ onClose }) => {
        return (
          <div className="react-confirm-alert-body">
            <h1>{messages.data_policy_info}</h1>
            {option === 'manual' ? (
              <p>{messages.fill_data_policy_before_save}</p>
            ) : (
              <p>{messages.fill_data_policy_before_autosave}</p>
            )}
            <div className="react-confirm-alert-button-group" style={{ width: '100%', margin: '0 auto' }}>
              <Button
                style={{ marginRight: '0px', minWidth: '100%' }}
                onClick={() => {
                  onClose();
                  this.showSettingsF();
                }}
              >
                <i className="far fa-cog" /> <span> {messages.editor.settings}</span>
              </Button>
              <Button
                style={{ margin: '0 auto', marginTop: '20px', width: '100px' }}
                bsStyle="primary"
                onClick={onClose}
              >
                OK
              </Button>
            </div>
          </div>
        );
      },
      childrenElement: () => <div />,
      closeOnEscape: true,
      closeOnClickOutside: true,
    };
    confirmAlert(options);
  };

  showSettingsF = () => {
    this.setState({
      showSettings: true,
    });
  };

  closeSettingsF = () => {
    this.setState({ showSettings: false });
  };

  setSelectedColor = (
    blockId,
    colorId,
    currentPage,
    info,
    sectionTag,
    pageTag,
    blockContainer,
    stageTag,
    attributeName
  ) => {
    this.setState(prevState => {
      const _colorSettings = _cloneDeep(prevState.colorSettings);
      const _currentPage = 'page_' + (currentPage * 1 + 1);
      const existingBlockIndex = _.findIndex(
        _colorSettings,
        setting => setting.id === blockId && setting.name === attributeName
      );
      const existingPageIndex = _.findIndex(
        _colorSettings,
        setting => setting.id === _currentPage && setting.activeSection === blockId && setting.name === attributeName
      );
      const existingStageIndex = _.findIndex(
        _colorSettings,
        setting => setting.id === 'stage' && setting.activeSection === blockId && setting.name === attributeName
      );

      if (pageTag) {
        info = 'page';
        const colorPageSetting = {
          id: _currentPage,
          color: colorId,
          type: info,
          page: currentPage,
          activeSection: blockId,
          blockContainer,
          name: attributeName,
        };
        if (existingPageIndex !== -1) {
          _colorSettings[existingPageIndex] = colorPageSetting; // replace old entry
        } else {
          _colorSettings.push(colorPageSetting); // add new entry
        }
      }

      if (sectionTag) {
        info = 'section';
        const colorBlockSetting = {
          id: blockId,
          color: colorId,
          type: info,
          page: currentPage,
          activeSection: blockId,
          blockContainer,
          name: attributeName,
        };
        if (existingBlockIndex !== -1) {
          _colorSettings[existingBlockIndex] = colorBlockSetting; // replace old entry
        } else {
          _colorSettings.push(colorBlockSetting); // add new entry
        }
      }

      if (stageTag) {
        info = 'stage';
        const colorStageSetting = {
          id: 'stage',
          color: colorId,
          type: info,
          page: currentPage,
          activeSection: blockId,
          blockContainer,
          name: attributeName,
        };
        if (existingStageIndex !== -1) {
          _colorSettings[existingStageIndex] = colorStageSetting; // replace old entry
        } else {
          _colorSettings.push(colorStageSetting); // add new entry
        }
      }

      return { colorSettings: _colorSettings };
    });
  };

  deleteSelectedColor = blockId => {
    this.setState(prevState => {
      const { colorSettings } = prevState;
      let _colorSettings = _.cloneDeep(colorSettings);

      // Step 1: Find and delete color of section or page on page or container
      _colorSettings = _.filter(_colorSettings, x => x.activeSection !== blockId && x.blockContainer !== blockId);

      // Step 2: Return updated _colorSettings
      return { colorSettings: _colorSettings };
    });
  };

  getColorSettings = id => {
    // get color for section
    const { colorSettings } = this.state;
    let sectionColorClasses = '';

    if (colorSettings) {
      let filteredSectionColorSettings = [];
      filteredSectionColorSettings = _.filter(colorSettings, setting => setting.id === id);

      _forEach(filteredSectionColorSettings, (filteredSectionColorSetting, i) => {
        sectionColorClasses = sectionColorClasses + ' ' + filteredSectionColorSetting.color;
      });
    }

    return sectionColorClasses;
  };

  setFontSizeSettings = (blockId, fontSizeClass) => {
    this.setState(prevState => {
      const _fontSizeSettings = _cloneDeep(prevState.fontSizeSettings);
      _fontSizeSettings[blockId] = fontSizeClass;
      return { fontSizeSettings: _fontSizeSettings };
    });
  };

  render() {
    const {
      globalCss,
      appIntl,
      user,
      edition,
      history,
      savingR,
      unsavedR,
      campaignOptionsR,
      printingR,
      previewingR,
      printx,
      exportingR,
      activeForms,
    } = this.props;
    const {
      campaign,
      editionTitle,
      editionId,
      itemName,
      saving,
      usedMedia,
      currentPage,
      toolbar,
      pageAmount,
      templateSettings,
      contentPages,
      templateBlocks,
      pasteData,
      copiedBlock,
      showTour,
      raster,
      filesUploading,
      blockContainer,
      activeHeight,
      navEntry,
      item,
      publisherInfo,
      editionSettings,
      goToLastPageState,
      resetPageState,
      updateBlocksState,
      uploadError,
      showSettings,
      colorSettings,
      fontSizeSettings,
    } = this.state;

    const pagesAmount = contentPages.length;
    const pageNames = [];
    const pageFinder = [];
    const pagePreview = [];
    const handledOversizeIds = [];
    const ratio = templateSettings.height / templateSettings.width;
    let previewStyles = {
      height: (100 * ratio).toFixed(0) + 'px',
    };
    const pagePreviewZoom = 100 / templateSettings.width;
    let pagePreviewStyles;
    let pageSpace = 0;
    let errorsCount = 0;
    let errorsText = '';
    let lastPageNumber = templateSettings.last_page_number;
    const {
      intl: { messages },
    } = this.props;
    if (lastPageNumber === 'last') {
      lastPageNumber = pagesAmount;
    }
    let imageExport = false;
    if (templateSettings.export_type.value === '000000000000000000000001') {
      imageExport = true;
    }

    let website = false;
    if (templateSettings.export_type.value === '000000000000000000000003') {
      website = true;
    }
    const hidePreview =
      this.props.edition?.item?.hide_preview === true ? this.props.edition?.item?.hide_preview : false;

    let pageSizeIndex = _.findIndex(templateSettings.page_sizes, ['pages_amount', contentPages.length]);
    if (pageSizeIndex < 0) {
      // no sizes for this page, let check if we have a default entry
      pageSizeIndex = _.findIndex(templateSettings.page_sizes, ['pages_amount', 0]);
      if (pageSizeIndex < 0) {
        // also no default entry, so give the user a hint
        alert(messages.editor.no_page_definitions_found);
      }
    }
    const pageWidths = templateSettings.page_sizes[pageSizeIndex].page_widths.split(',');
    const pageHeights = templateSettings.page_sizes[pageSizeIndex].page_heights.split(',');
    const topPaddings = templateSettings.page_sizes[pageSizeIndex].top_paddings
      ? templateSettings.page_sizes[pageSizeIndex].top_paddings.split(',')
      : null;
    const rightPaddings = templateSettings.page_sizes[pageSizeIndex].right_paddings
      ? templateSettings.page_sizes[pageSizeIndex].right_paddings.split(',')
      : null;
    const bottomPaddings = templateSettings.page_sizes[pageSizeIndex].bottom_paddings
      ? templateSettings.page_sizes[pageSizeIndex].bottom_paddings.split(',')
      : null;
    const leftPaddings = templateSettings.page_sizes[pageSizeIndex].left_paddings
      ? templateSettings.page_sizes[pageSizeIndex].left_paddings.split(',')
      : null;
    let paddingStyle = '';

    _.forEach(contentPages, (contentPage, i) => {
      let pageFinderStyle = '';
      let pageActive = '';
      let previewClass = 'pagePreview ';
      let pageUpDisable = '';
      let pageDownDisable = '';
      let pageAddDisable = '';
      let pageSubDisable = false;
      let locked = '';
      let endlessIcon = '';

      if (
        (templateSettings.format.divide_pages === 'half' && pagesAmount / 2 === i) || // half of document pages
        (templateSettings.format.divide_pages === 'single' && i > 0) || // all but first page
        (templateSettings.format.divide_pages === 'double' &&
          templateSettings.format.order_pages !== 'first-last-single' &&
          i % 2 === 0 &&
          i > 0) || // even
        (templateSettings.format.order_pages === 'first-last-single' &&
          (i === 1 || i + 1 === pagesAmount || i % 2 !== 0)) ||
        (templateSettings.format.divide_pages === 'double-single' && (i === 2 || i > 3))
      ) {
        const sepClass = 'sep_' + templateSettings.format.divide_pages + ' ' + templateSettings.format.order_pages;
        if (templateSettings.format.order_pages === 'down') {
          pageFinder.unshift(
            <span key={sepClass} className={sepClass}>
              &nbsp;
            </span>
          );
        } else {
          pageFinder.push(
            <span key={sepClass} className={sepClass}>
              &nbsp;
            </span>
          );
        }
        pageNames.push(<MenuItem key="MenuItem1" divider />);
        pageFinderStyle += ` first`;
      }
      if (pagesAmount / 2 === i + 1) {
        if (templateSettings.format.order_pages === 'down-up') {
          pageFinderStyle += ` first`;
        } else if (templateSettings.format.divide_pages === 'half') {
          pageFinderStyle += ` last`;
        }
      }
      if (pagesAmount === i + 1) {
        if (templateSettings.format.order_pages === 'down') {
          pageFinderStyle += ` first`;
        } else {
          pageFinderStyle += ` last`;
        }
      }
      if (i === 0) {
        if (
          templateSettings.format.order_pages === 'down-up' ||
          templateSettings.format.order_pages ===
            'down' /* templateSettings.format.order_pages === 'double-firstDown' */
        ) {
          pageFinderStyle += ` last`;
        } else {
          pageFinderStyle += ` first`;
        }
      }
      if (
        templateSettings.format.divide_pages === 'single' ||
        (templateSettings.format.order_pages === 'first-last-single' && (i === 0 || i === pagesAmount))
      ) {
        if (!pageFinderStyle.includes('first')) {
          pageFinderStyle += ` first`;
        }
        if (!pageFinderStyle.includes('last')) {
          pageFinderStyle += ` last`;
        }
      }
      if (templateSettings.format.divide_pages === 'double') {
        if (templateSettings.format.order_pages === 'down') {
          if (i % 2 === 0) {
            pageFinderStyle = ` last`;
          } else {
            pageFinderStyle = ` first`;
          }
        } else if (templateSettings.format.order_pages === 'first-last-single') {
          if (i === 0) {
            pageFinderStyle = `first last`;
          } else if (i + 1 === pagesAmount) {
            pageFinderStyle = `last first`;
          } else if (i % 2 !== 0) {
            pageFinderStyle = ` first`;
          } else {
            pageFinderStyle = ` last`;
          }
        } else {
          if (i % 2 === 0) {
            pageFinderStyle = ` first`;
          } else {
            pageFinderStyle = ` last`;
          }
          if (templateSettings.format.order_pages === 'firstDown-up') {
            if (i === 0) {
              pageFinderStyle = ` last`;
            } else if (i === 1) {
              pageFinderStyle = ` first`;
            }
          }
        }
      }
      if (templateSettings.format.divide_pages === 'double-single') {
        if (!pageFinderStyle.includes('first') && (i === 1 || i === 2)) {
          pageFinderStyle = ` first`;
        }
        if (!pageFinderStyle.includes('last') && (i === 0 || i === 3)) {
          pageFinderStyle = ` last`;
        } else if (i > 3) {
          pageFinderStyle = `first last`;
        }
      }

      if (i === currentPage) {
        pageFinderStyle += ` active`;
        pageActive = 'active';
      }
      if (
        (templateSettings.format.order_pages === 'down-up' && i < pagesAmount / 2) ||
        templateSettings.format.order_pages === 'down' ||
        (templateSettings.format.order_pages === 'firstDown-up' && i === 1) ||
        (templateSettings.format.order_pages === 'double-firstDown' && i === 1)
      ) {
        pageFinder.unshift(
          <span key={'pageFinderStyleDown' + i} className={pageFinderStyle} onClick={this.setCurrentPage}>
            {contentPage.page_number}
          </span>
        );
      } else {
        pageFinder.push(
          <span key={'pageFinderStyle' + i} className={pageFinderStyle} onClick={this.setCurrentPage}>
            {contentPage.page_number}
          </span>
        );
      }
      previewClass += pageActive;
      const pageNameIndex = getPageNameIndex(i, templateSettings, pagesAmount);
      const paddingLeft =
        templateSettings.pages[pageNameIndex].padding_left !== null
          ? [templateSettings.pages[pageNameIndex].padding_left]
          : leftPaddings
          ? parseFloat(leftPaddings[parseInt(i, 10)] ? leftPaddings[parseInt(i, 10)] : leftPaddings[0])
          : null;
      const paddingRight =
        templateSettings.pages[pageNameIndex].padding_right !== null
          ? [templateSettings.pages[pageNameIndex].padding_right]
          : rightPaddings
          ? parseFloat(rightPaddings[parseInt(i, 10)] ? rightPaddings[parseInt(i, 10)] : rightPaddings[0])
          : null;
      const endless = !!templateSettings.pages[pageNameIndex].endless;
      if (paddingLeft) {
        paddingStyle += '.seite_' + (i + 1) + ' article.typesetting{padding-left: ' + paddingLeft + 'px}';
      }
      if (paddingRight) {
        paddingStyle += '.seite_' + (i + 1) + ' article.typesetting{padding-right: ' + paddingRight + 'px}';
      }
      pagePreviewStyles = {
        width: templateSettings.width + 'px',
        height: templateSettings.height + 'px',
        transform: `scale(${pagePreviewZoom})`,
        transformOrigin: 'top left',
      };
      if (website || endless) {
        pagePreviewStyles = {
          width: templateSettings.width + 'px',
          minHeight: templateSettings.height + 'px',
          height: 'auto',
          transform: `scale(${pagePreviewZoom})`,
          transformOrigin: 'top left',
        };

        previewStyles = {
          height: activeHeight * pagePreviewZoom + 'px',
          minHeight: (100 * ratio).toFixed(0) + 'px',
        };
      }
      pageSpace = templateSettings.pages[pageNameIndex].page_space;
      pageNames.push(
        <MenuItem key={'MenuItem2_' + i} className={pageActive} eventKey={contentPage.page_number}>
          {getNameInCurrentLanguage(templateSettings.pages[pageNameIndex].name, appIntl)}
        </MenuItem>
      );

      const pageButtons = [];
      const {
        intl: { messages },
      } = this.props;

      let checkMoveUp = false;
      let moveUpBy = 1;
      const counterUp = i - 1;
      for (let x = counterUp; x >= 0; x--) {
        const pageNameIndexForIt = getPageNameIndex(x, templateSettings, pagesAmount);
        if (templateSettings.pages[pageNameIndexForIt] && templateSettings.pages[pageNameIndexForIt].fixed === false) {
          checkMoveUp = true;
          moveUpBy = i - x;
          x = -1;
        }
      }

      let checkMoveDown = false;
      let moveDownBy = 1;
      const counterDown = i + 1;
      let checkAddAndRemovePage = true;
      for (let x = counterDown; x <= pagesAmount; x++) {
        const pageNameIndexForIt = getPageNameIndex(x, templateSettings, pagesAmount);
        if (templateSettings.pages[pageNameIndexForIt] && templateSettings.pages[pageNameIndexForIt].fixed === false) {
          checkMoveDown = true;
          moveDownBy = x - i;
          x = pagesAmount + 1;
        }
      }
      for (let y = counterDown; y <= pagesAmount; y++) {
        const pageNameIndexForIt2 = getPageNameIndex(y, templateSettings, pagesAmount);
        if (templateSettings.pages[pageNameIndexForIt2] && templateSettings.pages[pageNameIndexForIt2].fixed === true) {
          checkAddAndRemovePage = false;
          y = pagesAmount + 1;
        }
      }

      if (
        (templateSettings.last_page_fixed && i === parseInt(lastPageNumber, 10) - 1) ||
        (templateSettings.first_page_fixed && i === templateSettings.first_page_number) ||
        (templateSettings.last_page_fixed && i === parseInt(lastPageNumber, 10)) ||
        i === 0 ||
        templateSettings.pages[pageNameIndex].fixed === true ||
        checkMoveUp === false
      ) {
        pageUpDisable = true;
      }

      const targetUp = i - moveUpBy;
      pageButtons.push(
        <Button
          key="ButtonPageUp"
          onClick={this.changePages}
          data-page={i}
          disabled={pageUpDisable}
          data-target={targetUp}
          data-tip={messages.editor.by + moveUpBy + messages.editor.move_up}
          data-for="right"
        >
          <i data-page={i} data-target={targetUp} className="far fa-angle-up" />
        </Button>
      );

      if (
        (templateSettings.first_page_fixed && i === templateSettings.first_page_number - 1) ||
        (templateSettings.last_page_fixed && i === parseInt(lastPageNumber, 10) - 1) ||
        (templateSettings.last_page_fixed && i === parseInt(lastPageNumber, 10) - 2) ||
        i === pageAmount - 1 ||
        templateSettings.pages[pageNameIndex].fixed === true ||
        checkMoveDown === false
      ) {
        pageDownDisable = true;
      }
      if (
        (templateSettings.first_page_fixed && i === templateSettings.first_page_number - 1) ||
        (templateSettings.last_page_fixed && i === parseInt(lastPageNumber, 10) - 1) ||
        templateSettings.pages[pageNameIndex].fixed === true
      ) {
        locked = <i className="fa fa-lock" data-tip={messages.editor.side_position_fixed} data-for="right" />;
      }
      if (endless) {
        endlessIcon = <i className="fa fa-infinity" data-tip={messages.editor.generated_in_pdf} data-for="right" />;
      }
      const blockThumb = [];
      const paddingTop =
        templateSettings.pages[pageNameIndex].padding_top !== null
          ? parseFloat(templateSettings.pages[pageNameIndex].padding_top)
          : topPaddings
          ? parseFloat(topPaddings[i] ? topPaddings[i] : topPaddings[0])
          : 0;
      const paddingBottom =
        templateSettings.pages[pageNameIndex].padding_bottom !== null
          ? parseFloat(templateSettings.pages[pageNameIndex].padding_bottom)
          : bottomPaddings
          ? parseFloat(bottomPaddings[i] ? bottomPaddings[i] : bottomPaddings[0])
          : 0;
      const rasterV = Math.floor(
        (templateSettings.height - paddingTop - paddingBottom) / templateSettings.pages[pageNameIndex].page_space
      );

      if (contentPage.blocks.length > 0) {
        _.forEach(contentPage.blocks, (block, index) => {
          const sectionClassPre = block.template ? block.template.match(/(?:data-section=").*?(?=\s*")/gm) : [''];
          const sectionClass = sectionClassPre ? ' ' + sectionClassPre[0].replace('data-section="', '') : '';
          let offsetTop = block.top;
          let bottomClass = '';
          const blockHeight = rasterV * block.page_space_needed;
          if (offsetTop === -1 || offsetTop === null || typeof offsetTop === 'undefined') {
            offsetTop = getBlockTopFromRaster(raster, i, block.id);
            offsetTop = offsetTop < 0 ? 0 : offsetTop;
          }
          const vPos = paddingTop + offsetTop * rasterV;
          const verticalPosition = {
            top: vPos + 'px',
            height: blockHeight + 'px',
          };

          if (block.bottom) {
            verticalPosition.bottom = block.bottom + 'px';
            bottomClass = ' page-bottom';
          }
          let multiplePagesBlockClass = '';
          let containerClass = '';
          if (block.container) {
            containerClass = ' block-container';
          }
          let innerClass = '';
          if (block.inline_block) {
            innerClass = ' inline-block col-section';
          }
          if (typeof block.oversize !== 'undefined' && block.oversize !== null && block.oversize !== 0) {
            let copyBlockIndex = 0;
            multiplePagesBlockClass = ' page-span-' + block.oversize.toString() + ' ' + block.oversize_position;
            if (handledOversizeIds.findIndex(element => element === block.oversizeId) === -1) {
              copyBlockIndex = contentPages[block.links_to].blocks.findIndex(element => element.id === block.id);
              // check if already connected
              if (contentPages[block.links_to].blocks[copyBlockIndex].params !== block.params) {
                contentPages[block.links_to].blocks[copyBlockIndex].params = block.params;
              }
              handledOversizeIds.push(block.id);
            }
          }
          let heightClass = '';
          if (block && block.page_space_needed) {
            heightClass = ' height_' + block.page_space_needed;
          }
          const positionX = block.position_x;
          const positionY = block.position_y;

          let sectionColorClasses = '';
          let filteredSectionColorSettings = [];
          filteredSectionColorSettings = _.filter(colorSettings, setting => setting.id === block?.id);

          _forEach(filteredSectionColorSettings, (filteredSectionColorSetting, i) => {
            sectionColorClasses = sectionColorClasses + ' ' + filteredSectionColorSetting.color;
          });

          let sectionFontSize = ' ';
          sectionFontSize = ' ' + (fontSizeSettings[(block?.id)] ?? '') + ' ';

          blockThumb.push(
            <section
              data-id={block.id}
              key={'prev_bl_' + block.id}
              style={verticalPosition}
              className={
                multiplePagesBlockClass +
                heightClass +
                containerClass +
                bottomClass +
                innerClass +
                sectionClass +
                sectionColorClasses +
                sectionFontSize
              }
            >
              {block.template &&
              (block.template.includes('draggable') || block.template.includes('allowdraganddrop')) ? (
                <div
                  class="react-draggable"
                  style={{ transform: 'translate(' + positionX + 'px, ' + positionY + 'px)' }}
                >
                  <PreviewBlock
                    data={block}
                    index={i + '.' + index}
                    key={i + '.' + index}
                    rasterV={rasterV}
                    setSelectedColor={this.setSelectedColor}
                    currentPage={i}
                    updateParam={this.updateParam}
                    getColorSettings={this.getColorSettings}
                    setFontSizeSettings={this.setFontSizeSettings}
                    fontSizeSettings={this.state.fontSizeSettings}
                    updateInnerBlock={this.updateInnerBlock}
                    usedMedia={usedMedia}
                  />
                </div>
              ) : (
                <PreviewBlock
                  data={block}
                  index={i + '.' + index}
                  key={i + '.' + index}
                  rasterV={rasterV}
                  setSelectedColor={this.setSelectedColor}
                  currentPage={i}
                  updateParam={this.updateParam}
                  getColorSettings={this.getColorSettings}
                  setFontSizeSettings={this.setFontSizeSettings}
                  fontSizeSettings={this.state.fontSizeSettings}
                  updateInnerBlock={this.updateInnerBlock}
                  usedMedia={usedMedia}
                />
              )}
            </section>
          );

          /* New Error Handler */
          _.forEach(block.params, blockParams => {
            if (blockParams.errors && blockParams.errors.overflow === true) {
              errorsCount++;
              errorsText +=
                messages.editor.page + ' ' + contentPage.page_number + ': ' + messages.editor.text_overflow + '<br>';
            }
          });

          /* Error Handler within Blockcontainer */
          _.forEach(block.blocks, blockContainer => {
            _.forEach(blockContainer.params, blockContainerParams => {
              if (blockContainerParams.errors && blockContainerParams.errors.overflow === true) {
                errorsCount++;
                errorsText +=
                  messages.editor.page + ' ' + contentPage.page_number + ': ' + messages.editor.text_overflow + '<br>';
              }
            });
          });

          if (block.errors && block.errors.default_image) {
            errorsCount++;
            errorsText +=
              messages.editor.page +
              ' ' +
              contentPage.page_number +
              ': ' +
              messages.editor.sample_picture_found +
              '<br>';
          }
        });
      }
      const targetDown = i + moveDownBy;

      pageButtons.push(
        <Button
          key="ButtonPageDown"
          onClick={this.changePages}
          data-page={i}
          disabled={pageDownDisable}
          data-target={targetDown}
          data-tip={messages.editor.by + moveDownBy + messages.editor.move_down}
          data-for="right"
        >
          <i data-page={i} data-target={targetDown} className="far fa-angle-down" />
        </Button>
      );
      pagePreview.push(
        <div key={'pagePreview_pageEdit' + i} className="pageEdit">
          <ButtonGroup bsSize="xsmall" vertical>
            {pageButtons}
          </ButtonGroup>
        </div>
      );
      const previewIndex = 'page-preview-'.i;
      const evenOdd = (i + 1) % 2 === 0 ? ' even' : ' odd';
      const _page = 'page_' + (i * 1 + 1);

      let pageColorClasses = '';
      let filteredColorSettings = [];
      filteredColorSettings = _.filter(colorSettings, setting => setting.id === _page);
      _forEach(filteredColorSettings, (filteredColorSetting, i) => {
        pageColorClasses = pageColorClasses + ' ' + filteredColorSetting.color;
      });

      pagePreview.push(
        <div
          key={'pagePreview_previewPage' + i}
          id={previewIndex}
          className={previewClass + ' previewPage'}
          style={previewStyles}
        >
          <a data-page={i} onClick={this.selectPage} />
          <div
            className={'page seite_' + (i + 1) + ' anzahl_' + pageAmount + evenOdd + pageColorClasses}
            style={pagePreviewStyles}
          >
            {blockThumb}
          </div>
        </div>
      );
      pagePreview.push(
        <div className="pageName" style={{ borderBottom: '1px solid #999' }}>
          <b>{contentPage.page_number}:</b>{' '}
          {getNameInCurrentLanguage(templateSettings.pages[pageNameIndex].name, appIntl)} {locked} {endlessIcon}
        </div>
      );
      if (
        i < pageAmount - 1 ||
        (i === pageAmount - 1 &&
          (templateSettings.last_page_number !== 'last' || !templateSettings.last_page_fixed) &&
          pageAmount < templateSettings.page_max_amount) ||
        (pageAmount === 1 && templateSettings.page_max_amount > 1)
      ) {
        if (
          templateSettings.last_page_fixed &&
          i + templateSettings.page_add_step === parseInt(lastPageNumber, 10) - 1
        ) {
          // you are not allowed to delete the last pages
          pageSubDisable = true;
          pageAddDisable = true;
        }
        if (
          templateSettings.page_max_amount === pageAmount ||
          templateSettings.page_add_step === 0 ||
          (lastPageNumber === pageAmount && lastPageNumber === i + 1) ||
          checkAddAndRemovePage === false
        ) {
          pageAddDisable = true;
        }

        if (
          templateSettings.page_add_step === 0 || // if you cant add pages, you are not allowed to delete
          templateSettings.page_min_amount === pageAmount || // if pagenumber min amount is equal to page amount, you are not allowed to delete
          pageAmount - templateSettings.page_add_step < templateSettings.page_min_amount || // The number of "add_step_page" is deleted, so it must not fall below the minimum number"
          i + 1 === pageAmount || // you are not allowed to delete a page which not exists
          checkAddAndRemovePage === false || // its about fixed pages
          (templateSettings.page_add_step > 1 && (i + 1) % templateSettings.page_add_step !== 0) // you may only delete the first page of the "added page step" if page_add_step > 1.
        ) {
          pageSubDisable = true;
        }
        const titleAdd = templateSettings.page_add_step + messages.editor.add_page;
        const titleSub = templateSettings.page_add_step + messages.editor.remove_page;
        pagePreview.push(
          <div key={'pageAddSub' + i} className="pageAddSub">
            <ButtonGroup bsSize="xsmall">
              <Button
                key="ButtonAddPage"
                data-tip={titleAdd}
                data-for="right"
                disabled={pageAddDisable}
                data-page={i}
                onClick={this.addPages}
              >
                <i className="far fa-plus" data-page={i} /> {templateSettings.page_add_step}
              </Button>
              <Button
                key="ButtonRemovePage"
                data-tip={titleSub}
                data-for="right"
                disabled={pageSubDisable}
                data-page={i}
                onClick={this.removePages}
              >
                <i className="far fa-minus" data-page={i} /> {templateSettings.page_add_step}
              </Button>
            </ButtonGroup>
          </div>
        );
      }
    });

    const tourSteps = this.getTourSteps();
    const emergency = true;
    return (
      <div className="cc-editor">
        {activeForms.allowEdit && !activeForms.requestFormReleasePending ? (
          <></>
        ) : !may('ROLE_ROOT_EMPLOYEE', user.roles) ? (
          <div className="blockedByOverflow">
            <div className="blockedByOverflowContent">
              {user.username === activeForms.blockedBy ? (
                <p>{messages.editor.already_edited_in_another_tab}</p>
              ) : (
                <p>{messages.already_edited_by_another_user}</p>
              )}
              {!!activeForms.blockedBy && (
                <div className="pull-right m-r-10">
                  <ActiveFormsToolbar
                    formStatus=""
                    entityChanged=""
                    formType="editor"
                    formId={editionId}
                    emergency={emergency}
                  />
                </div>
              )}
            </div>
          </div>
        ) : (
          <></>
        )}

        {unsavedR && <Beforeunload onBeforeunload={() => messages.editor.unsaved_changes} />}
        {paddingStyle.length > 0 ? <EditorApplyStyles styles={paddingStyle} update /> : null}
        <Tour
          steps={tourSteps}
          goToStep={0}
          isOpen={showTour}
          onRequestClose={this.closeTour}
          lastStepNextButton={
            <Button key="ButtonLastStepNext" onClick={this.closeTour}>
              {messages.editor.i_have_it}
            </Button>
          }
        />
        <EditorApplyStyles styles={globalCss} />
        {window.whiteLabelSettings.css ? <EditorApplyStyles styles={window.whiteLabelSettings.css} /> : null}
        {item.css ? <EditorApplyStyles styles={item.css} /> : null}
        {item.vendor ? <EditorApplyStyles styles={item.vendor.css} /> : null}
        {templateSettings.target ? <EditorApplyStyles styles={templateSettings.target.css} /> : null}

        <EditorApplyStyles styles={templateSettings.css} />

        <EditorToolbar
          user={user}
          imageExport={imageExport}
          website={website}
          editionId={editionId}
          appIntl={appIntl}
          options={toolbar}
          updateOptions={this.setToolbarOptions}
          currentPage={currentPage}
          pageAmount={pageAmount}
          updateCurrentPage={this.updateCurrentPage}
          pageNames={pageNames}
          saveContent={this.saveContent}
          init={templateSettings.initialized}
          initTemplate={this.initTemplate}
          savingR={savingR}
          saveTo={this.saveTo}
          unsavedR={unsavedR}
          campaign={campaign}
          editionTitle={editionTitle}
          itemName={itemName}
          changeEditionTitle={this.changeEditionTitle}
          campaignOptionsR={campaignOptionsR}
          getCampaigns={this.getCampaigns}
          edition={edition}
          showWebPreview={this.webPreview}
          history={history}
          editionSettings={editionSettings}
          changeEditionSettings={this.changeEditionSettings}
          printingR={printingR}
          previewingR={previewingR}
          printx={printx}
          exportingR={exportingR}
          placeholder={templateSettings.placeholder}
          checkDataPolicy={this.checkDataPolicy}
          checkImpressum={this.checkImpressum}
          hidePreview={hidePreview}
          getDataPolicyWarning={this.getDataPolicyWarning}
          showSettings={showSettings}
          showSettingsF={this.showSettingsF}
          closeSettingsF={this.closeSettingsF}
        />

        <EditorCanvas
          appIntl={appIntl}
          currentPage={parseInt(currentPage, 10)}
          raster={raster}
          templateSettings={templateSettings}
          templateBlocks={templateBlocks}
          contentPages={contentPages}
          updateParam={this.updateParam}
          updateParams={this.updateParams}
          updateInnerBlock={this.updateInnerBlock}
          updateBlock={this.updateBlock}
          updateCurrentPage={this.updateCurrentPage}
          pageFinder={pageFinder}
          pagePreview={pagePreview}
          insertContentBlock={this.insertContentBlock}
          changeBlock={this.changeContentBlock}
          removeContentBlock={this.removeContentBlock}
          resetBlock={this.resetContentBlock}
          copyBlock={this.copyContentBlock}
          copiedBlock={copiedBlock}
          pasteBlock={this.pasteContentBlock}
          resizeBlock={this.resizeContentBlock}
          pasteData={pasteData}
          errorsCount={errorsCount}
          errorsText={errorsText}
          filesUploading={filesUploading}
          imageUpload={this.uploadImage}
          saving={saving}
          usedMedia={usedMedia}
          blockContainer={blockContainer}
          setBlockContainer={this.selectBlockContainer}
          unsetBlockContainer={this.unselectBlockContainer}
          website={website}
          showTour={this.showTour}
          setActiveHeight={this.setActiveHeight}
          navEntry={navEntry}
          resetNavEntry={this.resetNavEntry}
          init={templateSettings.initialized}
          pageWidths={pageWidths}
          pageHeights={pageHeights}
          topPaddings={topPaddings}
          rightPaddings={rightPaddings}
          bottomPaddings={bottomPaddings}
          leftPaddings={leftPaddings}
          publisherInfo={publisherInfo}
          rootUser={may('ROLE_ROOT_EMPLOYEE', user.roles)}
          wlAdminUser={may('ROLE_WL_ADMIN', user.roles)}
          isSubmission={edition?.is_submission}
          onlyWhitelabelAdminCanModifyBlocks={edition?.only_whitelabel_admin_can_modify_block_ids ?? []}
          savingR={savingR}
          saveDescription={this.saveDescription}
          goToLastPageState={goToLastPageState}
          goToLastPage={this.goToLastPage}
          resetPageState={resetPageState}
          resetPage={this.resetPage}
          updateBlocksState={updateBlocksState}
          uploadError={uploadError}
          updateSpecialBlockAttributes={this.updateSpecialBlockAttributes}
          initDefaultPosition={this.initDefaultPosition}
          setSelectedColor={this.setSelectedColor}
          colorSettings={this.state.colorSettings}
          getColorSettings={this.getColorSettings}
          deleteSelectedColor={this.deleteSelectedColor}
          fontSizeSettings={fontSizeSettings}
          setFontSizeSettings={this.setFontSizeSettings}
        />
        <ReactTooltip id="left" place="left" effect="solid" />
        <ReactTooltip id="right" place="right" effect="solid" />
        <ReactTooltip id="top" place="top" effect="solid" />
        <GrowlComponent />
      </div>
    );
  }
}
Editor.defaultProps = {};

const mapDispatchToProps = dispatch => {
  return {
    doBlockForm: id => {
      dispatch(blockForm('editor', id));
    },
    doReleaseForm: id => {
      dispatch(releaseForm('editor', id));
    },
  };
};

const mapStateToProps = state => {
  return {
    appIntl: state.intl,
    user: state.login.user,
    activeForms: state.activeForms,
  };
};

export default withRouter(
  injectIntl(
    connect(
      mapStateToProps,
      mapDispatchToProps
    )(Editor)
  )
);
